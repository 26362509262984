/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */
import LabelUtil from '../components/util/LabelUtil';

export const htmlTooltipStyles = (theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    borderRadius: 10,
    boxShadow: '0 0 20px -7px black',
    padding: 30,
    maxWidth: 420,
    '& h4': {
      fontFamily: theme.typography.roboto,
      fontSize: 16,
      lineHeight: '1.1'
    },
    '& p': {
      ...theme.typography.body1,
      fontSize: 14,
      lineHeight: '1.1'
    }
  },
});

export const primaryColorCheckBox = (theme) => ({
  root: {
    color: theme.palette.text.primary,
    '&$checked': {
      color: theme.palette.labelColor?.main,
    },
  },
  checked: {},
  indeterminate: {
    color: theme.palette.labelColor?.main,
  }
});

export const detailPanelStyles = {
  dialogRoot: {
    position: 'absolute',
    right: 0,
    width: 750,
  }
};

export const detailPanelRootStyles = (customStyles) => (theme) => (
  theme.mergeStyles(detailPanelStyles.dialogRoot, customStyles(theme))
);

export const passwordStyle = {
  backgroundColor: 'transparent',
  border: 'none',
};

export const menuItemStyle = {
  root : {
    display: 'flex',
    justifyContent: 'space-between',
  },
  active: {
    color: LabelUtil.getLabelColor()
  },
};

export const resetPasswordStyles = () => ({
  style: {
    padding: '57px 93px',
  }
});

export const menuItemHeaderStyle = (theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    color: theme.palette.common.black,
    opacity: 1
  }
});

export const comparisonFilterIconBtn = () => ({
  root: {
    backgroundColor: 'transparent !important',
    border: 'none !important'
  }
});

export const switchField = (theme) => ({
  root: {
    width: 32,
    height: 18,
    padding: 0,
    display: 'flex',
    overflow: 'visible'
  },
  switchBase: {
    padding: 2,
    color: '#A3A3A6',
    '&$checked': {
      transform: 'translateX(12px)',
      color: 'white',
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.labelColor?.main,
        borderColor: theme.palette.labelColor?.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  track: {
    border: '1px solid #A3A3A6',
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: 'white',
  },
  checked: {},
});

export const datePickerStyles = {
  orderDate: {
    padding: '0 10px 0 15px',
    display: 'flex',
    alignItems: 'center',
  }
};

export const FundingTextFieldStyles = {
  root: {
    '& .MuiInputBase-input': {
      color: '#393A3F',
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: 'rgb(224, 224, 224)'
    },
  },
};

export const customLinearProgressStyles = (theme) => ({
    root: {
      borderRadius: '2px',
    },
    colorPrimary: {
      backgroundColor: '#888C8D',
    },
    bar: {
      borderRadius: '2px',
      backgroundColor: theme.palette.labelColor?.main,
    },
  });

export const customUpdateSpinnerStyles = (theme) => ({
  circle: {
    color: theme.palette.labelColor?.main
  },
});

export const customRatingStyles = (theme) => ({
  iconFilled: {
    color: theme.palette.labelColor?.main,
  },
  iconHover: {
    color: theme.palette.labelColor?.light,
  },
});

export const dialogStyles = () => ({
  style: {
    width: '540px',
    maxHeight: '535px'
  }
});

export const antSwitchStyles = (theme) => ({
  root: {
    width: 36,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.grey[700],
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
    height: 'auto',
  },
  checked: {},
});

export const chipStyles = {
  root: { top: '0px' },
  input: {
    height: 'unset',
    alignItems: 'center',
    minHeight: '48px'
  },
  autoComplete: {
    '.MuiButtonBase-root.MuiChip-root': {
      width: 'auto !important',
    },
    '.MuiChip-label': {
      color: 'black',
      ml: 'unset',
      p: '0 12px 0 12px',
    }
  }
};


export const AccordionStyles = {
  root: {
    border: '1px solid #DDDDDD',
    borderColor: 'common.lineColor3',
    borderRadius:'4px',
    marginBlockEnd:'20px',
    boxShadow:'none',
    padding:'10px',
    '&:before': {
      display: 'none',
    },
    ':first-of-type, :last-child': {
      borderRadius: '4px !important',
    },
  }
};

export const FaqStyles = {

  '.MuiAccordion-root': {
    boxShadow: 'none',
    '&.Mui-expanded::before': {
      opacity: 1,
    }
  },
  '.MuiAccordionDetails-root': {
    fontSize: '13px',

    '& table': {
      borderCollapse: 'collapse',

      '& th': {
        fontSize: '16px'
      },

      '& tr:not(:last-child)': {
         borderBottom: '1px solid #ddd'
       },

       '& th, td': {
         textAlign: 'left',
         p: '20px 10px',

         '&.nowrap': {
           whiteSpace: 'nowrap'
         }
       }
    },

    '& ul': {
      px: '20px',

      '& li, dd': {
        mb: '25px',
        p: 0
      }
    }
  },
  '.MuiAccordionSummary-expandIconWrapper svg': {
    height: '20px',
    width: '20px'
  }
};

export const cursorPointerStyles = {
  cursor: 'pointer'
}

export const iconAdornmentStyles = () => ({
  root: {
    ...cursorPointerStyles
  }
});


export const linkFocusStyles = () => ({
  '&:focus': { textDecoration: 'underline' }
});


export const ToggleButtonGroupStyles = {
  root: {
    backgroundColor: '#E0DDDD',
    borderRadius:'40px',
    padding: '5px'
  },
};

export const ToggleButtonStyles = {
  root: {
    borderRadius: '40px !important',
    border: '0',
    fontSize: '10px',
    textTransform: 'capitalize',
    height: '31px',
    '&.Mui-selected': {
      color:'#1D1D1D',
      backgroundColor: 'white',
    }
  }
};

export const filterPanelMobileMenuItem = {
  margin: '5px 0',
  backgroundColor: 'transparent'
};

export const filterPanelMobileMenuItemSelected = {
  color: 'labelColor',
};


export const dateSelectorToggle = {
  marginRight: '12px',
};

export const labelStyle = {
  marginLeft: '10px',
};

export const midHolderStyles = {
  '&.midHolder .icon.active svg': {
    color: (theme) => `${theme.palette.primary.main} !important`
  }
};

export const upgradePlanGridBorderBottomRight = {
  borderBottom: {
    xs: '1px solid #DDDDDD',
    sm: '1px solid #DDDDDD',
    md: '1px solid #DDDDDD',
    lg: '1px solid #DDDDDD'
  },
  borderRight: {
    xs: 0,
    sm: 0,
    md: 0,
    lg: '1px solid #DDDDDD'
  }
};

export const upgradePlanGridBorderBottom = {
  borderBottom: {
    xs: '1px solid #DDDDDD',
    sm: '1px solid #DDDDDD',
    md: '1px solid #DDDDDD',
    lg: '1px solid #DDDDDD'
  },
  paddingTop: {
    xs: '30px',
    sm: '30px',
    md: '30px',
    lg: 0
  }

};

export const upgradePlanGridBorderRight = {
  borderBottom: {
    xs: '1px solid #DDDDDD',
    sm: '1px solid #DDDDDD',
    md: '1px solid #DDDDDD',
    lg: 0
  },
  borderRight: {
    xs: 0,
    sm: 0,
    md: 0,
    lg: '1px solid #DDDDDD'
  }

};

export const loyaltyAddOnMenuItemStyle = {
  columnGap: '10px',
  justifyContent: 'normal'
};

export const davoIntegrationsImgContainer = { display: 'flex', justifyContent: 'center' };

export const davoIntegrationsBottomPanel = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  pt: '20px',
  pb: '20px'
};

export const davoIntegrationsStyles = {
  legal: {
    '.MuiList-root': {
      pl: 4,
      '&.decimal': {
        listStyle: 'decimal'
      },
      '&.latin': {
        listStyle: 'lower-latin'
      },
      '&.roman': {
        listStyle: 'lower-roman'
      }
    },
    '.MuiListItem-root:': {
      p: 0
    }
  }
};

export const itemsMobileStyles = {
  columnStack: {'.empty': {color: 'grey.500'}},
  columnStackLast: {textAlign: 'right', pr: 3,'.empty': {color: 'grey.500'} },
  columnSelector: {'.MuiFormControlLabel-root': { ml: 0 }}
};

export const landscapeHeight = (theme) => ({
  minHeight: theme.measures.landscapeHeight
});

export const landscapePosition = {position: 'relative !important'};

export const loadingStyle = {
  height: '70% !important'
};

export const activityStyles = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  borderRadius: '18px',
  width: '100%',
  boxSizing: 'border-box',

  '& ul': {
    width: '100%'
  },

  '& li': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `0.5px solid ${theme.palette.common.borderGray}`,
    fontFamily: 'interSemiBold',
    fontSize: '15px',
    py: 3,

    '& a': {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: theme.palette.labelColor.main,
      cursor: 'pointer',

      '&.notClickable': {
        cursor: 'default',
        pointerEvents: 'none',

        'svg': {
          visibility: 'hidden'
        }
      }
    }
  },

  '& li:last-of-type': {
    borderBottom: '0px',
    pb: 2
  }
});

export const topReportsStyles = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  backgroundColor: theme.palette.common.featureBgcolor,
  borderRadius: '18px',
  width: '100%',
  boxSizing: 'border-box',
  px: {xs: 2, md: 4},
  py: 2,

  '& h2': {
    color: 'common.black2',
    fontFamily: 'interBold'
  },

  '& ul': {
    width: '100%'
  },

  '& li': {
    borderBottom: `0.5px solid ${theme.palette.common.borderGray}`,
    py: 3,

    '& a': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      textDecoration: 'none',
      color: theme.palette.labelColor.main,
      fontFamily: 'interSemiBold',
      fontSize: '15px',
      cursor: 'pointer'
    }
  },

  '& li:last-of-type': {
    borderBottom: '0px',
    pb: 2
  }
});

export const validationErrorStyles = (theme) => ({
  color: 'error.main',
  display: 'inline-block',
  marginLeft: 'auto',
  padding: '5px 0 0 15px',
  minHeight: '11.5px'
});

export const termsAndConditionsLabelStyles = (theme) => ({
  fontSize: '15px',
  '& label':{
    lineHeight: 1,
  },

  '& .tsAndCsCheckbox':{
    padding: '0 0 0 15px',
  },

  '& .legalLink': {
    fontSize: '15px !important',
    textDecoration: 'none'
  },
  '& .legalLink:focus': {
    textDecoration: 'underline',
    textDecorationThickness: '2px',
    color: theme.palette.primary.main
  }
});

export const mainMenuHeaderStyles = {
  '.mainMenuIcon': {
    width: 49,
    height: 49,
    '.menuButtonSvg': {
      width: '25px',
      height: '25px',
      'svg': {
        width: '20px',
        height: '20px !important',
        pr: '5px'
      }
    },
  },
};

export const mainMenuTopItemStyles ={
  alignItems: 'center',
  '.headerItemText': {
    color: 'action.active'
  }
};

export const ItemizedOrCustomTabsStyles = {
  '&.itemizedOrCustomTabs .tab.selectedTab': {
    color: 'primary.main'
  }
};

export const sharedIntegrationDialogStyles = {
  '& a': {
    textDecoration: 'none',
    color: LabelUtil.getLabelColor(),
    cursor: 'pointer'
  },
  linkBox: { display: 'flex', justifyContent: 'space-between' },
  integrationHeader: { textAlign: 'center', pb: '42px' },
  borderBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '0.5px solid #ACABAB',
    borderRadius: '6px',
    paddingX: '18px',
    paddingTop: '22px',
    paddingBottom: '17px'
  },
  brandBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: '10px'
  },
  brandText: {
    fontSize: '18px',
    lineHeight: '36px'
  },
  divider: {
    borderColor: '#C3C2C2',
    borderWidth: '0.5px',
    width: '100%',
    marginY: '23px',
  },
  linkDivider: {
    borderColor: '#C3C2C2',
    borderWidth: '0.5px',
    width: '100%',
    marginY: '9px',
  },
  featureBox: { paddingTop: '24px' },
  featureText: {
    fontSize: '14px',
    lineHeight: '36px'
  },
  listItemBox: {
    display: 'flex', flexDirection: 'row', gap: '5px'
  },
  listItemText: {
    fontSize: '12px', lineHeight: '18px'
  },
  listItemIcon: {
    minWidth: '25px'
  },
  buttonContainer: {
    boxShadow: '4px -4px 14px #00000021',
    display: 'flex',
    position: 'absolute',
    left: 0,
    width: '100%',
    height: '104px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '35px',
  }
};

export const depositSummaryStyles = (theme) => ({
  margin: 0,
  listStyle: 'none',
  display: 'flex',
  alignItems: 'center',
  padding: '30px 35px',
  borderBottom: `1px solid ${theme.palette.common.lineColor}`,

  [theme.breakpoints.down(theme.breakpoints.values.dashboardMd)]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  },

  '.dataItem': {
    display: 'flex',
    width: '100%',
    mr: '12%',
    justifyContent: 'space-evenly',

    [theme.breakpoints.down(theme.breakpoints.values.dashboardMd)]: {
      mr: '0',
      mb: '15px',
      justifyContent: 'flex-start'
    },

    '&:last-child': {
      mr: 0
    }
  },

  '.data': {
    margin: '3px 0 0 15px',

    '.amount': {
      ...theme.sharedStyles.bodyText(theme)
    },

    '.text': {
      ...theme.sharedStyles.contentLabel(theme),
      whiteSpace: 'pre'
    }
  }
});

export const customerDirectoryHeaderStyles = (theme) => ({
  display: 'flex',
  height: '50px',
  mt: '25px',
  mb: '25px',

  [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMax)]: {
    flexWrap: 'wrap',
    mb: '70px',

    '.firstPart, .secondPart': {
      width: '100% !important'
    },

    '.secondPart': {
      mt: '25px'
    }
  },

  '.firstPart, .secondPart': {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '.firstPart.withBorder': {
    [theme.breakpoints.up(theme.breakpoints.values.sizeDesktopMin)]: {
      borderRight: `1px solid ${theme.palette.common.lineColor}`
    }
  },

  '.totalCustomers, .rewardsRedeemed': {
    display: 'flex'
  },

  '.totalCustomers, .repeatCustomers, .rewardsRedeemed, .discountVolume': {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    'h1': {
      mb: 0,
      mt: 0,
      fontSize: '16px'
    },
  },

  '.repeatCustomers, .discountVolume': {
    flexDirection: 'column'
  },

  '.repeatCustomers': {
    '.repeatCustomersTitle': {
      width: '110px'
    }
  },

  '.discountVolume': {
    '.discountVolumeTitle': {
      width: '185px'
    }
  }
});

export const batchesSummaryHeaderStyles = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  p: '30px 35px',
  borderBottom: `1px solid ${theme.palette.common.lineColor}`,

  [theme.breakpoints.down(theme.breakpoints.values.dashboardMd)]: {
    flexDirection: 'column'
  },

  '.summaryHeader': {
    [theme.breakpoints.down(theme.breakpoints.values.dashboardMd)]: {
      display: 'none'
    },

    '.heading': {
      ...theme.sharedStyles.heading(theme)
    }
  },

  '.dataList': {
    listStyle: 'none',
    display: 'flex',
    p: 0,
    m: '0 0 0 225px',
    width: '100%',
    justifyContent: 'space-evenly',

    [theme.breakpoints.down(theme.breakpoints.values.sizeDesktopMedium)]: {
      ml: 0
    },

    [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMax)]: {
      mt: '15px'
    },

    [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
      flexDirection: 'column'
    },

    '.dataItem': {
      mr: '140px',
      display: 'flex',

      [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMax)]: {
        mr: '15px',
        mb: '15px'
      },

      [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
        mr: 0
      },

      '.data': {
        m: '3px 3px 0 15px',

        '.amount': {
          ...theme.sharedStyles.bodyText(theme),
          fontWeight: 'bold',
          mb: '5px'
        },

        '.text': {
          ...theme.sharedStyles.contentLabel(theme),
          whiteSpace: 'pre'
        }
      },

      '&:last-child': {
          mr: 0
      }
    }
  },

  '.subTitle': {
    '& button': {
      width: 'max-content'
    }
  },

  '.hideDesktop': {
    display: 'none',

    [theme.breakpoints.down(theme.breakpoints.values.dashboardMd)]: {
      display: 'contents'
    }
  }
});

export const batchDetailsTableSummaryHeaderStyles = (theme) => ({
  mt: '25px',

  '.summaryHeader': {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.common.lineColor}`,
    mb: '15px',
    pl: '25px',

    [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
      flexDirection: 'column'
    },

    '.heading': {
      display: 'flex',

      '.goBack': {
        cursor: 'pointer',
        margin: '12px 14px 0 0',
        fill: theme.palette.common.primaryTextColor,
        height: 'min-content',

        [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMax)]: {
          margin: '7px 7px 0 0'
        },
      },

      '& h2': {
        ...theme.sharedStyles.heading(theme)
      },

      '& p': {
        ...theme.sharedStyles.bodyText(theme),
        mb: '15px'
      },

    },

    '.summaryHeaderActions': {
      display: 'flex',
      ml: 'auto',
      fill: theme.palette.labelColor?.main,

      [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
        ml: 0
      },

      '& div': theme.mergeStyles(
        theme.sharedStyles.bodyTextSubHeading(theme),
        theme.sharedStyles.responsiveStyles(theme, {
          color: theme.palette.labelColor?.main,
          height: 'min-content',
          margin: '12px 15px',
          cursor: 'pointer',
        }, {
          sizeTabletMax: {
            mt: '6px'
          },
          sizeMobileMax: {
            ml: 0
          }
        })
      ),

      '& svg': {
        ...theme.sharedStyles.largeIcon,
        verticalAlign: 'text-bottom'
      }
    }
  },

  '.batchDetails': {
    '.detailDescription': {
      p: '15px 25px'
    },

    '.detailDescription:last-child': {
      borderBottom: `1px solid ${theme.palette.common.lineColor}`
    }
  }
});

export const carouselStyles = {
  '.slick-dots': {
    '.slick-active': {
      backgroundColor: 'primary.main'
    },

    '& li': {
      width: 65,
      height: 5,
      backgroundColor: '#EAECEF',
      borderRadius: 9,
      cursor: 'default',

      '& button': {
        padding: 0,
        cursor: 'default',

        '&::before': {
          content: 'none'
        }
      }
    }
  }
};

export const detailsListStyles = {
  color: `${LabelUtil.getLabelColor()} !important`,
};

export const virtualTerminalInvoicesBottomBarStyles = {
  '& > div + div': {
    '.settingsErrorBottom': {
      margin: '17px',
      minWidth: 200
    },
    'button:last-of-type': {
      maxWidth: 200
    }
  }
};

export const listItemIcon = (theme) => ({
  fill: theme.palette.labelColor.main,
  minWidth:'18px',
  margin:'0 12px'
});

export const discountTypeAccessoryFieldStyles = {
  position: 'relative',

  '.typeAccessory': {
    position: 'absolute',
    top: '14px',
    right: '10px',
    '.textFieldHolder': {
      border: 'none'
    }
  }
};

export const detailPanelOptionsListItemButtonStyles = {
  paddingLeft: 0,
  ':hover': {
    background:'none'
  }
};

export const BulkInvoicesDialogStyles = (theme) => ({
  padding: '20px',
  minWidth: '411px',
  display: 'flex',
  [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMin)]: {
    minWidth: '100%',
  },
  '& ol': {
    flexDirection: 'column',
    display: 'flex'
  },
  '& ol li': {
    width: '400px!important',
    [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMin)]: {
      width: '100%!important',
    },
  },
  '& ol li:last-of-type': {
    marginBottom: 0
  },
  '.bulkInput':{
    width: '100%'
  },
  '.instructions': {
    color: LabelUtil.getLabelColor(),
    fontWeight: 'bold',
    fontSize: '13px',
  },
  '.fileBox': {
    boxSizing: 'border-box',
    display: 'flex',
    height: '68px',
    width: '100%',
    marginTop:'10px',
    padding: '23px 20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '0.5px solid #B5B5B5',
    background: '#F7F7F7',
    '& .fileNameWrapper':{
      display:'flex',
      flexDirection:'row',
      gap:'8px',
      overflow:'hidden',
      alignItems:'center'
    },
    '& .fileName':{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: '0 !important'
    }
  },
  '.dropLabel': {
    fontSize: '10px',
    fontFamily: 'interRegular',
    fontWeight: '500',
    lineHeight: '11px',
    letterSpacing: '0.2px',
    '&.bold':{
      fontFamily: 'interBold',
    }
  },
  '& .bulkInput > div:first-of-type': {
    width: '100% !important',
    borderRadius: '0!important',
    backgroundColor: '#F7F7F7',
    height: '145px!important',
    border: '0.5px solid #B5B5B5 !important',
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'center'
  },
  '& .fileUploadError.errorText': {
    display: 'none'
  }
});


export const VerifyBankAccountDialogStyles = (theme) => ({
  padding: '0',
  minWidth: '411px',
  display: 'flex',
  [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMin)]: {
    minWidth: '100%',
  },
  '.uploadInput':{
    width: '100%'
  },
  '.contactEmail':{
    color: 'labelColor.main',
  },
  '.fileBoxWrapper': {
    marginY:3.5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '.deleteAction': {
    width: '50px',
    height: '48px',
    marginLeft: 2,
    '.MuiButtonBase-root': {
      height: '48px',
    },
  },
  '.fileBox': {
    boxSizing: 'border-box',
    display: 'flex',
    height: '68px',
    width: 'calc(100% - 50px)',
    padding: '23px 20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `2px solid ${theme.palette.labelColor.main}`,
    backgroundColor: '#F7F7F7',
    borderRadius: '6px',
    '&.MuiStack-root': {
      padding: '14px 20px',
      width: '100%',
    },
    '& .fileNameWrapper':{
      display:'flex',
      flexDirection:'row',
      gap:'8px',
      overflow:'hidden',
      alignItems:'center'
    },
    '& .fileName':{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: '0 !important'
    }
  },
  '.dropLabel': {
    fontSize: '12px',
    fontFamily: 'interRegular',
    fontWeight: '500',
    lineHeight: '11px',
    letterSpacing: '0.2px',
    '&.bold':{
      fontFamily: 'interBold',
    },
    '&.dropLabelColor':{
      color: 'labelColor.main',
    }
  },
  '& .uploadInput > div:first-of-type': {
    width: '100% !important',
    borderRadius: '20px',
    height: '145px!important',
    border: `2px dashed ${theme.palette.labelColor.main}`,
    marginTop: 3.5,
    display: 'flex',
    justifyContent: 'center'
  },
  '& .fileUploadError.errorText': {
    display: 'none'
  }
});

export const BulkInvoicesProgressDialogStyles = {
  wrapper: {
    padding: '34px 20px 15px 20px',
    justifyContent: 'space-between',
    border: '1px solid #F0F0F0',
    display: 'flex',
    flexDirection: 'row'
  },
  topLabel: {
    fontSize: '14px',
    fontFamily: 'interSemiBold'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '55px',
  },
  textTitle: {
    textAlign: 'center',
    fontFamily: 'interSemiBold',
    fontSize: '28px',
    lineHeight: '36px',
  },
  text: {
    paddingTop: '16px',
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '20px',
    width: 'auto'
  },
  progressWrapper: {
    justifyContent:'center',
    position: 'relative',
    display: 'inline-flex',
    paddingTop: '75px'
  },
  progressLabelWrapper: {
    paddingTop: '75px',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressLabel: {
    color: 'common.black',
    textAlign: 'center',
    fontFamily: 'interBold',
    fontSize: '24px',
  },
  continueButton: {
    width: '325px !important',
    '&.raisedButton': {
      height: '72px !important',
    }
  },
  cancelButton: {
    backgroundColor: 'white',
    width: '325px !important',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
    '&.raisedButton': {
      '&:hover': {
        backgroundColor: 'white'
      },
      border: '2px solid red !important',
      boxShadow: 'none !important',
      height: '72px !important',
      span: {
        color: 'common.negativeColor',
        display: 'inline-flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        textTransform: 'none',
      }
    }
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'center',
    paddingTop: '100px',
    paddingBottom:'10px'
  }
}

export const BulkInvoicesStyles = {
  mainWrapper: {
    flexBasis: 'auto',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    height: '100%'
  },
  confirmationPage: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '40px',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingTop: '40px',
      width: '100%',
    },
    headText: {
      fontFamily: 'interBold',
      fontSize: '24px',
      m: '0',
      mb: '20px',
    },
    descriptionText: {
      width: '50%',
      margin: '0 auto'
    },
    descriptionLink:{
      color: 'primary.main',
      cursor: 'pointer',
      fontSize: '12px'
    },
    cardContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingX: '35px',
      paddingY: '30px',
      backgroundColor: 'common.whiteSmoke',
      width: '315px',
      color: 'common.black'
    },
    cardTitle: {
      fontFamily: 'interSemiBold',
      fontSize: '14px',
      paddingBottom: '20px'
    },
    cardLabel: {
      fontFamily: 'interRegular',
      fontSize: '14px'
    },
    cardLabelError: {
      fontFamily: 'interSemiBold',
      fontSize: '14px',
      color: 'common.negativeColor'
    }
  },
  bulkList: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      position: 'relative',
      height:'100%'
    },
    firstBox: {
      paddingTop: '40px',
      paddingX: '40px',
      paddingBottom:'90px'
    },
    details: {
      marginTop: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '20px'
    }
  },
  bulkReviewPage: {
    cardContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '12px',
    },
    tabWrapper: {
      borderBottom: '0.5px',
      borderColor: '#C1C1C1',
      borderBottomStyle: 'solid'
    },
    tabIcon: {'& .MuiBadge-badge': {color: 'common.white'}},
    tab: {
      textTransform: 'none',
      fontSize: '12px',
      fontFamily: 'interRegular',
      color: 'common.black',
      width: '160px',
      '& .MuiBadge-root': {
        marginLeft: '25px'
      },
      '&.Mui-selected': {
        fontWeight: 'bold'
      },
    },
    errorTabWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& a': {
        textDecoration: 'none',
        color: 'primary.main'
      },
      paddingBottom: '15px',
      gap: '10px'
    }
  },
  informationCard: {
    wrapper: {
      display: 'flex',
      backgroundColor: 'common.whiteSmoke',
      borderRadius: '10px',
      paddingX: '30px',
      paddingY: '46px',
      width: '568px',
      height: '100px',
      gap: '60px'
    },
    image: {
      width: '135px',
      height: '87px',
    }
  },
  table: {
    pageWrapper: {
      width: '100%',
      overflow: 'hidden',
    },
    bulkInvoicesTableContainer: {
      width: '3100px',
      '&.errorTable': {
        width: '3600px',
      },
      '.infiniteListAutoSizerBulkInvoices': {
        width: '100% !important',
      },
      '.ReactVirtualized__Grid__innerScrollContainer .ReactVirtualized__Table__row .ReactVirtualized__Table__rowColumn': {
        p: 0
      },
      '.ReactVirtualized__Table__headerRow .ReactVirtualized__Table__headerColumn': {
        cursor: 'unset'
      },
    },
    errorText: {
      color: 'common.negativeColor',
      fontSize: '12px',
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
      wordBreak: 'break-word',
      whiteSpace: 'normal'
    },
    tableContainer: {
      minWidth: 700,
    },
    tableHeaderRow: {
      '& th': {
        fontFamily: 'interSemiBold',
        fontSize: '12px',
        minWidth: '150px',
        paddingY: 0,
        paddingLeft: '25px',
        border: 'none'
      }
    },
    tableBodyRow: {
      backgroundColor: 'common.whiteSmoke',
      borderRadius: '4px',
      paddingY: '6px',
    },
    invertedScrollBar: {
      transform: 'rotateX(180deg)',
      '> div': {
        transform: 'rotateX(180deg)'
      }
    }
  },
  heading: {
    fontFamily: 'interBold',
    fontSize: '24px',
    m: '0',
    mb: '40px',
  }
};

export const mobileScrollNoteStyles = (theme) => ({
  '.mobileScrollNote': {
    [theme.breakpoints.down(theme.breakpoints.values.sizeDesktopMedium)]: {
      display: 'flex',
    }
  }
});

export const BulkInvoiceCardStyles = (theme) => ({
  display: 'flex',
  backgroundColor: 'common.whiteSmoke',
  borderRadius: '4px',
  padding: '30px',
  '.cardWrapper': {
    display: 'flex',
    flexDirection: 'row',
    gap: '45px',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down(theme.breakpoints.values.sizeDesktopMedium)]: {
      flexDirection: 'column',
      gap: '10px',
      alignItems: 'center',
      textAlign: 'center'
    },
  },
  '.progressContainer':{
    alignItems:'center',
    display:'flex',
    flexDirection:'row',
    gap:'40px',
    [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMin)]: {
      flexDirection:'column',
      gap:'10px',
    },
  },
  '.buttonContainer': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& button.raisedButton': {
      height: '35px!important'
    }
  }
});

export const TermsAndConditionsStyles = {
  TermsAndConditionBox: {
    minHeight: '400px'
  },
  TermsAndConditionsButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    paddingBottom: '20px',
    margin: '15px',
    backgroundColor: 'common.backgroundColor',
    width: '60%'
  }
};

export const BulkLandingStyles = (theme) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  paddingTop: '40px',
  width: '100%',
  overflowY: 'auto',
  '.heading': {
    fontFamily: 'interBold',
    fontSize: '24px',
    m: '0',
    mb: '40px',
  },
  '.linkInstructions': {
    color: 'common.black',
    'a': {
      textDecoration: 'none',
      color: 'primary.main',
      fontWeight: 'bold',
      cursor: 'pointer'
    }
  },
  '.buttonContainer': {
    paddingTop: '20px',
    paddingBottom: '50px'
  },
  '.text': {
    fontSize: '18px'
  }
});

export const BulkListButtonContainer = (theme) => ({
  marginTop: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '20px',
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  paddingLeft: '100px',
  [theme.breakpoints.up(theme.breakpoints.values.sizeDesktopMin)]: {
    paddingLeft: '80px',
  },
});

export const BulkListInformationCard = (theme) => ({
  display: 'flex',
  backgroundColor: 'common.whiteSmoke',
  borderRadius: '10px',
  paddingX: '30px',
  paddingY: '46px',
  width: '568px',
  height: '100px',
  gap: '60px',
  alignItems: 'center',
  flexDirection:'row',
  [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMin)]: {
    width: 'auto',
    height: 'auto',
    flexDirection:'column',
    gap: '20px',
  },
  '.informationCardImage': {
    width: '135px',
    height: '87px',
    '.labelColor':{ fill: theme.palette.labelColor.main },
    '.labelColorText': { fill: theme.palette.labelColor.contrastText }
  }
});

export const TileDisclaimerText = (theme) => ({
  '.disclaimer': {
    color: `${theme.palette.common.white} !important`,
  }
});

export const BulkInvoicesTableCellStyles = (theme) => ({
  border: 'none',
  paddingLeft: '25px',
  paddingTop: '30px',
  paddingBottom: '28px',
  '.text': {
    fontSize: '12px'
  }
});

export const paymentLinksWelcomeScreenStyles = {
  '.labelColor': ({palette}) => ({ fill: palette.labelColor.main }),
  '.labelColorText': ({palette}) => ({ fill: palette.labelColor.contrastText })
};

export const ModalTooltipStackStyles = (theme) => ({
  '&.toolTipStack': {
    flexDirection: 'row',
    gap: '10px',
    [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
      flexDirection: 'column',
    },
  },
});

export const OwnerContainerStyles = (theme)=>({
  '.formHeading':{
    display: 'flex',
    gap: '10px',
    alignItems: 'center'
  }
});

export const BulkImagesSvgStyles = (theme) => ({
  '.labelColor': {fill: theme.palette.labelColor.main},
  '.labelColorText': {fill: theme.palette.labelColor.contrastText}
});

export const vtResizableTextArea = {
  padding: '0 6px'
};

export const vtStyles = (theme) => ({
  '&.cdAmountContainer': {
    display: 'flex',
    alignItems: 'center'
  }
});

export const BulkConfirmationPageStyles = (theme)=>({
  display:'flex',
  flexDirection:'column',
  gap:'10px',
  paddingBottom:'60px'
});

export const TransactionsAmountCellStyles = (theme) => ({
  display: 'block',
  marginRight: '40px',
  textAlign: 'right'
});

export const customerPaymentInvoiceDiscountBtn = (theme) => ({
  marginRight: '0 !important',
  [theme.breakpoints.down(theme.breakpoints.values.sizeDesktopMax)]: {
    'span.buttonLabel': {
      fontSize: '12px',
      lineHeight: '14px'
    }
  }
});

export const customerPaymentInvoiceDefaultBtn = (theme) => ({
  [theme.breakpoints.down(theme.breakpoints.values.sizeDesktopMax)]: {
    margin: '0 10px'
  }
});

export const virtualTerminalPageStyles = (theme) => ({

  '&.virtualTerminal': {

    '.filter': {
      minWidth: '230px'
    },

    '.invoiceForm': {

      '.summary .summarySection': {
        width: '100%',
        [theme.breakpoints.down(theme.breakpoints.values.dashboardMd)]: {
          width: '600px'
        }
      },

      '.invoiceInfo div:nth-child(2)': {
        width: 'auto'
      },

      '.paymentInformation': {
        padding: '0 6px'
      }

    }

  }

});

export const cardContainerStyles = (theme) => ({

  '&.cardContainerWithVisibilityToggle': {

    '.cardImage': {
      position: 'absolute',
      marginTop: '38px',
      right: '54px'
    },

    '#cardNumberVisibilityIcon': {

      position: 'absolute !important',
      right: '6px',
      padding: '12px',
      marginTop: '27px',
      width: '48px',
      height: '48px',
      boxSizing: 'border-box',

      'svg': {
        paddingTop: '12px'
      }

    }

  },
});

export const virtualTerminalFormContainerStyles = (theme) => ({

  '&.virtualTerminalFormContainer': {

    '.virtualTerminalForm': {

      '.flex': {
        display: 'flex',
        alignItems: 'flex-start'
      },

      '.cashDiscountingContainer': {
        alignItems: 'center'
      },

      '.textField.categoryNameWrapper': {
        display: 'flex',
        flexDirection: 'column',
        '#sharedSelect': {
          display: 'flex',
          alignItems: 'center'
        },
        '.bubble': {
          marginRight: 15,
          marginLeft: 0,
          paddingLeft: 0,
          paddingTop: '8px !important'
        },
      }

    },

    '#paymentMethodSelect': {
      padding: '0 6px'
    },

    '.processingDataSelection': {
      '.textFieldHolder.selectField': {
        height: '48px'
      }
    },

    '.levelThree': {
      '.textFieldHolder.selectField': {
        height: '48px'
      }
    },

    '.textFieldInfo label': theme.sharedStyles.responsiveFontSize(theme, 13, {sizeTabletMax: 13}),
  }

});

export const discardInvoiceModalButtonStyles = {padding: '0px !important'};

export const invoicesPageStyles = (theme) => ({
  '&.invoicesContainer': {
    '#masterInfiniteList.masterInfinitListContainer.infiniteList': {
      'overflowX': 'auto',
      'height': 'auto'
    },
    '.mobile .ReactVirtualized__Table__headerColumn:last-child':{
     textAlign: 'initial!important',
    }
  },
  [theme.breakpoints.down(theme.breakpoints.values.sizeDesktopMax)]: {
    '&.invoicesContainer': {
      '& .bulkInvoicesTableContainer': {
        '.ReactVirtualized__Table__headerRow': {
          width: '100%!important'
        },
        '.ReactVirtualized__Grid.ReactVirtualized__Table__Grid, .ReactVirtualized__Grid.ReactVirtualized__Table__Grid .ReactVirtualized__Table__row, .ReactVirtualized__Grid.ReactVirtualized__Table__Grid .ReactVirtualized__Grid__innerScrollContainer, .ReactVirtualized__Grid.ReactVirtualized__Table__Grid .ReactVirtualized__Grid__innerScrollContainer .ReactVirtualized__Table__row': {
          width: '100%!important'
        }
      },
      '.ReactVirtualized__Table__headerRow': {
        width: '1300px !important'
      },
      '.ReactVirtualized__Grid.ReactVirtualized__Table__Grid, .ReactVirtualized__Grid.ReactVirtualized__Table__Grid .ReactVirtualized__Table__row, .ReactVirtualized__Grid.ReactVirtualized__Table__Grid .ReactVirtualized__Grid__innerScrollContainer, .ReactVirtualized__Grid.ReactVirtualized__Table__Grid .ReactVirtualized__Grid__innerScrollContainer .ReactVirtualized__Table__row': {
        width: '1300px !important',
        maxWidth: 'none !important'
      },
      '.paginationHolder': {
        position: 'fixed',
      }
    }
  }
});

export const colorGridStyles = {
  width: '25px',
  height: '25px',
  margin: '10px',
};

export const categoriesIndicatorStyles = {
  width: '12px',
  height: '12px'
};
