/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import emailValidator from 'email-validator';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import {
  submit,
  reset,
  getFormValues,
  isSubmitting,
  getFormSyncErrors,
  change
} from 'redux-form';
import { Box } from '@mui/material';

import {
  setModalVisibility,
  setOpenMessageDialog,
  setVisibilityFilter,
  setSelectedDateRange,
  acknowledgeIssueCreditDialog,
  toggleNorthGatekeeper
} from '../../actions/userExperienceActions';
import { getDiscounts } from '../../actions/discountsActions';
import { getItems, setSalesItem } from '../../actions/itemsActions';
import { processCreditCardVoid } from '../../actions/transactionsActions';
import { payInvoiceWithoutToken } from '../../actions/invoicesActions';
import { getCustomers, setCustomer } from '../../actions/customerActions';
import { updateCardPresentState } from '../../actions/cardPresentActions';

import {countryStateList} from '../../constants/countryStateList';
import messages from '../../constants/messages';
import routes from '../../constants/routes';
import actionTypes from '../../constants/actionTypes';
import { highTransactionLimitAmount } from '../../constants/applicationConstants';

import CardPresentUtil from '../util/CardPresentUtil';
import IconUtils from '../util/IconUtil';
import LabelUtil from '../util/LabelUtil';
import MobileUtil from '../util/MobileUtil';
import PaymentUtil from '../util/PaymentUtil';
import FormUtil from '../util/FormUtil';
import ReceiptUtil from '../util/ReceiptUtil';
import UserUtil from '../util/UserUtil';
import DateUtil from '../util/DateUtil';
import InvoiceUtil, {
  FormType,
  INVOICE_FORM_ID,
  PaymentMethod,
  PaymentFrequency
} from '../util/InvoiceUtil';
import Validator from '../util/Validator';

import Button from '../shared/Button';
import BottomBar from '../shared/BottomBar';
import Modal from '../shared/Modal';
import MessageDialog from '../shared/MessageDialog';
import ErrorMessageDialog from '../shared/ErrorMessageDialog';
import DownloadMobileAppDialog from '../DownloadMobileAppDialog';
import FilterPanel from '../shared/FilterPanel';
import InvoiceForm from '../shared/enhancedInvoices/InvoiceForm';
import { PAYMENT_LINK_FORM_ID } from '../shared/paymentLinks/createPaymentLinkForm';
import CreatePaymentLinkControl from '../shared/paymentLinks/createPaymentLinkControl';
import PlanUpgradePaymentLinks from '../shared/paymentLinks/planUpgradePaymentLinks';
import Loading from '../Loading';
import VirtualTerminalForm from './VirtualTerminalForm';
import VirtualTerminalSettingsForm from './VirtualTerminalSettingsForm';
import VirtualTerminalFormItemSelection from './VirtualTerminalFormItemSelection';
import numeral from 'numeral';
import HighAmountModal from './HighAmountModal';
import LowAmountModal from './LowAmountModal';
import InvoicePreview from '../invoice/InvoicePreview';
import FormatTextUtil from '../util/FormatTextUtil';
import Bugsnag from '@bugsnag/js';
import { removeRewardCode } from '../../actions/loyaltyVpcActions';
import Page from '../shared/Page';
import { virtualTerminalInvoicesBottomBarStyles, virtualTerminalPageStyles } from '../../jss/inlineStyles';
import LocalStorageUtil from '../util/LocalStorageUtil';

const approvedResponseCodes = ['APR', 'AVS'];

const statesList = countryStateList('United States');

class VirtualTerminal extends Component {

  constructor(props) {
    super(props);

    this.process = this.process.bind(this);
    this.issueCredit = this.issueCredit.bind(this);
    this.toggleCardPresent = this.toggleCardPresent.bind(this);
    this.onProcessClick = this.onProcessClick.bind(this);
    this.onIssueCreditClick = this.onIssueCreditClick.bind(this);
    this.onProcessVoid = this.onProcessVoid.bind(this);
    this.onProcessVoidButtonAction = this.onProcessVoidButtonAction.bind(this);
    this.handlePrintReceipt = this.handlePrintReceipt.bind(this);
    this.handleSendReceipt = this.handleSendReceipt.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleFilterSelection = this.handleFilterSelection.bind(this);
    this.openItemsDialog = this.openItemsDialog.bind(this);
    this.openItemsDialogFromVirtualTerminal = this.openItemsDialogFromVirtualTerminal.bind(this);
    this.addDiscountToCart = this.addDiscountToCart.bind(this);
    this.closeItemsDialog = this.closeItemsDialog.bind(this);
    this.openIssueCreditDialog = this.openIssueCreditDialog.bind(this);
    this.closeIssueCreditDialog = this.closeIssueCreditDialog.bind(this);
    this.cancelIssueCreditDialog = this.cancelIssueCreditDialog.bind(this);
    this.onAcknowledgeIssueCreditDialog = this.onAcknowledgeIssueCreditDialog.bind(this);
    this.onAddItemClick = this.onAddItemClick.bind(this);
    this.handleAddItemToOrder = this.handleAddItemToOrder.bind(this);
    this.cleanItemizedCart = this.cleanItemizedCart.bind(this);
    this.changeItemQuantity = this.changeItemQuantity.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.editItem = this.editItem.bind(this);
    this.deleteDiscount = this.deleteDiscount.bind(this);
    this.onRemoveFromOrderClick = this.onRemoveFromOrderClick.bind(this);
    this.proceedWithDuplicate = this.proceedWithDuplicate.bind(this);
    this.confirmPartialPayment = this.confirmPartialPayment.bind(this);
    this.voidWithoutReload = this.voidWithoutReload.bind(this);
    this.toggleLoyaltyRewardCode = this.toggleLoyaltyRewardCode.bind(this);
    this.getCartForPreview = this.getCartForPreview.bind(this);
    this.closeFuturePaymentDialog = this.closeFuturePaymentDialog.bind(this);
    this.onCreatePaymentLinkClick = this.onCreatePaymentLinkClick.bind(this);
    this.closeHighAmountDialog = this.closeHighAmountDialog.bind(this);
    this.openHighAmountDialog = this.openHighAmountDialog.bind(this);
    this.handleInvoiceSavedAsDraft = this.handleInvoiceSavedAsDraft.bind(this);
    this.openNorthGatekeeper = this.openNorthGatekeeper.bind(this);
    this.invoiceFormRef = React.createRef();

    this.state = {
      selectedReceipt: null,
      payment: {},
      cardPresentPendingFormData: null,
      cardPresentUtil: null,
      renderer: false,
      submitButtonClicked: false,
      processPaymentInProgress: false,
      issueCreditInProgress: false,
      sendInvoiceInProgress: false,
      isItemsDialogOpen: false,
      isIssueCreditDialogOpen: false,
      selectedItemIndex: null,
      addToOrderAdditionalInfo: null,
      isEditingItem: false,
      itemizedCart: InvoiceUtil.initialItemizedCartObject(),
      showLowAmountWarningModal: false,
      openPreviewModal: false,
      isSavingDraft: false,
      partialPayment: null,
      isPayInvoice: false,
      isVirtualTerminal: false,
      futurePaymentModalOpen: false
    };
  }

  UNSAFE_componentWillMount() {
    this.loadData(this.props);
    this.props.dispatch(setCustomer({}));
    if (this.props.auth.needsInfo) {
      this.props.dispatch(setOpenMessageDialog('restrictedMbp'));
    }

    this.setState({
      cardPresentUtil: new CardPresentUtil(this.props.dispatch)
    });

  }

  componentWillUnmount() {
    /* istanbul ignore else */
    if (this.props.cardPresent.isUsing && this.state.cardPresentUtil && this.state.cardPresentUtil.connectionState > 3) {
      this.toggleCardPresent();
      this.state.cardPresentUtil.close();
    }
    this.props.dispatch(setVisibilityFilter(null, 'filter'));

  }

  componentDidUpdate(prevProps, prevState) {

    const nextProps = this.props;

    // we must have current form data (meaning we're actively trying to process a card), no previous card data (prevent duplicate transactions)
    // and card data in the most recent reducer (meaning we received data to process)
    if (this.state.cardPresentPendingFormData == null &&
      nextProps.cardPresent.isUsing && !nextProps.cardPresent.isWorking && nextProps.cardPresent.state && nextProps.cardPresent.state.cardData &&
      (!prevProps.cardPresent.state || !prevProps.cardPresent.state.cardData || prevProps.cardPresent.state.cardData != nextProps.cardPresent.state.cardData)) {

      // immediately send out the isWorking flag
      this.props.dispatch(updateCardPresentState({isWorking: true}));
      let data = {
        tlv: _.cloneDeep(nextProps.cardPresent.state.cardData),
        cpVersion: _.cloneDeep(nextProps.cardPresent.state.driver?.version),
        machineSerialNumber: nextProps.cardPresent.state.systemInfo?.serialNumber
      };

      let chunks = (data.tlv.cardholderName || '').split(' ');
      data.first_name = chunks.shift();
      data.last_name = chunks.join(' ');

      this.setState({cardPresentPendingFormData: data});
    }

    // process if card present data exists
    if (nextProps.cardPresent.isUsing && !prevState.cardPresentPendingFormData && this.state.cardPresentPendingFormData) {
      this.state.cardPresentUtil.resetReading();
      this.onProcessClick();
      this.props.dispatch(updateCardPresentState({isWorking: false}));
    }

    // update the token if it exists
    if (nextProps.cardPresent.isUsing && nextProps.auth?.desktopToken && (prevProps.auth?.desktopToken != nextProps.auth.desktopToken)) {
      this.state.cardPresentUtil.authenticate(nextProps.auth.desktopToken);
    }

    // kill card present if necessary (usually for logout or state changes)
    if (!nextProps.cardPresent.isUsing && prevProps.cardPresent.isUsing) {
      this.state.cardPresentUtil.disconnect();
    }

    if (nextProps.vtFormValues?.cashDiscounting && prevProps.vtFormValues?.cashDiscountingAmount !== nextProps?.vtFormValues?.cashDiscountingAmount) {
      const newCart = InvoiceUtil.recalculateCart(this.state.itemizedCart, nextProps?.vtFormValues, nextProps?.loyaltyVpc?.rewardCodeInfo);
      this.setState({itemizedCart: newCart});
    }
  }

  loadData(props) {
    const isPayanywhereMerchant = UserUtil.isPayanywhere(props.user);

    if (isPayanywhereMerchant) {
      props.dispatch(getDiscounts(props.user));
    }

    if (isPayanywhereMerchant && (!props.items.categories || props.merchantSettings.express_category_enabled || props.items.salesItemsPagination?.pageSize)) {
      props.dispatch(getItems(props.user));
    }

    props.dispatch(setVisibilityFilter({
      property: 'paymentType',
      value: 'Custom amount'
    }, 'filter'));

    props.dispatch(getCustomers(props.user))
  }

  toggleLoyaltyRewardCode(isRemove) {
    const isItemizedSale = this.props.visibilityFilter?.filter?.value === 'Itemized sale';
    let newCart = this.state.itemizedCart;

    if (isRemove) {
      if (isItemizedSale) {
        newCart = InvoiceUtil.recalculateCart(this.state.itemizedCart, this.props?.vtFormValues, {});
      }

      this.props.dispatch(removeRewardCode());
    } else if (isItemizedSale) {
      newCart = InvoiceUtil.recalculateCart(this.state.itemizedCart, this.props?.vtFormValues, this.props.loyaltyVpc.rewardCodeInfo);
    }

    this.setState({itemizedCart: newCart});
  }

  handleClose() {

    const {payment = {}, showConfirmation, operationType} = this.props.userExperience.modalVisibility;
    const isInvoice = this.props.visibilityFilter?.filter?.value === 'Create an invoice';

    if ((payment.response && payment.response.response_code === 'APR') || operationType === 'partial' || showConfirmation || isInvoice) {
      this.props.dispatch(setModalVisibility('hidden'));
      location.reload();
    } else if (payment.response && payment.response.response_code === 'AVS') {
      this.props.dispatch(setModalVisibility('vt', {showConfirmation: true}));
    } else {
      this.props.dispatch(setModalVisibility('hidden'));
    }

  }

  handleViewInTransactions = () => {
    this.props.dispatch(setSelectedDateRange('Today', DateUtil.getToday()));
    this.handleClose();
  };

  issueCredit =  async (values)  => {
    const { issueCreditInProgress } = this.state;
    const { dispatch, t } = this.props;

    if (!issueCreditInProgress) {
      const processCb = async () => {
        try {
          const resp = await PaymentUtil.processIssueCredit(values, this.props);
          dispatch(setModalVisibility('vt', {...resp}));
        } catch(error) {
          toastr.error('Error', t('IssueCreditError'));
        } finally {
          this.setState({ issueCreditInProgress: false });
        }
      }
      this.setState({ issueCreditInProgress: true }, processCb);
    }
  }

  process(formValues) {

    const { merchantSettings: { merchantSettings }, visibilityFilter, vtFormValues, vtFormSyncErrors, user } = this.props;

    if (UserUtil.isNorthDemoAccount(user)) {
      this.openNorthGatekeeper();
      return;
    }

    const fieldsWithErrors = Object.getOwnPropertyNames(vtFormSyncErrors || {});
    if (!!fieldsWithErrors.length) {
      return;
    }

    const trimmedFormValues = FormUtil.getTrimmedFormValues(vtFormValues);
    let values = {...formValues, ...trimmedFormValues};

    values.customer_state = statesList.find(state =>
        [state.label, state.key].includes(values.customer_state?.key || values.customer_state))?.key;

    if (visibilityFilter?.filter?.value === 'Issue a credit') {
      this.issueCredit(values);
    } else if (!this.state.processPaymentInProgress) {

      //Uses setState callback to make sure we process only after the state has been set
      this.setState({processPaymentInProgress: true}, () => {

        // this means we're processing tlv data from the card reader
        const processedFromCardReader = this.state.cardPresentPendingFormData || null;
        values = _.omit(values, 'creditCardPaymentFlag');

        if (processedFromCardReader) {
          values = Object.assign({}, values, this.state.cardPresentPendingFormData);
          this.setState({cardPresentPendingFormData: null}); // assure we don't rerun this
        }

        let that = this;

        const itemizedCart = InvoiceUtil.recalculateCart(that.state.itemizedCart, that.props.vtFormValues, that.props.loyaltyVpc.rewardCodeInfo);

        if (visibilityFilter?.filter?.value === 'Custom amount') {
          let effectiveLoyaltyDiscountAmount = numeral(values.amount).value() - numeral(values?.rewardCodeInformation?.amount).value()
          const shouldChangeLoyaltyDiscountAmount = effectiveLoyaltyDiscountAmount < 0 && values?.rewardCodeInformation?.type === 'dollar';
          if (shouldChangeLoyaltyDiscountAmount) {
            values.rewardCodeInformation.amount = `${numeral(values.rewardCodeInformation.amount).value() - Math.abs(effectiveLoyaltyDiscountAmount)}`
          }
        }

        return new Promise(function (resolve, reject) {
          PaymentUtil.process(values, that.props, itemizedCart)
            .then(function (resp) {
              if (processedFromCardReader) {
                that.clearCardPresentAfterTransaction();
              }

              if (resp.postPaymentError && (resp.response_code === 'APR' || resp.response_code === 'AVS')) {
                if (resp?.isPartialAuth) {
                  that.props.dispatch(setModalVisibility('partialPayment', {
                    ...resp,
                    postPaymentError: resp.postPaymentError
                  }));
                } else {
                  if(!merchantSettings?.ignore_avs_failure){
                    that.props.dispatch(setModalVisibility('avs', {
                      ...resp,
                      postPaymentError: resp.postPaymentError
                    }));
                  }
                }
              } else {
                if (resp?.isDeclined){
                  that.props.dispatch(setModalVisibility('vt', {
                      postPaymentError: {type:'expiredCard', heading:'Declined', message: resp?.status_message }
                  }));
                } else if (!resp?.isPartialAuth){
                  that.props.dispatch(setModalVisibility('vt', {...resp}));
                }  else if (resp?.isDuplicate) {
                  that.props.dispatch(setModalVisibility('duplicate', {...resp,}));
                }  else {
                  that.props.dispatch(setModalVisibility('partialPayment', {...resp}));
                }
              }

              that.setState({processPaymentInProgress: false});

            }).catch((error) => {
            if (processedFromCardReader) {
              that.clearCardPresentAfterTransaction();
            }
            that.setState({processPaymentInProgress: false});
            // expired card code EPX
            if(error?.reason?.status_code === '54'){
              that.props.dispatch(setModalVisibility('vt', {
                postPaymentError: {type:'expiredCard', heading:'Declined', message: 'Validator.InvalidCardNumber' }
              }));
            }
          });
          resolve();
        });

      });

    }
  }

  sendInvoice = (values) => {
    const { isSavingDraft, sendInvoiceInProgress, itemizedCart } = this.state;
    const { loyaltyVpc, user } = this.props;

    if (UserUtil.isNorthDemoAccount(user)) {
      this.openNorthGatekeeper();
      return;
    }

    const recalculateItemizedCart = InvoiceUtil.recalculateCart(itemizedCart, values, loyaltyVpc?.rewardCodeInfo);

    if (!sendInvoiceInProgress) {
      this.setState({ sendInvoiceInProgress: true });

      const formValues = {
        ...values,
        isDraft: isSavingDraft,
      };

      formValues.customer_state = statesList.find(state =>
          [state.label, state.key].includes(values.customer_state?.key || values.customer_state))?.key;

      if (values.selectedPaymentMethod === null && (values.selectedDueDateValue > 0 || values.selectedSendDateValue > 0)) {
        formValues.saveCreditCard = true;
      }

      InvoiceUtil.processInvoice(
        formValues,
        recalculateItemizedCart,
        this.props,
        !isSavingDraft
      )
        .then((response) => this.handleSendInvoiceResponse(response, formValues))
        .catch(this.handleSendInvoiceError);
    }
  };

  payInvoice = (values) => {
    const { dispatch, user, userExperience } = this.props;
    const { sendInvoiceInProgress } = this.state;

    const invoiceId = userExperience.modalVisibility?.payment?.response?.invoice_id;

    if (!sendInvoiceInProgress) {
      this.setState({ sendInvoiceInProgress: true });

      dispatch(payInvoiceWithoutToken(user, invoiceId, values))
        .then((response) => this.handleSendInvoiceResponse(response, values))
        .catch(this.handleSendInvoiceError);
    }
  };

  handleSendInvoiceResponse = (response, formValues) => {
    const { dispatch } = this.props;
    const { merchantSettings } = this.props.merchantSettings;

    if (response?.type === actionTypes.creditCardPaymentFailure) {
      this.handleSendInvoiceError(response);
    } else if (formValues?.paymentMethod !== PaymentMethod.SEND_TO_CUSTOMER && formValues?.frequency !== PaymentFrequency.SERIES) {
      this.setState({ sendInvoiceInProgress: false, itemizedCart: InvoiceUtil.initialItemizedCartObject() });

      const { requested_amt, authorized_amt, response_code } = response?.response;
      const isApproved = response_code === 'APR';
      const isAvs = response_code === 'AVS';
      const isPartialPayment = requested_amt !== authorized_amt;
      const transactionId = FormatTextUtil.formatTransactionId(response.response?.uniq_id);

      if (isApproved && isPartialPayment) {

        dispatch(setModalVisibility('partialPayment', {
          payment: {
            ...response,
            response: {
              ...response.response,
              entity: {
                id: transactionId
              }
            }
          },
          receipt: {
            id: transactionId
          },
          formValues,
          postPaymentError: {
            type: 'partial',
            heading: 'Errors.PartialPayment.Heading',
            message: 'Errors.PartialPayment.Message'
          }
        }));
      } else if (isApproved || (isAvs && merchantSettings?.ignore_avs_failure)) {
        if (this.state.isSavingDraft) {
          this.handleInvoiceSavedAsDraft();
        } else {
          dispatch(setModalVisibility('vtInvoice', {
            invoicePayment: response.response,
            receipt: {
              id: FormatTextUtil.formatTransactionId(response.response.uniq_id),
              loyalty_vpc: response.response.loyalty_vpc
            }
          }));
        }
      } else if (response?.response?.id) {
         this.setState({futurePaymentModalOpen: true});
      } else {
        if (response?.response?.response_code === 'AVS' && !merchantSettings?.ignore_avs_failure) {
          dispatch(setModalVisibility('avs', {
            payment: {
              ...response
            },
            postPaymentError: {
              type: 'avs',
              heading: 'Errors.Avs.Heading',
              message: 'Errors.Avs.Message'
            }
          }));
        } else {
          dispatch(setModalVisibility('vt', {
            postPaymentError: {
              type: 'expiredCard',
              heading: 'Declined',
              message: response?.response?.status_message
            }
          }));
        }
      }

    } else {
      if (this.state.isSavingDraft) {
        this.handleInvoiceSavedAsDraft();
      } else {
        this.setState({ sendInvoiceInProgress: false, itemizedCart: InvoiceUtil.initialItemizedCartObject() });
        dispatch(setModalVisibility('vtInvoice', {
          receipt: response?.response?.receipt
        }));
      }
    }
  };

  handleSendInvoiceError = (error) => {
    const { dispatch, t } = this.props;
    let errorMessage;

    try {
      errorMessage = JSON.parse(error.error).reason;
    } catch (e) {
      errorMessage = t('InvoiceStatusMessageDialog.Error');
    }

    this.setState({ sendInvoiceInProgress: false, itemizedCart: InvoiceUtil.initialItemizedCartObject() });
    dispatch(setModalVisibility('vtInvoice', { errorMessage }));
  };

  toggleCardPresent() {
    const isUsing = !this.props.cardPresent.isUsing;

    if (isUsing) {
      this.state.cardPresentUtil.connect();
    } else {
      this.state.cardPresentUtil.disconnect();
    }
    this.props.dispatch(updateCardPresentState({isUsing: isUsing}));
    this.setState({renderer: !this.state.renderer});
  }

  clearCardPresentAfterTransaction() {
    const cardPresentState = this.props.cardPresent.state,
      state = {state: Object.assign({}, cardPresentState, {cardData: null})};
    this.props.dispatch(updateCardPresentState(state));
    this.setState({cardPresentPendingFormData: null});
    this.state.cardPresentUtil.resetReading();
  }

  handleSendReceipt() {

    const that = this;
    const {payment, formValues} = this.props.userExperience.modalVisibility;
    const { response } = payment;
    let receiptValues = {id: response?.entity?.id, type: response?.uniq_id?.includes('ach') ? 'ACH Sale' : 'Credit Sale'};
    if (payment.type === actionTypes.creditMerchantRefundSuccess) {
      receiptValues = { id: response?.refund_id, type: response?.uniq_id?.includes('ach') ? 'ACH Refund' : 'Credit Refund' };
    }

    const validEmails = _.filter(formValues?.customer_email_addresses, contact => emailValidator.validate(contact));
    const validMobileNumber = Validator.isPhoneNumber(formValues.customer_phone) ? formValues.customer_phone : '';

    const {dispatch, user} = that.props;

    return new Promise((resolve, reject) => {
      return ReceiptUtil.sendReceipt(dispatch, user, receiptValues, validEmails, validMobileNumber).then(() => {
        that.props.dispatch(setModalVisibility('vt'));
        resolve()
      }).catch(error => {
        reject(error);
      });
    });
  }

  validateAmountOrSubmit = () => {
    const { vtFormValues, invoiceFormValues, visibilityFilter, loyaltyVpc, invoiceFormSyncErrors, dispatch } = this.props;
    const { itemizedCart } = this.state;

    let amount, shouldValidateLowAmount, shouldValidateHighAmount, values;

    const isInvoiceFilter = visibilityFilter?.filter?.value === 'Create an invoice';

    if (isInvoiceFilter) {
      if (invoiceFormValues.amount === '') {
        dispatch(change(INVOICE_FORM_ID, 'amount', 0));
      }

      values = invoiceFormValues;

      amount = values.type === 'itemized'
        ? InvoiceUtil.recalculateCart(itemizedCart, values, loyaltyVpc.rewardCodeInfo)?.total_amt
        : InvoiceUtil.recalculateCartCustomAmount(values, loyaltyVpc.rewardCodeInfo)?.total;

      const invoiceFieldsWithErrors = Object.getOwnPropertyNames(invoiceFormSyncErrors || {});
      shouldValidateLowAmount = amount === 0 && !invoiceFieldsWithErrors.length;
      shouldValidateHighAmount = amount > highTransactionLimitAmount && !invoiceFieldsWithErrors.length;
    } else {
      values = vtFormValues;
      amount = visibilityFilter?.filter?.value === 'Itemized sale'
        ? InvoiceUtil.recalculateCart(itemizedCart, values, loyaltyVpc.rewardCodeInfo)?.total_amt
        : InvoiceUtil.recalculateCartCustomAmount(values, loyaltyVpc.rewardCodeInfo)?.total;

       shouldValidateLowAmount = false;
       shouldValidateHighAmount = visibilityFilter?.filter?.value !== 'Issue a credit' && amount > highTransactionLimitAmount;
    }

    if (shouldValidateHighAmount) {
      this.openHighAmountDialog();
    } else if (shouldValidateLowAmount) {
      this.setState({showLowAmountWarningModal: true});
    } else if (amount >= 0) {
      this.handleSubmitActions();
    }
  };

  closeHighAmountDialog() {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('hidden'));
  }

  openHighAmountDialog() {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('highTransactionAmountDialog'));
  }

  closeLowAmountDialog = () => this.setState({ showLowAmountWarningModal: false });

  handleSubmitActions = () => {
    const { dispatch, visibilityFilter } = this.props;

    this.closeHighAmountDialog();
    this.closeLowAmountDialog();

    if (visibilityFilter?.filter?.value === 'Create an invoice') {
      dispatch(submit(INVOICE_FORM_ID));
    } else {
      dispatch(submit('virtualTerminalForm'));
    }
  };

  onProcessClick() {
    const { processPaymentInProgress } = this.state;

    this.setState({ submitButtonClicked: true }, () => {
      if (!processPaymentInProgress) {
        this.validateAmountOrSubmit();
      }
    });
  }

  onIssueCreditClick() {
    const { issueCreditInProgress } = this.state;

    this.setState({ submitButtonClicked: true }, () => {
      if (!issueCreditInProgress) {
        this.validateAmountOrSubmit();
      }
    });
  }

  onSaveDraftClick = () => {
    const { sendInvoiceInProgress } = this.state;

    this.setState({ submitButtonClicked: true, isSavingDraft: true }, () => {
      if (!sendInvoiceInProgress) {
        this.validateAmountOrSubmit();
      }
    });
  };

  onSendInvoiceClick = () => {
    const { sendInvoiceInProgress } = this.state;

    this.setState({ submitButtonClicked: true, isSavingDraft: false }, () => {
      if (!sendInvoiceInProgress) {
        this.validateAmountOrSubmit();
      }
    });
  };

  getDisableSendInvoiceButton(isItemizedSale) {
    const { invoiceFormValues } = this.props;
    const { itemizedCart } = this.state;
    const isItemizedInvoice = invoiceFormValues?.type === FormType.ITEMIZED;

    return InvoiceUtil.getDisableSendInvoice(itemizedCart, isItemizedSale || isItemizedInvoice);
  }

  onProcessVoidButtonAction(reloadPage = true) {

    const that = this;

    const { visibilityFilter } = that.props;
    const isInvoice = visibilityFilter?.filter?.value === 'Create an invoice';

    this.props.dispatch(setModalVisibility('hidden'));

    return new Promise((resolve, reject) => {
      that.onProcessVoid().then(() => {
        reloadPage && location.reload();
      }).catch((e) => {
        let errorMessage;

        try {
          const parsedError = JSON.parse(e);
          errorMessage = parsedError?.reason;
          toastr.error('Error', errorMessage);
        } catch (error) {
          errorMessage = e ?? error?.message;
          toastr.error('Error', errorMessage);
        }

        if (isInvoice) {
          this.props.dispatch(setModalVisibility('vtInvoice', { errorMessage }));
        }
      }).finally(() => {
        resolve();
      });
    });

  }

  onProcessVoid() {
    let that = this;

    return new Promise(function (resolve, reject) {

      const {payment, receipt} = that.props.userExperience.modalVisibility;

      const voidPayload = {
        id: payment?.response?.entity?.id,
        item_ids: !!receipt?.line_items ? _.map(receipt.line_items, 'id') : []
      };

      that.props.dispatch(processCreditCardVoid(voidPayload, that.props.user)).then((voidResponse) => {
        that.setState({processPaymentInProgress: false});
        if (voidResponse.hasOwnProperty('error')) {
          const err = voidResponse.error || that.props.t(messages.errors.voidRefundError);
          Bugsnag.notify(new Error(err), event => {
            event.addMetadata('user_id', that.props.user?.data?.user_id);
            event.addMetadata('void_id', voidPayload?.id);
            event.addMetadata('void_item_ids', voidPayload?.item_ids);
          });
          reject(err);
        } else {
          resolve(voidResponse);
        }
      });
    });
  }

  isRefund(payment) {
    return payment?.type === actionTypes.creditMerchantRefundSuccess
  }

  isAchPayment(paymentDetails) {
    return paymentDetails?.uniq_id?.includes('ach');
  }

  getReceiptType(paymentDetails, payment) {

    const isAchPayment = this.isAchPayment(paymentDetails);

    if (this.isRefund(payment)) {
      return isAchPayment ? 'ACH Refund' : 'Credit Refund';
    } else {
      return isAchPayment ? 'ACH Sale' : 'Credit Sale';
    }
  }

  getReceiptId(paymentDetails, payment) {
    if(this.isRefund(payment)){
     return paymentDetails?.refund_id
    }else{
      return paymentDetails?.id ?? paymentDetails?.entity?.id ?? payment.receipt?.id;
    }
  }

  getPaymentDetails(){
    const payment = this.props.userExperience.modalVisibility?.payment?.response;
    const invoicePayment = this.props.userExperience.modalVisibility?.invoicePayment;

    return payment ?? invoicePayment;
  }

  handlePrintReceipt() {

    let that = this;

    const paymentDetails = this.getPaymentDetails();
    const payment = this.props.userExperience.modalVisibility?.payment ?? this.props.userExperience.modalVisibility;

    const selectedReceipt = {
      type: this.getReceiptType(paymentDetails, payment),
      id: this.getReceiptId(paymentDetails, payment),
      device: this.state.renderer
    };

    return ReceiptUtil.getPrintReceipt(that.props, selectedReceipt).catch(error => toastr.error('Error', error));
  }

  clearSelectedCustomer = () => {
    const {dispatch} = this.props;
    dispatch(setCustomer({}));
  }

  clearCustomerFields = (includeEmail = true) => {
    const {dispatch} = this.props;

    const fieldsToClean = [
      'customer_phone',
      'customer_first',
      'customer_last',
      'customer_street_address_1',
      'customer_street_address_2',
      'customer_state',
      'customer_zip',
      'customer_city'
    ];

    if (includeEmail) {
      fieldsToClean.unshift('customer_email_addresses');
    }

    const isInvoice = this.getSelectedPaymentType() === 'invoice';

    fieldsToClean.forEach((field) => {
      if (isInvoice) {
        dispatch(change(INVOICE_FORM_ID, field, null));
      } else {
        dispatch(change('virtualTerminalForm', field, ''));
      }
    });
  }

  handleFilterSelection(filter) {
    const { userExperience, dispatch } = this.props;

    dispatch(setVisibilityFilter({
      property: 'paymentType',
      value: filter.name
    }, 'filter'));

    this.clearSelectedCustomer();
    this.clearCustomerFields();

    dispatch(removeRewardCode());
    this.setState({
      itemizedCart: InvoiceUtil.initialItemizedCartObject(),
      submitButtonClicked: false
    });

    if (filter.value === 'issueCredit' && !userExperience.issueCreditDialogAcknowledged) {
      this.openIssueCreditDialog();
    }

  }

  handleInvoiceSavedAsDraft() {
    const {t, dispatch} = this.props;
    toastr.success(t('DraftNotification.Title'), t('DraftNotification.Description'), {
      icon: IconUtils.getIcon('InvoiceDraftIcon', LabelUtil.getLabelColor()),
      progressBar: false,
      removeOnHover: false,
      className: 'draftInvoiceNotification'
    });

    this.setState({sendInvoiceInProgress: false, submitButtonClicked: false});
    this.invoiceFormRef.current?.scrollIntoView();
    window.scrollTo(0,0);
    dispatch(reset(INVOICE_FORM_ID));
  }

  openItemsDialog(selectedItemIndex) {

    const { items, dispatch } = this.props;
    const selectedItem = items.filteredItems && items.filteredItems.length > 0 && items.filteredItems[selectedItemIndex];

    dispatch(setSalesItem(selectedItem, items));
    this.setState({selectedItemIndex: selectedItemIndex, isItemsDialogOpen: true, isVirtualTerminal: false});
  }

  openItemsDialogFromVirtualTerminal(selectedItemIndex) {
    this.openItemsDialog(selectedItemIndex);
    this.setState({isVirtualTerminal: true});
  }

  closeItemsDialog() {

    const { items, dispatch } = this.props;

    dispatch(setSalesItem(null, items));
    this.setState({selectedItemIndex: null, isItemsDialogOpen: false, isEditingItem: false});
  }

  openIssueCreditDialog() {
    this.setState({isIssueCreditDialogOpen: true});
  }

  closeIssueCreditDialog() {
    this.setState({isIssueCreditDialogOpen: false});
  }

  closeFuturePaymentDialog() {
    this.setState({futurePaymentModalOpen: false});
  }

  cancelIssueCreditDialog() {
    this.handleFilterSelection('Custom amount');
    this.closeIssueCreditDialog();
  }

  onAcknowledgeIssueCreditDialog() {
    this.props.dispatch(acknowledgeIssueCreditDialog());
    this.closeIssueCreditDialog();
  }

  handleOpenPreviewModal = () => {
    this.setState({ openPreviewModal: true });
  };

  handleClosePreviewModal = () => {
    this.setState({ openPreviewModal: false });
  };

  handlePreviewInvoice = () => {
    const { dispatch, invoiceFormSyncErrors } = this.props;

    const fieldsWithErrors = Object.getOwnPropertyNames(invoiceFormSyncErrors || {});

    if (!fieldsWithErrors.length || (fieldsWithErrors.length === 1 && fieldsWithErrors[0] === 'amount')) {
      this.handleOpenPreviewModal();
    } else {
      dispatch(submit(INVOICE_FORM_ID));
      this.setState({ submitButtonClicked: true });
    }
  };

  onAddItemClick(itemCurrentSubtotal, itemCurrentTax, selectedModifiers) {

    this.setState({
      addToOrderAdditionalInfo: {
        itemCurrentSubtotal,
        itemCurrentTax,
        selectedModifiers
      }
    }, () => {

      this.props.dispatch(submit('virtualTerminalFormItemSelection'));

    });

  }

  onRemoveFromOrderClick(itemIndex) {
    this.deleteItem(itemIndex);
    this.closeItemsDialog();
  }

  handleAddItemToOrder(formValues) {
    const { addToOrderAdditionalInfo, itemizedCart, selectedItemIndex, isEditingItem } = this.state;
    const { selectedItem } = this.props.items;
    const { taxRate } = this.props.taxes;

    let newCart = InvoiceUtil.handleAddItemToOrder(formValues, addToOrderAdditionalInfo, itemizedCart, selectedItemIndex, isEditingItem, selectedItem, taxRate);
    newCart = InvoiceUtil.recalculateCart(newCart, this.props?.vtFormValues, this.props.loyaltyVpc.rewardCodeInfo);
    this.setState({itemizedCart: newCart});
    this.closeItemsDialog();

  }

  addDiscountToCart(discountIndex) {
    let newCart = InvoiceUtil.addDiscountToCart(discountIndex, this.props.items, this.state.itemizedCart);
    newCart = InvoiceUtil.recalculateCart(newCart, this.props?.vtFormValues, this.props.loyaltyVpc.rewardCodeInfo);
    this.setState({itemizedCart: newCart});
  }

  cleanItemizedCart() {
    const itemizedCart = InvoiceUtil.initialItemizedCartObject();
    this.setState({ itemizedCart });
  }

  changeItemQuantity(itemIndex, isSubtracting) {
    let itemizedCart = InvoiceUtil.changeItemQuantity(itemIndex, isSubtracting, this.state.itemizedCart);
    itemizedCart = InvoiceUtil.recalculateCart(itemizedCart, this.props?.vtFormValues, isSubtracting, this.props.loyaltyVpc.rewardCodeInfo);
    this.setState({ itemizedCart });
  }

  deleteItem(itemIndex) {
    let itemizedCart = InvoiceUtil.deleteItem(itemIndex, this.state.itemizedCart);
    itemizedCart = InvoiceUtil.recalculateCart(itemizedCart, this.props?.vtFormValues, this.props.loyaltyVpc.rewardCodeInfo);
    this.setState({ itemizedCart });
  }

  editItem(itemIndex) {
    const { items } = this.props;
    const { itemizedCart } = this.state;

    const { selectedItem } = InvoiceUtil.editItem(itemIndex, items, itemizedCart);

    this.props.dispatch(setSalesItem(selectedItem, items));
    this.setState({selectedItemIndex: itemIndex, isItemsDialogOpen: true, isEditingItem: true});

  }

  deleteDiscount(discountIndex) {
    let newCart = InvoiceUtil.deleteDiscount(discountIndex, this.state.itemizedCart);
    newCart = InvoiceUtil.recalculateCart(newCart, this.props?.vtFormValues, this.props.loyaltyVpc.rewardCodeInfo);
    this.setState({ itemizedCart: newCart });
  }

  confirmPartialPayment() {
    const { userExperience: { modalVisibility }, dispatch, visibilityFilter } = this.props;

    const isInvoice = visibilityFilter?.filter?.value === 'Create an invoice';
    const partialPayment = modalVisibility?.payment?.response;
    const authorizedAmt = parseFloat(partialPayment?.authorized_amt);
    const requestedAmt = parseFloat(partialPayment?.requested_amt);
    const remainingBalance = (requestedAmt - authorizedAmt).toFixed(2);

    this.setState({
      partialPayment: { ...partialPayment, remainingBalance, authorized_amt: authorizedAmt, requested_amt: requestedAmt},
      isPayInvoice: isInvoice
    });

    const formattedRemainingBalance = FormatTextUtil.formatCurrencyWithMaxDigit(remainingBalance, 20);
    if (isInvoice) {
      dispatch(change(INVOICE_FORM_ID, 'amount', formattedRemainingBalance));
    } else {
      dispatch(change('virtualTerminalForm', 'amount', formattedRemainingBalance));
    }

    this.props.dispatch(setModalVisibility('hidden'));
  }

  proceedWithDuplicate() {
    const { userExperience: { modalVisibility } } = this.props;
    const payment = modalVisibility?.payment?.response;
    if (payment?.authorized_amt !== payment?.requested_amt) { // Partial auth
      this.props.dispatch(setModalVisibility('partialPayment', payment));
    } else {
      this.props.dispatch(setModalVisibility('vt', {}));
    }
    this.setState({processPaymentInProgress: false});
  }

  voidWithoutReload() {
    const { visibilityFilter } = this.props;
    const isInvoice = visibilityFilter?.filter?.value === 'Create an invoice';

    if (isInvoice) {
      this.setState({ isPayInvoice: true });
    }

    this.onProcessVoidButtonAction(false);
  }

  openNorthGatekeeper() {
    this.props.dispatch(toggleNorthGatekeeper(true));
    LocalStorageUtil.setDemoAccountTriedPayment();
  }

  getCartForPreview() {
    const { invoiceFormValues } = this.props;
    const { itemizedCart, openPreviewModal } = this.state;

    if (!openPreviewModal) return {};

    return  InvoiceUtil.previewCart(itemizedCart, invoiceFormValues, this.props.loyaltyVpc.rewardCodeInfo);
  }

  onCreatePaymentLinkClick() {
    this.props.dispatch(submit(PAYMENT_LINK_FORM_ID));
  }

  getSelectedPaymentType = () => {
    const {visibilityFilter} = this.props;
    const filterValue = (visibilityFilter?.filter?.value) || '';
    let selectedPaymentType;

    switch (filterValue) {
      case 'Debt repayment':
        selectedPaymentType = 'debtRepayment';
        break;
      case 'Itemized sale':
        selectedPaymentType = 'itemizedSale';
        break;
      case 'Create an invoice':
        selectedPaymentType = 'invoice';
        break;
      case 'Create Payment Link':
        selectedPaymentType = 'paymentLink';
        break;
      case 'Issue a credit':
        selectedPaymentType = 'issueCredit';
        break;
      case 'Custom amount':
      default:
        selectedPaymentType = 'oneTimePayment';
    }

    return selectedPaymentType;
  }

  render() {

    const isMobile = MobileUtil.isMobileDevice();
    const { userExperience, taxes, virtualTerminal, invoices, transactions, user, auth, visibilityFilter, vtFormSyncErrors, invoiceFormSyncErrors, items, customers, t, merchantSettings, dispatch } = this.props;

    const { payment, invoicePayment, receipt, postPaymentError, errorMessage, formValues, partialPaymentDialogOpen  } = userExperience.modalVisibility;
    let submitButtonDisabled = (typeof userExperience.enableFormSubmitButton != 'undefined') ? !userExperience.enableFormSubmitButton && virtualTerminal.isProcessing : false;

    const readOnly = !UserUtil.isActive(user) || auth.isSpr;

    const { isProcessing, isFetching } = virtualTerminal;
    const isProcessingInvoice = invoices.isProcessing;
    const isProcessingTransaction =  transactions?.isProcessing;

    const transactionPath = globalApplicationLabel.path + routes.activity.root + routes.activity.transactions;
    const invoicesPath = globalApplicationLabel.path + routes.business.root + routes.business.invoices;
    const isApproval = payment && payment.response && approvedResponseCodes.includes(payment.response.response_code);
    const isSavingCustomer = customers.isFetching || customers.selectedCustomer?.isFetching;

    const showPostPaymentError = isApproval && postPaymentError !== null;
    const hasExpiredCardError = postPaymentError && postPaymentError.type === 'expiredCard';
    const hasAVSError = postPaymentError && postPaymentError.type === 'avs';
    const showViewInTransactions = !(auth.isManager && !auth.isManagerActivity);

    let declineStatusMessage = payment && payment.response && payment.response.transaction_response ? payment.response.transaction_response.status_message : null;

    declineStatusMessage = hasExpiredCardError ? t(postPaymentError?.message) : declineStatusMessage;

    const filterValue = (visibilityFilter?.filter?.value) || '';
    const selectedPaymentType = this.getSelectedPaymentType();

    const isItemizedSale = selectedPaymentType === 'itemizedSale';
    const isInvoice = selectedPaymentType === 'invoice';
    const isPaymentLink = selectedPaymentType === 'paymentLink';
    const isIssueCredit = selectedPaymentType === 'issueCredit';
    const syncErrors = isInvoice ? invoiceFormSyncErrors : vtFormSyncErrors;
    const fieldsWithErrors = Object.getOwnPropertyNames(syncErrors || {});

    //For itemized sale, isFormInvalid doesnt consider the amount, we have isAmountInvalid for that (to customize error message)
    const isFormInvalid = isItemizedSale
      ? fieldsWithErrors.length > 1 || (!!fieldsWithErrors.length && fieldsWithErrors[0] !== 'amount')
      : !!fieldsWithErrors.length;
    const isAmountInvalid = !!fieldsWithErrors.find(field => field === 'amount');

    const hasItems = !!(items && items.salesItems && items.salesItems.length);

    const isPaymentApproved = isApproval && postPaymentError === null;

    const isLoading = isProcessing || isProcessingInvoice || isProcessingTransaction || isSavingCustomer;

    const paymentLinksEnabled = merchantSettings?.merchantSettings?.payment_links_enabled;
    const canSeePaymentLink = UserUtil.isPayanywhere(user) && UserUtil.isEPX(user) && paymentLinksEnabled;

    const itemCartHandlers = {
      cleanItemizedCart: this.cleanItemizedCart,
      changeItemQuantity: this.changeItemQuantity,
      deleteItem: this.deleteItem,
      editItem: this.editItem,
      deleteDiscount: this.deleteDiscount
    }

    const pageLoading = (userExperience.geoTaxFetching && (isFetching && !userExperience.modalVisibility.vtDialogOpen)) || taxes.isFetching || userExperience.isFetching;
    if (pageLoading) {
      return  (
        <Page loading title={t('Virtual terminal')} />
      );
    }

    const filterData = [
      {
        name: 'Custom amount',
        value: 'oneTimePayment'
      }
    ];

    const isPremiumPlus =  UserUtil.isPremiumPlusAccount(user);
    const isPremiumPlusLoyalty =  UserUtil.isPremiumPlusWithLoyaltyAccount(user);
    const showPaymentLinks =  (isPremiumPlus || isPremiumPlusLoyalty);

    const hideCreateInvoiceOption =  (auth.isManager &&
      merchantSettings?.merchantSettings?.manager_online_payments_access_enabled &&
      !merchantSettings?.merchantSettings?.manager_can_access_invoices) || !(isPremiumPlus || isPremiumPlusLoyalty);


    if ( hasItems) {
      filterData.push({
        name: 'Itemized sale',
        value: 'itemizedSale'
      });
    }

    if ( !hideCreateInvoiceOption) {
      filterData.push({
        name: 'Create an invoice',
        value: 'invoice'
      });
    }

    if ( canSeePaymentLink) {
      filterData.push({
        name: 'Create Payment Link',
        value: 'paymentLink'
      });
    }

    filterData.push({
      name: 'Issue a credit',
      value: 'issueCredit'
    });

    if ( auth.debtRepayment) {
      filterData.push({
        name: 'Debt repayment',
        value: 'debtRepayment'
      });
    }

    if ( isPremiumPlus && auth.role !== 'report') {
      filterData.push(
        { name: 'Settings', value: 'settings', border: true }
      );
    }

    const filterPanel =  (
      <FilterPanel
        {...this.props}
        filterData={filterData}
        selectFilterCallback={this.handleFilterSelection}
        buttonBar
      />
    );

    const loyaltyVpc =  merchantSettings.loyaltyVpc;
    const isLoyaltyProgramEnabled =  loyaltyVpc?.enabled;
    const isPercentReward =  receipt?.loyalty_vpc?.reward_amount_type === 'percent';
    const loyaltyIcon = IconUtils.getIcon('LoyaltyProgramIcon');

    if ( isLoyaltyProgramEnabled && receipt?.loyalty_vpc && typeof receipt?.loyalty_vpc !== 'string') {
      receipt.loyalty_vpc.program_label = (receipt.loyalty_vpc.program_name === 'Loyalty Program') ? t('LoyaltyPrograms.TitleSingular') : receipt.loyalty_vpc.program_name;
      receipt.loyalty_vpc.points_label = (receipt.loyalty_vpc.points_name === 'points') ? t('LoyaltyPrograms.Points') : receipt.loyalty_vpc.points_name;
    }

    const paymentStatusMessage =  (
      <div className='paymentStatusMessageDialog'>
        {isFetching && <div><Loading/><br/></div>}
        {isPaymentApproved ? (
          <div className='successWrapper'>
            <span className='statusTitle'>{t('PaymentStatusMessageDialog.Title')}</span>
            {
              isLoyaltyProgramEnabled && receipt?.loyalty_vpc && typeof receipt?.loyalty_vpc !== 'string' &&
              <div className='loyaltyStatusMessage'>
                <div className='loyaltyIcon'>{loyaltyIcon}</div>
                {
                  receipt?.loyalty_vpc?.reward_is_active && receipt.loyalty_vpc.points_earned === receipt.loyalty_vpc.points_to_earn_reward ?
                    <>
                      <p>{t('PaymentStatusMessageDialog.LoyaltyRewardEarned', {amount: isPercentReward ? `${receipt.loyalty_vpc.reward_amount}%` : `$${receipt.loyalty_vpc.reward_amount}`})}</p>
                    </>
                    :
                    <>
                      <p>{`${receipt.loyalty_vpc.points_earned} ${t('PaymentStatusMessageDialog.LoyaltyRewardInfoFirst')} ${receipt.loyalty_vpc.points_to_earn_reward} ${t('PaymentStatusMessageDialog.LoyaltyRewardInfoSecond', {amount: isPercentReward ? `${receipt.loyalty_vpc.reward_amount}%` : `$${receipt.loyalty_vpc.reward_amount}`, points_label: receipt?.loyalty_vpc?.points_label })}`}</p>
                    </>
                }
              </div>
            }
            <div
              className={`paymentLink ${isFetching && 'disabledSubmitButton'} `}
              onClick={!isFetching ? this.handlePrintReceipt : undefined}>{t('PrintReceiptButton')}</div>
            {
              showViewInTransactions &&
              <div onClick={this.handleViewInTransactions}>
                <Link className='paymentLink'
                      to={`${transactionPath}?receiptId=${receipt?.id}`}>
                  {t('PaymentStatusMessageDialog.ViewInTransactionsButton')}
                </Link>
              </div>
            }
            {formValues?.customer_email_addresses?.length > 0 || formValues?.customer_phone ? (
              <div>
                <div
                  className={`paymentLink sendReceiptLink ${isFetching && 'disabledSubmitButton'}`}
                  onClick={!isFetching ? this.handleSendReceipt : undefined}>{t('PaymentStatusMessageDialog.SendReceiptButton')}</div>
              </div>
            ) : null}

          </div>
        ) : (
          <div className='declinedWrapper'>
            {showPostPaymentError ? (
              <div>
                {postPaymentError.heading ?
                  <h1>{t(postPaymentError.heading)}</h1> : null}
                {postPaymentError.message ?
                  <span>{t(postPaymentError.message)}</span> : null}
              </div>
            ) : (
              <div>
                <div className='statusMessage'>{declineStatusMessage}</div>
                <div
                  className='pleaseTryAgain'>{!userExperience.debtRepaymentError ? t('DebtRepaymentError') : null}</div>
                {payment && payment.response && (payment.response.response_code === 'REF' || (payment.response.transaction_response && payment.response.transaction_response.status_code === '01')) ? (
                  <div>
                    <div
                      className={`paymentLink ${isFetching && 'disabledSubmitButton'} `}
                      onClick={!isFetching ? this.handlePrintReceipt : undefined}>{t('PrintReceiptButton')}</div>
                  </div>
                ) : null
                }
              </div>
            )}
          </div>
        )}
      </div>
    );

    const invoiceStatusMessage =  (
      <div className='invoiceStatusMessageDialog'>
        {isFetching && <div><Loading/><br/></div>}
        {!errorMessage ?
          (<div className='successWrapper'>
              <span className='statusTitle'>
                {t(!!invoicePayment ? 'PaymentStatusMessageDialog.Title' : 'InvoiceStatusMessageDialog.Title')}
              </span>
            {
              !!invoicePayment  && <>
                {
                  isLoyaltyProgramEnabled && receipt?.loyalty_vpc && typeof receipt?.loyalty_vpc !== 'string' &&
                  <div className='loyaltyStatusMessage'>
                    <div className='loyaltyIcon'>{loyaltyIcon}</div>
                    {
                      receipt?.loyalty_vpc?.reward_is_active && receipt.loyalty_vpc.points_earned === receipt.loyalty_vpc.points_to_earn_reward ?
                        <>
                          <p>{t('PaymentStatusMessageDialog.LoyaltyRewardEarned', {amount: isPercentReward ? `${receipt.loyalty_vpc.reward_amount}%` : `$${receipt.loyalty_vpc.reward_amount}`})}</p>
                        </>
                        :
                        <>
                          <p>{`${receipt.loyalty_vpc.points_earned} ${t('PaymentStatusMessageDialog.LoyaltyRewardInfoFirst')} ${receipt.loyalty_vpc.points_to_earn_reward} ${t('PaymentStatusMessageDialog.LoyaltyRewardInfoSecond', {amount: isPercentReward ? `${receipt.loyalty_vpc.reward_amount}%` : `$${receipt.loyalty_vpc.reward_amount}`, points_label: receipt?.loyalty_vpc?.points_label })}`}</p>
                        </>
                    }
                  </div>
                }
                <div
                  className={`paymentLink ${isFetching && 'disabledSubmitButton'} `}
                  onClick={!isFetching ? this.handlePrintReceipt : undefined}
                >
                  {t('PrintReceiptButton')}
                </div>
                {
                  showViewInTransactions &&
                  <div onClick={this.handleViewInTransactions}>
                    <Link className='paymentLink'
                          to={`${transactionPath}?receiptId=${receipt?.id}`}>
                      {t('PaymentStatusMessageDialog.ViewInTransactionsButton')}
                    </Link>
                  </div>
                }
              </>
            }
            <div onClick={this.handleClose}>
              <Link
                className='invoiceLink'
                to={`${invoicesPath}${receipt?.id ? `?receiptId=${receipt.id}` : ''}`}
              >
                {t('InvoiceStatusMessageDialog.ViewInInvoicesButton')}
              </Link>
            </div>
          </div>)
          :
          (<div className='declinedWrapper'>
            <div className='statusMessage'>
              {errorMessage}
            </div>
          </div>)
        }
      </div>
    );


    const restrictedAccessDialog =  userExperience.openMessageDialog === 'restrictedMbp' && !UserUtil.isHumbolt(user) && (
      <ErrorMessageDialog
        messageTitle={t('RestrictedAccessDialog.Title')}
        messageContent={t(messages.user.restrictedMbpAccess)}
      />
    );

    const mobileDialog = isMobile ? (<DownloadMobileAppDialog t={t} />) : '';

    const itemsDialog =  (
      <Modal
        hideActions
        hideTitle
        maxWidth='md'
        externalClassName='itemsVTDialog'
        contentClassName='itemsDialogBody'
        onClose={this.closeItemsDialog}
        open={this.state.isItemsDialogOpen}
      >
        {<VirtualTerminalFormItemSelection
          {...this.props}
          selectedItemIndex={this.state.selectedItemIndex}
          itemizedCart={this.state.itemizedCart}
          isEditingItem={this.state.isEditingItem}
          onRequestClose={this.closeItemsDialog}
          onAddToOrderClick={this.onAddItemClick}
          onRemoveFromOrderClick={this.onRemoveFromOrderClick}
          onSubmit={this.handleAddItemToOrder}
          isVirtualTerminal={this.state.isVirtualTerminal}
        />}
      </Modal>
    );

    const duplicateConfirmationDialog =  (
      <MessageDialog
        externalClassName={'duplicateConfirmationDialog'}
        titleText={t('DuplicateConfirmationDialog.Title')}
        bodyText={t('DuplicateConfirmationDialog.Description')}
        confirmText={t('Yes')}
        cancelText={t('NoVoid')}
        isChoiceRequired={true}
        open={!!this.props.userExperience?.modalVisibility?.vtDuplicateDialogOpen}
        onConfirm={this.proceedWithDuplicate}
        onRequestClose={this.onProcessVoidButtonAction}
        hideCloseIcon={true}
      />
    );

    const authorizedAmt =  payment && parseFloat(payment.response.authorized_amt);
    const requestedAmt =  payment && parseFloat(payment.response.requested_amt);
    const remainingBalance =  payment && (requestedAmt - authorizedAmt);

    const formattedAuthorizedAmt =  payment && numeral(authorizedAmt.toFixed(2)).format('$0,0.00');
    const formattedRemainingBalance =  payment && numeral(remainingBalance.toFixed(2)).format('$0,0.00');

    const partialPaymentDialog =  (
      <MessageDialog
        externalClassName={''}
        titleText={t('PartialPaymentModal.Title', { authorizedAmt: formattedAuthorizedAmt })}
        bodyText={t('PartialPaymentModal.Description', { remainingBalance: formattedRemainingBalance })}
        confirmText={t('PartialPaymentModal.ConfirmText', { remainingBalance: formattedRemainingBalance })}
        cancelText={t('PartialPaymentModal.CancelText')}
        isChoiceRequired={true}
        open={partialPaymentDialogOpen}
        onConfirm={this.confirmPartialPayment}
        onRequestClose={isInvoice ? this.voidWithoutReload : this.onProcessVoidButtonAction}
        hideCloseIcon={true}
      />
    );

    const futurePaymentDialog =  (
      <Modal
        cancelText={t('Close')}
        contentClassName='futurePaymentModal'
        hideConfirmButton
        onClose={this.closeFuturePaymentDialog}
        open={this.state.futurePaymentModalOpen}
      >
        <p>{t('FuturePaymentDisclaimer')}</p>
      </Modal>
    );

    const issueCreditDialog =  (
      <Modal
        confirmText={t('GotIt')}
        cancelText={t('Cancel')}
        onConfirm={this.onAcknowledgeIssueCreditDialog}
        className='businessOnlinePayments'
        contentClassName='issueCreditDialogContent'
        open={this.state.isIssueCreditDialogOpen}
        onClose={this.cancelIssueCreditDialog}
      >
        <h1 className='formTitle'>
          {t('IssueCreditDialog.Title')}
        </h1>
        <p className='formDescription'>
          {t('IssueCreditDialog.Description')}
        </p>
        <p className='formDescription'>
          {t('IssueCreditDialog.Note')}
        </p>
      </Modal>
    );

    const isHighAmountTransactionDialogOpen =  userExperience?.modalVisibility?.highTransactionAmountDialog;

    const highAmountDialog =  (
      <HighAmountModal
        onClose={this.closeHighAmountDialog}
        open={isHighAmountTransactionDialogOpen}
        t={t}
      />
    );

    const lowAmountDialog =  (
      <LowAmountModal
        onClose={this.closeLowAmountDialog}
        onConfirm={this.handleSubmitActions}
        open={this.state.showLowAmountWarningModal}
        t={t}
      />
    );

    const invoicePreviewDialog =  (
      <Modal
        isClosableIconEnable
        hideConfirmButton
        hideCancelButton
        open={this.state.openPreviewModal}
        onClose={this.handleClosePreviewModal}
      >
        <InvoicePreview
          cart={this.getCartForPreview()}
          loyaltyReward={this.props.loyaltyVpc?.rewardCodeInfo}
        />
      </Modal>
    );

    const sendOrPayInvoice =  !!this.state.isPayInvoice ? {
      key: 'payInvoice',
      type: 'contained',
      label: t('PayAmount', { amount: numeral(this.state.partialPayment?.remainingBalance || this.props.invoiceFormValues?.amount).format('$0,0.00') }),
      disabled: submitButtonDisabled || readOnly || this.state.sendInvoiceInProgress || this.getDisableSendInvoiceButton(),
      onClick: this.onSendInvoiceClick
    } : {
      key: 'sendInvoice',
      type: 'contained',
      label: t('SendInvoice'),
      disabled: submitButtonDisabled || readOnly || this.state.sendInvoiceInProgress || this.getDisableSendInvoiceButton(),
      onClick: this.onSendInvoiceClick
    };

    const invoiceForm =  (
      <div ref={this.invoiceFormRef}>
        <InvoiceForm
          {...this.props}
          openItemsDialog={this.openItemsDialog}
          addDiscountToCart={this.addDiscountToCart}
          itemizedCart={this.state.itemizedCart}
          itemCartHandlers={itemCartHandlers}
          onSubmit={!!this.state.isPayInvoice ? this.payInvoice : this.sendInvoice}
          formType='vt'
          submitting={this.props.submitting}
          partialPayment={this.state.partialPayment}
          toggleLoyaltyRewardCode={this.toggleLoyaltyRewardCode}
          clearCustomerFields={this.clearCustomerFields}
          clearSelectedCustomer={this.clearSelectedCustomer}
          selectedPaymentType={selectedPaymentType}
        />
      </div>
    );

    const virtualTerminalForm =  (
      <VirtualTerminalForm
        {...this.props}
        onSubmit={this.process}
        onToggleCardPresent={this.toggleCardPresent}
        selectedPaymentType={selectedPaymentType}
        openItemsDialog={this.openItemsDialogFromVirtualTerminal}
        addDiscountToCart={this.addDiscountToCart}
        itemizedCart={this.state.itemizedCart}
        itemCartHandlers={itemCartHandlers}
        submitting={this.props.submitting}
        partialPayment={this.state.partialPayment}
        toggleLoyaltyRewardCode={this.toggleLoyaltyRewardCode}
        clearCustomerFields={this.clearCustomerFields}
        clearSelectedCustomer={this.clearSelectedCustomer}
      />
    );

    const paymentLinkForm =  (
      <CreatePaymentLinkControl
        {...this.props}
        className='createPaymentLinkControl'
      />
    );

    const planUpgradePaymentLinks =  (
      <PlanUpgradePaymentLinks {...this.props} />
    );

    let mainContent =  virtualTerminalForm;
    const isPaymentLinkForm =  isPaymentLink && showPaymentLinks;
    const isPlanUpgradePaymentLinks =  isPaymentLink && !showPaymentLinks;

    if (isInvoice) {
      mainContent = invoiceForm;
    } else if (isPaymentLinkForm) {
      mainContent = paymentLinkForm;
    } else if (isPlanUpgradePaymentLinks) {
      mainContent = planUpgradePaymentLinks;
    }

    const hideSaveContainer =  isPaymentLink;

    return (
      <div>
        <Page
          loading={pageLoading}
          title={t('Virtual terminal')}
          showSecondaryLoading={isLoading}
        >
          <Box className={`virtualTerminal pageWrap  ${isLoading ? 'virtualTerminalLoading' : ''}`} sx={virtualTerminalPageStyles}>
            <div className='flexContainer flexContainerResponsiveLayout'>
              { filterPanel }
              <div className='masterListContainer'>
                {filterValue === 'Settings' ?
                  <VirtualTerminalSettingsForm
                    submitting={this.props.submitting}
                    readOnly={readOnly}
                    {...this.props}
                  /> :
                  <section className='businessOnlinePayments'>

                    { mobileDialog }

                    { mainContent }

                    { !hideSaveContainer &&
                      <div className='detailPanelHolderButtons saveContainer'>

                        {isFormInvalid && !isInvoice && this.state.submitButtonClicked && !isPaymentLink &&
                          <span className='settingsErrorBottom'>{t('SubmitButtonClickedError')}</span>}

                        {isAmountInvalid && isItemizedSale && !isInvoice && !isPaymentLink
                          && !isFormInvalid //<- To avoid showing two errors simultaneously
                          && (this.state.submitButtonClicked || this.props.cardPresent.isUsing) &&
                          <span className='settingsErrorBottom'>{t('SaleAmountError')}</span>}

                        {!this.props.auth.needsInfo && !this.props.cardPresent.isUsing && !isInvoice && !isIssueCredit && !isPaymentLink ?
                          <Button
                            id='paymentButton'
                            type='submit'
                            label={t('ProcessPaymentButton')}
                            disabled={submitButtonDisabled || readOnly || this.getDisableSendInvoiceButton(isItemizedSale)}
                            onClick={this.onProcessClick}
                          /> : null}

                        {isInvoice &&
                          <BottomBar
                            sx={virtualTerminalInvoicesBottomBarStyles}
                            rightContent={isFormInvalid && this.state.submitButtonClicked && <span className='settingsErrorBottom'>{t('SubmitButtonClickedError')}</span>}
                            leftActions={[
                              {
                                key: 'previewInvoice',
                                label: t('PreviewInvoice'),
                                labelStyle: {marginLeft: '10px'},
                                icon: IconUtils.getIcon('Visibility'),
                                onClick: this.handlePreviewInvoice,
                                variant: 'text'
                              },
                            ]}
                            rightActions={[
                              {
                                key: 'saveDraft',
                                label: t('SaveDraft'),
                                labelStyle: {marginLeft: '10px'},
                                icon: IconUtils.getIcon('EmailIcon'),
                                disabled: submitButtonDisabled || readOnly || this.state.sendInvoiceInProgress,
                                onClick: this.onSaveDraftClick,
                                variant: 'text'
                              },
                              {
                                ...sendOrPayInvoice
                              }
                            ]}
                          />
                        }

                        {isIssueCredit &&
                          <Button
                            id='issueCreditButton'
                            type='submit'
                            label={t('IssueCreditButton')}
                            disabled={submitButtonDisabled || readOnly}
                            onClick={this.onIssueCreditClick}
                          />
                        }

                      </div> }

                    <Modal
                      id='paymentStatusModal'
                      onClose={hasAVSError ? this.voidWithoutReload : this.handleClose}
                      confirmText={t('PaymentStatusMessageDialog.ContinueTransaction')}
                      cancelText={hasAVSError ? t('PaymentStatusMessageDialog.VoidTransaction') : t('Close')}
                      onConfirm={this.handleClose}
                      hideConfirmButton={!hasAVSError || isInvoice}
                      isChoiceRequired={hasAVSError}
                      open={userExperience.modalVisibility.vtDialogOpen}
                      title={isPaymentApproved ? `${t('Approved')}.` : `${t('Declined')}.`}
                      titleIcon={
                        isPaymentApproved
                          ? IconUtils.getIcon('CheckCircle', LabelUtil.getLabelColor())
                          : IconUtils.getIcon('Error', LabelUtil.getLabelColor())
                      }
                    >
                      {paymentStatusMessage}
                    </Modal>

                    <Modal
                      id='invoiceStatusModal'
                      onClose={this.handleClose}
                      cancelText={t('Close')}
                      hideConfirmButton={true}
                      open={userExperience.modalVisibility.vtInvoiceDialogOpen}
                      title={!errorMessage ? `${t('Success')}.` : `${t('Failure')}.`}
                      titleIcon={
                        !errorMessage
                          ? IconUtils.getIcon('CheckCircle', LabelUtil.getLabelColor())
                          : IconUtils.getIcon('Error', LabelUtil.getLabelColor())
                      }
                    >
                      {invoiceStatusMessage}
                    </Modal>
                    {restrictedAccessDialog}
                    {itemsDialog}
                    {duplicateConfirmationDialog}
                    {issueCreditDialog}
                    {highAmountDialog}
                    {lowAmountDialog}
                    {partialPaymentDialog}
                    {invoicePreviewDialog}
                    {futurePaymentDialog}
                  </section>}
              </div>
            </div>
          </Box>
        </Page>
      </div>
    );
  }

}

export const mapStateToProps = (state = {}) => ({
  vtFormValues: getFormValues('virtualTerminalForm')(state),
  vtFormSyncErrors: getFormSyncErrors('virtualTerminalForm')(state),
  invoiceFormValues: getFormValues(INVOICE_FORM_ID)(state),
  invoiceFormSyncErrors: getFormSyncErrors(INVOICE_FORM_ID)(state),
  submitting: isSubmitting(INVOICE_FORM_ID)(state) || isSubmitting('virtualTerminalForm')(state)
});

export { VirtualTerminal as ComponentUnderTest };
export default connect(mapStateToProps)(VirtualTerminal);
