/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

export const TRANSLATIONS_ES = {

  // Shared Statements
  welcome: 'Bienvenido',
  week: 'semana',
  month: 'mes',
  year: 'año',
  downward: 'baja',
  upward: 'alza',
  AccountLocked: 'Cuenta Bloqueada',
  Action: 'Acción',
  BackToForgotPassword: 'Regresar a olvidó contraseña',
  BackToLogin: 'Regresar a Inicio de Sesión',
  ConfirmNewPassword: 'Confirmar Nueva Contraseña',
  CreateResetPassword: 'Crear o restablecer contraseña',
  ResetPassword: 'Restablecer Contraseña',
  ExistingPassword: 'Contraseña Existente',
  Date: 'Fecha',
  Default: 'Defecto',
  Disconnect: 'Desconectar',
  EmailAddress: 'Correo Electrónico',
  EmailAddressPlaceholder: 'Ingrese su correo electrónico',
  FirstName: 'Nombre',
  ForgotPassword: '¿Olvidó la Contraseña?',
  InvalidToken: 'Token Inválido',
  Invoice: 'Factura',
  Invoices: 'Facturas',
  EnhancedInvoices: 'Facturas mejoradas',
  LastFourDigitsSsn: 'Últimos 4 Digitos del NSS',
  LearnMore: 'Aprende más',
  MIDAccountNumber: 'ID del Comerciante/Número de Cuenta',
  Month: 'Mes',
  Name: 'Nombre',
  NewPassword: 'Contraseña Nueva',
  Note: 'Nota',
  Offline: 'Desconectado',
  Online: 'En línea',
  Password: 'Contraseña',
  PasswordPlaceholder: 'Ingrese contraseña',
  PhoneNumber: 'Número de Teléfono',
  MobileNumberLabel: 'Número de Celular',
  MobileNumberHint: 'Ingrese el número de celular',
  MobileNumberDescription: 'Para proteger su cuenta, su empleado recibirá un código único a este número para verificar su identidad cuando inicie sesión en Payments Hub.',
  Processor: 'Procesador',
  LogIn: 'Iniciar Sesión',
  SoldBy: 'Vendido Por',
  Ssn: 'NSS',
  SsnHint: 'Número de Seguro de Social',
  Subtotal: 'Subtotal',
  Success: 'Éxito',
  Failure: 'Falla',
  Tax: 'Impuesto',
  ThankYou: 'Gracias.',
  Tip: 'Propina',
  Total: 'Total',
  UserRegistration: 'Registro de Usuario.',
  AddTip: 'Propina',
  Zip: 'Código postal',
  Zip4: 'Código postal + 4',
  Optional: 'Opcional',
  BillingStreetNumber: 'Número de calle de facturación',
  Expiration: 'Expiración',
  City: 'Ciudad',
  State: 'Estado',
  VerifyPassword: 'Verificar Contraseña',
  PartialAuth: 'Autorizacion parcial {{cardInfo}}',
  BalanceRemaining: 'Monto restante',
  InvoiceAmount: 'Monto de la factura',
  ViewEnrollment: 'Ver inscripción',
  PoweredBy: 'Desarrollado por {{company}}',
  SwitchTo: 'Cambiar a',
  OneTimePayment: 'Pago Único',
  AddEmployee: 'Agregar Empleado',
  ExportCustomers: 'Exportar Clientes',
  DBAName: 'Nombre DBA',
  BusinessAddress: 'Dirección del Negocio',
  AccountNumber: 'Número de Cuenta',
  RoutingNumber: 'Número de Ruta',
  Checking: 'De Cheques',
  Savings: 'Ahorros',
  ClipboardPermissonError: 'Se requiere permiso de portapapeles, verifique la configuración del navegador',
  // Shared Statements

  CustomerService: 'Servicio al Cliente',
  NoAccountName: 'Cuenta Sin Nombre',
  TermsIndicator: '* Aceptar Términos',
  NeedsInfoIndicator: '* Acceso limitado',

  // Action Buttons
  Approved: 'Aprobado',
  Cancel: 'Cancelar',
  Close: 'Cerrar',
  Confirm: 'Confirmar',
  Continue: 'Continuar',
  Create: 'Crear',
  Delete: 'Eliminar',
  GotIt: 'Entendido',
  LogOut: 'Cerrar sesión',
  No: 'No',
  NoVoid: 'No',
  Ok: 'Aceptar',
  Okay: 'De acuerdo',
  OptOut: 'Rechazar',
  Print: 'Imprimir',
  Save: 'Guardar',
  SaveDraft: 'Guardar borrador',
  DraftSaved: 'Borrador guardado',
  SaveProfile: 'Guardar perfil',
  Submit: 'Enviar',
  Upgrade: 'Actualizar',
  Yes: 'Sí',
  Respond: 'Responder',
  Rebut: 'Refutar',
  Accept: 'Aceptar',
  Attach: 'Agregar',
  GoBackAction: 'Ir Atrás',
  View: 'Ver',
  DontShowAgain: 'No volver a mostrar',
  HideProgress: 'Ocultar Progreso',
  StartOver: 'Iniciar de nuevo',
  'Create Report': 'Crear Reporte',
  'Create Invoice': 'Crear Factura',
  'Create Customer': 'Crear Cliente',
  'Create Employee': 'Crear Empleado',
  Loading: 'Cargando',

  //login page
  BusinessOwner: 'Soy un dueño de negocio',
  ImageText: 'Los pagos son sencillos para dueños de negocios, <br/> empleados, y clientes.',
  LoginError: 'Hubo un error inesperado durante el proceso de autenticación, por favor revise su información e intente de nuevo.',
  NoPassword: '¿Olvidaste tu contraseña o no tienes una?',
  Register: 'Registrarse ahora',
  SalesPartner: 'Soy un socio de ventas',
  ResendCode: 'Reenviar Código',
  VerifyCode: 'Verificar Código',
  EnterCode: 'Ingresar Código',
  TwoFactorMessageWithNumber: 'Un código de verificación se ha enviado a tu número móvil terminado en',
  TwoFactorMessage: 'Un código de verificación se ha enviado a tu número móvil',
  VerificationCodeLogin: 'Código de Verificación',
  success2FaSetupMessage: 'Número de celular verificado. Por favor inicie sesión nuevamente en su cuenta.',
  errorRequestCodeSetup2fa: 'Hubo un problema al enviar el código, inténtalo de nuevo.',
  errorVerifyCodeSetup2fa: 'Hubo un problema al validar el código, inténtalo de nuevo.',
  RememberDevice: 'Recordar este dispositivo',
  RememberDeviceDescription: 'Habilítelo si usa este navegador o dispositivo con regularidad y no desea ingresar un código cada vez que inicia sesión. Puede administrar sus dispositivos en Configuración.',
  MerchantLogin: 'Login Comerciante',
  WelcomeBack: 'Bienvenido de vuelta',
  GoToPartnerHub: 'Ir al centro de socios',
  NorthPartners : 'Socios North',
  GoToDeveloperHub: 'Ir al centro de desarrolladores',
  NorthDeveloper : 'Desarrollador North',
  MFASetupDisclaimer: {
    Title: 'Proteja su cuenta.',
    Description: 'Ahora requerimos autenticación de dos factores. Ingrese su número de teléfono móvil para recibir un código de verificación.',
    Footnote: 'Se pueden aplicar tarifas por mensajes y datos.',
  },
  MFAFormDisclaimer: {
    Title: 'Proteja su cuenta.',
    Description: 'Por su seguridad, ahora requerimos autenticación de dos factores.'
  },
  MFABlockDisclaimer: {
    Title: 'Proteja su cuenta.',
    Description: 'Por su seguridad, ahora requerimos autenticación de dos factores. Solicite al propietario o administrador de la cuenta que agregue un número de teléfono móvil a su perfil de empleado.'
  },
  ForceResetPasswordDisclaimer: {
    Title: 'Tu contraseña ha expirado.',
    Description: 'Restablezca su contraseña para continuar. Una vez que la haya cambiado, vuelva a iniciar sesión en Payments Hub con su nueva contraseña.'
  },

  PricingDetailsModal: {
    PricingDetails: 'Detalles de precios',
    SwipedRate: 'Tarifa deslizada',
    KeyedRate: 'Tarifa manual',
    KeyedRateDescription: 'Transacciones de crédito manuales, terminal virtual, pagos de facturas y enlaces de pago',
    OtherFees: 'Otras tarifas (si corresponde)',
    MPosPerItem: 'mPOS por tarifa de artículo',
    MPosPerDay: 'mPOS por día por tarifa de dispositivo activo',
    MPosPerDayDescription: 'Estas tarifas son específicas de las transacciones Tap to Pay y no se aplican si no procesa transacciones Tap to Pay. Para deshabilitar la funcionalidad Tap to Pay, vaya a su aplicación POS → Configuración → Tipo de pago.',
    RetrievalFee: 'Tarifa de recuperación',
    ChargebackFee: 'Tarifa de Contracargo',
    NSFFee: 'Tarifa NSF',
    InactivityFee: 'Tarifa de inactividad',
    InactivityFeePerMonth: '$3,99/mes',
    InactivityFeeDescription: 'Aplicable solo a comerciantes que no procesan una transacción durante 12 meses. Cuando se reanude el procesamiento, ya no se cobrará la tarifa de inactividad.',
    CellularDataProgram: 'Programa de datos móviles (se aplica solo a dispositivos de pago inteligentes)',
    MonthlyCellularData: 'Datos móviles mensuales',
    MonthlyCellularDataPerMonthPerDevice: '$9.95/mes/dispositivo',
    MonthlyCellularDataDescription: 'Primeros 10 MB gratis. Hasta 250mb. Cada 250 mb adicionales cuesta $9.95/mes/dispositivo adicional.',
    CellularDataTransaction: 'Transacción de datos móviles',
    CellularDataTransactionDescription: 'Las primeras 25 transacciones gratis'
  },
  DevicePricingModal: {
    DevicePricing: 'Precio del Dispositivo',
    DeviceName: 'Nombre del Dispositivo',
    OneTimeSetupFee: 'Tarifa de Instalación Única',
    SoftwareFee: 'Tarifa de Software',
    PurchasePrice: 'Tarifa de Compra',
    Device: 'dispositivo',
    MoDevice: 'al mes/dispositivo'
  },

  // Login footer
  Chat: 'Chat',
  EmailUs: 'Envíenos un correo electrónico',
  FAQ: 'Preguntas frecuentes',
  LiveChat: 'Chat en vivo',
  New: 'Nuevo',
  PaymentsHubDevelopers: 'Portal de DEV: Payments Hub Desarrolladores',
  PrivacyPolicy: 'Política de privacidad',

  // Reset Password
  InstructionText: 'Su token no es válido o ha expirado, por favor intente restablecer contraseña de nuevo.',
  ResetPasswordDescription: 'Ingrese una nueva contraseña. Se usará esta contraseña para ingresar a los productos North, Payments Hub y Payanywhere. Por su seguridad, le pedimos que ingrese algunos detalles adicionales para verificar su identidad.',
  ResetPasswordForm: {
    TwoFactorToken: 'Ingrese el código de verificación enviado a su número celular',
    RequestToken: 'Reenviar código',
    SuccessText: 'Ha restablecido su contraseña. Regrese a la pantalla de ingreso y digite su nueva contraseña para iniciar sesión en los productos North, Payments Hub y Payanywhere.',
    EnterCode: 'Ingresar codigo'
  },

  // Forgot Password
  ForgotPasswordDescription: 'Ingrese su correo electrónico para iniciar. Recibirá un correo electrónico con más instrucciones sobre cómo crear o restablecer su contraseña para los productos North, Payments Hub y Payanywhere.',
  ForgotPasswordForm: {
    SuccessHeader: 'Revise su bandeja de entrada.',
    SuccessText: 'Hemos enviado instrucciones para crear o restablecer su contraseña a su dirección de correo.'
  },
  ForgotPasswordMPB: {
    Header: 'Solicitar Token de Verificación',
    Text: 'Un token único será enviado a su correo y es requerido para restablecer su contraseña'
  },

  // Set Password
  SetPasswordHeader: 'Configurar Contraseña',
  SetPasswordInvalid: {
    Text: 'Su token no es válido o ha expirado, por favor intente una solicitud de nuevo.'
  },
  SetPasswordSuccess: {
    Text: 'Su contraseña ha sido configurada. Regrese a la pantalla de ingreso e inicie sesión con su nueva contraseña.'
  },
  SetMBPPasswordForm: {
    Header: 'Restablecer contraseña.',
    SSNTaxDivider: ' O ',
    TaxId: 'Últimos 4 Identificador de Impuesto',
    TaxIdHint: 'Últimos 4 Dígitos del Identificador de Impuesto'
  },

  //Mid Selector
  SelectMID: 'Selecciona el MID que deseas usar',
  //Mid Selector

  // Dashboard
  DeveloperAccess: 'Acceso de desarrollador',
  LoyaltyNorth: 'Lealtad por North',
  LoyaltyPayAnywhere: 'Loyalty by Payanywhere',
  LoyaltyDescription: 'Crea un programa personalizado que motive a los clientes a seguir regresando.',
  ReputationManagementDescription: 'Administrar reseñas de clientes, ver información y realizar un seguimiento de la competencia.',
  ExploreIntegrations: 'Explorar integraciones',
  PowerUser: 'Conviértete en un usuario avanzado',
  GoFurtherPaymentsHubFeature: 'Ir más lejos con Payments Hub',
  GoFurtherNorthFeature: 'Ir más lejos con North',
  NorthFeature: 'Ir más lejos con North',
  MoreFeatures: 'Más funciones',
  PremiumPlusDescription: 'Desbloquee facturación mejorada, pagos recurrentes, tarjetas registradas y fidelización.',
  IntegrationsDescription: 'Explore una colección de potentes herramientas de terceros para mejorar su experiencia de pagos.',
  YelpIntegrationDescription: 'Sea encontrado por nuevos clientes con Yelp.',
  PowerUserText: 'Busca videos de productos y tutoriales útiles que te ayudarán a convertirte en un profesional.',
  DashboardDisclaimer: '©2024 Payments Hub. Todos los derechos reservados.',
  DashboardNorthDisclaimer: 'North is a registered tradename of NorthAB, LLC, a registered Independent Sales Organization of Wells Fargo Bank, N.A., Concord, CA, BMO Harris Bank N.A., Chicago, IL, Citizens Bank N.A., Providence, RI, The Bancorp Bank, Philadelphia, PA, and FFB Bank Bank, Fresno, CA.',
  QuickReports: 'Informes rápidos',
  TodayTransactions: 'Transacciones de hoy',
  TodayFlashReport: 'Informe Flash de hoy',
  ViewTransactions: 'Ver Transacciones',
  ViewBatches: 'Ver Lotes',
  MostRecentDeposit: 'Depósito más reciente',
  MostRecentStatement: 'Declaración más reciente',
  NewInvoice: 'Nueva Factura',
  NewPaymentLink: 'Nuevo Enlace de Pago',
  ManageDisputes: 'Administrar Disputas',
  GetHelp: 'Obtener Ayuda',
  NorthDevelopers: {
    HeaderNH: 'Vaya más allá con North',
    HeaderPH: 'Vaya más allá con Payments Hub',
    Title: 'Desarrolladores North',
    Description: 'Intégrese al ecosistema de pagos de North con API y SDK fáciles de usar.'
  },
  Deposit: 'Depósito',
  NewDisputes: 'Nuevas Disputas',
  Activity: 'Actividad',
  Snapshot: '"Snapshot" para',
  TransactionVolume: 'Volumen de Transacciones',
  PaymentMethodBreakdown: 'Desglose del Método de Pago',
  // Dashboard

  //Sidebar menu
  Dashboard: 'Tablero',
  Sales: 'Ventas',
  Transactions: 'Transacciones',
  Deposits: 'Depósitos',
  Batches: 'Lotes',
  Reports: 'Reportes',
  Disputes: 'Disputas',
  Statements: 'Estados de cuenta',
  'Virtual Terminal': 'Terminal Virtual',
  Customers: 'Clientes',
  Inventory: 'Inventario',
  Employees: 'Empleados',
  'Hardware & Supplies': 'Hardware y Suministros',
  'Business Settings': 'Configuración',
  //Sidebar menu

  // Statements
  DownloadButton: 'Descargar {{statements}} Seleccionados',
  SearchStatements: 'Buscar Estados de cuenta',
  StatementsEmpty: 'No hay estados de cuenta disponibles para comercio actualmente.',

  //Terms
  VerificationCode: 'Código de verificación',
  EnterVerificationCode: 'Ingrese el código de verificación',
  SendNewCode: 'Enviar nuevo código',
  'Send code': 'Enviar código',
  'Sending code': 'Enviando código',
  'Verify code': 'Verificar código',
  'Error validating the code': 'Error al validar el código',
  'Code verified': 'Código verificado',
  'Validating code': 'Código de validación',
  'Verify phone': 'Verificar teléfono',
  'Verifying phone': 'Verificando teléfono',
  'Error verifying phone': 'Error al verificar el teléfono',
  'Phone verified': 'Teléfono verificado',
  //Terms

  // Business Settings Page
  ClosingSettingSecondaryTitle: 'Configuración de Cierre',
  ManualClose: 'Cierre Manual',
  AutoClose: 'Cierre del Host por defecto',
  AutoClosePrintTime: 'Tiempo de Auto Cierre de Impresión',
  IsOwnerLabel: 'Impresión Automática del Informe de Cierre',
  Theme: 'Tema',
  Retail: 'Al Detalle',
  Terminal: 'Terminal',
  DefaultSaleViewLabel: 'Vista de Venta Predeterminada',
  ItemLibrary: 'Biblioteca de Elementos',
  ExpressSale: 'Venta Express',
  ExpressCategoryEnabledLabel: 'Asignar Categorías a Artículos Express',
  ExpressCategoryEnabledSubtext: 'Permita que los artículos express se asocien con una categoría.',
  ShowInvoiceNumberingTitle: 'Numeración de Facturas',
  ShowInvoiceNumberingOpt1Label: 'Usar números de factura generados por el sistema',
  ShowInvoiceNumberingOpt1Subtext: 'Payanywhere proporciona números de factura generados por el sistema (pueden no ser secuenciales).',
  ShowInvoiceNumberingOpt2Label: 'Definir la numeración automática de facturas',
  ShowInvoiceNumberingOpt2Subtext: 'Defina su propia numeración de factura y continúe en orden secuencial (ej. 1, 2, 3). Todavía puede ingresar un número de factura personalizado en el momento de cada venta en la pantalla Compartir Recibo.',
  ShowInvoiceNumberingOpt3Label: 'Requerir número de factura personalizado al momento de cada venta',
  ShowInvoiceNumberingOpt3Subtext: 'Ingrese manualmente sus propios números de factura personalizados, incluidas las facturas alfanuméricas (p. ej., ABC123). Los usuarios no podrán completar la transacción hasta que se haya ingresado un número de factura. Esta configuración no se aplica a las facturas recurrentes.',
  ResetInvoiceNumbering: 'Restablecer numeración de factura',
  InvoiceNumberingStartField: 'Número de Factura Inicial',
  InvoiceNumberingStartFieldSubtext: 'Ingrese el número en el que desea que comiencen sus facturas. Nota: la edición de la numeración de las facturas puede generar números de factura duplicados con transacciones históricas.',
  InvoiceNumberingResetToggleLabel: 'Restablecer Numeración de Facturas por Lote',
  InvoiceNumberingResetToggleSubtext: 'Después de cada lote, la numeración de las facturas se restablecerá en función de su número inicial definido. Esta configuración dará como resultado números de factura duplicados y requerirá que haga coincidir el número de factura con la fecha del lote para fines de conciliación.',
  InvoiceNumberingApplyAccountsLabel: 'Aplicar a todas las cuentas',
  InvoiceNumberingApplyAccountsSubtext: 'Use su secuencia de números de factura definida en todas las cuentas (MID) que posee. Cuando está deshabilitada, la secuencia se aplica solo a esta cuenta ({{selectedMid}})',
  ShowTaxSettingsTitle: 'Configuración de Impuestos',
  EnableDavoTitle: 'Habilite la presentación automática de impuestos sobre las ventas con DAVO',
  EnableDavoSubText: 'DAVO reserva el impuesto sobre las ventas diariamente y lo paga y presenta, a tiempo y en su totalidad. La primera presentación mensual es gratuita.',
  DavoGetFreeMonthButton: 'Obtenga la presentación del primer mes gratis',
  DavoConnectLabel: 'Conectado',
  DavoPortalLink: 'Visita el Portal DAVO',
  DavoWebsiteLink: 'Visita el Sitio de DAVO',
  DavoLegalLink: 'Ver términos y condiciones',
  DavoTaxLink: 'Ver Ajustes de Impuestos',
  DavoDisconnectLink: 'Desconectar',
  DavoIntegrationDetails: {
    Title: 'Detalles de Integración',
    ConfirmTitle: 'Confirmar Integración',
    DBALabel: 'Nombre DBA',
    MID: 'MID',
    Owner: 'Dueño',
    BusinessAddress: 'Dirección del Negocio',
    PaymentsHub: 'PaymentsHub',
    Davo: 'DAVO',
    BodyText: 'DAVO reserva el impuesto sobre las ventas diariamente y lo paga y presenta, a tiempo y en su totalidad. La primera presentación mensual es gratuita.',
    BottomBody: 'Obtenga la presentación de impuestos el primer mes gratis y luego $54,99/mes por ubicación',
    ConnectedBottomText: '$54.99/mes por ubicación',
    ConnectedDate: 'Conectado desde {{date}}',
    Section1: {
      Title: 'Nunca más te preocupes por el impuesto sobre las ventas.',
      Body: 'Con DAVO y Payanywhere, los impuestos sobre las ventas son una cosa menos de qué preocuparse. DAVO Sales Tax reserva automáticamente su impuesto sobre las ventas diariamente utilizando los datos de ventas de Payanywhere. Cuando llega el momento de presentar la declaración ante el Estado, DAVO automáticamente presenta y paga su impuesto sobre las ventas a tiempo y en su totalidad.'
    },
    Section2: {
      Title: 'Cómo se integra DAVO con Payanywhere.',
      Body: 'DAVO se integra directamente con Payanywhere para determinar exactamente cuánto impuesto sobre las ventas reservar cada día. Ese dinero se guarda en la cuenta de retención de impuestos segura de DAVO, luego se presenta y se paga automáticamente al estado cada mes o trimestre.'
    },
    Section3: {
      Title: '¿Por qué DAVO?',
      Item1: '<0>Ahorro de tiempo:</0> Por solo $54,99 por mes por ubicación, DAVO administra los impuestos sobre las ventas de principio a fin para que pueda ahorrar tiempo y no preocuparse por los impuestos sobre las ventas. Evite los problemas de último minuto en materia de impuestos sobre las ventas para presentar y pagar.',
      Item2: '<0>Configuración rápida:</0> Se instala en minutos y es fácil.',
      Item3: '<0>Fácil de usar:</0> Una vez que DAVO esté configurado, su impuesto sobre las ventas estará en piloto automático. Utilice el panel en línea para acceder a registros de declaraciones de impuestos sobre las ventas anteriores y realizar actualizaciones en su cuenta según sea necesario.'
    },
    Legal: {
      Title: 'End User License Agreement',
      SubText: 'These DAVO by Avalara Supplemental Terms (“DAVO Terms”) govern Customer’s purchase and use of DAVO by Avalara. These DAVO Terms are in addition to, and incorporate by reference, the Avalara Service Terms and Conditions available at www.avalara.com/terms (the “Terms”). Any capitalized terms used in these DAVO Terms and not defined have the meanings given in the Terms.',
      Section1: {
        Title: 'Use of the Services.',
        Item1: 'Account Setup.',
        Item1Regular: ' Customer shall provide Avalara with all information requested by Avalara to establish and set up Customer’s account for DAVO by Avalara. Such information includes: (i) a list of taxing jurisdictions for which Customer requests Avalara prepare Returns (as defined in Section 3(a) below) (each, a “Filing Jurisdiction”); (ii) the dates for filing Returns in each of the Filing Jurisdictions (the “Scheduled Returns”); (iii) tax registration numbers and login information for Customer in each Filing Jurisdiction sufficient to allow Avalara to identify and access Customer’s account in that Filing Jurisdiction (the “Account Information”); and (iv) other information necessary to properly configure Customer’s Account and prepare the Returns (all such information in (i)-(iv) collectively, the “Filing Information”). Customer authorizes Avalara to access Customer’s accounts using Customer’s Account Information. Customer is solely responsible for timely providing and maintaining accurate, complete, and current Filing Information, and Avalara has no obligation to audit, verify, correct, or maintain any Filing Information. Customer acknowledges and agrees that its failure to provide accurate, current, and complete Filing Information (for example, tax registration numbers) may limit Avalara’s ability to file Returns and remit any tax amounts due (the “Taxes”).',
        Item2: 'Changes to Filing Information.',
        Item2Regular: ' Customer shall review its Filing Information periodically and shall promptly communicate any changes to Avalara. Customer shall submit any changes to the Filing Information to Avalara by the deadline specified in the Documentation. Any changes received after the deadline specified in the Documentation will be implemented and become effective in the subsequent month.',
        Item3: 'License.',
        Item3Regular: ' Avalara grants Customer a limited, nonexclusive, nontransferable, non-assignable, worldwide license to use and retain the Content that is returned by the Services to Customer solely for its internal compliance purposes in connection with the specific Return for which it was received.'
      },
      Section2: {
        Title: 'Customer Obligations.',
        Item1: 'Tax Data.',
        Item1Regular: ' Customer is solely responsible for the accuracy and completeness of all the data necessary to properly complete Returns (the “Tax Data”) and all Filing Information. Avalara does not audit, validate, or verify Tax Data. Customer shall transmit to Avalara via the method designated by Avalara all Tax Data for Customer’s Return(s) before the deadline specified in the Documentation.',
        Item2: 'Funding.',
        Item2Regular: ' Unless otherwise agreed in writing, Customer shall make sufficient funds available to pay the Taxes (“Tax Funds”) on a daily basis. Avalara will not provide any Tax Funds. Notwithstanding any other provision of the Agreement, Customer shall defend, indemnify, and hold Avalara, its Affiliates, and their respective officers, directors, employees, and representatives harmless from any Losses arising out of or related to Customer’s failure to timely provide immediately available Tax Funds for Taxes due. In order for the Tax Funds to be remitted timely to the applicable Filing Jurisdiction, provided all Tax Data and Filing Information is accurate and complete, Customer must do the following:',
        SubItem1: 'Customer shall provide bank account information for a bank account (the “Bank Account”) from which Customer authorizes Avalara to draw by ACH to pay Customer’s Taxes and any applicable DAVO by Avalara fees and Expenses.',
        SubItem2: 'Customer shall ensure the Bank Account has sufficient Tax Funds to pay the Taxes on a daily basis and any applicable DAVO by Avalara fees and Expenses by the deadlines specified in the Documentation. If Customer fails to timely and sufficiently fund the Bank Account, Customer shall remit all remaining payment due for the Taxes plus any interest or penalties that may accrue directly to the applicable Filing Jurisdiction. If the ACH payment Avalara draws from the Bank Account is cancelled or returned other than by Avalara, that will be considered a failure to timely fund the Bank Account. Avalara may suspend or terminate Customer’s subscription to DAVO by Avalara immediately upon notice if Customer fails to timely and sufficiently fund the Bank Account.',
        SubItem3: 'Customer authorizes Avalara to (i) withdraw the Tax Funds to pay the Taxes in accordance with the Scheduled Returns, including making any required prepayments; and (ii) if applicable, withdraw Customer’s DAVO by Avalara fees and Expenses.'
      },
      Section3: {
        Title: 'Avalara Obligations.',
        Item1: 'Returns Preparation and Filing.',
        Item1Regular: ' Avalara will prepare and file Returns for the Filing Entities and in the Filing Jurisdictions agreed by Avalara and Customer. Each tax return filing or prepayment mandated by a Filing without a tax return filing, prepared for one Filing Jurisdiction for one filing period, will count as one “Return.”',
        Item2: 'Funding.',
        Item2Regular: ' Avalara will remit Taxes for the agreed-upon Filing Entities in agreed-upon Filing Jurisdictions, provided Customer has timely made Tax Funds available.',
        SubItem1: 'Each day, Avalara will draw upon the Bank Account to pay the Taxes.',
        SubItem2: 'Each month, if applicable, Avalara will draw upon the Bank Account to pay DAVO by Avalara fees and Expenses due.',
        SubItem3: 'Unless otherwise agreed in writing, Avalara shall hold the Tax Funds in a trust account prior to disbursement to the applicable Filing Jurisdiction. Avalara holds all Tax Funds in trust for the benefit of the applicable Filing Jurisdiction and shall not comingle the Tax Funds with its general funds but may comingle the Tax Funds with funds held in trust on behalf of other Avalara customers. Any interest earned on the Tax Funds accrues for the benefit of and is the sole property of Avalara.',
        SubItem4: 'Avalara shall not provide funds to pay Taxes. If Tax Funds are not timely available to Avalara for remitting in full, Avalara will file the Return(s) without payment or with partial payment from the Tax Funds available.',
        SubItem5: 'If Avalara is unable to remit Taxes for Customer for six consecutive months after Avalara has begun holding Tax Funds, Avalara will stop collecting Tax Funds from Customer and return to Customer any Tax Funds that were collected but not remitted.'
      },
      Section4: {
        Title: 'Notice Management.',
        TitleRegular: ' Customer will receive all notices relating to Customer’s Returns directly from Filing Jurisdictions. During the Subscription Term, for any (i) error notice relating to a Return filed or Taxes remitted by Avalara or (ii) notice relating to changes to the Scheduled Returns, Customer shall immediately, and not later than 10 business days after the date of such notice, deliver such notice to Avalara. Such notice must be submitted in accordance with the instructions in the Documentation. During the Subscription Term, Avalara will respond to error notices for Returns filed and Taxes remitted by Avalara. In the case of error notices received by Avalara more than 10 business days after the date of the notice, Avalara may delay response or not respond or, if Customer asks Avalara to expedite the notice response and Avalara agrees, charge an additional fee to expedite the notice response. Customer is responsible for responding to or otherwise addressing all other notices. Avalara shall have no responsibility for any notice management upon expiration or termination of the Subscription Term.',
      },
      Section5: {
        Title: 'DAVO Guarantee.',
        TitleRegular: ' Avalara provides a guarantee of the timeliness of Returns prepared and filed through DAVO by Avalara and that it will draw upon the Bank Account daily to pay the Taxes (the “DAVO Guarantee”) under the following terms:',
        Item1: 'If Customer receives a notice of late filing, failure to file, or a failure to remit Taxes that results in liability for penalties or interest due solely to Avalara’s failure to timely prepare and file a Return it was obligated to file, to timely remit Taxes it was obligated to remit, or to draw upon the Bank Account daily to pay the Taxes (an “Avalara Error”), Avalara will pay the Filing Jurisdiction the lesser of either: (i) the amount of the penalties and interest that directly result from the Avalara Error, as specified in the final assessment notice received from the applicable Filing Jurisdiction after all administrative appeals and abatement options are exhausted, or (ii) the amount of the DAVO by Avalara fees paid by Customer during the 365 days preceding the final assessment of penalties or interest for the Avalara Error (calculated as described in subsection (c) below). If there are any remaining penalties or interest due, Customer shall remit payment to the applicable Filing Jurisdiction.',
        Item2: 'The following conditions apply to the DAVO Guarantee:',
        SubItem1: 'Customer must have met all of its obligations under the Terms and these DAVO Terms, including by providing and maintaining accurate, complete, and current Filing Information and Tax Data; timely providing all Tax Funds; and timely paying DAVO by Avalara fees. To the extent the Avalara Error was caused by Customer’s failure to perform any of the obligations in these DAVO Terms or the Terms, the DAVO Guarantee will not apply.',
        SubItem2: 'Customer must promptly forward any notices and relevant information from the Filing Jurisdiction within 10 days of the date of the notice.',
        SubItem3: 'Customer must assist Avalara in challenging the Filing Jurisdiction’s findings if Avalara deems it appropriate in Avalara’s sole discretion.',
        SubItem4: 'Customer must assist Avalara’s efforts to abate or reduce the amount of penalties or interest imposed by the Filing Jurisdiction.',
        Item3: 'For purposes of calculating the amount of the DAVO by Avalara fees paid that are eligible for the DAVO Guarantee payment, the amount will be the fees actually paid by Customer to Avalara for DAVO by Avalara, and the time period will be the 365 days preceding the final assessment of penalties or interest after all appeals and abatement options have been exhausted (e.g., in the case of a final assessment issued by a taxing authority on March 31, 2020, the period used in the calculation will be from April 1, 2019, to March 31, 2020). For clarity, the parties acknowledge that fees paid for DAVO by Avalara do not include any activation fees, fees for ancillary Professional Services, or any other one-time fees.',
        Item4: 'If the audit implicates other issues in addition to the alleged Avalara Error, the amount to be paid by Avalara under this DAVO Guarantee will be the percentage of the final assessment amount equal to the percentage of the final assessment related to the alleged Avalara Error.',
        Item5: 'Avalara will make the DAVO Guarantee payment within 30 days after the date that Avalara receives the final assessment notice from the applicable Filing Jurisdiction, after all administrative appeals and abatement options are exhausted. Avalara may also, in its sole discretion, make the payment at an earlier date, in which case Customer’s obligations to continue to assist Avalara in contesting the Avalara Error will cease on the date of the payment.',
        Item6: 'Customer must have a current DAVO by Avalara subscription in good standing when the claim is submitted to Avalara to be eligible to receive payment under this DAVO Guarantee.'
      },
      Section6: {
        Title: 'Fees.',
        TitleRegular: ' Customer agrees to pay fees for DAVO by Avalara based on Customer’s subscription plan and usage of DAVO by Avalara. Unless otherwise agreed in writing, Customer shall participate in Avalara’s automatic payment option for DAVO by Avalara and authorizes Avalara to draw from the Bank Account by ACH transfer to pay DAVO by Avalara fees and Expenses.',
        Item1: 'Return Fees.',
        Item1Regular: ' Customer’s monthly subscription fee includes three Returns per Subscription Term. Avalara will charge Customer the subscription fee each time Customer exceeds the three Returns included in subscription fee (e.g., if Customer files seven Returns in a Subscription Term, Avalara will charge Customer an amount equal to three subscription fees).',
        Item2: 'Additional Fees.',
        Item2Regular: ' If Customer fails to timely and completely provide accurate Filing Information, Tax Data, or Tax Funds, as applicable, or otherwise fails to fulfill any obligations under these DAVO Terms, or if Customer requires a change to the standard process described in these Return Terms, Avalara may charge Customer additional fees. For example, Avalara may charge a fee for Avalara to file an amended Return.',
        Item3: 'Rejected Transaction Fee.',
        Item3Regular: ' If the ACH payment Avalara draws from the Bank Account is cancelled or returned other than by Avalara, Avalara will charge Customer $3.00 per rejected transaction.'
      },
      Section7: {
        Title: 'Term and Termination.',
        TitleRegular: ' Notwithstanding anything to the contrary in the Terms, the Subscription Term for Customer’s DAVO by Avalara subscription will terminate on the last calendar day of the Subscription Term month. Customer may instruct Avalara not to file Returns by contacting DAVO by Avalara customer support in accordance with the instructions provided in the Documentation.',
        Item1: 'Termination for Cause.',
        Item1Regular: ' If the financial institution providing the Customer Bank Account instructs Avalara that Avalara must terminate this Agreement (for example, because Customer is conducting its business in violation of Applicable Law), Avalara may immediately terminate this Agreement upon written notice to Customer. In the event of such termination, Avalara will not refund any amounts to Customer.',
        Item2: 'Failure to Provide Account Information.',
        Item2Regular: ' In addition to any other applicable termination rights, if Customer does not provide Avalara with accurate and complete Account Information within three months following the Effective Date, Avalara may terminate the Agreement or the DAVO by Avalara Service and return to Customer the Tax Funds it collected.',
        Item3: 'Automatic Renewal.',
        Item3Regular: ' At the end of the then-current Subscription Term, each subscription to a Service will automatically renew for an additional one-month period (a “Renewal Subscription Term”) unless (1) Customer provides written notice of non-renewal to Avalara on or before the expiration date of the then-current Subscription Term, or (2) Avalara provides written notice of non-renewal to Customer at least 30 days before such expiration date.'
      },
      Section8: {
        Title: 'Compliance with NACHA Operating Rules.',
        TitleRegular: ' The funding process described in Section 3(b) (Funding) of these Returns Terms may be subject to the Operating Rules of NACHA, the organization that regulates the ACH network in the United States.',
        Item1: 'To the extent that Customer’s funding is governed by the NACHA Operating Rules, Customer specifically agrees to the following NACHA requirements:',
        SubItem1: 'Customer will comply with all applicable requirements under the then-current version of the NACHA Operating Rules;ii. Customer authorizes Avalara to originate the funding requests described in Section 3(b) (Funding); and',
        SubItem2: 'Customer will comply with the laws of the United States in providing such funding.',
        Item2: 'In addition to any other applicable termination rights, Avalara may terminate the Agreement for Customer’s non-compliance with the NACHA Operating Rules if such breach or non-compliance is not cured within 10 days of Avalara first notifying Customer of its non-compliance.',
        Item3: 'Avalara has the right to audit Customer’s funding process, at a time and location mutually agreeable to both Customer and Avalara, to ensure compliance with the NACHA Operating Rules and the Agreement.'
      },
      Section9: {
        Title: 'Deviations from the Terms.',
        Item1: 'Status Updates.',
        Item1Regular: ' Avalara may elect not to provide status updates at status.avalara.com or an equivalent replacement site.',
        Item2: 'Planned Downtime.',
        Item2Regular: ' Avalara may elect not to provide notice of planned downtime.',
        Item3: 'Customer Affiliates.',
        Item3Regular: ' Customer may not enable its Affiliates to use DAVO by Avalara.',
        Item4: 'Privacy Policy.',
        Item4Regular: ' Avalara’s use of Personal Information relating to an employee or other authorized Representative of Customer that is collected or received by Avalara in connection with the procurement or use of, or payment for, DAVO by Avalara is governed by the DAVO by Avalara Privacy Policy available at https://www.davosalestax.com/privacy-policy-davo-technologies/.',
        Item5: 'Material Reduction.',
        Item5Regular: ' Avalara may materially reduce a Service or its features or functionality with at least 30 days’ written notice to Customer.',
        Item6: 'Account Notices.',
        Item6Regular: ' Customer should send account notices (for example, name or address changes) in accordance with the instructions in the Documentation.',
        Item7: 'Entire Agreement.',
        Item7Regular: ' For avoidance of doubt, the Agreement supersedes and replaces the DAVO by Avalara End User License Agreement previously available at https://www.davosalestax.com/terms-of-use-davo-technologies/ and https://www.davosalestax.com/terms, as well as any DAVO by Avalara End User License Agreement provided by a Distributor to Customer.'
      },
    },
    CheckboxText: 'Acepto los Términos y Precios',
    ActionDisclaimer: 'Al darle clic a conectar, una cuenta de DAVO será creado.',
    ConfirmationDialog: {
      Title: '¿Está seguro de que quieres desconectar?',
      Body: 'Las cancelaciones deben realizarse al menos 24 horas antes del mes siguiente para evitar que se cobre el mes siguiente. Si elige cancelar, su servicio y suscripción DAVO continuarán hasta fin de mes y no se le cobrarán tarifas el mes siguiente.',
      Disconnect: 'Desconectar'
    }
  },
  AutoDetectTax: 'Detección Automática de Impuestos',
  TaxRate: 'Tasa de Impuesto',
  ExpressItemTaxableLabel: 'Artículos Expres sujetos a impuestos por defecto',
  OfflineTransactionsLabel: 'Transacciones Fuera de Línea',
  OfflineTransactionsSubtext: 'Acepte pagos cuando no pueda acceder a una conexión a Internet. Sus transacciones guardadas se procesarán cuando recupere la conectividad. Al habilitar esta configuración, reconoce que es responsable de cualquier transacción rechazada o devolución de cargo. Si borra sus datos o elimina la aplicación Payanywhere, sus transacciones guardadas se perderán. Para disminuir el riesgo de transacciones rechazadas, verifique la información de su cliente y valide su tarjeta.',
  OfflineTransactionsNote1: 'Se aplica solo a transacciones con chip EMV, deslizadas y en efectivo.',
  OfflineTransactionsNote2: 'Límite de transacción predeterminado de $100.',
  OfflineTransactionsNote3: 'Las transacciones fuera de línea deben procesarse dentro de las 72 horas. Las transacciones después de este tiempo serán eliminadas del sistema.',
  EnableTicketsLabel: 'Habilitar Tiquetes',
  EnableTicketsSubtext: 'Cree, retenga y edite tiquetes o abra pestañas.',
  IdentifiersTitle: 'Identificadores',
  IdentifiersSubtext: 'Los identificadores son descriptores que lo ayudan a identificar rápidamente los boletos y los clientes. Configure identificadores en la aplicación Payanywhere.',
  AllowPinDebitLabel: 'PIN de Débito',
  AllowPinDebitSubtext: 'Acepte pagos con débito con PIN. Puede elegir ejecutar una transacción como crédito o débito; al elegir Débito, se le pedirá a su cliente que ingrese su PIN para completar la transacción.',
  PinDebitEnabledTitle: 'Forzar PIN de Débito solamente',
  PinDebitEnabledSubtext: 'Acepte SOLAMENTE pagos con débito con PIN. No podrá ejecutar transacciones como crédito.',
  EBTEnabledLabel: 'Pagos EBT/SNAP',
  EBTEnabledSubtext: 'Acepte pagos de EBT/SNAP en artículos elegibles si es un minorista autorizado de SNAP con el FNS. No puede cobrar impuestos sobre artículos pagados con beneficios de Alimentos o Dinero en efectivo.',
  CashFieldSubText: 'Aceptar y rastrear pago en efectivo',
  GiftCardEnabledTitle: 'Pagos con tarjetas de regalo',
  GiftCardEnabledSubtext: 'Venda tarjetas de regalo y acepte pagos con tarjetas de regalo con un proveedor externo de tarjetas de regalo. Inscríbase en un programa de tarjetas de regalo a través de Payments Hub.',
  GiftCardStockTitle: 'Stock de Tarjetas de Regalo',
  GiftCardStockSubtext: 'Realice un seguimiento de la cantidad de tarjetas de regalo físicas que tiene a mano.',
  GiftCardPreset: 'Tarjeta de Regalo Preestablecida {{presetNumber}}',
  LearnMoreLinkIntegration: 'Obtenga más información en la página de integración.',
  GiftCardOnTopLabel: 'Mantenga la Tarjeta de Regalo siempre a la vista',
  GiftCardOnTopSubtext: 'Forzar la Tarjeta de Regalo como el primer artículo en su lista de artículos disponibles para vender.',
  GiftCardFavoritesLabel: 'Agregar Tarjeta de Regalo a Favoritos',
  GiftCardFavoritesSubtext: 'Agregue el elemento Tarjeta de Regalo a su lista de elementos favoritos para un acceso rápido.',
  TapToPayLabel: 'Tap to Pay on iPhone',
  TapToPaySubtext: 'Acepta pagos sin contacto solo con un iPhone. Esta configuración se realiza a nivel de cuenta y no es específica de cada iPhone que utilice.',
  IgnoreAvsFailureLabel: 'Mostrar Alerta de Discrepancia de AVS',
  IgnoreAvsFailureSubtext: 'Por motivos de seguridad, se le avisa cuando el número de la calle y/o el código postal ingresados no coinciden con la dirección de facturación del titular de la tarjeta. La alerta le permitirá continuar o anular la transacción. En los pagos de facturas, se le pedirá a su cliente que pruebe con otro método de pago; de lo contrario, la transacción se rechazará. Al desactivar esta configuración, usted reconoce que es responsable de cualquier transacción rechazada o contracargo.',
  BarcodeSearchEnabledLabel: 'Habilitar Búsqueda de Código de Barras',
  IsSignatureRequiredLabel: 'Siempre Requerir Firma',
  IsSignatureRequiredSubtext: 'Habilitar esta configuración mostrará la pantalla Firma. No se requieren firmas para transacciones en el punto de venta. Puede requerir firmas a su propia discreción.',
  PaperSignatureEnabledLabel: 'Firma y Propina en el Recibo Impreso',
  PaperSignatureEnabledSubtext: 'Los clientes firman y dan propina en un recibo impreso. Esta opción imprimirá una copia del recibo para el comerciante y el cliente. Habilitar esta configuración desactivará las firmas digitales y las sugerencias en pantalla. Para ajustar las transacciones con propinas añadidas, active Habilitar propinas en Configuración*. Vaya a Transacciones --> Ajustar propinas e ingrese los montos de las propinas antes de que las transacciones cierren a las 10 p.m. EST o su hora de cierre automático personalizada.',
  IsTipEnabledLabel: 'Habilitar Propinas',
  TipEnabledPresetTip: 'Propina Preestablecida {{presetNumber}}',
  CombineTipsLabel: 'Combine pantallas de sugerencias y firmas',
  EdgeProgramPendingWarning: 'Su solicitud del programa Edge está Pendiente',
  EdgeProgramLabel: 'Programa Edge',
  CashDiscountingLabel: 'Descuento por Efectivo',
  EnablePerTransactionLabel: 'Habilitar por Transacción',
  EnablePerTransactionSubtext: 'Permita que el usuario deshabilite el descuento por efectivo durante el pago.',
  DisplayCashPriceLabel: 'Mostrar Precio en Efectivo',
  DisplayCashPriceSubText: 'Su cliente verá dos totales. El precio de venta al público si pagan con crédito y el precio en efectivo si pagan con efectivo, PIN Débito o EBT.',
  CashFixedAmountLabel: 'Cantidad Fija',
  CashFixedAmountSubtext: 'Entre $0.01 y $1.00',
  CashDiscountAmountLabel: 'Porcentaje Fijo',
  CashDiscountAmountSubtext: 'Entre 1% y 4%',
  EdgeNoticeTitle: 'Programa Edge',
  EdgeNoticeReverseBtn: 'Actualizar al Programa Edge',
  SubmitEdgeProgramTermsTitle: 'Términos del Programa Edge',
  SaveSettingsBtn: 'Guardar Ajustes',
  SettingsErrorBottom: 'Formulario está incompleto. Por favor revise sus cambios.',
  settingsDisclaimer: 'Se aplicará por operación el importe fijo o el importe por porcentaje, en función de los límites que se hayan establecido anteriormente.',
  EditBusinessProfile: 'Editar perfil del negocio',
  DeleteOwnerDialog: {
    Title: 'BORRAR PROPIETARIO',
    ConfirmText: 'BORRAR',
    DeleteOwnerMessage: '¿Está seguro de borrar permanentemente este propietario?'
  },
  AddOwnerDialog: {
    Title: 'Propietario'
  },
  OwnerDialogError: {
    Title: 'Error',
    OwnerDialogErrorMessage: 'Hubo un error al procesar su información, por favor intente de nuevo o contacte a Servicio al Cliente al {{phoneNumber}}'
  },
  PCICompliance: {
    global: {
      criteria: 'Criterios',
      exclusions: 'Exclusiones',
      requirements: 'Requisitos',
      level: 'Nivel',
      annual: 'Anual:',
      quarterly: 'Trimestral:'
    },
    title: 'Cumplimiento de PCI',
    humboldt: {
      listText: 'Descargar política de Seguridad de la Información',
      description: 'Nuestro programa PCI facilita el cumplimiento del estándar de seguridad de datos de la industria de tarjetas de pago (PCI DSS). Para ayudar a garantizar la seguridad de los datos de los titulares de tarjetas, todos los comerciantes deben cumplir con el PCI DSS.',
      faqContactText: 'Notificar a PCI en',
      faqContactTextTwo: 'e incluya la siguiente información:',
    },
    paymentsHub: {
      listText: 'Descargar Política de seguridad de la información y capacitación de PCI Plus',
      description: 'Nuestro programa PCI Plus facilita el cumplimiento del Estándar de seguridad de datos de la industria de tarjetas de pago (PCI DSS). Para ayudar a garantizar la seguridad de los datos de los titulares de tarjetas, todos los comerciantes deben cumplir con el PCI DSS.',
      faqContactText: 'Notificar a PCI Plus en',
      faqContactTextTwo: 'e incluya la siguiente información:'
    },
    meetsComplianceWithoutDate: 'Su cuenta es compatible con PCI',
    meetsComplianceWithDate: 'Su cuenta es compatible con PCI hasta {{complianceDate}}',
    nonCompliant: 'Su cuenta no cumple con PCI',
    alarm: 'Su estado de cumplimiento de PCI está pendiente.',
    downloadPCIPlusTraining: 'Capacitación PCI Plus',
    downloadInformationSecurityPolicy: 'Política de seguridad de la información',
    downloadSAQ: 'Descargar Cuestionario de autoevaluación (SAQ)',
    saqDateTitle: 'Fecha de finalización del SAQ',
    scanDateTitle: 'Fecha de finalización del Scan',
    billingDateTitle: 'Facturación',
    billingDateText: 'Por adelantado Pago Anual + Mensual',
    informationNeeded: 'Si necesita editar alguna información, ya completó documentos fuera de línea para validar el cumplimiento de PCI en los últimos 12 meses o tiene preguntas sobre el estado del escaneo o SAQ, comuníquese con el departamento de Cumplimiento al',
    learnMore: 'Obtenga más información sobre el cumplimiento de PCI',
    learnMoreCTA: 'aquí',
    FAQS: {
      title: 'Recursos para comerciantes que cumplen con las normas PCI',
      whatsPCIDSS: {
        title: '¿Qué es PCI DSS?',
        PCDStandard: 'Los estándares de seguridad de datos de la industria de tarjetas de pago (PCI DSS) son un estándar de seguridad global para todas las entidades que procesan, almacenan o transmiten datos de titulares de tarjetas. Estos estándares son aplicables a todos los comerciantes, independientemente de su tamaño o volumen, y ayudan a reducir el fraude y a proteger el ecosistema de las tarjetas de pago.',
        PCDGoal: 'El objetivo del Estándar de Seguridad de Datos PCI (PCI DSS) es proteger los datos de los titulares de tarjetas y los datos de autenticación confidenciales dondequiera que se procesen, almacenen o transmitan. Los controles y procesos de seguridad que exige el PCI DSS son fundamentales para proteger todos los datos de las tarjetas de pago.',
        PCDCompliance: 'El cumplimiento del PCI DSS es exigido por las principales marcas de tarjetas de pago: American Express, Discover Financial Services, JCB International, Mastercard Worldwide y Visa Inc.'
      },
      whatIsSAQ: {
        title: '¿Qué es un SAQ?',
        selfAssessmentQuestionnaire: 'El Cuestionario de autoevaluación (SAQ) es una herramienta de validación para comerciantes y proveedores de servicios diseñada para el entorno específico de su empresa. Está compuesto por 12 requisitos que cubren controles de seguridad tanto técnicos como no técnicos. A continuación, se detallan los requisitos de PCI DSS:',
        requirementsList: {
          installAndMaintain: 'Instalar y mantener una configuración de firewall para proteger los datos del titular de la tarjeta',
          vendorSupplied: 'No utilice valores predeterminados proporcionados por el proveedor para las contraseñas del sistema y otros parámetros de seguridad',
          cardHolder: 'Proteger los datos almacenados del titular de la tarjeta',
          encryptTransmission: 'Cifrar la transmisión de datos del titular de la tarjeta a través de redes públicas abiertas',
          antiVirus: 'Proteja todos los sistemas contra malware y actualice periódicamente los programas de software antivirus.',
          secureSystemAndApps: 'Desarrollar y mantener sistemas y aplicaciones seguros',
          restrictAccess: 'Restringir el acceso a los datos del titular de la tarjeta según la necesidad de conocerlos',
          authenticateAccess: 'Identificar y autenticar el acceso a los componentes del sistema',
          restrictPhysicalAccess: 'Restringir el acceso físico a los datos del titular de la tarjeta',
          trackAndMonitor: 'Seguimiento y control de todos los accesos a los recursos de la red y a los datos del titular de la tarjeta.',
          testSecuritySystem: 'Pruebe periódicamente los sistemas y procesos de seguridad',
          maintainPolicy: 'Mantener una política que aborde la seguridad de la información para todo el personal.'
        }
      },
      howToAccessPCI: {
        title: 'Cómo evaluar la seguridad PCI DSS:',
        howToAccessOptions: {
          reviewPCIRequirementsTitle: 'Revise los requisitos del PCI DSS:',
          reviewPCIRequirementsDescription: 'Conozca lo que el estándar PCI exige de su negocio.',
          inventoryITTitle: 'Inventario de activos y procesos de TI:',
          inventoryITDescription: 'Identificar todos los sistemas, personal y procesos involucrados en la transmisión, procesamiento o almacenamiento de datos del titular de la tarjeta.',
          findVulnerabilitiesTitle: 'Encuentra vulnerabilidades:',
          findVulnerabilitiesDescription: 'Utilice el SAQ apropiado para guiar la evaluación y las tecnologías apropiadas para localizar sistemas inseguros.',
          thirdPartyTitle: 'Validar con expertos externos:',
          thirdPartyDescription: 'La complejidad de su entorno puede requerir un evaluador de seguridad calificado y/o un proveedor de escaneo aprobado.'
        },
        QSAsLink: 'Evaluadores de seguridad calificados (QSA)',
        ASVsLink: 'Proveedores de servicios de escaneo aprobados (ASV)',
        securityListTitle: 'Lograr y mantener un entorno de cumplimiento seguro y sólido incluye lo siguiente:',
        securityList: {
          accessingTitle: 'Evaluando:',
          accessingDescription: 'Probar y verificar los controles implementados para proteger los datos de los titulares de tarjetas durante el almacenamiento, el procesamiento y la transmisión. Los sistemas y los datos deben estar disponibles para su análisis.',
          reportingTitle: 'Informes:',
          reportingDescription: 'Validar el cumplimiento y presentar evidencia de que existen controles de protección de datos.',
          monitoringAlertingTitle: 'Monitoreo y alertas:',
          monitoringAlertingDescription: 'Implementar sistemas que proporcionen alertas automáticas para monitorear constantemente el acceso y el uso de los datos. Ampliar los controles del sistema para incluir la recopilación y el almacenamiento de datos de registro.',
        },
        ensureSecurityControl: 'Para garantizar que los controles de seguridad se sigan implementando correctamente, el PCI DSS debe implementarse en las actividades habituales como parte de la estrategia de seguridad general de una entidad. Esto permite a la entidad monitorear la eficacia de sus controles de seguridad de manera continua y mantener su entorno de cumplimiento del PCI DSS entre evaluaciones del PCI DSS.',
        table: {
          body: {
            rowOne: {
              levelOne: 'Nivel 1',
              criteria: 'Comerciantes que procesan más de 6 millones de transacciones al año en todos los canales.',
              requirements: {
                annualOne: 'Informe de cumplimiento (ROC) elaborado por un asesor de seguridad cualificado (QSA)',
                annualTwo: 'Presentar una Certificación de Cumplimiento (AOC)',
                quarterlyOne: 'Escaneo en red por un proveedor de escaneo aprobado (ASV)'
              }
            },
            rowTwo: {
              levelTwo: 'Nivel 2',
              criteria: 'Entre 1 y 6 millones de transacciones anuales en todos los canales',
              requirements: {
                annualOne: 'Cuestionario de autoevaluación (SAQ) completado por un asesor de seguridad interna (ISA) certificado o contrate a un QSA para una auditoría en el sitio',
                annualTwo: 'Presentar una Certificación de Cumplimiento (AOC)',
                quarterlyOne: 'Escaneo en red por un proveedor de escaneo aprobado (ASV)'
              }
            },
            rowThree: {
              levelThree: 'Nivel 3',
              criteria: 'Entre 20.000 y 1 millón de transacciones de comercio electrónico al año',
              requirements: {
                annualOne: 'Informe de cumplimiento (ROC) elaborado por un asesor de seguridad cualificado (QSA)',
                annualTwo: 'Presentar una Certificación de Cumplimiento (AOC)',
                quarterlyOne: 'Análisis de red trimestral realizado por un proveedor de análisis autorizado (ASV)'
              }
            },
            rowFour: {
              levelFour: 'Nivel 4',
              criteria: 'Comerciantes que procesan menos de 20.000 transacciones de comercio electrónico al año y todos los demás comerciantes que procesan hasta 1 millón de transacciones al año.',
              requirements: {
                annualOne: 'Informe de cumplimiento (ROC) elaborado por un asesor de seguridad cualificado (QSA)',
                annualTwo: 'Presentar una Certificación de Cumplimiento (AOC)',
                quarterlyOne: 'Análisis de red trimestral realizado por un proveedor de análisis aprobado (ASV) (si corresponde)'
              }
            }
          }
        }
      },
      consequencesOfNonCompliance: {
        title: 'Consecuencias del incumplimiento',
        list: {
          accountClosure: 'Cierre de cuenta y/o pérdida del privilegio de ofrecer tarjeta de pago',
          branMarket: 'Daño a la reputación de marca y mercado',
          cardBrand: 'Evaluaciones de marcas de tarjetas',
          dataBreach: 'Violación de datos de la cuenta'
        }
      },
      whatToDoWhenBreached: {
        title: 'Qué hacer en caso de violación de datos',
        descriptionOne: 'Las filtraciones ocurren todos los días, los datos se ven comprometidos las 24 horas del día, en todo el mundo, por lo que es importante saber qué hacer al respecto. Debe comprender que existe la posibilidad de que cualquier filtración en la que se robe información confidencial de los clientes tenga consecuencias negativas. Estas consecuencias negativas pueden ser, como mínimo, financieras, de reputación o una combinación de las tres.',
        descriptionTwo: 'Independientemente del tamaño de su empresa, la cantidad de datos que se ve comprometida <strong>puede ser pequeña y manejable</strong> con la misma facilidad, o puede ser enorme y catastrófica. De hecho, lo que muchos dueños de empresas no saben es que el 90% de las violaciones de datos que involucran información de pago ocurren en pequeñas empresas.',
        largeCompanyBreaches: 'Las violaciones de seguridad que afectan a grandes empresas que aparecen en las noticias no ocurren con tanta frecuencia como las que afectan a pequeñas y medianas empresas, que tienen menos recursos disponibles para defenderse.',
        contact: 'Comuníquese con nosotros de inmediato con respecto al programa de perdón de violaciones si cree que ha experimentado una vulneración de datos y tome las siguientes medidas:',
        list: {
          mids: 'Todos los MIDs que cree que podrían verse afectados',
          incidentOcurred: 'El mes y año en que cree que ocurrió el incidente.',
          contactInformation: 'Nombre, información de contacto y correspondencia relacionada de cualquier persona o entidad que le informó sobre el posible incidente'
        },
        whatHappensNext: {
          title: '¿Qué pasa después?',
          description: 'Un miembro del equipo PCI se comunicará con usted y revisará con usted los criterios y exclusiones del programa, así como el incidente informado, para confirmar su elegibilidad para el programa de condonación. Una vez que se confirme su elegibilidad, su representante del equipo PCI coordinará con usted las respuestas y acciones necesarias, que incluyen:',
          list: {
            reporting: 'Completar con usted los requisitos de informes, como la información que debe reportarse a las marcas de tarjetas.',
            engagement: 'Contratación de especialistas forenses para realizar investigaciones forenses',
            reCertification: 'Coordinación de recertificación en caso de requerirse',
          },
          costsOfTheForensics: 'Recuerde que cubriremos los costos forenses y otros gastos que estén cubiertos por los beneficios del programa y no buscaremos su reembolso mientras siga siendo elegible.',
          breachForgiveness: 'El programa de condonación de incumplimientos ofrece los beneficios que se enumeran a continuación para los comerciantes en función del cumplimiento de los criterios identificados, siempre que no se apliquen exclusiones. La inscripción en el programa se renueva cada mes en función de las calificaciones del comerciante para continuar en el programa.',
          mustHaveLevels: 'Debe ser un comerciante de nivel 3 o nivel 4, o de nivel 2 si se cumplen ciertos criterios*',
          noFineOrPenalty: 'No puede tener una marca de tarjeta anterior multa o penalización',
          SEQ: '(Evaluación PCI) a menos que haya sido reintegrado en función de la finalización de la Certificación PCI (por ejemplo, Cuestionario de autoevaluación)',
          notSuffered: 'No haber sufrido una <strong>infracción previa</strong> sin recertificación PCI',
          incidentsTitle: 'El incidente ocurre, se descubre y se informa.',
          incidentsDescription: 'durante el período del programa de condonación de incumplimientos (todos los gastos que surjan del mismo incidente o cadena de incidentes relacionados en un comercio se considerarán un solo incidente). Tenga en cuenta que el período del programa de condonación de incumplimientos se renueva cada mes en función del cumplimiento continuo de los criterios y siempre que no se aplique ninguna exclusión.',
          exclusionList: {
            incidentOcurred: 'Cualquier incidente ocurrido antes del 1 de diciembre de 2019',
            incidentKnow: 'Cualquier incidente conocido o descubierto antes del 1 de diciembre de 2019',
            incidentDiscovered: 'Un incidente descubierto antes de la fecha de vigencia de su acuerdo comercial',
            incidentFailed: 'Cualquier incidente relacionado con una falla de seguridad que sea específicamente conocida y que existiera antes de que se le proporcionara el programa de perdón de infracciones.',
            costsOrExpenses: 'Cualquier costo o gasto incurrido o requerido para que un comerciante cumpla con PCI en primera instancia antes de que ocurra un incidente.',
            incidentCertified: 'Cualquier incidente en el que haya experimentado un incidente previo a menos que su empresa haya sido recertificada a un nivel de cumplimiento PCI elegible por un asesor de seguridad calificado antes del incidente actual',
            softwareWithinYourControl: 'Cualquier software que no esté bajo su control',
            softwareIncident: 'Cualquier incidente que ocurra en cualquier sistema informático en el que varios comerciantes sin relación legal entre sí hayan alojado cuentas o compartan una base de datos, un sistema operativo o aplicaciones de software comunes.',
            incidentWithout: 'Cualquier incidente sin',
            incidentWithoutWrittenNotification: 'una notificación formal por escrito por parte de la red de tarjetas al banco adquirente, al ISO o al comerciante sobre un incidente,',
            incidentWithoutContractually: 'una demanda exigible contractualmente por parte de la red de tarjetas para el reembolso de gastos debido a un incidente (no se aplica a actualizaciones de hardware o software necesarias para evitar una evaluación de PCI)'
          },
          additionalInformation: 'Información de referencia adicional:',
          additionalInformationVisaLink: 'Suplemento de visa: qué hacer en caso de violación',
          additionalInformationPFIsLink: 'Investigadores forenses PCI (PFIs)',
        }
      },
      helpMerchants: {
        title: '¿Qué hacemos para ayudar a los comerciantes?',
        description: 'Entendemos que PCI DSS puede ser un proceso complicado. Nuestro objetivo es simplificarle esto al facilitar el cumplimiento a cualquier comerciante que utilice nuestras soluciones patentadas de procesamiento de tarjetas de crédito. Utilizaremos una combinación de tecnología y experiencia del equipo de cumplimiento para determinar los requisitos de PCI y utilizaremos análisis de datos para brindar un mayor apoyo en la identificación, el logro y el mantenimiento del cumplimiento de PCI. Además, ofrecemos beneficios interesantes, como la exención de cargos por incumplimiento, la condonación de infracciones para comerciantes calificados y análisis de vulnerabilidad incluidos si es necesario (¡hasta 3 direcciones IP!).'
      }
    }
  },

  // Business Settings Page
  VerifiedDialog: {
    Text: 'Por motivos de seguridad, las actualizaciones que haya realizado en su cuenta surtirán efecto una vez verificadas.'
  },

  //News & Tips Card
  'News & Tips': 'Noticias y Consejos',
  'Need help navigating Payments Hub?': '¿Necesita ayuda para navegar Payments Hub?',
  'Watch video tutorials': 'Mira los tutoriales',
  'Download the guide': 'Descarga la guía',
  //News & Tips Card

  //Statements News & Tips
  'Watch our statement video to understand your processing statement better.': 'Mira el video para entender mejor el procesamiento de tu estado de cuenta.',
  'Watch video': 'Mira el video',
  //Statements News & Tips

  //Sales
  Volume: 'Volumen',
  Method: 'Método',
  Items: 'Artículos',
  Categories: 'Categorías',
  'Total to Account For': 'Total a Contabilizar',
  Quantity: 'Cantidad',
  'Total Sales': 'Total ventas',
  Current: 'Actual',
  Previous: 'Previo',
  Discounts: 'Descuentos',
  'Gross Sales': 'Ventas brutas',
  'Net Sales': 'Ventas netas',
  Refunds: 'Reembolsos',
  Tips: 'Propinas',
  Unpaid: 'Sin pagar',
  Void: 'Anular',
  Swiped: 'Deslizado',
  'Chip (EMV)': 'Chip (EMV)',
  Contactless: 'Sin contacto',
  Keyed: 'Manual',
  Cash: 'Efectivo',
  'Chip-Read Credit': 'Chip crédito',
  'Contactless Credit': 'Sin contacto Crédito',
  'Keyed Credit': 'Crédito Manual',
  Voids: 'Nulas',
  Change: 'Cambio',
  Status: 'Estado',
  LastVisit: 'Ultima visita',
  Sold: 'Vendido',
  UniqueCustomers: 'Clientes Unicos',
  NewCustomers: 'Clientes nuevos',
  TopByVolume: 'Top 3 por Volumen',
  TopByQuantity: 'Top 3 por Cantidad',
  Custom: 'Personalizado',
  ComparedTo: 'Comparado A',
  'Compared to previous month': 'Comparado al último mes',
  'Previous Month': 'Último mes',
  'Compared to previous 3 months': 'Comparado a los últimos 3 meses',
  'Previous 3 Months': 'Últimos 3 meses',
  'Compared to previous 6 months': 'Comparado a los últimos 6 meses',
  'Previous 6 Months': 'Últimos 6 meses',
  None: 'Ninguno',
  'Not categorized': 'No categorizado',
  NoSalesData: {
    Volume: 'No Hay Datos de Volumen de Ventas',
    Method: 'No Hay Datos de Métodos de Pago',
    Customers: 'No Hay Datos de Ventas de Clientes',
    Employees: 'No Hay Datos de Ventas de Empleados',
    Items: 'No Hay Datos de Ventas de Artículos',
    Categories: 'No Hay Datos de Ventas de Categorías'
  },
  'Bank Account Sales': 'Ventas de Cuentas Bancarias',
  //Sales

  //Invoice
  ReplaceExistingPaymentMethodSeries: 'Reemplazar el método de pago existente para todos los pagos futuros de esta serie.',
  ReplaceExistingPaymentMethodInvoice: 'Reemplazar el método de pago existente para esta factura.',
  ReplaceExistingPaymentMethod: 'Reemplazar método de pago existente.',
  PaymentMethod: 'Método de pago',
  UseSavedPaymentMethod: 'Usar método de pago guardado',
  AddPaymentMethod: 'Agregar nuevo método de pago',
  SendWithoutPaymentMethod: 'Enviar sin método de pago guardado',
  AutopayLabel: 'Pago Automático',
  Autopay: 'Al seleccionar esta opción, se mostrará el pago automático en esta serie y se requerirá que el cliente ingrese manualmente un método de pago por factura. El cliente puede volver a habilitar el pago automático en cualquier momento.',
  WrittenPermission: 'Es su responsabilidad recopilar el permiso por escrito de su cliente para guardar su tarjeta. Al agregar una tarjeta, reconoce que es responsable de cualquier transacción rechazada o contracargo',
  SeriesDetails: 'Detalles de la serie',
  InvoiceTitle: '{{name}} por {{total}}',
  StartDate: 'Inicio {{date}}',
  StartDateLabel: 'Fecha de Inicio',
  EndDate: 'Finaliza {{date}}',
  DueIn: 'Vence en {{date}}',
  DueInDays: 'Vence en {{days}}',
  DueToday: 'Vence Hoy',
  SavedPaymentMethod: 'Método de pago guardado',
  ExpiresCard: 'Caduca {{card}}',
  Edit: 'Editar',
  ViewCustomerProfile: 'Ver perfil de cliente',
  InvoiceDescription: 'Descripción',
  InvoiceActivity: 'Actividad de la {{invoice}}',
  EditedBy: 'Editado por {{name}}',
  ViewAllActivity: 'Ver toda la actividad',
  Details: 'Detalles',
  Resend: 'Reenviar',
  PaymentFailed: 'Pago fallido',
  FailedPayments: '{{number}} pagos fallidos',
  ContactCustomerEditPaymentMethod: 'Contactar con el cliente o editar método de pago.',
  ViewFailedInvoices: 'Ver facturas fallidas',
  ViewInvoicesSeries: 'Ver facturas en serie',
  ViewTransactionHistory: 'Ver historial de transacciones',
  EditPaymentAmount: 'Editar cantidad de pago',
  ManageSeries: 'Administrar esta serie',
  ViewTransactionDetails: 'Ver detalles de la transacción',
  EditPaymentMethod: 'Editar metodo de pago',
  AutopayCard: 'Pago automatico {{processor}} {{lastDigits}}',
  DueDate: '<0>Vence</0> {{date}}',
  DueDateLabel: 'Vence {{date}}',
  SentDate: '<0>Enviada</0> {{date}}',
  ScheduleDate: '<0>Programada</0> para auto pago el {{date}}',
  WillSendDate: '<0>Va</0> ser enviada el {{date}}',
  OverDueDate: '<0>Contactar</0> cliente o editar método de pago.',
  PaymentFailedDescription: '<0>Pago</0> intentado el {{date}}<3 /><4>Contactar</4> al cliente o editar el método de pago.',
  PaidDate: 'Pagada ',
  InvoiceNumberID: 'Factura #{{number}}',
  RepeatsFrequency: 'Se repite {{frequency}}',
  InvoiceIndex: 'Factura {{index}} de {{size}}',
  NoEndDate: 'sin fecha de finalización',
  Never: 'nunca',
  RepeatsText: 'Repite',
  Daily: 'diaramente',
  Weekly: 'semanalmente',
  Monthly: 'mensualmente',
  Yearly: 'anualmente',
  On: 'los',
  At: 'a las',
  OnThe: 'los',
  Every: 'cada',
  days: 'dias',
  weeks: 'semanas',
  months: 'meses',
  years: 'años',
  CreateInvoice: 'Crear una factura',
  EditDraftInvoice: 'Editar borrador de factura',
  EditInvoice: 'Editar factura',
  PreviewInvoice: 'Vista previa de factura',
  DeleteDraft: 'Eliminar borrador',
  SendInvoice: 'Enviar factura',
  NoInvoices: 'Sin facturas',
  InvoicesData: 'Datos de facturas',
  AddItems: 'Agregar elementos',
  TaxNoteText: 'La tasa impositiva predeterminada se establece en',
  ConfigureTax: 'Configurar impuesto en',
  CustomerEmailAddress: 'Dirección de correo electrónico del cliente',
  EnterEmailAddress: 'Ingrese una dirección de correo electrónico o busque en el directorio de clientes',
  CustomerPhoneNumber: 'Número de teléfono del cliente',
  EnterPhoneNumber: 'Ingrese el número de teléfono',
  EnterFirstName: 'Ingrese nombre',
  EnterLastName: 'Ingrese apellido',
  EnterInvoiceName: 'Ingrese el nombre de la factura',
  CustomerStreetAddress1: 'Dirección de calle del cliente 1',
  EnterStreetAddress1: 'Ingrese la dirección de la calle 1',
  CustomerStreetAddress2: 'Dirección de calle del cliente 2',
  EnterStreetAddress2: 'Ingrese la dirección de la calle 2',
  Country: 'País',
  SelectState: 'Seleccionar estado',
  EnterCity: 'Ingresar ciudad',
  EnterZipCode: 'Ingrese el código postal',
  AddCustomerAddress: 'Añadir dirección de cliente',
  InvoiceName: 'Nombre Factura',
  InvoiceNumberText: 'Número de factura',
  InvoiceType: 'Tipo Factura',
  PaymentAmount: 'Importe del pago',
  RewardCode: 'Código de Descuento o Recompensa',
  RewardCodeHintText: 'Ingrese el código de recompensa',
  RewardCodeError: 'El código de recompensa que usted ingresó no es válido o ha expirado',
  EnterValidRewardCode: 'Por favor ingrese un código de recompensa válido',
  Apply: 'Aplicar',
  Remove: 'Eliminar',
  LoyaltyReward: 'Recompensa de Lealtad',
  LoyaltyRewardOff: 'de descuento',
  AvailableRewards: 'Recompensas disponibles',
  OffEntirePurchase: 'de descuento en toda la compra',
  Expires: 'Expira el',
  Frequency: 'Frecuencia',
  NumberPayments: '# de Pagos',
  RepeatEvery: 'Repetir cada',
  DueDateText: 'Fecha de vencimiento',
  CustomDate: 'Fecha Personalizada',
  Select: 'Seleccionar',
  Cadence: 'Cadencia',
  TipEnabled: 'Propinas Activas',
  CDEnabled: 'Descuento en Efectivo Activo',
  EnterDate: 'Ingrese fecha',
  'Itemized invoice': 'Factura detallada',
  'One time': 'Una vez',
  Recurring: 'Recurrente',
  'Upon Receipt': 'Al recibir',
  'Immediately': 'Inmediatamente',
  '7 Days': '7 Días',
  '14 Days': '14 Días',
  '30 Days': '30 Días',
  'Select Date': 'Seleccionar fecha',
  'On date': 'A tiempo',
  'After # of payments': 'Después de # de pagos',
  'Day(s)': 'Día(s)',
  'day(s)': 'día(s)',
  'Week(s)': 'Semana(s)',
  'week(s)': 'semana(s)',
  'Month(s)': 'Mes(es)',
  'month(s)': 'mes(es)',
  'Year(s)': 'Año(s)',
  'year(s)': 'año(s)',
  Days: 'días',
  'Send to customer': 'Enviar al cliente',
  'Invoice created': 'La factura fue creada',
  'Payment amount changed': 'El monto de pago cambió',
  'Invoice canceled': 'La factura fue cancelada',
  'Series canceled': 'La serie fue cancelada',
  'Series created': 'La serie fue creada',
  By: 'por',
  'NoActivities': 'Sin actividades',
  'Apply to next invoice only.': 'Aplicar solo a la siguiente factura.',
  'Apply to all future invoices.': 'Aplicar a todas las facturas futuras.',
  Comment: 'Comentario',
  ColumnsGeneral: {
    Customer: 'Cliente',
    Amount: 'Monto',
    Created: 'Creado',
    EndDate: 'Finaliza',
    Frequency: 'Frecuencia',
    Invoice: 'Factura',
    InvoiceName: 'Nombre en Factura',
    LastName: 'Cliente',
    StartDate: 'Empieza',
    Status: '',
    PaidDate: 'Fecha del Pago',
    SendDate: 'Fecha del envío',
  },
  ColumnsForSeries: {
    SeriesName: 'Nombre de Serie',
  },
  ColumnsForCanceled: {
    Canceled: 'Cancelado'
  },
  ColumnsForInvoices: {
    Due: 'Vencimiento'
  },
  ColumnsForFailedPayment:{
    FailedDate: 'Fecha del fallo ',
  },

  // Invoice
  Partially_paid: 'Parcialmente pago',
  UnsavedChanges: 'Tiene cambios sin guardar.',
  Discard: 'Sí, descartar',
  GoBack: 'No, volver',
  DiscardInvoice: '¿Está seguro de que desea descartar esta factura?',
  EnterEmail: 'Busque en el directorio de clientes o ingrese un correo electrónico',
  EnterItem: 'Ingrese el nombre del artículo para buscar en el inventario',
  UpgradeNow: 'Actualizar ahora',
  CashPaidInvoiceStatusMessage: {
    DeclinedStatus: 'Error al procesar la transacción de venta de factura en efectivo.',
    PleaseTryAgain: 'Inténtalo de nuevo.',
    SendReceipt: 'ENVIAR RECIBO',
    ViewInTransactions: 'VER EN TRANSACCIONES',
    PrintReceipt: 'IMPRIMIR EL RECIBO',
    SuccessStatus: 'El pago de la factura en efectivo se ha procesado correctamente.'
  },
  UpgradeDialog: {
    Title: 'Actualizada',
    BodyText: 'Ha actualizado con éxito su plan a Premium.'
  },
  MembershipDialog: {
    Title: 'Actualizar al Plan Premium',
    BodyText: '¿Quieres actualizar a Premium por $14.95/mes?'
  },
  CreateInvoiceError: 'Error al Crear Factura {{errorMessage}}',
  SendInvoiceError: 'Error al Enviar Factura',
  UpdateDraftError: 'Error al Actualizar Factura Borrador {{errorMessage}}',
  UnnamedCustomer: 'Cliente sin nombrar',
  BasePrice: 'Precio Base',
  ExpressItemDescription: 'Artículo Express',
  PayAmount: 'Pagar {{amount}}',
  PayBy: 'A pagar el {{date}}',
  InvoiceRepeat: 'Repite cada {{length}} {{period}} {{dueDate}}',
  InvoiceRepeatUntil: ' hasta {{date}}',
  InvoiceSeriesDetail: '({{currentInvoice}} de {{quantity}})',
  NoBalanceDue: {
    Header: 'Sin saldo adeudado.',
    Description: 'Enviamos un recibo a <1>{{email}}}</1>'
  },

  // Invoice Detail
  InvoiceDetail: {
    AppleInvoice: 'Factura {{invoice}} Subtotal',
    AppleTax: 'Impuestos',
    AppleTotal: 'Total',
    AppleTip: 'Propina',
    BillTo: 'Cobrar a {{billTo}}',
    InvoiceNumber: 'Factura #{{invoiceNumber}}',
    PaidDate: 'Pagado el {{date}}',
    AutoPayCharge: 'Este método de pago será utilizado para futuros cobros en esta serie',

    // Paid Invoice
    PaidInvoice: {
      Header: 'Gracias, su factura ha sido pagada.',
      Description: 'Hemos enviado un recibo a <1>{{email}}</1>'
    }
  },
  NoTip: 'Sin propina',
  Other: 'Otro',
  TipModalHeader: 'Ingresar monto de propina.',
  AutoSaveCardMessage: 'Este método de pago registrado se cargará automáticamente por cada factura recurrente de esta serie. Protéjase de posibles devoluciones de cargo o fraude asegurándose de tener el consentimiento por escrito de su cliente para cargar el método de pago en el archivo.',
  AutoSaveCardMessageOneTime: 'Este método de pago se guardará en el perfil del cliente y se cargará en la fecha de vencimiento de esta factura. A su cliente se le enviará un recibo del pago de la factura. Protéjase de posibles fraudes o cargos estando seguro de que tiene por escrito el consentimiento de su cliente para cargar este método de pago.',
  AutoSaveCardMessageOneTimeDueDateToday: 'A este método de pago guardado se le cobrará inmediatamente esta factura. A su cliente se le enviará un recibo de factura pagada. Protéjase de posibles devoluciones de cargo o fraudes asegurándose de tener el consentimiento por escrito de su cliente para cobrar este método de pago guardado.',
  SavedCardMessageOneTime: 'Este método de pago guardado se cargará en la fecha de vencimiento de esta factura. A su cliente se le enviará un recibo de factura pagada. Protéjase de posibles devoluciones de cargo o fraudes asegurándose de tener el consentimiento por escrito de su cliente para cobrar este método de pago guardado.',
  SavedCardMessageOneTimeDueDateToday: 'A este método de pago guardado se le cobrará inmediatamente esta factura. A su cliente se le enviará un recibo de factura pagada. Protéjase de posibles devoluciones de cargo o fraudes asegurándose de tener el consentimiento por escrito de su cliente para cobrar este método de pago guardado.',
  FuturePaymentDisclaimer: 'El método de pago guardado se cargará automáticamente en la fecha de vencimiento. Protéjase de posibles devoluciones de cargo o fraudes asegurándose de tener el consentimiento por escrito de su cliente para cargar su método de pago guardado.',
  // Open Invoices
  OpenInvoices: {
    Title: 'Facturas Abiertas'
  },
  NoOpenInvoices: {
    Title: '¡Estás al día!',
    Description: 'No tienes facturas pendientes. ¡Gran trabajo!'
  },

  // Payment Methods
  // No Payment Method
  NoPaymentMethod: {
    Header: 'Agregue un método de pago guardado.',
    Text: 'Guardar una tarjeta de crédito en el archivo le permite inscribirse en el pago automático. También hace que las compras futuras sean mucho más fáciles.',
    Button: 'Agregar método de pago'
  },
  // Delete Method Dialog
  DeleteMethodDialog: {
    Header: 'Eliminar método de pago',
    Text: 'Si este método de pago está asociado con algún pago recurrente, ya no se cobrará'
  },
  // Add Second Payment Method
  AddSecondPaymentMethod: {
    Button: 'Agrega un metodo de pago'
  },
  // Method Options
  MakeDefault: 'Hacer por defecto',

  // Payment History
  PaymentHistory: {
    DownloadReceipt: 'Descargar {{count}} recibos',
    DownloadReceipts: 'Descargar {{count}} recibos',
    NoPaymentHistoryDescription: 'Las facturas que se paguen con éxito aparecerán aquí para sus registros.',
    NoPaymentHistoryTitle: 'Sin historial de pago.',
    SearchInvoices: 'Buscar facturas',
    SendReceipt: 'Enviar {{count}} recibo',
    SendReceipts: 'Enviar {{count}} recibos',
    Title: 'Historial de Pagos'
  },

  // Mark as Paid
  CreditPrice: 'Precio en Crédito',
  CashPrice: 'Precio en Efectivo',
  CommentField: {
    Label: 'Commentario (visible únicamente para usted)',
    Hint: 'Ingrese un comentario; ejemplo: cliente pagó en efecivo',
  },

  // Edit payment amount modal
  OriginalPaymentAmount: 'Monto del pago original',
  NewPaymentAmountField: {
    Label: 'Nuevo monto del pago',
    Hint: '$0.00'
  },
  EditPaymentAmountCommentField: {
    Label: 'Commentario (visible únicamente para usted)',
    Hint: 'Opcional',
  },
  Duration: 'Duración',

  // High amount modal
  HighAmountModal: {
    Title: 'Límite de transacción excedido',
    ConfirmText: 'Procesar transacción',
    CancelText: 'Atras',
    Body: 'Ha ingresado un monto que excede el límite de transacción. Ingrese un nuevo monto o comuníquese al 877.464.4218 para solicitar una actualización de los parámetros de su transacción.',
    ExternalBody: 'Esta cantidad excede el límite de transacción. Comuníquese con {{dba}} si necesita ayuda.',
  },

  // Low amount modal
  LowAmountModal: {
    Title: 'Factura de $0.00.',
    ConfirmText: 'Sí, continuar',
    CancelText: 'Cancelar',
    Body: 'Su factura tiene un saldo de $0.00. Por favor, confirme que la cantidad es correcta antes de enviar.'
  },

  // Partial Payment Modal
  PartialPaymentModal: {
    Title: 'Parcialmente autorizado {{authorizedAmt}}',
    Description: 'Cobrar el saldo restante de {{remainingBalance}} con un método de pago adicional. También puede anular la autorización parcial y comenzar de nuevo.',
    ConfirmText: 'Cobrar el saldo restante de {remainingBalance}}',
    CancelText: 'Anular y empezar de nuevo',
  },

  // Pay And Save Form
  PayAndSaveForm: {
    AccountNumberField: 'Número de Cuenta Bancaria',
    AccountNumberTooltipTitle: 'Mostrar mensaje de información',
    AccountTypePersonalChecking: 'Cheque Personal',
    AccountTypePersonalSavings: 'Ahorro Personal',
    AccountTypeBusinessChecking: 'Cheque Empresarial',
    AccountTypeBusinessSavings: 'Ahorro Empresarial',
    AccountTypeField: 'Tipo de cuenta',
    AddTitle: 'Agregar método de pago',
    EditTitle: 'Editar método de pago',
    IsAutopayLabel: 'Guarde este método de pago y cargue automáticamente los pagos futuros en esta serie.',
    IsDefaultHelp: 'Para cambiar el estado predeterminado, use la opción de menú "Establecer como predeterminado" en la lista.',
    IsDefaultLabel: 'Hacer de este mi método de pago predeterminado',
    NameOnAccountField: 'Nombre en la Cuenta',
    PaymentMethodField: 'Método de Pago',
    PayWithCreditCardOption: 'Pagar con tarjeta de crédito',
    PayWithBankAccountOption: 'Agregar Cuenta Bancaria',
    RoutingNumberField: 'Número de Ruta Bancaria',
    RoutingNumberTooltipTitle: 'Mostrar mensaje de información',
    NameOnAccount: 'Nombre en la Cuenta',
    EnterName: 'Ingrese el nombre',
    EnterAccountNumber: 'Ingrese el número de cuenta bancaria',
    EnterRoutingNumber: 'Ingrese el número de ruta bancaria',
    BankAccountTooltip: 'Este es el número asociado a la cuenta bancaria. Los números de cuentas bancarias suelen tener entre 5 y 17 dígitos.',
    RoutingNumberTooltip: 'Este es el número de 9 dígitos que identifica a tu banco.',
    Agreement: 'Estoy de acuerdo con los',
    Conditions: 'Términos y Condiciones ACH',
  },

  // Payment Method Summary
  PaymentMethodSummary: {
    Expires: 'Expira el {{date}}'
  },

  // Invoice Summary
  InvoiceSummary: {
    BalanceRemaining: 'Monto restante: {{amount}}',
    Due: 'Vence el {{date}}'
  },

  // Transactions
  'All Transactions': 'Transacciones',
  'Open Transactions': 'Transacciones Abiertas',
  'All Sales': 'Ventas',
  'Gift Card Sales': 'Ventas de Tarjeta de Regalo',
  'Gift Card Activations': 'Activaciones de Tarjeta de Regalo',
  'Credit Sales': 'Créditos',
  'Debit Sales': 'Débitos',
  'EBT Sales': 'Ventas EBT',
  'Cash Sales': 'Efectivo',
  'Pre-Auths': 'Preautorizaciones',
  'Refunds & Voids': 'Reembolsos y Nulos',
  'Total Collected': 'Total',
  'Contact Info': 'Info Contacto',
  'Related Transactions': 'Transacciones Relacionadas',
  'Declined Transactions': 'Transacciones Rechazadas',
  'Advanced Search': 'Búsqueda Avanzada',
  Comments: 'Comentarios',
  Location: 'Lugar',
  NoCustomerAssigned: 'Cliente sin Asignar',
  Amount: 'Monto',
  RecurringInvoice: 'Factura Recurrente',
  UnenrollFromAutopay: 'Desactivar Autopay',
  Device: 'Dispositivo',
  Authorization: 'Autorización',
  SplitPayment: 'Pago Dividido',
  InstantFunded: 'Financiado al Momento',
  AddCustomer: 'Agregar cliente',
  Add: 'Agregar',
  AddTo: 'Agregar a',
  SelectCustomer: 'Seleccionar Cliente',
  TransactionType: 'Tipo de Transacción',
  AuthorizedAmount: 'Monto autorizado',
  TransactionDetails: 'Detalles Transacción',
  NoDataText: 'Sin Transacciones',
  NoDataHint: 'Las transacciones se mostrarán después de que haya agrupado. Haga clic ',
  NoDataHintHere: 'Aquí',
  NoDataComponent: '<1>Las transacciones se mostrarán después de que haya agrupado. Haga Click en <2>Abrir Grupo</2> para ver una lista de autorizaciones de tarjetas de crédito activas que aún no se han liquidado. No incluye reembolsos, efectivo y transacciones con terminal virtual.</1>',
  Complete: 'Complete',
  CancelPreAuth: 'Cancelar Pre-Aut',
  CompletePreAuth: 'Completar Pre-Aut',
  Refund: 'Reembolsar',
  SendReceipt: 'Enviar Recibo',
  PrintReceipt: 'Imprimir Recibo',
  Done: 'Hecho',
  Send: 'Enviar',
  SendDate: 'Fecha de envío',
  LastSendDate: 'Ultima fecha de envío',
  OpenBatchButton: 'Abrir Grupo de transacciones',
  RefundTransaction: 'Reembolsar Transacción',
  RefundAchDisabled: 'El reembolso aún no está disponible para esta transacción. Por favor, inténtelo de nuevo más tarde.',
  VoidTransaction: 'Anular Transacción',
  Declined: 'Rechazada',
  UnableProcessPayment: 'No se puede procesar el pago',
  DeclinedTryDifferentPayment: '(Rechazada{{statusCode}}) Por favor, intente con otro método de pago',
  UnableToPayTitleCustomerFacing: 'Pago rechazado',
  UnableToPayCustomerFacing: 'No pudimos completar la transacción.',
  TryOtherPaymentMethodCustomerFacing: 'Pruebe con otro método de pago.',
  Incomplete: 'Incompleto',
  PaidInvoice: 'Factura pagada',
  ViewInvoice: 'Ver Factura',
  CashRefund: 'Reembolso en Efectivo',
  CashSale: 'Venta en Efectivo',
  ContactlessEMVCreditRefund: 'Reembolso de Crédito EMV sin contacto',
  ChipReadCreditRefund: 'Reembolso de Crédito de Lectura de Chip',
  ExportingDialogTitle: 'Obteniendo CSV',
  Update: 'Actualizar',
  CloseAll: 'Cerrar Todo',
  SaveTips: 'Guardar propinas',
  CloseAllTransactions: 'Cerrar Todas las Transacciones',
  Search: 'Buscar',
  AvailableActions: 'Acciones Habilitadas',
  AutopayText: 'Inscribirse en Autopay',
  InvoiceFrequency_one: 'Cada {{dayCount}} día',
  InvoiceFrequency_other: 'Cada {{dayCount}} días',
  Ends: 'Finaliza',
  NeverEnds: 'No Finaliza',
  KeyedCreditSale: 'Venta a Crédito Manual',
  KeyedCreditSaleSplitPayment: 'Venta a Crédito Manual - Pago Dividido',
  KeyedVoid: 'Anulación Manual',
  KeyedCreditRefund: 'Reembolso de Crédito Manual',
  IncompleteKeyedCreditSale: 'Venta a Crédito Manual Incompleta',
  IncompleteKeyedVoid: 'Anulación Manual Incompleta',
  IncompleteKeyedCreditRefund: 'Reembolso de Crédito Manual Incompleto',
  IncompleteCashSale: 'Venta en Efectivo Incompleta',
  IncompleteContactlessEMVCreditSale: 'Venta a Crédito EMV sin contacto Incompleta',
  CheckingAccountSale: 'Venta de cuenta corriente',
  PersonalCheckingAccountSale: 'Venta de cuenta corriente personal',
  BusinessCheckingAccountSale: 'Venta de cuenta corriente empresarial',
  CheckingAccountVoid: 'Anulación de venta de cuenta corriente',
  PersonalCheckingAccountVoid: 'Anulación de venta de cuenta corriente personal',
  BusinessCheckingAccountVoid: 'Anulación de venta de cuenta corriente empresarial',
  CheckingVoid: 'Anulación de venta de cuenta corriente',
  PersonalCheckingVoid: 'Anulación de venta de cuenta corriente personal',
  BusinessCheckingVoid: 'Anulación de venta de cuenta corriente empresarial',
  CheckingAccountRefund: 'Reembolso de venta de cuenta corriente',
  PersonalCheckingAccountRefund: 'Reembolso de venta de cuenta corriente personal',
  BusinessCheckingAccountRefund: 'Reembolso de venta de cuenta corriente empresarial',
  CheckingRefund: 'Reembolso de venta de cuenta corriente',
  PersonalCheckingRefund: 'Reembolso de venta de cuenta corriente personal',
  BusinessCheckingRefund: 'Reembolso de venta de cuenta corriente empresarial',
  SavingsAccountSale: 'Venta de cuenta de ahorro',
  PersonalSavingsAccountSale: 'Venta de cuenta de ahorro personal',
  BusinessSavingsAccountSale: 'Venta de cuenta de ahorro empresarial',
  SavingsAccountVoid: 'Anulación de venta de cuenta de ahorro',
  PersonalSavingsAccountVoid: 'Anulación de venta de cuenta de ahorro personal',
  BusinessSavingsAccountVoid: 'Anulación de venta de cuenta de ahorro empresarial',
  SavingsVoid: 'Anulación de venta de cuenta de ahorro',
  PersonalSavingsVoid: 'Anulación de venta de cuenta de ahorro personal',
  BusinessSavingsVoid: 'Anulación de venta de cuenta de ahorro empresarial',
  SavingsAccountRefund: 'Reembolso de venta de cuenta de ahorro',
  PersonalSavingsAccountRefund: 'Reembolso de venta de cuenta de ahorro personal',
  BusinessSavingsAccountRefund: 'Reembolso de venta de cuenta de ahorro empresarial',
  SavingsRefund: 'Reembolso de venta de cuenta de ahorro personal',
  PersonalSavingsRefund: 'Reembolso de venta de cuenta de ahorro personal',
  BusinessSavingsRefund: 'Reembolso de venta de cuenta de ahorro empresarial',
  StartTime: 'Tiempo de Inicio',
  CustomTimeText: 'La hora de finalización se establece automáticamente en 24 horas más tarde. Si necesita un período de tiempo más específico, seleccione la opción <1>Rango Personalizado</1> en el menú anterior.',
  CreditSale: 'Venta a Crédito',
  CreditRefund: 'Reembolso de Crédito',
  KeyedPreAuth: 'Preautorización Manual',
  SwipedPreAuth: 'Preautorización Deslizada',
  SwipedCreditRefund: 'Reembolso de Crédito Deslizado',
  SwipedCreditSale: 'Venta a Crédito Deslizada',
  SwipedCreditSaleSplitPayment: 'Venta a Crédito Deslizada - Pago Dividido',
  IncompleteSwipedCreditSale: 'Venta a Crédito Deslizada Incompleta',
  SwipedDebitRefund: 'Reembolso de Débito Deslizado',
  SwipedDebitSale: 'Venta a Débito Deslizada',
  SwipedDebitSaleSplitPayment: 'Venta a Débito Deslizada - Pago Dividido',
  IncompleteSwipedDebitSale: 'Incomplete Venta a Débito Deslizada',
  SwipedVoid: 'Anulación Deslizada',
  ContactlessEMVCreditSale: 'Venta a Crédito EMV sin contacto',
  ContactlessEMVCreditSaleSplitPayment: 'Venta a Crédito EMV sin contacto - Pago Dividido',
  ContactlessEMVDebitSale: 'Venta a Débito EMV sin contacto',
  ContactlessEMVDebitSaleSplitPayment: 'Venta a Débito EMV sin contacto - Pago Dividido',
  ContactlessEMVVoid: 'Anulación EMV sin contacto',
  ContactlessEMVPreAuth: 'Preautorización EMV sin contacto',
  KeyedDebitSale: 'Venta a Débito Manual',
  KeyedDebitSaleSplitPayment: 'Venta a Débito Manual - Pago Dividido',
  KeyedDebitRefund: 'Reembolso de Débito Manual',
  IncompleteKeyedDebitSale: 'Venta a Débito Manual Incompleta',
  IncompleteKeyedDebitRefund: 'Reembolso de Débito Manual Incompleto',
  IncompleteContactlessEMVDebitSale: 'Venta a Débito EMV sin contacto Incompleta',
  ChipReadCreditSaleSplitPayment: 'Venta a Crédito de Lectura de Chip - Pago Dividido',
  ChipReadCreditSale: 'Venta a Crédito de Lectura de Chip',
  IncompleteChipReadCreditSale: 'Venta a Crédito de Lectura de Chip Incompleta',
  ChipReadDebitSaleSplitPayment: 'Venta a Débito de Lectura de Chip - Pago Dividido',
  ChipReadDebitSale: 'Venta a Débito de Lectura de Chip',
  IncompleteChipReadDebitSale: 'Venta a Débito de Lectura de Chip Incompleta',
  ChipReadVoid: 'Anulación de Lectura de Chip',
  ChipReadRefund: 'Reembolso de Lectura de Chip',
  ChipReadPreAuth: 'Preautorización de Lectura de Chip',
  CashSaleSplitPayment: 'Venta en Efectivo - Pago Dividido',
  AppleTapToPayCreditSale: 'Venta a Crédito Tap to Pay on iPhone',
  AppleTapToPayCreditRefund: 'Reembolso de Crédito Tap to Pay on iPhone',
  AppleTapToPayDebitSale: 'Venta a Débito Tap to Pay on iPhone',
  AppleTapToPayDebitRefund: 'Reembolso de Débito Tap to Pay on iPhone',
  AppleTapToPayVoid: 'Anulación Tap to Pay on iPhone',
  PaymentLinkRefund: 'Reembolso Enlance de Pago',
  PaymentLinkVoid: 'Anulación Enlance de Pago',
  KeyedEBT: 'Venta de EBT Manual',
  SwipedEBT: 'Venta EBT Deslizada',
  TransactionDateRange: 'Rango de Fechas de Transacción',
  TotalAmount: 'Monto Total',
  From: 'Desde',
  To: 'Hasta',
  PaymentConfirmation: 'N.º Confirmación de Pago',
  Last4: 'Últimos 4 Dígitos',
  LessFields: 'Menos Campos',
  MoreFields: 'Más Campos',
  ClearFields: 'Limpiar Campos',
  TipAmount: 'Monto de Propina',
  AuthAmount: 'Monto Autorizado',
  SoldByEmployee: 'Vendido por Empleado',
  AuthNumber: 'N.º de Autenticación',
  AmountHint: '$0.00',
  //Transactions

  // Transactions Details
  Employee: 'Empleado',
  Customer: 'Cliente',
  IncompleteTransaction: 'Incompleta',
  Transaction: 'Transacción',
  IncompleteFilterMessage: 'Las transacciones incompletas pueden incluir pagos realizados con fondos insuficientes o pagos divididos que no se completaron.',
  OpenTransactionsDetails_one: '{{count}} Transacción abierta. Agregue sugerencias antes de que las transacciones se cierren y liquiden a las {{settleTime}} ET. Después de este tiempo, no puede ajustar el monto de la propina.',
  OpenTransactionsDetails_other: '{{count}} Transacciones abiertas. Agregue propinas antes del cierre de la transacción y liquide a las {{settleTime}} ET. Después de este tiempo, no puede ajustar el monto de la propina.',
  OpenTransactionsDetailsManualCapture_one: '{{count}} Transacción abierta. Agregue propinas antes de cerrar y liquidar la transacción.',
  OpenTransactionsDetailsManualCapture_other: '{{count}} Transacciones abiertas. Agregue propinas antes de que las transacciones se cierren y liquiden.',

  // MobileScrollNote
  ScrollNoteText: 'Desplácese a la derecha para ver más columnas',

  // Date Filter
  Today: 'Hoy',
  Yesterday: 'Ayer',
  ThisWeek: 'Esta Semana',
  ThisMonth: 'Este Mes',
  ThisYear: 'Este Año',
  LastWeek: 'Semana Anterior',
  LastMonth: 'Mes Anterior',
  LastYear: 'Año Anterior',
  CustomDateRangeTitle: 'Rango de Fecha Personalizado',
  CustomDateError: '{{dataName}} sólo se puede mostrar por un período de 18 meses o menos. Elija un intervalo de fechas que no supere los 18 meses.',
  StartDateTime: 'Inicio Fecha / Tiempo',
  EndDateTime: 'Finaliza Fecha / Tiempo',
  PreviousCustomTitle: 'Rango de Fecha Personalizado Anterior',
  PreviousStartDateTime: 'Inicio Fecha / Tiempo Anterior',
  PreviousEndDateTime: 'Finaliza Fecha / Tiempo Anterior',
  CustomTimeRangeTitle: 'Rango de Tiempo por Defecto Personalizado',
  CustomRange: 'Rango Personalizado',
  FilterByDate: 'FILTRAR POR FECHA',

  // Employee Filter
  AllEmployees: 'Todos los Empleados',
  ByEmployee: 'Filtrar por Empleado',

  // Levels
  LevelsToggleLabel: 'Datos de nivel 2 y 3',
  Show: 'Mostrar',
  TaxExempt: 'Exento de impuestos:',
  CustomerCode: 'Código de Cliente:',
  TaxAmount: 'Monto de Impuesto:',
  TaxId: 'Identificador de Impuesto:',
  ItemDescription: 'Descripción del Artículo:',
  ItemQuantity: 'Cantidad de Artículos:',
  MeasureUnit: 'Unidad de Medida:',
  UnitPrice: 'Precio Unitario:',
  ProductCode: 'Código de Producto:',
  OrderDate: 'Fecha de Orden:',
  CommodityCode: 'Código de Mercancía:',
  RequesterName: 'Npmbre del Solicitante:',
  CompanyName: 'Nombre de la Compañía:',
  ShipmentId: 'ID de Envío:',
  ShipmentCountry: 'País de Envío:',
  ShipmentState: 'Estado de Envío:',
  ShipmentZip: 'Zip de Envío:',
  DestinationCountry: 'País de Destino:',
  DestinationState: 'Estado de Destino:',
  DestinationZip: 'Zip de Destino:',
  DiscountAmount: 'Monto de Descuento:',
  DutyAmount: 'Monto de Impuesto:',
  FreightAmount: 'Monto de Carga:',
  DiscountName: 'Nombre del Descuento',
  AddDiscount: 'Agregar Descuento',
  Percentage: 'Porcentaje',
  DiscountColor: 'Color del Descuento',
  NewDiscount: 'Nuevo Descuento',
  DiscountValue: 'Valor del Descuento',

  // Payment Dialog Modal
  CompleteTransaction: 'Completar Transacción',
  CancelTransaction: 'Cancelar Transacción',
  TransactionVoided: 'Transacción Anulada',
  TransactionCompleted: 'Transacción Completada',
  TransactionCancelled: 'Transacción Cancelada',
  TransactionRefunded: 'Transacción Reembolsada',
  RefundAsVoidHeading: 'Completar Transacción',
  RefundAsVoidInfo: 'Esta transacción ha sido procesada como anulada.',
  TransactionVoidDeclined: 'Anular Transacción Rechazada',
  TransactionCompleteDeclined: 'Completar Transacción Rechazada',
  TransactionRefundDeclined: 'Reembolsar Transacción Rechazada',
  RefundNoteHint: 'por ejemplo: cargo accidental',
  PartialRefundsLabel: 'Reembolsos Parciales Existentes:',
  PartialRefundAmount: 'Monto reembolso:',
  Off: 'de descuento',
  Discount: 'Descuento',
  RefundAll: 'Reembolsar Todo',
  RefundAmount: 'Monto Reembolso',
  TipAmountLimit: 'Propina (Límite {{currentTipLimit}})',
  TotalRefund: 'Reembolso Total',
  RefundLimit: '(Límite {{currentTotalLimit}})',
  CustomRefundTitle: 'Monto de Reembolso Personalizado <1>(Límite {{currentTotalLimit}})</1>',
  OptionalRestock: 'Opciones para Reestablecer (Opcional)',
  Itemized: 'Detallado',
  ItemizedCustom: 'Detallado o Personalizado',
  NoCustomers: 'No hay Clientes',
  SearchBarPlaceholder: 'Buscar Clientes',

  ManageIntegrations: 'Administrar Integraciones',
  RevokeAccess: 'Revocar Accesos',

  // Gift Card Dialog
  GiftCardRefundDialogInfo: 'Por favor vuelva a cargar la tarjeta de regalo con el monto de reembolso correspondiente o proporcione un reembolso en efectivo.',
  GiftCardRefundDialogTitle: 'Reembolso de Tarjeta de Regalo',

  // Line Item List
  Taxed: 'I.I.',
  ReStock: 'Reestablecer',

  // Export Selector
  ExportBtn: 'Exportar',
  CSVFile: 'Archivo CSV',
  ExportTransactions: 'Exportar Transacciones',
  ExportDisputes: 'Exportar Disputas',

  // Send Receipt Modal
  AddEmailAddressBtn: 'Agregar correo electrónico',
  AddPhoneNumberBtn: 'Agregar número telefónico',

  // Too Many Transactions Modal
  TooManyTransactionDialogTitle: 'No se puede exportar.',
  TooManyTransactionDialogTransactionInfo: '<1>La cantidad de datos devueltos en su selección supera el límite de {{maxTransactions}} por fila. Por favor seleccione un rango de fechas más pequeño.</1>',

  // Pending Changes Modal
  PendingChangesTitle: 'Cambios sin guardar',
  PendingChangesInfo_one: '<1>Usted tiene {{openTransactionsLength}} cambio sin guardar.</1>',
  PendingChangesInfo_other: '<1>Usted tiene {{openTransactionsLength}} cambios sin guardar.</1>',

  CloseTransactions: 'Cerrar Transacciones',

  // Success Modal
  SuccessModalInfo: 'Todas las transacciones han sido enviadas para liquidación.',

  // Error Modal
  ErrorModalInfo: 'No pudimos cerrar sus transacciones. Inténtalo de nuevo.',

  // Error Save Tip Modal
  ErrorSaveTipModalTitle: 'Guardar Propinas',
  ErrorSaveTipModalInfo: 'Hubo un error al guardar las propinas. Inténtalo de nuevo.',

  // Close All Transactions Tip Modal
  CloseAllTransactionsTitle: '¿Cerrar todas las transacciones?',
  CloseAllTransactionsInfo: 'Cerrar sus transacciones significa que está enviando sus transacciones para su liquidación. Asegúrese de haber ingresado las propinas, si corresponde.',

  //Deposits
  All: 'Todos',
  Withdrawals: 'Retiros',
  'Resolved transactions': 'Transacciones resueltas',
  'Held funds': 'Fondos retenidos',

  //Batches
  BatchDetails: 'Detalles de Lote',
  NoBatches: 'No Lotes',
  NoBatchDetails: 'No Detalles de Lote',
  ViewOpenBatch: 'Ver Lote Abierto',

  //Reports
  'All Reports': 'Todos los Reportes',
  'Sales Reports': 'Reportes Venta',
  'Customer Reports': 'Reportes Clientes',
  'Employee Reports': 'Reportes Empleados',
  'Inventory Reports': 'Reportes Inventario',
  'Custom Reports': 'Reportes Personalizados',
  'What does Total Collected mean?': '¿Qué significa Total Recaudado?',

  SearchReports: 'Buscar Reportes',
  AccountSummary: 'Resumen de la cuenta',
  'A breakdown of gross sales and total collected by account.': 'Un desglose de las ventas brutas y el total recaudado por cuenta.',
  BatchDetail: 'Detalle de lote',
  'The sum of all your authorized credit card transactions for the day at close of business, or by the designated batch closing time.': 'La suma de todas sus transacciones autorizadas con tarjeta de crédito para el día al cierre de operaciones, o para la hora de cierre del lote designada.',
  BatchSummary: 'Resumen de lote',
  'An overview of your batches with totals.': 'Una visión general de sus lotes con totales.',
  FraudMonitoring: 'Monitoreo de Fraude',
  'The TC40 report from Visa and SAFE report from MasterCard with information about customer fraud claims.': 'El informe TC40 de Visa y el informe SAFE de MasterCard con información sobre reclamaciones de fraude de clientes.',
  CardBrandSummary: 'Resumen por marca de tarjeta',
  'View sales totals for credit transactions by card brand.': 'Vista de totales de ventas para transacciones de crédito por marca de tarjeta.',
  CustomReports: 'Reportes Personalizados',
  CustomerExport: 'Reporte de Clientes',
  'Export a listing of your customers and their contact information.': 'Exporte una lista de sus clientes y su información de contacto.',
  CustomerExperience: 'Experiencia del cliente',
  'A summary of customer feedback by name, date, invoice number, rating, and employee.': 'Un resumen de los comentarios de los clientes por nombre, fecha, número de factura, calificación y empleado.',
  CustomerPerformance: 'Rendimiento del cliente',
  'Track top customers and see their spending habits.': 'Realice un seguimiento de los principales clientes y vea sus hábitos de gasto.',
  DepositDetail: 'Detalle de depósito',
  'The transactions and related fees within each deposit and withdrawal posted to your bank account.': 'Las transacciones y las tarifas relacionadas dentro de cada depósito y retiro registrados en su cuenta bancaria.',
  DepositSummary: 'Resumen de depósito',
  EstimatedDepositDate: 'Fecha de Depósito Estimada',
  DepositInitiated: 'Depósito Iniciado',
  'An overview of your deposits with net totals': 'Una visión general de sus depósitos con totales netos',
  DisputesReport: 'Informe de disputas',
  'View the status of your disputes.': 'Ver el estado de tus disputas.',
  EmployeeFlashReport: 'Informe relámpago del empleado',
  'Totals for net sales, discounts, tax, tips, voids, refunds, and payment methods by single employee.': 'Totales de ventas netas, descuentos, impuestos, propinas, anulaciones, reembolsos y métodos de pago por empleado individual.',
  SearchEmployees: 'Buscar Empleados',
  SelectEmployee: 'Seleccionar Empleado',
  EmployeePerformance: 'Rendimiento de empleado',
  'Track employees by number of transactions and total collected.': 'Realice un seguimiento de los empleados por número de transacciones y el total recaudado.',
  ExpandedTransactionReport: 'Informe de transacciones ampliado',
  CreateReportTitle: 'Crear Reporte Personalizado',
  ReportName: 'Nombre del Reporte',
  SelectReportFields: 'Cree un reporte personalizado dándole un nombre y seleccionando los datos que desea en su reporte.',
  'Export an expanded listing of all transactions in a period. Includes deposit dates, deposit amounts, and applicable fees.': 'Exporte una lista ampliada de todas las transacciones en un período. Incluye fechas de depósito, montos de depósito y tarifas aplicables.',
  FlashReport: 'Informe relámpago',
  GiftCardSummary: 'Resumen de Tarjetas de Regalo',
  'Totals for net sales, discounts, tax, tips, voids, refunds, and payment methods. Ideal for closing your day.': 'Totales de ventas netas, descuentos, impuestos, propinas, anulaciones, reembolsos y métodos de pago. Ideal para cerrar tu día.',
  InventoryExport: 'Exportación de inventario',
  'Export your inventory with stock counts.': 'Exporte su inventario con recuentos de existencias.',
  ItemPerformance: 'Rendimiento del artículo',
  'Track items by number sold, refunded, voided, and total collected.': 'Realice un seguimiento de los artículos por número vendido, reembolsado, anulado y total cobrado.',
  LoyaltyRewardsReport: 'Reporte de Recompensas de Lealtad',
  LoyaltyRewardsReportDisabled: 'Reporte de Recompensas de Lealtad (Inactivo)',
  'View a listing of your customers who have earned rewards.': 'Vea una lista de sus clientes que han obtenido recompensas.',
  ModifierPerformance: 'Rendimiento del modificador',
  'Track modifiers by modifier set and individual modifier used.': 'Realice un seguimiento de los modificadores por conjunto de modificadores y modificador individual utilizado.',
  PaymentTypeSummary: 'Resumen del tipo de pago',
  'View sales totals by type of transaction.': 'Ver totales de ventas por tipo de transacción.',
  ProductSales: 'Venta de productos',
  'Track category performance and items within category by items sold and total sales.': 'Realice un seguimiento del rendimiento de la categoría y los artículos dentro de la categoría por artículos vendidos y ventas totales.',
  'View tax collected.': 'Ver impuestos recaudados.',
  'Time Clock and Schedules': 'Reloj de tiempo y horarios',
  'View clock in/out, break times, and employee schedules via Homebase.': 'Vea el reloj de entrada/salida, los tiempos de descanso y los horarios de los empleados a través de Homebase.',
  'Export a listing of all transactions in a period.': 'Exporte una lista de todas las transacciones en un período.',
  TransactionsByCategory: 'Transacciones por Categoría',
  'View transactions by category.': 'Ver transacciones por categoría.',
  TransactionsByItem: 'Transacciones por artículo',
  'View transactions by item.': 'Ver transacciones por artículo.',
  DeclinedTransactions: 'Transacciones rechazadas',
  'Export a listing of all declined transactions in a period.': 'Exporte una lista de todas las transacciones rechazadas en un período.',
  RefundSummary: 'Resumen de reembolso',
  'View refunded transactions in a period.': 'Ver transacciones reembolsadas en un período.',
  Export: 'Exportar',
  'This is the amount that you sold. Includes your transactions.': 'Esta es la cantidad que vendiste. Incluye tus transacciones.',
  'This is the amount that you earn as a business. Includes your transactions with discounts, refunds, and voids.': 'Esta es la cantidad que ganas como negocio. Incluye sus transacciones con descuentos, reembolsos y anulaciones.',
  'This is the amount that you collect. Includes your transactions with discounts, refunds, voids, taxes, and tips.': 'Esta es la cantidad que recaudas. Incluye tus transacciones con descuentos, devoluciones, anulaciones, impuestos y propinas.',
  NoGiftCardSummary: 'Sin Datos en el Informe Resumido de Tarjeta de Regalo',
  'An overview of gift cards activated/sold, redeemed, and refunds to gift cards. Details about payments accepted with gift cards are located in your Flash or Transactions reports.': 'Una descripción general de las tarjetas de regalo activadas/vendidas, canjeadas y devoluciones de tarjetas de regalo. Los detalles sobre los pagos aceptados con tarjetas de regalo se encuentran en sus informes Flash o Transacciones.',
  ReportComingSoon: '¡Informe próximamente!',
  NoCustomReports: 'No hay Reportes Personalizados creados',
  AllAccounts: 'Todas las Cuentas',
  UnauthorizedErrorModal: {
    Title: 'Error',
    Message: 'Usuario no tiene acceso para generar este reporte'
  },
  DeleteReportDialog: {
    Title: 'Eliminar Reporte',
    Message: '¿Está seguro que desea eliminar su reporte personalizado?',
    Confirm: 'Si, eliminar'
  },

  // Settings menu
  'Payment Settings': 'Configuración de pago',
  'Receipt & invoice settings': 'Configuración de recibos y facturas',
  'Business Profile': 'Perfil de negocio',
  'PCI Compliance': 'Cumplimiento de PCI',
  'Online store': 'Tienda en línea',
  '1099K & tax': '1099K e impuestos',
  'Funding': 'Financiamiento',
  'Communication Settings': 'Configuración de comunicación',
  'Integrations': 'Integraciones',
  'Devices': 'Dispositivos',
  'API Access': 'Acceso al API',

  // Hardware & Supplies menu
  'Shop Supplies': 'Tienda de Suministros',
  'Shop Hardware': 'Tienda de Hardware',
  'Order History': 'Historial de Órdenes',
  'Allotments': 'Asignaciones',
  'Electronic Return Labels': 'Etiquetas de devolución electrónicas',

  // Employees menu
  'All Employees': 'Todos los Empleados',
  'Administrator': 'Administrador',
  'Manager': 'Gerente',
  'Cashier': 'Cajero',
  'Reporter': 'Reportero',
  'Archive': 'Archivo',
  'Add Employee': 'Agregar Empleado',

  // Reputation menu
  'Reputation': 'Reputación',
  'Trends': 'Tendencias',
  'Customer Reviews': 'Reseñas de Clientes',
  'Competitor Performance': 'Rendimiento de la Competencia',
  'Settings': 'Ajustes',

  // Inventory menu
  ModifierSets: 'Conjuntos de Modificadores',
  'Modifier sets': 'Conjuntos de modificadores',
  'Archived items': 'Elementos archivados',

  // Customers menu
  'Add Customer': 'Agregar Cliente',
  'All Customers': 'Todos los clientes',
  'With Active Reward': 'Con Recompensa Activa',
  'Loyalty Program': 'Programa de Lealtad',
  'Activity & Reports': 'Actividad y Reportes',
  'Loyalty Program Settings': 'Configuración del Programa de Lealtad',

  // Invoices menu
  'All Invoices': 'Todas las facturas',
  Paid: 'Pagadas',
  Overdue: 'Atrasadas',
  OverdueToday: 'Atrasada Hoy',
  OverdueDays: 'Atrasada {{days}}',
  Failed: 'Fallidos',
  'Failed payments': 'Pagos fallidos',
  Scheduled: 'Programadas',
  Series: 'Serie',
  Drafts: 'Borradores',
  Draft: 'Borrador',
  Canceled: 'Canceladas',
  'Bulk Invoicing': 'Facturas masivas',
  Active: 'Activa',

  // Virtual terminal menu
  'Virtual terminal': 'Terminal virtual',
  'Custom amount': 'Monto personalizado',
  'Create an invoice': 'Crear una factura',
  'Itemized sale': 'Venta al Detalle',
  'Issue a credit': 'Emitir un Crédito',

  // Disputes menu
  'All Disputes': 'Todas las Disputas',
  'Action Required': 'Acción Requerida',
  'Under Review': 'En Revisión',
  Closed: 'Cerrada',
  'See how it works': 'Mira cómo funciona',
  'Refund & Chargeback Policies': 'Políticas de reembolso y contracargo',
  'Reason Code Glossary': 'Glosario de Códigos de Razones',

  // Disputes FAQ
  DisputesFAQ: 'Preguntas más frecuentes de Disputas',
  Definitions: 'Definiciones',
  Dispute: 'Disputa',
  Chargeback: 'Cobro',
  ChargebackReversa: 'Reversa de cobro',
  Representment: 'Representación',
  RepresentmentRevAdj: 'Rev/Ajuste de Representación',
  DisputeReversal: 'Disputa de Reversión',
  DisputeResponseReversal: 'Reversión de Respuesta de Disputa',
  Acquirer: 'Adquiridor',
  AcquirerDesc: 'Su proveedor de servicios de comerciante',
  Issuer: 'Emisor',
  IssuerDesc: 'El banco titular que emitió la tarjeta',
  Rebuttal: 'Refutación',
  Required: 'Requerido',
  ReasonCode: 'Código de Razón',
  Example: 'Ejemplo',
  RebuttalDesc: 'Respuesta del comerciante hacia el emisor, disputando el cobro / disputa del titular',
  CompellingEvidence: 'Evidencia Convincente',
  CompellingEvidenceDesc: 'Prueba que el titular participó en la transacción, recibió los bienes o servicios, o se benefició de la transacción',
  PreArbitration: 'Pre-Arbitraje',
  PreArbitrationDesc: 'La fase del proceso de disputa de Visa que precede inmediatamente el Arbitraje.',
  VCRTitle: '¿Qué es la Resolución de Reclamo de Visa (VCR)?',
  VCRAnswer: 'VCR es el nuevo programa de clamos de Visa, antes conocido como cobros. La nueva disputa será efectiva a partir del 13 de Abril del 2018.',
  VisaTitle: '¿Por qué Visa está implementando este nuevo proceso?',
  VisaAnswer: 'El proceso actual de cobros no es lo suficientemente flexible para manejar el volumen y complejidad de la industria de pagos moderna. El nuevo procesos de disputas es un proceso simplificado que acelera el tiempo de ciclo de disputa de extremo a extremo.',
  VCRBenefitsTitle: '¿Cuáles son los beneficios de VCR?',
  VCRBenefitsAnswer: 'Visa evaluará y determinará la validez de las disputas creadas por el emisor. Visa bloqueará las disputas invalidad y el comerciante/adquiriror no requieren tomar ninguna acción. Este proceso lleva a menos disputas',
  ChangingTitle: '¿Qué esta cambiando?',
  ChangingAnswer: 'Como parte de la iniciativa VCR, algunas de las terminologías relacionadas a las disputas esta cambiando. Tome nota, solo aplican a Visa por el momento. ',
  CTerminologyTableTitle: 'Terminología Actual',
  NTerminologyTableTitle: 'Nueva Terminología',
  ChangingListItem1: 'Nuevos códigos de razón serán usados en lugar de los actuales códigos de razón',
  ChangingListItem2: 'Las disputas tendrán una de estas dos categorías creadas por Visa, flujos de trabajo de <strong>Asignación</strong> y <strong>Colaboración</strong> (más sobre esto abajo)',
  ChangingListItem3: 'Para el flujo de trabajo de <strong>Asignación</strong>, no crêdito provisional sera emitidos si el caso dsputado esta siendo impugnado.',
  ChangingListItem4: 'Visa ha dejado claro que es aceptable como evidencia convincente para cada código de razón bajo Asignaión. Los comerciantes serán permitidos a impugnar la disputa si ellos son capaces de presentar evidencia convincente.',
  ChangingListItem5: 'Todos los comerciantes, adquiridores y emisores serán impactados por este nuevo programa de disputas.',
  ChangingListItem6: 'Sólo las nuevas disputas netas que ocurran después del 13 de Abril del 2018 seguirán el nuevo proceso de disputas (VCR).',
  WorkflowTitle: '¿Qué es el flujo de trabajo de Asignación y Colaboración?',
  WorkflowAnswer1: 'Las disputas categorizadas dentro del flujo de trabajo de <strong>Asignación</strong> son relacionadas a problemas de fraude y autorización. Visa va a ejecutar una serie de verificaciones en su sistema para ayudar a elimiinar disputas inválidas (es decir, en los casos en que los comerciantes ya han acreditado los titulares). Luego de las verificaciones, Visa "asignará" luego la responsabilidad financiera de estas disputas a los adquiridores y los comerciantes. Se anticipa que la mayoría de de estas disputas tendrán el flujo de trabajo de Asignación.',
  WorkflowAnswer2: 'Las disputas para los casos etiquetados como "Disputas de Clientes" (es decir, disputas de bienes y servicios) o como "Errores de Procesamiento" seguirán el flujo de trabajo de <strong>Colaboración</strong>, el cual es similar al existente proceso de cobro de Visa.',
  DisputeProcessTitle: '¿Cómo el nuevo proceso de disputas impacta a los comerciantes?',
  DisputeProcessListItem1: 'Para disputas de Asignación, los comerciantes deben responder dentro de 14 dias. El adquiridor aceptará la disputa de parte del comerciante si una respuesta no es recibida. Si la disputa es impugnada entonces el pre-arbitraje debe ser iniciado por el comerciante en lugar de represetación, se deberá enviar evidencia convincente. Crédito será emitido sólo si el fallo final es a favor del comerciante. Si el emisor declina el pre-arbitraje, entonces el adquiridor puede solicitar arbitraje. El comerciante será responsable por al menos $550 en caso de que el fallo final sea a favor del comerciante.',
  DisputeProcessListItem2: 'Para disputas de Colaboración, os comerciantes deben responder dentro de 14 dias. El adquiridor aceptará la disputa de parte del comerciante si una respuesta no es recibida. El proceso de disputa permanecerá el mismo al actual proceso de cobro, y el comerciante continuará recibiendo crédito provisional al impugnar la disputa. ',
  ArbitrationTitle: 'Qué es el Pre-Arbitraje y el Arbitraje',
  ArbitrationAnswer: '"Arbitraje" es un proceso vinculante de resolución de disputas donde la asociación de la marca de la tarjeta (en este caso Visa) evaluará toda la evidencia y emitirá un fallo final. Este es el último recurso para un comerciante y un adquiridor para luchar por la responsabilidad de la disputa del tarjetahabiente, y ambos lados tasan las tarifas en el proceso. La parte perdedora en un caso de arbitraje puede ser responsable tarifas de tanto como $550, asi que solo los casos con la evidencia más fuerte deben proceder para arbitraje. "Pre-Arbitraje" es la fase anterior al arbitraje, y es la única fase para que un comerciante rechase un caso de <strong>Asignación</strong>. Si el comerciante tiene <strong>evidencia contundente</strong> para pelear un caso de asignación, deben enviarlo dentro de los 14 dias de la notificación de la disputa y acceder a pagar el costo total del arbitraje si el emisor rechaza la refutación.',
  ReasonCodeTitle: '¿Qué son los códigos de razón?',
  ReasonCodeAnswer: 'Para simplificar el complejo sistema actual, la iniciativa VCR ha consolidado códigos de razón dentro de cuatro categorías fundamentales, como se muestra en este cuadro:',
  ReasonCode10: '10. Fraude',
  'ReasonCode10.1': '10.1 – Fraude falsificado de cambio de responsabilidad EMV',
  'ReasonCode10.2': '10.2 – Fraude no falsificado de cambio de responsabilidad EMV',
  'ReasonCode10.3': '10.3 – Otro entorno actual de la tarjeta de fraude',
  'ReasonCode10.4': '10.4 – Otro entorno ausente de la tarjeta de fraude',
  'ReasonCode10.5': '10.5 – Programa de monitoreo de fraude de Visa',
  ReasonCode11: '11. Autorización',
  'ReasonCode11.1': '11.1 – Boletín de recuperación de tarjetas',
  'ReasonCode11.2': '11.2 – Autorización declinada',
  'ReasonCode11.3': '11.3 – Sin autorización',
  ReasonCode12: '12. Errores de procesamiento.',
  'ReasonCode12.1': '12.1 – Presentación tardía',
  'ReasonCode12.1a': '12.1: Presentación tardía',
  'ReasonCode12.2': '12.2 – Código de transacción incorrecto',
  'ReasonCode12.2a': '12.2: Código de transacción incorrecto',
  'ReasonCode12.3': '12.3 – Moneda incorrecta',
  'ReasonCode12.3a': '12.3: Moneda incorrecta',
  'ReasonCode12.4': '12.4 – Número de cuenta incorrecto',
  'ReasonCode12.4a': '12.4: Número de cuenta incorrecto',
  'ReasonCode12.5': '12.5 – Cantidad incorrecta',
  'ReasonCode12.5a': '12.5: Cantidad incorrecta',
  'ReasonCode12.6': '12.6: Procesamiento duplicado / pagado por otros medios',
  'ReasonCode12.6.1': '12.6.1 – Procesamiento duplicado',
  'ReasonCode12.6.2': '12.6.2 – Pagado por otros medios',
  'ReasonCode12.7': '12.7 – Datos no válidos',
  ReasonCode13: '13. Disputas de consumo',
  'ReasonCode13.1': '13.1 – Mercancía / Servicios no recibidos',
  'ReasonCode13.1a': '13.1: No como se describe o mercancía/servicios defectuosos',
  'ReasonCode13.2': '13.2 – Cancelado recurrente',
  'ReasonCode13.3': '13.3 – No es como descrito o mercancía / servicios defectuosos',
  'ReasonCode13.4': '13.4 – Mercancía falsificada',
  'ReasonCode13.4a': '13.4: Mercancía falsificada',
  'ReasonCode13.5': '13.5 – Tergiversación',
  'ReasonCode13.5a': '13.5: Tergiversación',
  'ReasonCode13.6': '13.6 – Crédito no procesado',
  'ReasonCode13.6a': '13.6: Crédito no procesado',
  'ReasonCode13.7': '13.7 – Mercancía / Servicios cancelados',
  'ReasonCode13.7a': '13.7: Mercancía / Servicios cancelados',
  'ReasonCode13.8': '13.8 – Transacción de crédito original no aceptada',
  'ReasonCode13.9': '13.9 – No recibo de efectivo o valor de transacción de carga',
  ReasonCodeNote: '**El código de razón de cobro de Visa 75 ("Transación Irreconocida") ya no esta disponible.**',
  CompellingEvidenceTitle: '¿Cuál evidencia contundente un comerciante debería enviar para iniciar el pre-arbitraje?',
  CompellingEvidenceAnswer1: 'Con los nuevos flujos de trabajos establecidos, los comerciantes requerirán proveer "evidencia contuntente", como se indica en las Reglas de Visa. Si un comerciante no envia las piezas de evidencia contundente requeridas, es inprobable que Visa regulará a favor del comerciante.',
  CompellingEvidenceAnswer2: 'Los comerciantes tienen la opción de proveer mas de una pieza de evidencia contundente durante la lucha por una disputa. Es recomendado que los comerciantes reunan tanta evidencia contundente como sea posible en estos casos.',
  CompellingEvidenceList1Text: 'Los dos códigos de razón requieren evidencia contundente:',
  CompellingEvidenceList1Item1: 'Fraude / Tarjeta Ausente Ambiente (1040)',
  CompellingEvidenceList1Item2: 'Mercancía / Servicios no recibidos (1310)',
  CompellingEvidenceList2Text: 'Se debe enviar al menos uno de los siguientes para este tipo de disputas:',
  CompellingEvidenceList2Item1: 'Documentación para probar que el titular de la tarjeta está en posesión y / o usando la mercancía',
  CompellingEvidenceList2Item2: 'Prueba de un formulario de entrega firmado, o copia de la identificación del cliente como Bienes de prueba se recogieron en su ubicación comercial',
  CompellingEvidenceList2Item3: 'Prueba de entrega a la dirección asociada a una respuesta correspondiente AVS',
  CompellingEvidenceList2Item4: 'Prueba de entrega y prueba de clientes de empleo en la ubicación de entrega',
  CompellingEvidenceList2Item5: 'Prueba de que el boleto fue recibido o utilizado',
  CompellingEvidenceList2Item6: 'Prueba de una transacción indiscutible para el mismo comerciante o servicio',
  CompellingEvidenceList2Item7: 'Prueba de que la transacción fue completada por un firmante autorizado asociado con el titular de la tarjeta',
  CompellingEvidenceList2Item8: 'Prueba de un formulario de pedido firmado para una transacción de correo o teléfono',
  CompellingEvidenceList2Item9: 'Prueba de productos digitales descargados desde el sitio web o aplicación de un comerciante',
  CompellingEvidenceList2Item10: 'Prueba para la transacción anterior para la misma mercancía o servicio',
  CompellingEvidenceList2Item11: 'Prueba de antecedentes de transacción recurrente',
  CompellingEvidenceTable1Text: 'Si escoge proveer para cualquiera de los tres tipos de evidencia contundente señalada arriba en negrita, cada uno requerira evidencia explicita enviada. Aquí se detalla los requerimientos de la evidencia:',
  CompellingEvidenceTableHeader1: 'Descripción de la evidencia contundente',
  CompellingEvidenceTableHeader2: 'Requisito de Evidencia',
  CompellingEvidenceTableDescription1: 'Prueba de productos digitales descargados desde el sitio web o aplicación de un comerciante',
  CompellingEvidenceTableDescription2: 'Comprobante de la transacción anterior por la misma mercancía o servicio',
  CompellingEvidenceTableDescription3: 'Prueba de historial de transacciones recurrentes',
  CompellingEvidenceTableEvidenceList1Text: 'Los dos elementos con viñetas a continuación son obligatorios:',
  CompellingEvidenceTableEvidenceList2Text: '<strong>Además,</strong> se requieren dos o más de los siguientes elementos con viñetas:',
  CompellingEvidenceTableEvidenceList3Text: 'Otros ejemplos de evidencia para otros códigos de motivo de disputa comunes: *',
  CompellingEvidenceTableEvidenceListItem1: 'Descripción del producto descargado con éxito',
  CompellingEvidenceTableEvidenceListItem2: 'Fecha y hora en que se descargaron los servicios',
  CompellingEvidenceTableEvidenceListItem3: 'Dirección IP del cliente y ubicación del dispositivo',
  CompellingEvidenceTableEvidenceListItem4: 'ID del dispositivo y nombre del dispositivo',
  CompellingEvidenceTableEvidenceListItem5: 'Nombre y correo electrónico del cliente',
  CompellingEvidenceTableEvidenceListItem6: 'Perfil de cliente',
  CompellingEvidenceTableEvidenceListItem7: 'Historial de uso del perfil del cliente',
  CompellingEvidenceTableEvidenceListItem8: 'ID de transacción anterior indiscutible',
  CompellingEvidenceTableEvidenceListItem9: 'Fecha de Transacción',
  CompellingEvidenceTableEvidenceListItem10: 'Prueba de envío',
  CompellingEvidenceTableEvidenceListItem11: 'Dirección IP del cliente',
  CompellingEvidenceTableEvidenceListItem12: 'Dirección de correo electrónico del cliente',
  CompellingEvidenceTableEvidenceListItem13: 'Dirección del cliente',
  CompellingEvidenceTableEvidenceListItem14: 'Número de teléfono del cliente',
  ReasonCodeTableExampleListItem1: 'Fotografías, correos electrónicos o pruebas adicionales que demuestren que la mercancía o el servicio coinciden con la descripción',
  ReasonCodeTableExampleListItem2: 'Prueba de que el cliente no intentó una devolución',
  ReasonCodeTableExampleListItem3: 'Prueba de que el cliente no devolvió los productos',
  ReasonCodeTableExampleListItem4: 'Comprobante de que el comerciante hizo la diligencia debida para reparar o reemplazar mercancías/servicios.',
  ReasonCodeTableExampleListItem5: 'Evidencia que demuestre que la mercancía no es falsificada',
  ReasonCodeTableExampleListItem6: 'Consulta de terceros que prueba la legitimidad',
  ReasonCodeTableExampleListItem7: 'Prueba de reembolso',
  ReasonCodeTableExampleListItem8: 'Evidencia que muestra que los términos y condiciones se comunicaron claramente al titular de la tarjeta antes de que se completara la transacción',
  ReasonCodeTableExampleListItem9: 'Comprobante de reembolso o crédito de la tienda',
  ReasonCodeTableExampleListItem10: 'Evidencia que muestre los términos de servicio del comerciante (política de reembolso y cancelación)',
  ReasonCodeTableExampleListItem11: 'Evidencia que prueba que el cliente reconoció los términos de servicio',
  ReasonCodeTableExampleListItem12: 'Ejemplo: Hicieron clic en la casilla de verificación para confirmar que entienden los términos del servicio',
  ReasonCodeTableExampleListItem13: 'El comprobante de transacción es demasiado antiguo para ser cuestionado (recibo de transacción que muestra que la transacción tiene más de 180 días)',
  ReasonCodeTableExampleListItem14: 'Copia del recibo de la transacción que muestre que la moneda era correcta',
  ReasonCodeTableExampleListItem15: 'Copia de la factura que muestra que la transacción se aplicó a la cuenta correcta',
  ReasonCodeTableExampleListItem16: 'Copia del recibo de la transacción o factura que muestre que el monto fue correcto',
  ReasonCodeTableExampleListItem17: 'Evidencia que demuestre que el comerciante procesó dos transacciones separadas para los diferentes pedidos',
  ReasonCodeTableExampleListItem18: 'Junto con los cambios en el flujo de disputas, también habrá plazos más estrictos. Tenga en cuenta la fecha de vencimiento de sus disputas, ya que Visa no aceptará envíos adicionales o tardíos.',

  // User menu
  'Premium Member': 'Usuario Premium',
  'Free Member': 'Usuario gratuito',
  'Log Out': 'Cerrar sesión',
  'Add an Account': 'Agregar una cuenta',
  'No Account name': 'Sin nombre de cuenta',
  'User Settings': 'Ajustes de usuario',
  'Related Mids': 'Mids relacionados',

  // Messages

  // Toastr Dialogs
  Toastr: {
    PasswordExpiration: {
      Title: 'Expiración de Contraseña',
      Message: 'Su contraseña tiene más de 30 días. Mantenga sus cuentas seguras y establezca una nueva contraseña antes de que expire aquí.',
      ReferenceWord: 'aquí'
    },
    Error: {
      Title: 'Error'
    }
  },
  // What's New
  Notice: 'Aviso',
  // Maintenance
  SiteMessage1: 'Como parte de nuestro enfoque constante en brindarle el más alto nivel de seguridad y servicio, {{labelText}} tiene un mantenimiento del sistema programado para el 28 de junio, que comienza a la 1:00 a. m. EDT y finaliza aproximadamente a las 5:00 a. m. EDT.',
  SiteMessage2: 'Durante este tiempo no podrá iniciar sesión ni procesar ninguna transacción.',
  MaintenanceTitle: 'Mantenimiento del sistema',
  // Feedback
  FeedbackMessage: 'Confirme o revise su calificación y presione Enviar.',
  FeedbackThanks: 'Tus comentarios han sido recibidos. Siéntase libre de contactarnos con preguntas o inquietudes.',
  // Too Large
  TooLargeMessage: 'Carga demasiado grande: prueba con un tamaño más pequeño (menos de 5 MB)',
  // Too Large Transactions
  TooLargeTransactionsMessage: 'Demasiadas transacciones: intente seleccionar un rango de tiempo más pequeño.',
  // Employee
  EmployeeFullAccess: 'Acceso completo',
  EmployeeMidLevelAccess: 'Acceso de nivel medio',
  EmployeeLimitedAccess: 'Acceso Limitado',
  EmployeeActivityAccess: 'Acceso a la actividad',
  EmployeeActivityOnlyAccess: 'Solo Actividad',
  EmployeeVirtualTerminalAccess: 'Acceso a Terminal Virtual',
  EmployeeInvoicesAccess: 'Acceso a Facturas',
  EmployeePaymentLinksAccess: 'Accesso a Enlaces de Pago',
  EmployeeReputationManagementAccess: 'Acceso a Gestión de Reputación',
  EmployeeMBPOwnerDescription: 'Acceso completo al centro de pagos. El rol de Propietario no se puede archivar ni cambiar.',
  EmployeeMBPAdminDescription: 'Acceso completo al centro de pagos. Este rol otorga los mismos permisos que el Propietario, pero no permite el acceso a la información del Propietario. El rol de administrador solo debe asignarse a sus empleados más confiables y de alto nivel.',
  EmployeeMBPManagerDescription: 'Acceso limitado al centro de pagos. Como propietario o administrador, debe otorgar a la función de Administrador acceso a permisos adicionales, como Actividad, Terminal virtual y Facturas.',
  EmployeeMBPManagerOptionActivityDescription: 'Permita que los Gerentes accedan a Ventas, Depósitos, Informes y Estados de Cuenta.',
  EmployeeMBPManagerOptionOnlinePaymentsDescription: 'Permita que los gerentes ejecuten transacciones con tarjeta de crédito usando la terminal virtual en {{labelText}}.',
  EmployeeMBPManagerOptionInvoiceActionsDescription: 'Permita que los administradores creen y administren facturas en {{labelText}}.',
  EmployeeMBPReportDescription: 'Acceso limitado a Payments Hub para ver y ejecutar informes.',
  EmployeeMBPStaffDescription: 'Sin acceso al centro de pagos. El rol Staff está diseñado para usarse con la integración de administración de empleados de Homebase.',
  EmployeeOwnerDescription: 'Acceso completo a Payments Hub y a la aplicación Payanywhere. El rol de Propietario no se puede archivar ni cambiar.',
  EmployeeAdminDescription: 'Acceso completo a Payments Hub y a la aplicación Payanywhere. Este rol otorga los mismos permisos que el Propietario, pero no permite el acceso a la información del Propietario. El rol de administrador solo debe asignarse a sus empleados más confiables y de alto nivel.',
  EmployeeManagerDescription: 'Acceso limitado al centro de pagos. Acceso completo a la aplicación Payanywhere. Como propietario o administrador, debe otorgar a la función de Administrador acceso a permisos adicionales, como Actividad, Terminal virtual y Facturas.',
  EmployeeManagerOptsActivityDescription: 'Permita que los gerentes accedan a ventas, depósitos, informes y estados de cuenta en {{labelText}} y vea las tendencias de ventas en la aplicación Payanywhere.',
  EmployeeManagerOptsOnlinePaymentsDescription: 'Permita que los gerentes ejecuten transacciones con tarjeta de crédito usando la terminal virtual en {{labelText}}.',
  EmployeeManagerOptsInvoiceActionsDescription: 'Permita que los administradores creen y administren facturas en {{labelText}} y en la aplicación Payanywhere.',
  EmployeeManagerOptsPaymentLinkActionsDescription: 'Permita que los administradores creen y administren enlaces de pago en {{labelText}}.',
  EmployeeManagerOptsReputationActionsDescription: 'Permitir que los gerentes vean las reseñas de los clientes y borradores de respuestas que se enviarán a sus clientes.',
  EmployeeReportDescription: 'Acceso limitado a Payments Hub para ver y ejecutar informes. Sin acceso a la aplicación Payanywhere.',
  EmployeeCashierDescription: 'Sin acceso al centro de pagos. Acceso limitado a la aplicación Payanywhere. Como propietario o administrador, puede editar el rol de Cajero para establecer límites de anulación y reembolso para Cajeros dentro de la aplicación Payanywhere.',
  EmployeeStaffDescription: 'Sin acceso al centro de pagos. Acceso limitado a la aplicación Payanywhere para usar el reloj de tiempo. El personal no puede acceder a la funcionalidad de pago o informes dentro de la aplicación Payanywhere.',
  EmployeeCsAccess: 'Atención al Cliente',
  EmployeeCsDescription: 'Este usuario puede acceder a la aplicación {{labelText}} con permisos de atención al cliente a través del inicio de sesión de atención al cliente, pero no puede iniciar sesión en {{labelText}} Inside.',
  HomebaseEmployeeError: 'Este empleado se creó el {{labelText}}, pero no se pudo agregar a Homebase. Verifique si el correo electrónico o el número de teléfono del empleado ya se está utilizando en Homebase. Póngase en contacto con Homebase en help@joinhomebase.com o 415.951.3830.',
  DuplicateEmployeeError: 'Has introducido una dirección de correo electrónico que ya existe como usuario de esta cuenta. Vuelve a intentarlo con otro correo electrónico.',
  InvoiceGeneratedNumber: 'Si no se ingresa un número de factura, se generará uno automáticamente.',
  InvoiceConfirmEndSeries: '¿Estás seguro de que quieres terminar la serie {{invoiceName}}?',
  InvoiceSendToCustomer: 'El cliente recibirá un enlace para agregar detalles de pago. Esta factura aparecerá como sin pagar hasta que el cliente pague.',
  AutopayConfirmRemoveSeriesAutopay: '¿Está seguro de que desea darse de baja del pago automático para la serie {{invoiceName}}?',
  AutopayConfirmRemoveAutopay: '¿Estás seguro de que quieres darte de baja del pago automático?',
  Validator: {
    RequireText: 'Requerido',
    AlphaNumericRequired: 'El campo debe ser un valor alfanumérico',
    IncorrectPartialAmount: 'Monto parcial no valido',
    AccessMustBeSelected: 'Seleccione el acceso requerido',
    InvalidPhoneNumber: 'Este número de teléfono no es válido',
    InvalidMobileNumber: 'Numero de celular invalido',
    customerEquityOwnershipMore: 'El valor de la propiedad accionaria no puede ser superior al 100%',
    customerEquityOwnershipLess: 'La participación accionaria total debe ser superior al 75%',
    customerEquityOwnershipZero: 'El valor de propiedad accionaria no puede ser cero',
    customerEquityOwnershipPrimary: 'El valor de propiedad del capital social primario debe ser mayor al 75%',
    customerOwnershipPrimary: 'Debes seleccionar un propietario principal',
    InvalidEmail: 'Esta dirección de correo electrónico no es válida',
    InvalidEmailAmount: 'Sólo puedes agregar 5 emails',
    InvalidAmountRange: 'Intervalo de cantidades no válido',
    InvalidDateRange: 'Intervalo de fechas no válido',
    InvalidPreviousDateRange: 'Intervalo de fechas anterior no válido',
    InvalidWebDomain: 'Dominio web no válido',
    HttpsRequired: 'La URL debe incluir https://',
    PreviousRangeIdentical: 'El rango anterior es idéntico',
    PasswordsDoNotMatch: '¡Nueva contraseña y Confirmar contraseña no coinciden!',
    PasswordsDoNotMatchNew: '¡La contraseña y la contraseña verificada no coinciden!',
    InvalidPassword: 'Las contraseñas deben tener al menos 8 caracteres y contener una letra mayúscula, una letra minúscula y un número o símbolo (~!@#$%^&*()-=+).',
    EmailsDoNotMatch: '¡El correo electrónico nuevo y las direcciones de correo electrónico confirmadas no coinciden!',
    EmailOrPhoneRequired: 'Se requiere dirección de correo electrónico o número de teléfono',
    AmountRequired: 'Se requiere monto de la venta',
    ExpirationDateInvalid: 'La fecha de expiración no es válida',
    ExpirationDateRequired: 'Se requiere fecha de expiración',
    ExpirationDateExpired: 'La fecha de expiración está vencida',
    CreditCardRequired: 'Se requiere número de tarjeta de crédito',
    CreditCardInvalid: 'El número de la tarjeta de crédito es inválido',
    DebitCardRequired: 'Se requiere número de tarjeta de débito',
    DebitCardInvalid: 'El número de tarjeta de débito no es válido',
    DebitCardInvalidForInstantFunding: 'Los financiamientos instantáneos solo se pueden procesar con una tarjeta Mastercard o una tarjeta de débito Visa.',
    CreditCardInvalidForDebtRepayment: 'Las transacciones de pago de deuda solo se pueden procesar con una tarjeta Mastercard o Visa.',
    SecurityCodeRequired: 'Se requiere código de seguridad',
    SecurityCodeInvalid: 'Código de seguridad es inválido',
    BillingStreetNumberRequired: 'Se requiere el número de calle de facturación de la tarjeta de crédito',
    BillingStreetNumberInvalid: 'El número de calle de facturación de la tarjeta de crédito no es válido',
    InvalidStreet1: 'Calle 1 no es válida',
    RequiredStreet1: 'Calle 1 es requerido',
    RequiredState: 'Estado es requerido',
    RequiredCity: 'Cidudad es requerido',
    ZipRequired: 'Código postal es requerido',
    ZipInvalid: 'Código postal es inválido',
    ZipRequiredWithZipPlusFour: 'Código postal es requerido con Código postal +4',
    ZipShouldBeFiveDigits: 'El código postal debe tener 5 dígitos',
    ZipPlusFourShouldBeFourDigits: 'Código postal +4 debe tener 4 dígitos',
    NameOnCardRequired: 'Se requiere el nombre en la tarjeta',
    FirstAndLastNameRequired: 'Se requiere nombre y apellido',
    FirstOrLastNameRequired: 'Se requiere nombre o apellido',
    EmailRequired: 'Email requerido',
    DateCannotBeInPast: 'La fecha no puede estar en el pasado',
    RecurringEndRequiredAndNonZero: 'Debe ser mayor que 0',
    RecurringRepeatRequiredAndNonZero: 'Debe ser mayor que 0',
    RecurringEndLessThanStart: 'Debe ser posterior a la fecha de inicio',
    InvalidName: 'Nombre inválido',
    InvalidPrice: 'Precio inválido',
    InvalidQuantity: 'Rastrear Artículo está activado, debes introducir una cantidad',
    InvalidDate: 'Fecha invalida',
    InvalidOneOrMorePrice: 'Los artículos requieren uno o más precios',
    MissingModifiersError: 'Los conjuntos de modificadores requieren uno o más modificadores',
    TargetValueRequired: 'Requerido',
    ClosedAccount: 'Esta cuenta se ha cerrado y solo muestra datos históricos. Llámenos al {{phoneNumber}} si necesita más ayuda.',
    ClosedAccountTitle: 'Cuenta Cerrada',
    InvalidAccountNumber: 'El número de cuenta no puede tener menos de 4 dígitos, ni más de 17 dígitos, ni igual a 16 dígitos.',
    AbaInvalidNumber: 'Número de ruta ABA no válido',
    InvalidRoutingNumber: 'El número de ruta debe tener 9 dígitos',
    RoutingNumberNotFound: 'El número de ruta no es válido',
    InvalidSSN: 'El Número de Seguro Social debe tener 9 caracteres',
    InvalidSSNFormat: 'El Número de Seguro Social no es válido',
    InvalidFFSSN: 'El Número de Seguro Social debe tener 5 caracteres',
    AccountNameLengthInvalid: 'El nombre y al menos un apellido son necesarios',
    AccountNumber: 'Requerido. Verifique su estado de cuenta más reciente para su identificación de comerciante/número de cuenta.',
    AccountNumberInvalid: 'Requerido. Verifique su estado de cuenta más reciente para su identificación de comerciante/número de cuenta.',
    BankingAccountNumberInvalid: 'El número de cuenta bancaria es inválido',
    RoutingNumberInvalid: 'El número de ruta bancaria es inválido',
    AlreadyAttachedToAccount: 'Ya está adjunto a esta cuenta.',
    SsnTaxId: 'Se requiere un campo (SSN o identificación fiscal)',
    PrincipleNameError: 'Nombre principal debe contener un nombre y un apellido separados por un espacio',
    CashFixedAmount: 'El rango de valores debe estar entre $0.01 y $1',
    CashPercentAmount: 'El rango de valores debe estar entre 1% y 4%',
    InvalidOwnershipPercentage: 'El porcentaje de propiedad no puede ser superior al 100%',
    InvalidUnderage: 'Debe tener al menos 18 años',
    InvalidContactPriority: 'El valor ya está asignado a otro propietario',
    TaxAmountMustBeGreaterThanZero: 'El monto del impuesto debe ser mayor que cero si se selecciona imponible',
    TaxAmountMustBeEqualGreaterThanZeroNoTaxable: 'El monto del impuesto debe ser igual o mayor que cero',
    TaxAmountMetaphorMustBeLessThanNinetyNine: 'El monto del impuesto antes del punto decimal no puede ser mayor a 99',
    TaxAmountMantissaMustBeEqualOrLessThanThreeDigits: 'El monto del impuesto después del punto decimal solo puede tener 3 dígitos o menos',
    InvalidDisputeNote: 'Sus notas no pueden tener más de 1000 caracteres. Usa letras y números. ("-", "." y espacios están permitidos. Los caracteres especiales como "#", "&", "$" no están permitidos.)',
    InvalidDisputeDocumentDescription: 'Tu descripción no puede tener más de 50 caracteres. Usa letras y números. ("-", "." y espacios están permitidos. Los caracteres especiales como "#", "&", "$" no están permitidos).',
    InvalidURL: 'La URL no tiene el formato correcto. Ejemplo: https://www.payanywhere.com',
    NorthInvalidURL: 'La URL no tiene el formato correcto. Ejemplo: https://www.north.com',
    InvalidSecureURL: 'Se requiere un URL válido y seguro con HTTPS. Ejemplo: https://www.payanywhere.com',
    InvalidFacebookHandle: 'Identificador de Facebook no válido',
    InvalidTwitterHandle: 'Identificador de Twitter no válido',
    InvalidInstagramHandle: 'Identificador de Instagram no válido',
    InvalidYelpHandle: 'Identificador de Yelp no válido',
    PaymentTypeRequired: 'Seleccione un tipo de pago',
    PaymentInputTypeRequired: 'Seleccione un método de entrada de pago',
    CustomerCodeRequired: 'Se requiere código de cliente.',
    TaxAmountRequired: 'Se requiere el monto del impuesto.',
    TaxIdRequired: 'Se requiere identificador de impuesto.',
    InvalidTaxId: 'Identificación fiscal no válida',
    ItemDescriptionRequired: 'La descripción del artículo es requerida.',
    ItemQuantityRequired: 'Se requiere la cantidad del artículo.',
    MeasureUnitRequired: 'Se requiere unidad de medida.',
    UnitPriceRequired: 'Se requiere unidad de precio.',
    ProductCodeRequired: 'Se requiere el código del producto.',
    CommodityCodeRequired: 'Se requiere el código de la mercancía.',
    RequestorNameRequired: 'El nombre del solicitante es obligatorio.',
    CompanyNameRequired: 'El nombre de la compañía es obligatorio.',
    LengthOfFieldTooLong: 'La longitud del campo no puede exceder los {{maxLength}} caracteres',
    FirstFiveSSNShouldBeFiveDigits: 'El valor debe ser de 5 dígitos',
    TipType: 'Seleccione el tipo de propina: $ o %.',
    TipValue: 'Seleccione el monto de la propina.',
    AmountMustBeInteger: 'La cantidad del porcentaje no debe tener decimales',
    AmountMustBeGreaterThanZero: 'La cantidad debe ser mayor que cero.',
    AmountMetaphorMustBeLessThanNinetyNine: 'La cantidad antes del punto decimal no puede ser mayor a 99.',
    AmountMantissaMustBeEqualOrLessThanThreeDigits: 'La cantidad después del punto decimal solo puede tener 3 dígitos o menos.',
    RefundQuantityExceedsOriginal: 'No puede exceder la cantidad original del artículo.',
    RefundTipExceedsOriginal: 'No puede exceder la propina original de la transacción.',
    UnSelectedCategory: 'Seleccione la categoría que desea fusionar',
    ItemDiscountExceedsTotal: 'El monto del descuento no puede exceder el valor total del artículo.',
    ExpressItemNameRequired: 'El nombre del artículo es obligatorio.',
    ExpressItemPriceRequired: 'El precio del artículo es obligatorio.',
    ExpressItemTaxRateRequired: 'Se requiere la tasa de impuestos.',
    IntegratedTokenMustNotHaveSpaces: 'El token integrado no debe tener espacios.',
    DuplicateDiscount: 'El nombre del descuento ya está en uso. Introduzca un nombre de descuento válido.',
    DuplicateModifierSet: 'El nombre del conjunto de modificadores ya está en uso. Introduzca un nombre de conjunto de modificadores válido.',
    DuplicateIntegratedToken: 'El token integrado ya está en uso. Introduzca un token integrado válido.',
    CardInvalid: 'Número de tarjeta invalido.',
    CardAlreadyExists: 'La tarjeta ya existe.',
    CreditCardHolderNameRequired: 'Nombre del tarjetahabiente es requerido',
    InvalidBillingStreetNumber: 'Número de calle de facturación es inválido',
    InvalidCardNumber: '54 - Tarjeta Expirada',
    RewardDollarAmountLimit: 'El monto de la recompensa no puede ser mayor a $1,000,000',
    RewardPercentAmountLimit: 'El monto de la recompensa no puede ser mayor a 100%',
    MinimumLargerThanMaximum: 'El monto máximo debe ser mayor al monto mínimo.',
    DuplicatedPaymentAmounts: 'Los montos de pago no pueden ser repetidos.',
    AmountBetweenMinMax: 'Digite una cantidad entre {{minimum}} y {{maximum}}',
    MinWithoutMax: 'Digite una cantidad mayor o igual a {{minimum}}.',
    MaxAmountReached: 'Monto máximo {{limit}}.',
    AcceptTermsAndPrivacyPolicy: 'Por favor acepte los términos y condiciones y la política de privacidad.',
    InvalidPercent: 'Porcentaje inválido',
    SignupFullNameRequired: 'Ingrese nombre completo.',
    SignupInvalidEmail: 'Ingrese una dirección de correo electrónico válida.',
    SignupInvalidMobile: 'Ingrese un número de móvil válido de EE. UU. de 10 dígitos.',
    SignupInvalidPassword: 'Las contraseñas deben tener al menos 8 caracteres y contener una letra mayúscula, una letra minúscula y un número o símbolo (~!@#$%^&*()-=+).',
    SignupInvalidConfirmPassword: 'Escriba la contraseña otra vez. La contraseña nueva y la contraseña confirmada no coinciden.',
    SignupAcceptTerms: 'Please accept the Terms of Use.',
    SignupAcceptPrivacyPolicy: 'Please accept the Privacy Policy.'
  },
  Errors: {
    InternalProcessingError: 'Lo sentimos, error de procesamiento interno.',
    VoidRefundError: 'Anulado / Reembolso rechazado',
    CompletePreAuthError: 'Autorización previa completa rechazada',
    Activation: {
      Hardware: {
        MaxDevices: 'Por favor, no seleccione más de {{count}} dispositivos.'
      },
      Submitted: 'Ya ha iniciado una solicitud.',
      MerchantApplication: 'No se puede obtener el "Merchant Application" PDF.',
      SubmitFailed: {
        Title: 'No se puede continuar',
        Message: 'Confirme que su información sea correcta e inténtelo nuevamente. Si el problema persiste, comuníquese con cssales@north.com'
      },
      PublishFailed: {
        Title: 'No se pudo enviar',
        Message: 'Hubo un error al procesar tu solicitud. Regresa y edita tu información o cierra la solicitud y contáctanos al 866.611.4225',
        ExitAction: 'Salir de la solicitud',
        CancelAction: 'Volver a la solicitud'
      }
    },
    Avs: {
      Heading: 'Verificación de dirección fallida',
      Message: 'El número de calle y/o el código postal ingresados no coinciden con la dirección de facturación del titular de la tarjeta. ¿Le gustaría continuar con esta transacción?'
    },
    AvsPayInvoiceFailure: 'Su pago no pudo ser procesado. Verifique el número de tarjeta, la fecha de vencimiento y que su dirección de facturación coincida con lo que está registrado e intente nuevamente.',
    PartialPayment: {
      Heading: 'Aviso',
      Message: 'No se puede procesar la transacción. Asegúrese de que la tarjeta tenga fondos suficientes o utilice un método de pago diferente.'
    },
    TwoFactor: {
      RateLimit: 'Excedió la cantidad de intentos. Inténtelo de nuevo en 10 minutos.',
      VerifyFailed: '¡Lo sentimos! La verificación ha fallado.'
    },
    Funding: {
      BakingErrorTitle: 'No se puede actualizar la información bancaria.',
      BreadingErrorDescription: 'Asegúrese de que su información sea correcta y vuelva a intentarlo.',
      RateLimit: 'Excedió la cantidad de intentos. Inténtelo de nuevo en 24 horas.',
    },
    SignupError: 'Correo electrónico y/o número de teléfono ya existen.',
    InvoiceApiError: 'No se puede procesar el pago. Vuelva a intentarlo o comuníquese con la empresa si cree que puede haber un problema con el sistema.',
    InvalidCSRF: 'Hemos actualizado nuestra aplicación. Por favor refresque su navegador.',
    InvalidBusinessPercent: 'Porcentajes totales deben ser igual a 100%',
  },
  NoData: {
    EmployeesToday: {
      Header: 'Sin empleados.',
      Description: 'Agregue empleados con diversos roles para aceptar pagos en su nombre.',
      Link: 'Ir a Empleados'
    },
    StockToday: {
      Header: 'No hay stock.',
      Description: 'Habilite el seguimiento de existencias para administrar su inventario y configurar alertas de existencias.',
      Link: 'Ir a Inventario'
    },
    ItemsToday: {
      Header: 'No hay artículos.',
      Description: 'Agregue productos o servicios a su inventario para pagos más rápidos e informes valiosos.',
      Link: 'Ir a Inventario'
    },
    BatchDeposits: 'Los depósitos se mostrarán después de que sus transacciones se hayan liquidado. Puede haber un retraso en la visualización de los depósitos si tiene lotes abiertos o transacciones en proceso de revisión.',
    NoDeposits: 'Sin Depósitos',
  },
  DAVOIntegration: {
    Title: 'Automatiza tus impuestos de ventas con DAVO',
    LinkText: 'Obtiene el Primer Mes de Presentación Gratis ',
    Disclaimer: '*Solo disponible con un plan de pago.'
  },
  TapToPay: {
    Text: 'Acepte pagos sin contacto con solo un iPhone',
  },
  ReportsInfo: {
    GrossSales: 'Esta es la cantidad que vendiste. Incluye tus transacciones.',
    NetSales: 'Esta es la cantidad que ganas como negocio. Incluye sus transacciones con descuentos, reembolsos y anulaciones.',
    TotalCollected: 'Esta es la cantidad que recaudas. Incluye tus transacciones con descuentos, devoluciones, anulaciones, impuestos y propinas.'
  },
  HistoricHardwareMode: {
    SwitchToTerminalView: 'Cambiar a Vista de Terminal',
    Description: 'Ver actividad para sus terminales tradicionales. Algunas funciones estándar no estarán disponibles.',
    TerminalView: 'Vista de Terminal',
    NoticeInfo: 'Está viendo la actividad para sus terminales tradicionales. Algunas funciones estándar no estarán disponibles.',
    ExitTerminalView: 'Salir de Vista de Terminal',
    DashboardBanner: {
      Title: 'Está en Vista de Terminal.',
      Description1: 'Está viendo la actividad para sus terminales tradicionales.',
      Description2: 'Para acceder a su conjunto completo de funciones, salga de la Vista de Terminal.'
    }
  },
  TodayFeedback: {
    Heading: '¿Tienes comentarios?',
    Text: '¡Nos encantaría escucharlo! Cuéntanos lo que piensas sobre {{labelText}} con nuestra breve encuesta.',
    LinkText: 'Tomar encuesta'
  },
  'ReputationProject': {
    'Title': 'Manejo de Reputación',
    'GetStarted': 'Empezar',
    'LearnMore': 'Conocer Más',
    Trends: {
      MostCommonWords: 'Palabras Más Comunes',
      CompetitorPerformance: {
        Heading: 'Rendimento de la Comepetencia',
        Reviews: 'reseñas',
        Add: 'Agregar'
      },
      NoDataMessage: 'No hay datos disponibles',
      MostRecentReviews: {
        Heading: 'Más Recientes'
      }
    },
    CompetitorPerformance: {
      MilesAway: 'millas de distancia',
      NoLongerAvailable: 'La cuenta del competidor ya no está disponible.',
      Remove: 'Eliminar Competidor',
      Competitor: 'Competidor',
      Rating: 'Calificación',
      Comments: 'Comentarios',
      ReviewDate: 'Fecha de reseña',
      NoData: 'Sin reseñas de la competencia',
      AddCompetitor: 'Agregar un competidor.',
      SearchHintText: 'Busque negocios en Google',
      RemoveCompetitorTitle: 'Eliminar competidor.',
      RemoveCompetitorConfirm: 'Sí, eliminar',
      RemoveCompetitorCancel: 'No, cancelar',
      RemoveCompetitorText: 'Está seguro de que quiere eliminar {{business}}? Ya no verá calificaciones ni reseñas de esta empresa.'
    },
    Settings: {
      Title: 'Conectar cuenta',
      Subtitle: 'Inicie sesión en su cuenta de Google para conectar su empresa y ver y responder a las reseñas de los clientes. Configura tu perfil comercial de Google ',
      SetUpAlready: ' si aún no tiene uno.',
      Disconnect: 'Desconectar',
      ReviewsTitle: 'Reseñas de Google',
      Connected: 'Conectado',
      NotConnected: 'No Conectado',
      MyCompetitors: {
        Header: 'Mis Competidores',
        Text: 'Agregue hasta tres negocios similares en su área para ver cómo le va frente a la competencia.'
      },
      EditLocations: 'Editar Ubicaciones',
      SelectLocation: 'Seleccione una ubicación.',
      LocationsErrorTitle: 'Error',
      LocationsErrorBody: 'No se encontró ninguna ubicación de Google Business. Verifique su perfil comercial de Google.',
      UnnamedBusiness: 'Negocio sin nombre',
      NoAddressInformation: 'Sin información de ubicación',
      EmailPhoneForm: {
        Title: 'Agregue o elimine correo electrónicos para notificaciones.',
        EmailHint: 'Agregue hasta 5 correos electrónicos',
        PhoneHint: 'Agregue un número telefónico móvil',
      },
      Notifications: {
        Header: 'Notificaciones',
        Text: 'Reciba alertas diarias por correo electrónico cuando tenga nuevas reseñas para hasta cinco (5) destinatarios.',
        AlertMe: 'Alertarme de',
        SendAlerts: 'Enviar alertas a',
        Email: 'Correo electrónico',
        SMS: 'SMS',
        AddRecipient: 'Modificar destinatarios de correo electrónico'
      }
    },
    CustomerReviews: {
      Source: 'Origen',
      Rating: 'Calificación',
      Customer: 'Cliente',
      Comments: 'Comentarios',
      ReviewDate: 'Fecha de reseña',
      Responded: 'Fecha de respuesta',
      NoData: 'Sin reseñas de clientes',
      Details: {
        At: 'a las',
        Review: 'Reseña de {{platform}}',
        Said: 'dijo',
        YouSaid: 'Tu respondiste',
        EditResponse: 'Editar Respuesta',
        RespondTo: 'Responder a',
        Clear: 'Limpiar',
        ResponseHint: 'Escriba una respuesta personalizada o seleccione una respuesta rápida a continuación.',
        QuickReplies: 'Respuestas Rápidas',
        NoComment: 'Este usuario no dejó ningún comentario.'
      },
    }
  },
  Legal: {
    HeaderA: 'Revisar Términos.',
    PaCopy1: 'Para continuar utilizando nuestros servicios de procesamiento de pagos, debe revisar y aceptar los términos y condiciones.',
    HeaderRequired: 'Acción requerida',
    CopyRequired: 'Para continuar usando {{labelText}}, el director comercial/propietario de esta cuenta debe iniciar sesión y aceptar los términos actualizados. Póngase en contacto con su administrador.',
    UserAgree: 'He revisado y estoy de acuerdo con el Acuerdo de Usuario.',
    LinkText: 'De acuerdo',
    FDText: 'Para ver los términos y condiciones actualizados, haga click ',
    FDLink: ' aquí',
    VerificationAttempts: 'Intentos de verificación {{attempts}} de 3.',
    Or: ' o '
  },
  Supplies: {
    pa: {
      AgreeToDebit: 'Acepto que Payanywhere/North American Bancard debite la tarifa total de la cuenta bancaria que tengo registrada.'
    }
  },
  MBPRegistration: {
    AlreadyRegisteredHeader: 'Esta cuenta ya ha sido registrada.',
    AlreadyRegisteredContent: 'Vuelva a la pantalla de inicio de sesión y use su nombre de usuario y contraseña para iniciar sesión.',
    UnableToRegisterHeader: 'No se puede registrar.',
    UnableToRegisterContent: 'Para obtener ayuda, comuníquese con Servicio al Cliente al 877.464.4218 o support@paymentshub.com.',
    SuccessContent: 'Su cuenta ha sido registrada. Regrese a la pantalla de inicio de sesión para acceder a su cuenta.',
    PartialMatchHeader: 'Lo sentimos, la información que ingresó es incorrecta.',
    PartialMatchContent: 'Inténtalo de nuevo.',
    EmailSentHeader: 'Por favor revise su correo electrónico.',
    EmailSentContent: 'Para continuar con el registro, por favor sigue los pasos en el correo electrónico que te hemos enviado.',
    MidUsedHeader: 'Este MID ya ha sido registrado.',
    MidUsedContent: 'Intente e inicie sesión.',
    CanRegisterMid: [
      'Este número de cuenta no existe en nuestro sistema',
      'Este número de cuenta ya está registrado para otro usuario',
      'Algo salió mal al intentar verificar este número de cuenta'
    ],
    Helper: [
      'Verifique su estado de cuenta más reciente para su identificación de comerciante/número de cuenta.',
      'Ingrese el nombre del propietario principal asociado con la cuenta.',
      'Ingrese el apellido del propietario principal asociado con la cuenta.',
      'Esta dirección de correo electrónico será su nuevo inicio de sesión en el centro de pagos'
    ]
  },
  MBPMigration: {
    FormHeader: 'Registro de Usuario de MyBizPerks.',
    FormSubHeader: 'Use su nombre de usuario y contraseña de MBP para activar su cuenta de Payments Hub. Si no conoce la información de su MBP, haga clic en ',
    SuccessContent: 'Su cuenta se ha registrado y todos los MID de MyBizPerks se han migrado a su cuenta.',
    PartialSuccessHeader: 'Exitoso.',
    PartialSuccessContent: 'Su cuenta se registró, pero algunos MID ya se migraron de MyBizPerks a esta cuenta: ',
    AlreadyMigratedHeader: 'Esta cuenta ya registró y migró todos los MID.',
    AlreadyMigratedContent: 'Los MID de esta cuenta en MyBizPerks ya se han migrado a esta cuenta.',
    FailedMigrationHeader: 'Lo sentimos, esta cuenta no se pudo migrar.',
    FailedMigrationContent: 'Los MID de esta cuenta MyBizPerks no se pudieron migrar. Para obtener ayuda, comuníquese con Servicio al cliente al 877.464.4218 o support@paymentshub.com.',
    NoMbpUser: 'No autorizado',
    NoMbpUserMessage: 'No reconocimos las credenciales de MyBizPerks ingresadas. Cree un nombre de usuario y una contraseña de Payments Hub usando el formulario a continuación.',
    FieldLabels: {
      MbpUsername: {
        Label: 'Nombre de Usuario de MyBizPerks',
        Hint: 'Su Nombre de Usuario de MyBizPerks'
      },
      MbpPassword: {
        Label: 'Contraseña de MyBizPerks',
        Hint: 'Su Contraseña de MyBizPerks'
      },
      EmailAddress: {
        Hint: 'Dirección de correo electrónico (este es su nombre de usuario)'
      },
      CreatePassword: {
        Label: 'Crear contraseña del centro de pagos',
        Hint: 'Crear una nueva contraseña del centro de pagos'
      },
      VerifyCreatePassword: {
        Label: 'Verificar la contraseña del centro de pagos',
        Hint: 'Verificar la nueva contraseña del centro de pagos'
      }
    },
    Helper: {
      Option1: 'Este es el nombre de usuario de su cuenta MyBizPerks actual',
      Option2: 'Esta es la contraseña de su cuenta MyBizPerks actual',
      Option3: 'Esta dirección de correo electrónico será su nuevo inicio de sesión en el centro de pagos'
    },
    UserAlreadyAttachedToMIDs: 'Los MID solicitados ya están adjuntos a este usuario.'
  },
  RegisterMBPUserForm: {
    Header: 'Registrar Usuario',
    DescriptionPartOne: 'Debería de registrar una cuenta solo si aplicó usando una solicitud en papel o si tiene una cuenta creada antes de abril de 2018. ',
    DescriptionPartTwo: 'Si no está seguro de si necesita registrarse, intente restablecer su contraseña primero ',
    Here: 'aquí',
    Fields: {
      FirstName: 'Nombre',
      LastName: 'Apellido',
      EmailAddressHintText: 'Correo Electrónico (este es su nombre de usuario)',
      NewPasswordHintText: 'Crear Contraseña'
    },
  },
  RegisterMbpUserAuthenticationForm: {
    Header: {
      Welcome: 'Bienvenido de nuevo.',
      Invalid: 'Contraseña Inválida'
    },
    Description: 'Esta dirección de correo electrónico ya está asociada con una cuenta de Payments Hub. Inicie sesión para vincular su nuevo registro con su cuenta existente.',
    Or: 'Si no está seguro de su contraseña, puede restablecerla a continuación.',
    ResetPassword: 'Restablecer Contraseña'
  },
  AddAccountDialog: {
    DefaultError: 'No pudimos vincular las siguientes cuentas de comerciante debido a un error desconocido: ',
    Helper: {
      Option1: 'El MID de la cuenta que desea agregar.'
    },
    Title: 'Agregar una cuenta',
    ConfirmText: 'Agregar Cuentas',
    DialogSubtitle: 'Conecte una o más cuentas comerciales adicionales a sus credenciales de inicio de sesión existentes. Una vez agregados, puede alternar rápidamente entre ellos desde el Menú de Cuenta.',
    MidSuccessDialogWithMids: 'cuenta(s) a agregar',
    MidSuccessDialogWithoutMids: 'Su(s) cuenta(s) aparecerá(n) aquí',
    MID: 'Número de Cuenta de Comerciante (NCC)',
    Or: 'O',
    GetLabelHeader: 'Obtener el identificador de la Plantilla {{labelText}} del Comerciante',
    GetLabelBody: 'Utilice nuestra plantilla personalizada para crear e importar rápidamente sus ID de comerciante a su cuenta.',
    Download: 'Descargar',
    UploadMerchantHeader: 'Subir sus Identificadores de Comerciante',
    UploadMerchantBody: 'Arrastre su plantilla completa al espacio a continuación, o haga clic en "Examinar" para ubicarla. Debe ser un archivo CSV (.csv)',
    FileUpload: '<0>Arrastrar archivos aquí o </0><1>buscar</1>',
    SavedAccounts: 'Cuentas guardadas ',
    ErrorsMap: {
      MissingMids: 'Ingrese al menos 1 ID de cuenta de comerciante.',
      MissingUserData: 'Tu sesión ha expirado.',
      MidPrincipalsDoNotMatch: 'No podemos vincular las siguientes cuentas de comerciante porque el nombre del propietario, el NSS del propietario o la identificación fiscal federal no coinciden: ',
      MidAlreadyAttached: 'No podemos vincular las siguientes cuentas de comerciante porque ya están asociadas a este usuario: ',
      MidDoesNotExist: 'No pudimos vincular las siguientes cuentas de comerciante porque no existen: '
    }
  },
  AccountSection: {
    Credentials: {
      ph: 'cambiará sus credenciales de inicio de sesión para Payments Hub.',
      pa: 'cambiará sus credenciales de inicio de sesión tanto para la aplicación Payanywhere como para Payanywhere Inside.',
      ps: 'cambiará sus credenciales de inicio de sesión tanto para la aplicación PhoneSwipe como para PhoneSwipe Inside.'
    },
    TwoFactor: {
      HeaderText: 'La autenticación de dos factores es un proceso de seguridad que se utiliza para ayudar a mantener su cuenta segura. Cuando edite la información de su cuenta, se le pedirá un código único enviado por SMS. Se pueden aplicar tarifas por mensajes y datos.',
      Description: 'Para una capa adicional de seguridad, solicite un código único enviado por SMS cada vez que inicie sesión, a menos que seleccione la opción "Recordar este dispositivo". Se pueden aplicar tarifas por mensajes y datos.',
    },
    Giact: {
      AccountType: 'No se pudo validar su información bancaria para {{accountType}}. Puede cargar un cheque anulado para una mayor validación. Subir como jpg o png. Tamaño máximo de archivo 5 MB.',
      MissingVerification: 'Falta la imagen de verificación bancaria'
    }
  },
  User: {
    RestrictedMbpAccess: 'Actualmente está registrado para las funciones de informes de Payments Hub.'
  },
  LoadingReport: {
    Option1: 'Por favor espere.',
    Option2: 'Por favor espere..',
    Option3: 'Por favor espere...',
    Option4: 'Generando su reporte.',
    Option5: 'Generando su reporte..',
    Option6: 'Generando su reporte...'
  },
  TransactionInfo: {
    Title: '¿Dónde están las transacciones de hoy?',
    Details: 'Las transacciones se mostrarán después de que haya agrupado. Haga click en Abrir Grupo para ver una lista de autorizaciones de tarjetas de crédito activas que aún no se liquidaron. No incluye reembolsos, efectivo y transacciones con terminal virtual.'
  },
  HomebaseTitle: {
    Option1: 'Prueba Homebase gratis.',
    Option2: 'Está inscrito en una prueba gratuita de 30 días de Homebase.',
    Option3: '¡Tu prueba de Homebase está terminando!'
  },
  HomebaseContent: {
    Option1: 'Nos complace ofrecerle una prueba gratuita de 30 días de Homebase Plus.',
    Option2: 'Homebase es un software de gestión de empleados con funcionalidad de programación y reloj que se integra directamente en Payanywhere.',
    Option3: 'Después de que caduque la prueba, se le colocará automáticamente en el plan básico gratuito. Visite el sitio de Homebase para administrar su suscripción.',
    Option4: 'Para continuar usando Homebase, administre su suscripción desde la página de Integraciones.'
  },
  ApiAccess: {
    Title: 'Accesso al API',
    Credentials: 'Credenciales del API',
    JSKDomain: 'Dominio del JS SDK',
    MidRemoved: 'Acceso del MID eliminado',
    JSKDomainInfo: `Ingrese el dominio desde el que procesará los pagos.
    Tenga en cuenta que para usar dominios comodín, no debe usar el carácter "*". Si desea habilitar todo en mydomain.com, establezca mydomain.com como valor y automáticamente incluirá test.mydomain.com; midominio.com; etc.
    Solo se puede configurar un dominio a la vez.`,
    ApiAccessInfo: 'Accede a tus credenciales de producción y opciones de configuración de productos técnicos.',
    JSKInfo: 'El iFrame JavaScript SDK (JS SDK) es un producto de comercio electrónico que permite a los usuarios mantener los datos confidenciales de la tarjeta fuera del ambiente de su servidor.',
    InvoicingAPIInfo: 'Invoicing API permite a los usuarios generar un enlace para enviar a un cliente para que pueda realizar un pago.',
    SemiIntegratedAPI: 'API Semi-integrado de Payanywhere',
    SemiIntegratedAPIInfo: 'Este producto permite a los usuarios combinar una aplicación web con lectores de tarjetas BBPOS compatibles con Payanywhere y terminales inteligentes PAX para aceptar pagos con tarjeta presente',
    WebHook: 'Habilitar el webhook de Payanywhere',
    WebHookInfo: 'Configure un webhook para que su servidor pueda recibir una notificación cuando se apruebe o rechace un pago. Agregue la URL completa, en la que Payanywhere publicará el resultado del pago. Esto debe estar en https//: y su sitio debe tener una certificación de una CA (no autofirmada).',
    VerificationNotice: 'Se ha enviado un código de verificación al número de móvil que termina en {{number}}. Introdúcelo a continuación para restablecer tu contraseña.',
    ShowPassword: 'Mostrar contraseña del API',
    AreYouSure: '¿Estas seguro que deseas restablecer la contraseña?',
    PasswordReset: 'Restablecimiento de contraseña',
    Important: 'Importante: ',
    PasswordResetContent: 'Restablecer la contraseña del API romperá cualquier integración existente que use la contraseña de la API.',
    WebhookTitle: 'Webhook',
    AllowIncrementalAuthorization: 'Permitir Autorización Incremental',
    AuthoInfo: 'La Autorización Incremental le permite ajustar el monto autorizado de una transacción una o más veces antes de que se capture',
    HoverRemove: 'Desplaze el mouse para eliminar un MID',
    RemoveWarning: `Eliminar un MID revocará las credenciales de esa cuenta.
    Para generar nuevas credenciales, inicie sesión en la cuenta eliminada y haga clic en "Obtener credenciales" en la pantalla de Integraciones.`,
    NotActive: 'Utiliza el botón Obtener credenciales para completar tu configuración.',
    NotActiveWebhook: 'Activa el interruptor para completar la configuración de tu webhook.',
    ViewDocumentation: 'Ver documentación'
  },
  GetCredentials: {
    Title: 'Obtener Credenciales',
    Button: 'Obtener credenciales',
    Message: '¿Le gustaría obtener credenciales para todos sus MID o restringirlas al MID actual: {{currentMid}}?',
    CurrentMidButton: 'Obtener solo para MID actual',
    AllMidsButton: 'Obtenga para todos los MIDs'
  },
  ApiCredential: {
    Important: 'Importante: ',
    ResetAPIPassword: 'Restablecer la contraseña del API',
    ApiPasswordContent: `Copie la contraseña del API y guárdela en un lugar seguro. La contraseña del API solo se mostrará la primera vez que se muestre esta pantalla.
     Si pierde u olvida su contraseña de API, haga clic en el botón "Restablecer contraseña de API" y siga las instrucciones para autenticar su identidad.`,
    APIInfo: `La contraseña API es la misma para todos los productos y solo se puede ver una vez.
    Si perdió u olvidó su contraseña, haga clic en Restablecer contraseña API. Al hacer esto, se restablecerá la contraseña de la API para todos los productos.`
  },
  JsSdkForm: {
    Title: 'Dominio JS SDK',
    Important: 'Importante: ',
    Message: '¿Está seguro de que desea editar la URL del dominio? Esto romperá cualquier integración existente con el diseño actual.',
    Label: 'URL del dominio de JS SDK',
    EnterJsSdk: 'Ingrese el URL del dominio JS SDK'
  },
  WebHookForm: {
    Title: 'Payanywhere Webhook',
    Important: 'Importante: ',
    Message: '¿Está seguro de que desea editar la URL del dominio? Esto romperá cualquier integración existente con el diseño actual.',
    Label: 'URL del Webhook',
    EnterDomain: 'Ingrese el URL del dominio para el Webhook',
  },
  IntegrationsPage: {
    DialogHeader: 'Detalles de Integración',
    SubText: 'Las Integraciones solo están disponibles con un plan pagado.',
    Feedback: '¿Hay algún servicio de terceros o integración que le gustaría ver? Háganos saber en ',
    FeaturedIntegrations: 'Integraciones Destacadas',
    FeaturedIntegration: 'Integración Destacada',
    AllIntegrations: 'Todas las Integraciones',
    Tags: {
      BusinessListing: 'Publicación de Negocios',
      Accounting: 'Contabilidad',
      Tax: 'Impuestos',
      GiftCard: 'Tarjetas de Regalo',
      Payroll: 'Planillas',
      Scheduling: 'Planificación',
      Staff: 'Personal',
      Reviews: 'Reseñas',
      Partnership: 'Asociación',
      Marketing: 'Marketing',
      Reputation: 'Reputación',
      BusinessFunding: 'Financiamiento Empresarial',
      Insurance: 'Aseguradora'
    },
    planListDialog: {
      title: 'Actualice Payments Hub plan para obtener todo el acceso a las Integraciones.',
      subtitle: 'Las Integraciones sólo están disponibles en un plan de pago.'
    },
    Homebase: {
      SubText: 'Programación de empleados, hojas de tiempo y nómina para sus empleados.',
      UserInfo: 'página de {{labelText}}. Se sincronizarán automáticamente con Homebase.',
      TimeClockInfo: 'Sus empleados pueden acceder al reloj de tiempo de Homebase dentro de {{labelText}}.'
    },
    IntuitQuickBooksOnline: {
      SubText: 'Sincronice sus transacciones con su cuenta de Intuit QuickBooks Online.'
    },
    GiftCardInnerDetails: {
      Detail1: 'Suscríbete al programa de Tarjetas Regalo Factor4.',
      Detail2: 'Una vez suscrito, Factor4 se comunicará con usted dentro de 1 a 3 días hábiles para completar el proceso de configuración. Si lo prefieres, puedes contactar directamente con Factor4 a <1>sales@factor4gift.com</1> o al {{contactPhone}}.',
      Detail3: '¡Una vez que tenga tarjetas de regalo, inicie la aplicación Payanywhere para comenzar a vender y aceptar tarjetas de regalo!'
    },
    GiftCardSubscriptionText: 'No se puede cargar su cuenta. Vuelva a intentarlo más tarde o comuníquese con Atención al Cliente al',
    SubscriptionPending: 'Confirmación de subscripción pendiente',
    IntegratedAccessKeys: {
      Title: 'Llaves de Acceso Integradas',
      SubText: 'Integre con nuestra API configurando tokens de acceso'
    },
    TransactionsWebHook: {
      Title: 'Transacciones Web Hook',
      SubText: 'Integra un web hook para tus transacciones'
    },
    Chargezoom: {
      Card: {
        Title: 'Chargezoom',
        Text: 'Conecte sus pagos a QuickBooks en segundos con Chargezoom.'
      },
      Details: {
        Connect: 'Conectar',
        Connected: 'Conectado',
        DialogTitle: '¿Conectarse a Chargezoom?',
        DialogBody: 'Esto permitirá a Chargezoom crear una cuenta de Chargezoom para usted y acceder a sus transacciones, facturas, lotes e información de inventario.',
        Frequency: 'Frecuencia',
        Data: 'Datos',
        Includes: 'Incluye',
        Activate: 'Activar Ahora',
        VisitChargezoom: 'Visitar el Sitio Web de Chargezoom',
        VisitChargezoomPortal: 'Visitar el Portal de Chargezoom',
        DetailsTitle: 'Detalles',
        Line1: 'Elija su plan y haga clic en Activar ahora.',
        Line2: 'Recibirá un correo electrónico directamente de Chargezoom para completar la activación de la cuenta.',
        Line3: 'Una vez activado, se conectará a su cuenta de Intuit Quickbooks.'
      },
      Disconnect: {
        Title: '¿Está seguro de que desea desconectar?',
        Body: 'No tendrá acceso al Portal de Chargezoom y sus transacciones no estarán sincronizadas.'
      },
      BasicPlan: {
        Name: 'Sincronización Básica',
        Price: '$19.00/mes',
        Frequency: 'Sinc. diaria',
        Data: 'A nivel de lote',
        Includes: {
          CreditCardSales: 'Ventas Tarjetas Crédito',
          Credits: 'Créditos',
          Taxes: 'Impuestos',
          Tips: 'Propinas',
          Refunds: 'Reembolsos'
        },
        IncludesFooter: '*por lote'
      },
      AdvancedPlan: {
        Name: 'Sincronización Avanzada',
        Price: '$39.00/mes',
        Frequency: 'Sinc. cada 15 minutos',
        Data: 'A nivel de transacción',
        Includes: {
          Customers: 'Clientes',
          CreditCardSales: 'Ventas Tarjetas Crédito',
          CashSales: 'Ventas en Efectivo',
          Credits: 'Créditos',
          Taxes: 'Impuestos',
          Tips: 'Propinas',
          Refunds: 'Reembolsos'
        },
        IncludesFooter: '*por transacción'
      },
      errorMessage: 'Algo ha ocurrido mientras se desconectaba, intente de nuevo'
    },
    Davo: {
      Card: {
        Text: 'DAVO reserva el impuesto sobre las ventas diariamente, lo paga y lo presenta, a tiempo y en su totalidad - garantizado. La prueba de la primera presentación mensual es gratuita.'
      }
    },
    Yelp: {
      Card: {
        Text: 'Haz que te encuentren nuevos clientes que estén listos para comprar reclamando tu página de Yelp.'
      },
      Dialog: {
        Description: 'Reclame su perfil de Yelp y desbloquee funciones pagas para atraer más clientes',
        ClickedDescription: 'Logre que le encuentren nuevos clientes que estén listos para comprar al reclamar su página de Yelp.',
        Links: {
          ClaimYourBiz: 'Solicite su perfil de empresa gratuitamente',
          VisitYelp: 'Visite Yelp',
          TermsAndConditions: 'Ver términos y condiciones',
          PrivacyPolicy: 'Ver política de privacidad'
        },
        Features: {
          First: {
            Title: '¿Por qué los dueños de negocios deberían reclamar su página de Yelp?',
            Description: 'La información de su empresa es el corazón de su perfil comercial de Yelp, desde su dirección hasta sus especialidades. Actualizar su página hará que su negocio sea más atractivo para los clientes.'
          },
          Second: {
            Title: 'Haga que le encuentren nuevos clientes que estén listos para comprar al reclamar su página.',
            Description: 'Un promedio de 2,7 millones de personas visitan Yelp cada día para buscar servicios para el hogar, restaurantes y otros negocios. 4 de cada 5 usuarios contratan o compran en una empresa que encontraron en Yelp y las empresas con fotos, sitio web, número de teléfono y horario comercial enumerados en su página de Yelp promediaron 7,6 veces más visitas a la página por mes que las empresas sin ese contenido.'
          },
          Third: {
            Title: 'Una vez verificado, su negocio podrá:',
            List: {
              First: {
                Bold: 'Recibir consultas',
                Regular: 'de nuevos clientes potenciales mientras realizan búsquedas.'
              },
              Second: {
                Bold: 'Actualizar horas,',
                Regular: 'información de contacto, servicios y especialidades.'
              },
              Third: {
                Bold: 'Realizar seguimiento de la actividad del cliente',
                Regular: 'y acceder a insights competitivos',
                SecondaryRegular: 'Compartir fotos con describiendo su trabajo.'
              },
              Fourth: {
                Bold: 'Responder a reseñas,',
                Regular: 'y más'
              }
            }
          }
        }
      },
      TileText: 'Solicite su perfil de Yelp gratis'
    },
    Nav: {
      Card: {
        Text: 'Conozca para qué puede calificar antes de presentar la solicitud, desde la red más grande de soluciones de financiamiento para pequeñas empresas.'
      },
      IntegrationTile: {
        Text: 'Conozca sus opciones; Obtenga financiamiento - con Nav.',
        Action: 'Echarle un vistazo'
      },
      Details: {
        Description: 'Conozca para qué puede calificar antes de presentar la solicitud, desde la red más grande de soluciones de financiamiento para pequeñas empresas.',
        Options: 'Vea sus opciones en Nav',
        DetailedDescription: {
          TopTitle: 'Con la confianza de más de 2 millones de pequeñas empresas',
          Title: 'Aplique con confianza: ',
          Text: 'Solo las opciones de financiamiento que son importantes para su pequeña empresa, sin tanta búsqueda. Sepa cuál es la situación financiera de su empresa y vea para qué puede calificar antes de presentar la solicitud. Nav tiene la red más grande de soluciones financieras que incluye más de 160 opciones de los nombres más confiables en préstamos y tarjetas de crédito para pequeñas empresas. No más pérdida de tiempo y rechazos frustrantes.',
          List: {
            Title: '¿Por qué Nav?',
            First: {
              Title: 'Confiable: ',
              Text: 'Nav ha conectado empresas con más de 110.000.000 de financiamiento y 500.000 tarjetas de crédito sólo en 2023. Somos la plataforma líder en salud financiera para pequeñas empresas.'
            },
            Second: {
              Title: 'Transparente: ',
              Text: 'La PYME promedio dedica 26 horas a solicitar crédito. Ahorre tiempo sabiendo para qué puede calificar antes de presentar la solicitud'
            },
            Third: {
              Title: 'Extensivo: ',
              Text: 'Con más de 160 opciones de financiamiento y más de 220 soluciones comerciales, nunca tendrá que ir a ningún otro lugar para encontrar las soluciones adecuadas para su negocio.'
            }
          }
        },
        Actions: {
          VisitNavWebsite: 'Visitar sitio web de Nav',
          Connect: 'Conectar',
          VisitNavPortal: 'Visitar el Portal de Nav',
          ViewTermsAndConditions: 'Ver los Términos y Condiciones',
          ViewPrivacyPolicy: 'Ver la Política de Privacidad'
        }
      }
    },
    Next: {
      IntegrationTile: {
        Text: 'Seguro comercial de NEXT',
        Action: 'Aprender más'
      },
      Card: {
        Text: 'Proteja su negocio con un simple y asequible seguro comercial de una compañía 100% dedicada a ayudar a que sus clientes prosperen.'
      },
      Dialog: {
        Description: 'Next ofrece un simple y asequible seguro comercial hecho a la medida para 1,300+ profeciones de pequeñas empresas. Obtenga cero complicaciones y grandes ahorros.',
        Subtext: 'Obtenga una cotización de NEXT a continuación.',
        Links: {
          GetQuote: 'Consiga una cotización',
          VisitNextPortal: 'Visite el Portal de Next Insurance',
          VisitNextWebsite: 'Visite el Sitio de Next Insurance',
          TermsAndConditions: 'Ver Términos y Condiciones',
          PrivacyPolicy: 'Ver Políticas de Privacidad'
        },
        Features: {
          First: {
            Title: 'Seguros comerciales importan',
            Description: 'Proteger su empresa y sus empleados es fundamental. El seguro comercial proporciona una red de seguridad financiera en caso de que su empresa sea responsable de accidentes o errores. NEXT le facilita obtener la cobertura que necesita para mantenerse seguro.'
          },
          Second: {
            Title: 'Obtenga la cobertura que necesita',
            Description: 'Puede obtener una cotización y comprar cobertura de NEXT en línea en tan solo 10 minutos. Ellos ofrecen responsabilidad general, compensación laboral, propiedad comercial y otras coberturas más importantes para pequeñas empresas. Además, su seguro puede iniciar inmediatamente.'
          },
          Third: {
            Title: 'Por qué Next?',
            List: {
              First: {
                Bold: '100% dedicados a la pequeña empresa:',
                Regular: 'Obtenga una cobertura asequible diseñada para empresas como la suya.'
              },
              Second: {
                Bold: 'La aplicación móvil de NEXT:',
                Regular: 'Administre su cobertura las 24 horas del día, los 7 días de la semana usando la aplicación',
                SecondaryRegular: 'o su cuenta de cliente en línea.'
              },
              Third: {
                Bold: 'Servicio fenomenal:',
                Regular: 'Next se dedica a ayudar a los clientes a prosperar y',
                SecondaryRegular: 'cuenta con asesores en EE. UU. cuando necesita ayuda.'
              },
              Fourth: {
                Bold: 'Con la confianza de 500.000 clientes:',
                Regular: 'Las pequeñas empresas otorgan a Next 4,75 de 5 estrellas en calificaciones.'
              }
            }
          }
        },
        ConnectButton: 'Obtenga una Cotización'
      },
    }
  },
  ImportItems: {
    UploadMessage: '',
    MaxFileSize: '5MB',
    ItemLimit: 'Límite recomendado de 3000 elementos.'
  },
  PaymentshubTier: {
    PremiumPrice: '$14.95/mes.',
    OptOutOfPremiumContent: 'Al optar por no participar, cambia al plan gratuito y ya no tendrá acceso a la facturación, la facturación recurrente, los suministros y envíos gratuitos y la garantía del terminal.',
    UpgradeToPremiumContent: '¿Quieres actualizar a Premium por {{premiumPrice}}?',
    AccessInvoiceContentOwner: 'Esta característica es exclusiva para los miembros del Plan Premium. Actualice ahora para enviar su primera factura.',
    AccessInvoiceContentNonOwner: 'Esta función es exclusiva del Plan Premium. Póngase en contacto con el Propietario para actualizar y enviar su primera factura.',
    AccessSuppliesContent: 'Esta característica es exclusiva para los miembros del Plan Premium. Actualice ahora para obtener suministros gratis.',
    AccessReputationManagementContent: 'Esta característica es exclusiva para los miembros del Plan Premium',
    AccessLoyaltyProgramContent: 'Esta característica es exclusiva para los miembros del Plan Premium',
    NotificationTitle: 'Está inscrito en una prueba gratuita de 60 días del Plan Premium.',
    NotificationEpxContent: 'Premium incluye funciones avanzadas que pueden ayudar a impulsar su negocio, incluida la facturación con opciones recurrentes, suministros gratuitos y una garantía en las terminales de pago.',
    NotificationGlbContent: 'Premium incluye funciones avanzadas que pueden ayudar a impulsar su negocio, incluidos suministros gratuitos y una garantía en las terminales de pago.',
    NotificationBaseContent: 'Una vez que finalice su prueba gratuita, se le inscribirá automáticamente en el plan Premium. Si ya no desea recibir los beneficios ofrecidos a través del Plan Premium, puede cancelar su suscripción en Cuenta.',
    SuccessfullyEnrolled: 'Ha actualizado con éxito su plan a Premium.',
    SuccessfullyOptedOut: 'Ha cancelado con éxito la opción Premium y ha cambiado al plan gratuito.'
  },
  CardPresent: {
    Activity: {
      ConnectionConnecting: 'Conectando...',
      MissingDriver: 'Dispositivo no conectado.',
      MissingDevice: 'Lector de tarjetas no conectado.',
      Connecting: 'El lector de tarjetas se está conectando.',
      Connected: 'El lector de tarjetas está conectado.',
      Disconnecting: 'El lector de tarjetas se está desconectando.',
      Disconnected: 'El lector de tarjetas está desconectado.',
      Unknown: 'Buscando lectores de tarjetas.',
      ReadyToUse: 'Listo para usar',
      UseCard: 'Inserte, toque o deslice la tarjeta.',
      IsProcessing: 'Lectura de datos de la tarjeta',
      NeedsInput: 'Nosotros necesitamos algo de información',
      Processed: 'Tarjeta leída con éxito',
      TransactionComplete: 'Transacción completa',
      LocalAction: 'Esperando Respuesta',
      NeedsUpgrade: 'La versión actual no se puede actualizar automáticamente.'
    },
    ActivityDetails: {
      ConnectionConnecting: 'Intentando conectarse de forma segura al lector de tarjetas',
      MissingDriver: 'Abra la aplicación del lector de tarjetas Payanywhere y haga clic en "Descargar aplicación" para usar la versión negra del lector Payanywhere 2 en 1 o 3 en 1 con la terminal virtual. La versión blanca del lector 3 en 1 Payayanywhere (D135) no es compatible.',
      MissingDevice: 'Asegúrese de que su lector de tarjetas esté enchufado o encendido.',
      Connecting: 'Espere un momento mientras nos conectamos a este lector.',
      Connected: 'Seleccione "Iniciar Lector" para aceptar un pago.',
      Disconnecting: 'Este lector de tarjetas se está desconectando.',
      Disconnected: 'Asegúrese de que su lector de tarjetas esté enchufado o encendido.',
      Unknown: 'Asegúrese de que su lector de tarjetas esté enchufado o encendido.',
      ReadyToUse: 'Haga clic en "Iniciar Lector" para iniciar una transacción.',
      Processed: 'Espere un momento mientras completamos esta transacción.',
      LocalAction: 'Estamos esperando una respuesta del lector de tarjetas.',
      NeedsUpgrade: 'Elimine la versión instalada actual y haga clic en el botón de descarga para obtener la última versión.'
    },
    ActivityActions: {
      Download: 'Descargar Aplicación',
      Connect: '(¿El Dispositivo ya está funcionando?)',
      UpgradedConnect: '(¿El Dispositivo ya está actualizado?)',
      StartReader: 'Iniciar Lector'
    }
  },
  PaymentSeetingsTitle: 'Configuración de pago.',
  PaymentSeetingsSubTitle: 'Establezca preferencias a nivel de cuenta, como el monto de impuestos predeterminado, la discrepancia de AVS, etc. Esta configuración se aplica a los pagos en transacciones de Payanywhere, Terminal virtual y Factura.',
  ClosingManual: 'Ha optado por cerrar manualmente las transacciones para su liquidación cada día. Su financiación puede verse afectada según el momento en que cierre sus transacciones. Para cambiar a Cierre Automático, comuníquese con Servicio al cliente al 877.387.5640.',
  ClosingAuto: 'Su cuenta está configurada para enviar automáticamente transacciones para su liquidación cada día a las {{autoCaptureTime}} ET. Para editar su hora de cierre o cambiar a Cierre manual, comuníquese con Servicio al cliente al 877.387.5640.',
  VirtualTerminal: {
    IsMobileTitle: 'Terminal Virtual no disponible',
    IsMobileContent: 'La Terminal Virtual no esta soportada en dispositivos móviles. Para aceptar un pago usando la Terminal Virtual, inicie sesión en su escritorio. O procese pagos en su dispositivo móvil con la app Payanywhere.',
    CashDiscountInfo: 'El monto del descuento en efectivo se aplicará automáticamente en la transacción. Por ejemplo, si ingresa $100, con un monto de descuento en efectivo del 4%, a su cliente se le cobrará $104.'
  },
  RefreshTokenDialog: {
    Title: 'Su sesión está a punto de expirar.',
    Content: 'Para mantener la seguridad, su sesión está a punto de expirar. ¿Desea permanecer conectado?',
    TimeRemaining: 'Segundos Restantes'
  },
  RefreshInvoiceToken: {
    Title: 'Su sesión ha expirado.',
    Content: 'Haga clic en el botón a continuación para enviar un nuevo enlace de factura a su correo electrónico o número de teléfono móvil.',
    ButtonLabel: 'Enviar nuevo enlace'
  },
  RefreshInvoiceLinkSent: {
    Title: '¡Enlace enviado!',
    Content: 'Vigila tu bandeja de entrada. Su nuevo enlace de pago está en camino.'
  },
  BizSettings: {
    FormTitle: 'Perfil de Negocio.',
    FormSubtitle: 'Debe verificar su identidad antes de editar su perfil comercial.',
    SaveSettingsButton: 'Guardar ajustes'
  },
  AppSettings: {
    FormTitle: 'Configuración de Pago.',
    FormSubtitle: 'Establezca preferencias a nivel de cuenta, como el monto de impuestos predeterminado, la discrepancia de AVS, etc. Esta configuración se aplica a los pagos en transacciones de Payanywhere, Terminal virtual y Factura.',
    Closing: {
      Manual: 'Cerrará manualmente las transacciones para su liquidación cada día. Su financiación puede verse afectada según el momento en que cierre sus transacciones. Establezca un tiempo de Lote Automático a continuación si desea que sus transacciones se cierren automáticamente.',
      Auto: 'El Cierre del Host es la configuración predeterminada para Payanywhere. Esto significa que Payanywhere cerrará automáticamente sus transacciones en función de su selección de financiación: Financiación al día siguiente a las 8:30 p.m. ET; Financiamiento el mismo día a las 10:30 a.m. ET. \n Para cambiar a Cierre Manual, comuníquese con Servicio al Cliente al 877.387.5640.',
      ManualPrintReport: 'A las 3 am hora local, este dispositivo imprimirá automáticamente las transacciones que cerró. No se incluyen transacciones cerradas en otros dispositivos. Visite Payments Hub para obtener informes más detallados.',
      AutoPrintReport: 'A las 3 am hora local, su(s) dispositivo(s) imprimirá(n) automáticamente el informe de cierre más reciente. De forma predeterminada, este informe incluye todas las transacciones cerradas en todos los dispositivos de esta cuenta. Esta función está disponible solo para Payanywhere Smart Solutions. Vaya a la aplicación Payanywhere → Configuración → Configuración de cierre para una personalización adicional de esta función.',
      ManualCaptureTime: 'Ingrese una hora en la que sus transacciones se cerrarán automáticamente en caso de que elija no cerrar manualmente.'
    },
    CashDiscountDescription: 'El descuento por pronto pago lo ayuda a reducir las tarifas de procesamiento. Esto no se aplica a las transacciones de pago dividido. Asegúrese de informar a sus clientes que tiene precios en efectivo versus a crédito. Consulte con su socio de ventas si necesita cambiar su cantidad/porcentaje fijo o si desea participar en el programa.',
    EdgeProgramDescription: 'El programa Edge combina precios de tarifa plana con descuento en efectivo para ayudarlo a obtener el mismo margen de ganancias en pagos que no son en efectivo que en pagos en efectivo.',
    EdgeDialogFirst: 'Una vez que habilite el programa Edge, se aplican las siguientes reglas: Su precio se establecerá en 3.8461%. Se aplica un monto de descuento en efectivo del 4% a las transacciones que no son en efectivo. Nota: Todas las transacciones y lotes abiertos deben cerrarse antes de que entren en vigencia los cambios de precio y descuento por pronto pago.',
    EdgeDialogSecond: 'Si elige deshabilitar este programa, volverá a su precio anterior de forma predeterminada. Consulte con su socio de ventas si tiene preguntas sobre este programa.',
    EdgeTerms: 'Al tocar Enviar, acepta los términos de precios del programa Edge. Se aplican todos los demás términos y condiciones de su Solicitud de Comerciante y Acuerdo Progresivo de Comerciante.',
    EdgeDialogFirstOptOut: 'Si elige deshabilitar este programa, volverá a su precio anterior de forma predeterminada. Nota: Todas las transacciones y lotes abiertos deben cerrarse antes de que entren en vigencia los cambios de precio y descuento por pronto pago.',
    EdgeTermsOptOut: 'Al tocar Enviar, acepta volver a su precio anterior y deshabilitar el descuento por pronto pago. Se aplican todos los demás términos y condiciones de su Solicitud de Comerciante y Acuerdo Progresivo de Comerciante.',
    CantDoEdge: 'Consulte con su socio de ventas o llame a Servicio al Cliente al 877.387.5640 si desea participar en este programa.',
    CantDisableEdge: 'Consulte con su socio de ventas o llame a Servicio al cliente al 877.387.5640 para optar por no participar en el programa Edge.'
  },
  StoreSettings: {
    FormTitle: 'Tienda en línea',
    FormSubtitlePartOne: 'Habilite una tienda en línea para vender artículos de su inventario. Edite su logo, información de contacto comercial y nombres de usuario en redes sociales en ',
    FormSubtitlePartTwo: '.',
    FormSubtitleLink: 'Configuración de recibos y facturas',
    FormEnableStore: 'Habilitar tienda en línea',
    FormPreviewStore: 'Vista previa',
    FormStoreURL: 'URL de la tienda en línea',
    FormBusinessName: 'nombre-del-negocio',
    FormEnableShippingOption: 'Habilitar la opción de envío',
    FormShippingDisclaimer: 'Usted, como comerciante, es responsable de calcular los costos de envío y el cumplimiento.',
    FormErrorsLabel: 'El formulario está incompleto. Por favor revise sus cambios.',
    StorePreview: {
      Title: 'Vista previa de la tienda en línea',
    }
  },
  Tin: {
    TinContent: 'El Servicio de Impuestos Internos (IRS, por sus siglas en inglés) exige que se informen anualmente las transacciones con tarjetas de crédito y débito para las empresas mediante un formulario de información del IRS llamado 1099K. Para realizar presentaciones precisas ante el IRS, su nombre de presentación de impuestos y el Número de identificación fiscal (TIN) deben coincidir. ',
    TinImportantText: 'No proporcionar información que coincida con los registros del IRS puede resultar en multas o retención de respaldo exigida por el gobierno federal. También se puede aplicar la retención estatal adicional.',
    K1099Primary: 'Si no ve ningún 1099K disponible para descargar, entonces su cuenta no tiene ningún 1099K actualmente disponible o no cumple con los criterios exigidos por el IRS.',
    K1099Secondary: 'Si no puede obtener una copia de su Formulario 1099K, comuníquese con Servicio al Cliente al 877.464.4218. ',
    K1099Contact: 'Si necesita una corrección a su Formulario 1099K, contáctenos en'
  },
  TinFaq: {
    Question1: '¿Cuándo puedo esperar recibir mi Formulario 1099-K anual?',
    Answer1: {
      Option1: 'Los formularios de impuestos 1099-K se envían por correo anualmente en enero, con matasellos no posterior al 31 de enero y enviados por correo a la dirección registrada para su cuenta.',
      Option2: 'Las copias de 1099-K están disponibles en Payments Hub aproximadamente a mediados de febrero.'
    },
    Question2: '¿Cómo puedo recibir un 1099-K para todas mis cuentas?',
    Answer2: {
      Option1: 'Si tiene varias ubicaciones con el mismo nombre de presentación de impuestos y el mismo número de identificación de contribuyente, solo se emitirá un Formulario 1099-K. En este caso, incluirá el volumen de procesamiento combinado para todas las ubicaciones.'
    },
    Question3: '¿Qué necesito saber sobre mi 1099-K?',
    Answer3: {
      Option1: 'El documento 1099-K se basa en las ventas brutas y no tiene en cuenta las devoluciones, los reembolsos, las devoluciones de cargo, las reversiones, las tarifas o el intercambio. Debido a que el 1099K se basa en el volumen bruto de ventas, es posible que los montos del volumen bruto de ventas en el 1099K no coincidan con los montos de depósito que aparecen en su extracto bancario. Esto puede explicar cualquier discrepancia que haya encontrado entre su volumen de ventas y los montos informados en el 1099-K. Si aún cree que hay una discrepancia en su 1099-K, proporcione detalles específicos sobre los meses y montos en disputa.'
    }
  },
  TwoFactorAuth: {
    Header: 'Verifica que eres tú',
    Verification: {
      MissingNumber: 'Parece que no tenemos un número de móvil registrado para usted. Agregue su número de teléfono móvil en Configuración de seguridad antes de realizar cualquier cambio en su cuenta.',
      Error: 'Ha introducido un código de verificación incorrecto.',
      ErrorRetry: 'Hubo un problema al validar tu código, por favor solicita uno nuevo.'
    }
  },
  Banking: {
    AccountTypeHint: 'Tipo de Cuenta',
    AccountTypeLabel: 'Tipo de Cuenta',
    BankAccountHint: '0000000000000',
    BankAccountLabel: 'Cuenta Bancaria',
    BillingBankAccountTitle: 'Cuenta Bancaria para Facturación',
    ChargebackBankAccountTitle: 'Cuenta Bancaria para Recargo',
    Checking: 'De Cheques',
    EditBankAccount: 'Editar cuenta bancaria {{editButton}}',
    EditBankingNote: 'Debe verificar su identidad antes de editar su información bancaria. Tenga en cuenta que los cambios realizados aquí no se reflejan si sus pagos de American Express o Discover están separados, o si procesa utilizando Authorize.net. Comuníquese con American Express, Discover y Authorize.net directamente para actualizar su información bancaria con esas plataformas.',
    EditButton: {
      Settlement: 'primaria',
      Chargeback: 'contracargo',
      Billing: 'facturación'
    },
    NoData: 'No se encontró Información Bancaria - por favor contacte a Servicio al Cliente al número {{phoneNumber}}',
    PrimaryBankAccountTitle: 'Cuenta Bancaria Primaria',
    RoutingNumberHint: '0000000000000',
    RoutingNumberLabel: 'Número de Ruta',
    Savings: 'De Ahorros',
    Title: 'Información Bancaria.',
    UpdateAllBankAccountsNote: 'Utilice esta cuenta para todos los tipos de cuentas bancarias'
  },
  savings: 'ahorros',
  checking: 'cheques',
  Tooltips: {
    Banking: {
      Settlement: 'Esta es la cuenta donde se depositan los fondos de las transacciones completadas',
      Chargeback: 'Esta es la cuenta donde se retiran los fondos cuando un cliente disputa una transacción y se inicia una devolución de cargo.',
      Billing: 'Esta es la cuenta donde se retiran los cargos a su cuenta, como alquileres de equipos.'
    },
    Owners: {
      Support: 'Para actualizar, agregar o eliminar la información del Propietario, comuníquese con su socio de ventas. Si no está asociado con un socio de ventas, comuníquese al 866.485.8999.'
    },
    PendingVerification: 'Click aquí para reenviar la validación del email.',
    PhoneNumber: 'Para editar el número de teléfono en el que desea recibir códigos de seguridad, haga clic en Habilitar autenticación de dos factores.',
    OutOfStock: 'Este artículo no se encuentra disponible.',
    LowOfStock: 'Quedan pocas unidades de este artículo.',
    MoreOfStock: 'Las unidades seleccionadas son más que las disponibles para este artículo.',
    AccountClosed: 'Esta cuenta ya está cerrada.'
  },
  OpenTransactions: {
    TipExceedsLimit: 'La cantidad de propina excede el límite permitido.'
  },
  RefundsMessages: {
    RefundExceedsLimit: 'La cantidad total excede el límite permitido. Seleccione "Reembolsar todo" o cambie el monto del reembolso manualmente.',
    RefundCustomAmountExceedsLimit: 'La cantidad total excede el límite permitido.',
    RefundAll: 'Reembolsar el monto total de la transacción original, teniendo en cuenta los descuentos y reembolsos parciales existentes. Si está deshabilitado, el monto del reembolso se basa solo en los artículos individuales seleccionados.'
  },
  Terms2FADialog: {
    Header: 'Verifica que eres tú',
    MerchantHaveNoPhoneNumber: 'No pudimos encontrar ningún número de móvil en nuestros registros asociado con esta cuenta. Comuníquese con Atención al Cliente al ',
    DescriptionWithPhone: 'Ingrese su número de teléfono móvil completo que termina en {{lastDigits}} para recibir un código de verificación. Una vez que haya verificado, puede aceptar pagos en línea con nuestra terminal virtual. Se pueden aplicar tarifas por mensajes y datos. ',
    DescriptionNoPhone: 'Ingrese su número de teléfono móvil para recibir un código de verificación. Una vez que haya verificado, puede aceptar pagos en línea con nuestra terminal virtual. Se pueden aplicar tarifas por mensajes y datos.',
    ProcessFinishedSuccess: 'Has verificado correctamente tu identidad. Cierre la sesión y vuelva a iniciar sesión para obtener acceso a la terminal virtual.',
    ProcessFinishedFailureByToken: 'Has alcanzado el número máximo de intentos con el código de verificación. Serás desconectado momentáneamente. Comuníquese con Atención al Cliente al ',
    ProcessFinishedFailureByTokenAdd: ' para verificar su identidad y obtener acceso a la terminal virtual.',
    ProcessFinishedFailureByPhone: 'El número que ingresó no coincide con nuestros registros. Comuníquese con Atención al Cliente al ',
    ProcessFinishedFailureByPhoneAdd: ' para agregar su número de teléfono móvil y verificar su identidad.'
  },
  MerchantVerifyByCSDialog: {
    Title: 'Verificación de comerciante',
    Text: '¿El comerciante ha sido verificado por Servicio al Cliente?'
  },
  InvoiceDetailModal: {
    ResendInvoice: {
      Title: 'Reenviar factura',
      CancelText: 'No, vuelve',
      ConfirmText: 'Sí, reenviar factura',
      Children: '¿Está seguro de que desea volver a enviar esta factura?'
    },
    SendInvoice: {
      Title: 'Enviar la factura',
      CancelText: 'No, vuelve',
      ConfirmText: 'Si, enviar factura',
      Children: '¿Estás seguro de que quieres enviar esta factura?'
    },
    MarkAsPaid: {
      Title: 'Marcar como pagado',
      ConfirmText: 'Sí, marcar como pagado',
      CancelText: 'No, vuelve',
      Children: '¿Está seguro? El pago no se cargará a la tarjeta de su cliente. Esta acción no se puede deshacer.'
    },
    EditPaymentAmount: {
      Title: 'Editar monto del pago',
      SubTitle: 'El descuento por pronto pago y los impuestos no se incluyen cuando edita el monto del pago.',
      Children: null
    },
    CancelInvoice: {
      Title: 'Cancelar factura',
      ConfirmText: 'Sí, cancelar',
      CancelText: 'No, no cancelar',
      Children: `
        ¿Está seguro de que desea cancelar esta factura?
        Esta acción no se puede deshacer.
        Su cliente será notificado de esta cancelación.
      `
    },
    CancelSeries: {
      Title: 'Cancelar serie',
      ConfirmText: 'Sí, cancelar serie',
      CancelText: 'No, no cancelar',
      Children: `
        ¿Está seguro de que desea cancelar esta serie recurrente de facturas?
        Esta acción no se puede deshacer y detendrá todos los pagos futuros
        de ser cobrado. Su cliente será notificado de esta cancelación.
      `
    },
    ViewAllActivities: {
      Title: 'Actividad de la {{section}}',
      ConfirmText: 'Cerrar',
      CancelText: null,
      Children: null
    }
  },
  QuickbooksAccount: {
    Title: 'Verifique su cuenta de QuickBooks',
    Text: 'Hemos detectado un problema con su cuenta de QuickBooks Online. Verifique la configuración de su cuenta en el sitio web de Quickbooks.'
  },

  // Virtual Terminal Forms

  VirtualTerminalSettings: 'Configuración de Terminal Virtual',
  VirtualTerminalSettingsDescription: 'Personaliza y define qué campo deseas en tu Terminal Virtual. Esto se aplica tanto a la Terminal Virtual personalizada como a la detallada. El campo personalizado se muestran en Detalles e Informes de Transacciones.',
  CustomFields: 'Campo personalizado',
  Field: 'Campo',
  OpenField: 'Campo abierto (entrada de texto)',
  FieldName: 'Ingrese el nombre del campo',
  AddSelection: 'Agregar Selección',
  IssueAcredit: 'Emitir un crédito',
  VirtualTerminalFormTitles: {
    ItemizedSale: 'Crear una venta detallada.',
    OnePayment: 'Aceptar una cantidad personalizada.',
    PaymentLink: 'Crear Enlace de Pago'
  },
  VirtualTerminalFormHeading: 'TIPO DE PAGO',
  DebtRepayment: 'Pago de Deuda',
  ItemizedSale: 'Venta al Detalle',
  IncludeLevel2: 'Incluir Datos Nivel 2',
  IncludeLevel3: 'Incluir Datos Nivel 3',
  CardDetails: 'Ingresar Detalles de la Tarjeta',
  UseCardReader: 'Usar Lector de Tarjeta',
  BillingStreet: {
    Label: 'Número de Calle a Facturar',
    HintText: 'Opcional'
  },
  ZipCode: {
    Label: 'Código Postal',
    HintText: 'Opcional'
  },
  ZipCodePlus: {
    Label: 'Código Postal + 4',
    HintText: 'Opcional'
  },
  SaveCreditCard: {
    Label: 'Guardar este método de pago en el perfil de cliente para futuras compras',
    Notice: {
      Responsibility: 'Es su responsabilidad obtener el permiso por escrito de su cliente para guardar su método de pago.',
      Acknowledgement: 'Al agregar un método de pago, reconoce que es responsable de cualquier transacción rechazada o contracargo.'
    }
  },
  AvsNotice: 'Ingresar el número de la calle y el código postal puede ayudar con posibles disputas de los clientes.',
  Category: {
    Label: 'Categoría',
    NoCategory: 'Sin Categoría'
  },
  CustomAmount: 'Monto Personalizado',
  SaleAmount: 'Monto de la Venta',
  InvoiceNumber: {
    Label: 'Factura #',
    HintText: 'Opcional',
    HintRequiredText: 'Ingresar número de factura'
  },
  Taxable: 'Gravable',
  CashDiscounting: 'Descuento en Efectivo',
  Description: {
    Label: 'Descripción',
    HintText: 'Describir los bienes o servicios vendidos.'
  },
  AdditionalData: 'Datos Adicionales',
  UnitMeasure: 'Unidad de Medida',
  RequestorName: 'Nombre del Solicitante',
  SelectCountry: 'Seleccionar país',
  ShipmentZipCode: 'Código Postal de Envío',
  DestinationZipCode: 'Código Postal de Destino',
  EmailOrMobile: {
    Label: 'Dirección de correo electrónico o número de celular',
    HintText: 'Usar una coma o espacio para agregar varios'
  },
  SelectExistingCustomer: 'Seleccionar client existente',
  SelectExistingPaymentMethod: 'Seleccionar el Método de Pago Existente',
  EnterCardDetails: 'Ingrese los Detalles de la Tarjeta',
  CustomerName: 'Nombre del Cliente',
  CustomerLastName: 'Apellido del Cliente',
  Purchases: 'Compras',
  PurchasesItemizedSale: '(Seleccione un artículo para editar)',
  Searching: 'Buscando',
  NoItems: 'Sin artículos',
  NoRememberedDevices: 'No hay dispositivos recordados',
  TaxNote: 'El Rango de Impuesto por defecto es {{taxRate}}. Configure el impuesto en <3>Configuración.</3>',
  AddExpressItem: 'Agregar artículo express',
  NoBarCode: 'Sin Código de Barras',
  InStock: 'en inventario',
  OutStock: 'Agotado',
  NotTracked: 'Sin rastrear',

  // Disputes

  DisputesDetail: {
    NoticeDate: 'Fecha de notificación {{date}}',
    DateFormat: 'DD MMM, YYYY',
    ActionRequired: 'Acción requerida',
    ResponseRequired: 'Se requiere una respuesta de usted (comerciante) para el {{dueDate}} o este caso será automaticamente aceptado a su nombre',
    UnderReview: 'En revisión',
    NabReview: 'NAB está revisando su respuesta para enviarla al banco del cliente. Esté atento a las actualizaciones en los correos electrónicos ya que se pueden solicitar documentos adicionales.',
    Closed: 'Disputa Cerrada',
    Reason: 'Motivo',
    ReasonCode: '(Código del motivo: {{code}})',
    CaseNumber: 'Número del caso: {{case}}',
    Details: 'Detalles',
    OriginalTransaction: 'Transacción original',
    TransactionAmount: 'Monto de la transacción {{amount}}',
    InvoiceDate: 'Factura del {{date}}',
    Documents: 'Documentos',
    AssociatedNotices: 'Avisos asociados',
    AssociatedComments: 'Comentarios asociados',
    CommentInfo: 'Fecha de creación: {{createdDate}} - Hecho por {{madeBy}}',
    DisputedAmount: 'Monto disputado {{amount}}',
    CompellingEvidence: 'Ver preguntas frecuentes sobre evidencia convincente',
    Invoice: 'Factura {{number}}'
  },


  // Virtual Terminal Form Item Selection

  DisabledMultipleSelections: 'Selecciones múlltiples deshabilitadas',
  RemoveOrder: 'Remover de la orden',
  SelectPrice: 'Seleccione precio',
  ExpressModifiers: 'Modificadores express',
  AddExpressModifier: 'Agregar modificador express',
  AddExpressDiscount: 'Agregar descuento express por artículo',
  Percent: 'Porcentaje',
  ExpressItem: {
    Title: 'Artículo Exprés',
    ItemNameLabel: 'Nombre del Artículo',
    ItemNameHint: 'Describir los bienes o servicios vendidos',
    ItemPriceLabel: 'Precio del Artículo',
    ItemPriceHint: '$0.00',
    TaxRateHint: '00.000'
  },
  Order: 'orden',

  // Virtual Terminal Out of Stock Dialog

  OutOfStockItemDialogTitle: 'Precio Agotado',
  OutOfStockItemDialogBody: 'El precio seleccionado para este artículo está agotado. ¿Está seguro de querer agregarlo al carrito?',

  // Payment Method Select
  SelectPaymentMethod: 'Seleccionar el Método de Pago',
  NoPaymentMethodsAdded: 'No se agregaron métodos de pago',
  EnterCardManually: 'Ingresar tarjeta manualmente',
  SendToCustomer: 'Enviar al cliente',
  AddBankingAccount: 'Agregar cuenta bancaria',

  // Attach Files Form
  AttachFilesFormError: {
    GiactExceedsLimit: 'Tamaño del archivo excede el límite',
    ExceedsLimit: 'Tamaño del archivo excede el límite o el format noes aceptado y no puede ser subido.'
  },
  AttachFilesFormInputLabel: 'Archivos adjuntos',
  IconHolderText: 'Arrastre aquí',
  Browse: 'Subir archivos',
  AcceptedFiles: 'Formato de archivos aceptados: doc o docx, xls o xlsx, ppt o pptx, jpg o jpeg, gif, tif o mtif, pdf, txt, html.\nMáximo de tamaño del archivo 5 MB',
  DisputesFilesInputLabel: 'Comentarios',
  RebutDispute: 'Refutar disputa',
  NoDisputes: 'Sin Disputas',

  Import: 'Importar',
  Upload: 'Subir',
  ImportInvoices: 'Importar Facturas',
  Download: 'Descargar',
  DownloadTemplate: 'Descargar la plantilla.',
  MaxImageFileSize: 'Tamaño máximo de archivo para imágenes',
  UploadFile: 'Suba su archivo.',
  UploadInventory: 'Suba su inventario',
  CompleteYourFile: 'Complete su plantilla.',
  CompleteYourFileInstructions: 'Complete la plantilla con la información de sus facturas. Asegúrese de completar todos los campos y dejar la primera fila como encabezado.',
  ViewInstructions: 'Ver Instrucciones.',
  DragTemplate: 'Arrastre su plantilla completa al espacio de abajo, o haga clic en "Examinar" para ubicarla. Debe ser un archivo CSV (.csv)',
  DropFile: 'Arrastre su plantilla o haga clic en "Examinar" para ubicarla',
  DropFileFormat: 'Debe ser un archivo CSV (.csv), tamaño máximo 5MB',
  FileUploaded: '¡Archivo subido!',
  UploadTemplate: 'Subir plantilla',
  ScrollViewItems: ' Desplazarse para ver todos los elementos.',
  Progress: 'Progreso',
  ImportInventory: 'Importar inventario',
  ImportCSV: 'Importar usando CSV',
  ImportedFileUploaded: 'Archivo {{fileName}} cargado. Haga clic en Importar',
  ImportInProgress: 'Importación de {{fileName}} en curso.',
  ImportSmallerFile: '{{fileName}} tiene más de 3000 elementos. Cargue un archivo más pequeño',
  ImportInvalidItem: '{{item}} contiene datos de campo no válidos.',
  ImportFinished: 'Importación finalizada.',
  ImportedSuccessfully: '{{dataName}} importado con éxito',
  ImportFailed: 'Falló la importación de {{dataName}}.',
  OnDropFile: {
    ImportFailed: 'No se pudo importar el archivo.',
    ExceedsSize: 'No se pudo importar el archivo. El archivo excede el tamaño permitido.',
    InvalidType: 'No se pudo importar el archivo. Formato de archivo inválido, solo se permiten archivos .csv.',

  },
  ImportInvalid: 'Algunas importaciones fallaron. Esto podría estar relacionado con el formato de archivo utilizado. Asegúrese de utilizar el formato de archivo correcto o descargue el archivo de plantilla.',
  RequiredName: 'Columna de nombre inválida o faltante',
  RequiredFavorite: 'Columna favorita inválida o faltante',
  RequiredTaxable: 'Imponible no válido o faltante',
  RequiredTrackable: 'Columna rastreable inválida o faltante',
  RequiredStock: 'Columna agotada inválida o faltante',
  RequiredPrice: 'Se requieren uno o más precios',
  RequiredPriceName: 'Se requiere el nombre del precio y el monto en dólares para el precio de cada artículo',
  CreatedCategory: 'Se creó la categoría {{name}}',
  CreatingCategoryError: 'Error al crear la categoría {{name}}',

  // Receipt Settings
  Logo: 'Logo',
  UploadLogo: 'Cargar logo',
  PreviewReceipt: 'Vista previa del recibo',
  PreviewEmail: 'Vista previa del email',
  PreviewPayment: 'Vista previa del portal de pago',
  BusinessInformation: 'Información de negocio',
  BusinessDescription: 'Personalice la información de negocio que se muestra en los recibos de los clientes, las facturas y las páginas web orientadas al cliente.',
  IncompleteSettingsForm: 'El formulario está incompleto. Por favor revise sus cambios',
  SaveSettings: 'Guardar ajustes',


  // Receipt Settings Form
  ReceiptSettingsForm: {
    CustomerFaceLogo: 'Logo a mostrar al cliente',
    ThisLogo: 'Utilice este logotipo para la pantalla orientada al cliente',
    DifferentLogo: 'Use un logotipo diferente para la pantalla orientada al cliente',
    BusinessName: 'Nombre del negocio',
    BusinessNameHint: 'Nombre',
    BrandColor: 'Color de la marca',
    StreetAddress: 'Dirección de calle',
    StreetAddressHint: 'Dirección',
    ZipCode: 'Código postal',
    BusinessPhone: 'Teléfono del negocio',
    Website: 'Sitio web',
    BusinessEmail: 'Correo electrónico del negocio',
    FacebookUsername: 'Nombre de usuario de Facebook',
    InstagramUsername: 'Nombre de usuario de Instagram',
    XUsername: 'Nombre de usuario de X',
    YelpUsername: 'Nombre de usuario de Yelp',
    SocialHintText: 'Nombre de usuario',
    ReceiptSettings: 'Configuración de recibos',
    ReceiptSettingsDesc: 'Personalice la configuración específica de los recibos digitales e impresos.',
    BCCEmailReceipts: 'Recibos de correo electrónico de BCC',
    BCCEmailAddress: 'Dirección de correo electrónico de BCC',
    EmailHintText: 'Ingrese la dirección de correo electrónico',
    CustomerRating: 'Habilitar calificaciones de clientes',
    LogoPrintedReceipt: 'Mostrar el logotipo en el recibo impreso',
    ESN: 'Requerir ESN o número de código de barras',
    SocialMediaButtons: 'Mostrar botones de redes sociales en el recibo digital',
    DisplaySoldBy: 'Mostrar vendido por',
    CustomFooterMessage: 'Mensaje de pie de página personalizado',
    MessageHint: 'Mensaje',
    Options: 'Opciones de la pantalla de recibo',
    Display: 'Mostrar siempre la pantalla Opciones de recibo',
    SkipPrint: 'Omitir la pantalla Opciones de recibo e Imprimir de forma predeterminada',
    Skip: 'Omitir la pantalla Opciones de recibo y No imprimir',
    SelectOption: 'Por favor seleccione una opción',
  },

  PaymentPortalPreview: {
    OpenInvoices: 'Facturas abiertas',
    PaymentMethods: 'Métodos de pago',
    PaymentHistory: 'Historial de pago',
    SessionExpired: 'Sesión Expirada',
    BackToInvoices: 'Regresar a facturas abiertas',
    InvoiceAmount: 'Monto de la factura: $241.68',
    PayBy: 'Pagar antes del 10 de junio, 2021',
    PaymentMethod: 'Método de pago',
    PayWithCreditCard: 'Pagar con tarjeta de crédito',
    CardHolderName: 'Nombre del titular de la tarjeta',
    CardNumber: 'Número de tarjeta',
    Expiration: 'Vencimiento',
    SavePaymentMethodDesc: 'Guarde este método de pago y cobre automáticamente los pagos futuros en esta serie.',
    NoTip: 'Sin propina',
    Other: 'Otro',
    PayAmount: 'Pagar $241.68',
    TravelPackage: 'Paquete de Viaje (3 de 12)',
    BillTo: 'Factura a Michael Benedict',
    InvoiceNumber: 'Factura #1088',
    Date: '10 de mayo, 2021',
    RepeatsMonthly: 'Se repite mensualmente hasta el 5/10/2022',
    MonthlyTravelKit: 'Kit de Viaje Mensual (1)',
    VacationPackagePayment: 'Pago de Paquete Vacacional (1)',
    VacationPlace: 'Roma y Costa de Amalfi',
  },

  ReceiptPreview: {
    InvoiceNumber: 'Factura 1807',
    InvoiceDate: '26/9/22 a las 4:01 pm',
    CookiesItem: 'Galletas x1',
    SugarItem: 'Azucar',
    EspressoItem: 'Espresso x1',
    Double: 'Doble',
    ConPana: 'Con Pana',
    SoldTo: 'Vendido a James Centro',
    CardInfo: 'Visa 8339 (Pago sin contacto)',
    AuthCode: 'Código de autorización 88381H',
    ExperienceQuestion: '¿Cómo fue su experiencia?',
    LoyaltyHeaderFirstPart: '¡Ha ganado una recompensa de',
    LoyaltyHeaderSecondPart: 'de descuento!',
    LoyaltyText: 'Presente el código QR o código de descuento en su siguiente compra.'
  },

  InvoicePreview: {
    BillTo: 'Factura para',
    InvoiceNumber: 'Número de factura',
    Invoice: 'Aqui está su factura por',
    PayBy: 'Pagar antes del',
    PayNow: 'Pagar ahora',
    SaleAmount: 'Monto de la venta',
    RepeatsEvery: 'Se repite cada',
    RepeatsTime: 'los',
    Description: 'Detalle:'
  },

  // Dispute Detail
  UploadEvidence: 'Subir pruebas convincentes',

  // Client Service Form
  ClientServiceForm: {
    MidLabel: 'mid',
    MidHint: 'mid',
    KnowYouAreNow: 'Ahora tú eres:',
    Back: 'Volver',
    NoSprContainerError: 'Página Incorrecta.',
    NoSprContainerInstruction: 'Esta no es la página que estás buscando',
    NotFound: 'No encontrado',
    ViewingMid: 'Viendo el MID',
    CsPortalImpersonation: 'Interpretación de CS Portal',
  },

  // DemoMode
  DemoMode: {
    Title: 'Pon tu negocio en funcionamiento en minutos.',
    SubTitle: 'Estás en modo demostración. Activa tu cuenta para aceptar pagos y utilizar todas las funciones.',
  },

  // Funding
  FundingComponent: {
    ConfirmFundingDialog: {
      Title: '¿Cambiar Financiamiento?',
      Text: 'Esta a punto de cambiar sus opciones de financiamiento.'
    },
    ImgVerificationDialog: {
      Text: 'Ha cambiado su cuenta de banco {{type}}. Edita los otros tipos de cuenta según sea necesario. Las actualizaciones bancarias tomarán efecto una vez sean verificadas.',
      Primary: 'primaria',
      Chargeback: 'de cargos',
      Billing: 'de cobros'
    },
    AddFilesDialog: {
      Title: 'Verificación de Cuenta Bancaria',
    },
    UploadErrorDialog: {
      Title: 'Error de Subida de Archivo',
      Text: 'Hubo un error al subir su archivo, por favor intente de nuevo'
    },
    SuccessfullySavedFundingDialog: {
      Title: 'Guardado',
      Text: 'Opción de Financiamiento Guardada Exitosamente'
    }
  },

  // Funding Form
  FundingForm: {
    NameOnCard: 'Nombre en la Tarjeta',
    DebitCardNumber: 'Número de Tarjeta de Débito',
    StreetNumber: 'Número de Calle',
    ZipLabel: 'Código Postal',
    ZipHint: 'Código Postal',
    InstantFundingLabel: 'Habilitar Financiamiento Instantánea',
    InstantFundingSubText: 'Reciba financiamiento por hasta $5,000 por día en una tarjeta de débito calificada dentro de los 15 minutos. Costo: 1% de la transacción.',
    Heading: 'Financiamiento.',
    SubText: 'Decide cómo obtienes tu dinero y vincula tus cuentas bancarias.',
    SecondarySubText: 'Método de Financiación Predeterminado',
    NextDayFundingLabel: 'Financiamiento al día siguiente',
    NextDayFundingLabelSubText: ' (predeterminado) ',
    NextDayFundingSubText: 'Reciba financiamiento antes de las 10 am del día siguiente para transacciones procesadas antes de las 10 pm ET.',
    SameDayFundingLabel: 'Financiamiento el mismo día',
    SameDayFundingSubText: 'Reciba financiamiento el mismo día por hasta $50,000 por día. El tiempo exacto de depósito puede variar; comuníquese con su banco para obtener información sobre su cronograma de depósitos. Las transacciones deben procesarse y cerrarse antes de las 10:30 am ET / 7:30 am PT para calificar.'
  },

  // Last Login Activity
  LastLoginActivityHeader: 'Última actividad de ingreso',

  // Employee Table Headers
  EmployeeTable: {
    Headers: {
      Status: 'Estado',
      Name: 'Nombre',
      Role: 'Rol',
      LastLogin: 'Último Ingreso'
    }
  },

  // Employee Detail
  EmployeeDetailTitle: {
    ArchivedEmployee: 'Empleado Archivado',
    DetailsEmployee: 'Detalles del Empleado',
    EditEmployee: 'Editar Empleado',
  },
  EmployeeDetailOption: {
    Reactivate: 'Reactivar',
    Archive: 'Archivar'
  },
  EmployeeDetailResendInvoice: {
    Header: 'No Activado',
    Description: 'Este usuario no ha activado su cuenta. Haga clic abajo para reenviar la invitación. Las invitaciones son válidas por 30 minutos.',
    Resend: 'Reenviar ahora',
  },
  EmployeeDetailNewEmployee: {
    InfoHeader: 'Activando la Nueva Cuenta de Empleado',
    Description: 'Los nuevos empleados recibirán un enlace por correo para activar su cuenta.  Por razones de seguridad, un nuevo enlace debe ser reenviado si la cuenta no ha sido activada en los próximos 30 minutos.',
  },
  EmployeeDetailLastLogin: 'Última ubicación de ingreso',
  EmployeeDetailDetail: 'Detalle',
  EmployeeDetailAccountLocked: 'Cuenta: Bloqueada',
  EmployeeDetailAccountActive: 'Cuenta: Activa',
  EmployeeDetailAddedBy: 'por {{name}}',
  EmployeeDetailAddedDateBy: 'Agregado el {{date}}',
  EmployeeDetailLastAppLogin: 'Último Ingreso desde Aplicación Móvil el {{date}} a las {{time}}',
  EmployeeDetailLastUpdate: 'Última actialización el {{date}} a las {{time}}',
  EmployeeDetailDuplicateErrorDialogTitle: 'Usuario ya existente',
  EmployeeDetailArchiveConfirmationDialog: {
    Title: 'Archivar empleado.',
    Text: '¿Está seguro que desea mover este empleado al archivo? Puede reactivar este empleado en cualquier momento. Este empleado seguirá apareciendo en los reportes históricos.',
  },
  EmployeeUnlockRateLimit: 'Desbloqueo de limitación de intentos',
  EmployeeDetailForm: {
    Save: 'Guardar',
    Role: 'Rol',
    Roles: {
      Owner: 'Dueño',
      Admin: 'Administrador',
      Manager: 'Gerente',
      Cashier: 'Cajero',
      Reporter: 'Reportero',
      Report: 'Reportero',
      'Customer support': 'Servicio al Cliente',
    },
    FirstName: 'Nombre',
    LastName: 'Apellido',
    PhoneNumber: 'Número de Teléfono'
  },

  // Profile
  CsSettings: {
    AgentAccessStatus: 'Estado del Acceso de Agente:',
    Cannabis: 'Cannabis:',
    CanViewApplication: 'Puede Ver la Aplicación:',
    CashDiscountEnabled: 'Descuento en Efectivo habilitado:',
    CashDiscountFixedAmount: 'Monto fijo de descuento por pronto pago:',
    CashDiscountPercentAmount: 'Porcentaje de descuento por pronto pago:',
    CBD: 'CBD:',
    CsSettingsWithToggle: 'Habilitar notificaciones automáticas:',
    CsVerified: 'SC Verificado',
    DebtRepaymentAllowed: 'Reembolso de Deuda Permitido:',
    HasOptedOut: 'Ha rechazado:',
    HBT: 'HBT:',
    IsEdgeUser: 'Es usuario Edge:',
    IsISV: 'Es ISV:',
    AutoReportingEnabled: 'Habilitar Auto Reportes:',
    LargeVolumeProcessor: 'Procesador de Gran Volumen:',
    PlanOptedOut: 'Plan rechazado:',
    PlanType: 'Tipo de plan:',
    PremiumFree: 'Premium gratis:',
    ProcessingLevel: 'Nivel de procesamiento:',
    Salido: 'Salido:',
    SmsEnabled: 'SMS habilitado:',
    VtEnabled: 'TV habilitado:',
    ReputationManagement: 'Gestión de Reputación Activo:',
    LoyaltyVpc: 'Programa de Lealtad Activo:',
    LoyaltyStatus: 'Estado de Programa de Lealtad:',
    LoyaltyStatusWithToggle: 'Programa de Lealtad Habilitado:',
    LavaEnabled: 'Lava Activo:',
    DAVOStatus: 'Integración de DAVO Conectado:',
    DAVOStatusWithToggle: 'Accesso a la Integración de DAVO:'
  },
  CsSettingsTitle: 'Información relacionada con el Servicio al Cliente',
  EditPersonalProfile: 'Editar perfil personal',
  EmailCommunicationDialogContent: 'Revise su bandeja de entrada en {{username}} y verifique su dirección de correo electrónico para recibir notificaciones de disputas.',
  Enrolled: 'Inscrito el {{enrolledDate}}',
  Free: 'Gratis',
  MembershipDialogContent: 'Por favor contactar al Dueño.',
  MembershipRemaining: 'Días de Prueba: {{daysRemaining}} restantes',
  MerchantApplicationContent: 'Haga clic para ver su solicitud de comerciante. Si tiene alguna pregunta sobre la precisión o la integridad de su solicitud, comuníquese con Atención al cliente al {{customerServicePhone}} o {{customerServiceEmail}}. Si no recibimos noticias suyas, concluiremos que por la continuidad de procesamiento de transacciones que su solicitud de comerciante es precisa y completa.',
  MerchantApplicationDownload: 'Ver su aplicación de comerciante',
  MerchantProcessingAgreementDownload: 'Ver el Acuerdo de Procesamiento Comercial',
  MerchantApplicationHeading: 'Solicitud de comerciante',
  MerchantProcessingAgreementHeading: 'Acuerdo de Procesamiento Comercial',
  OptedOut: 'Rechazado',
  OptOutPremiumAccess: 'Rechazar acceso premium',
  OptOutPremiumPlan: 'Rechazar el plan premium',
  Premium: 'Premium',
  SecuritySettings: 'Configuración de seguridad',
  UnableEdit: 'No se puede editar',
  UnableEditContent: 'No pudimos verificar su identidad. Llame a Atención al cliente al {{phoneNumber}} para actualizar su número de teléfono móvil.',
  UpdateEmailAddress: 'Actualizar dirección de correo electrónico',
  UpdateManageRememberedDevices: 'Administrar dispositivos recordados',
  UpdatePassword: 'Actualizar contraseña',
  UpdatesPendingContent: 'Para propósitos de seguridad, las actualizaciones que usted haya hecho a su cuenta van a tener efecto cuando sean verificadas.',
  UpdatesPendingTitle: 'Actualizaciones Pendientes',
  Upgraded: 'Actualizado',
  UpgradePremiumAccess: 'Actualizar al acceso premium',
  UpgradePremiumPlan: 'Actualizar al Plan Premium',
  UsernameLabel: 'Nombre de usuario',
  UserSettingsTitle: 'Configuración de Usuario',
  VerifyEmailAddress: 'Verificar dirección de correo electrónico',
  UserLanguage: 'Idioma del Usuario',
  ManagePlan: 'Administrar plan',

  // Profile Form
  ProfileForm: {
    NameLabel: 'Nombre',
    NameHint: 'Nombre',
    DateOfBirthLabel: 'Fecha de Nacimiento',
    DateOfBirthHint: 'Fecha de Nacimiento',
    SsnLabel: 'NSS',
    SsnHint: 'NSS',
    HideSensitiveDataLabel: 'Ocultar datos sensibles',
    AddressLabel: 'Dirección',
    AddressHint: 'Dirección',
    SuiteAptLabel: 'Suite/Apt.',
    SuiteAptHint: 'Suite/Apt.',
    CityLabel: 'Ciudad',
    CityHint: 'Ciudad',
    StateLabel: 'Estado',
    StateHint: 'Estado',
    ZipLabel: 'Código Postal',
    ZipHint: 'Código Postal'
  },

  // Manage Remembered Devices
  ManageRememberedDevices: {
    Title: 'Dispositivos recordados',
    Description: 'Administre los dispositivos o navegadores que ha elegido recordar y que no requerirán un código de verificación al iniciar sesión.',
    DeviceType: 'Tipo de dispositivo',
    Location: 'Ubicación',
    Added: 'Agregado',
    AddedWithDate: 'Agregado {{date}}',
    Forget: 'Olvidar'
  },

  // Update Email Form
  UpdateEmailForm: {
    Description: 'Modificar su correo electrónico cambiará sus credenciales de inicio de sesión para los productos North, Payments Hub y Payanywhere.',
    NewEmailAddressLabel: 'Nueva Dirección de Correo Electrónico',
    NewEmailAddressHint: 'Digite la Nueva Dirección de Correo Electrónico',
    ConfirmNewEmailAddressLabel: 'Confirmar la Nueva Dirección de Correo Electrónico',
    ConfirmNewEmailAddressHint: 'Confirmar la Nueva Dirección de Correo Electrónico',
    EnterPasswordLabel: 'Digitar Contraseña',
    EnterPasswordHint: 'Contraseña Actual'
  },

  // Update Password Form
  UpdatePasswordForm: {
    CreateNewPassword: 'Crear Nueva Contraseña',
    Description: 'Modificar su contraseña cambiará sus credenciales de inicio de sesión para los productos North, Payments Hub y Payanywhere.',
    CurrentPasswordLabel: 'Contraseña Actual',
    CurrentPasswordHint: 'Digite su Contraseña Actual',
    NewPasswordLabel: 'Contraseña Nueva',
    NewPasswordHint: 'Digite su Contraseña Nueva'
  },

  // Communication Settings Form
  CommunicationSettingsForm: {
    AlertError: 'Alerta error de comunicación, intente actualizar de nuevo',
    Title: 'Configuración de comunicación.',
    SubTitle: 'Reciba alertas por correo electrónico sobre la actividad de su cuenta. Para obtener información detallada sobre la cuenta, consulte su Resumen de Actividad de Lote Diario y/o las capturas de los correos electrónicos diarios.',
    SettingsErrorBottom: 'El formulario está incompleto. Por favor revise sus cambios.',
    SubmitButton: 'Guardar ajustes',
    FeedbackTitle: '¿Tiene una alerta de correo electrónico específica que le gustaría ver en Payments Hub?',
    FeedbackText: 'Envíe su sugerencia a <anchor>{{feedbackEmail}}</anchor> y consideraremos su solicitud. Gracias por ayudarnos a mejorar continuamente Payments Hub.',
    CustomEmailAlertsSection: {
      Title: 'Alertas por Email Personalizadas',
      DailySummaryAlert: 'Resumen diario de actividad por lotes',
      CustomAlerts: 'Sin actividad de transacción',
      NoTransactionActivityHint: '0 Días',
      CustomEmailLabel: 'Enviar Alertas Personalizadas por correo a',
      CustomEmailHint: 'Dirección de correo',
      AdditionalEmailLabel: 'Dirección de Correo Adicional',
      AdditionalEmailHint: 'Dirección de correo adicional'
    },
    BasicAccountAlerts: {
      Title: 'Alertas de Cuenta Básicas',
      SubTitle: 'Las alertas básicas de la cuenta se envían únicamente a la dirección de correo electrónico comercial de esta cuenta. Para editar la dirección de correo electrónico de su empresa, comuníquese con Servicio al cliente al <phone>{{phoneNumber}}</phone>.',
      BusinessEmailLabel: 'Dirección de Correo Electrónico Comercial',
      DailySnapshotEmail: 'Captura de Email Diario',
      CommunicationsItemsDailySnapshotEmail: {
        Option1: 'No quiero recibir este correo electrónico.',
        Option2: 'Solo envíe este correo electrónico cuando tenga una retención en mi cuenta.',
        Option3WithEmail: 'Enviar diariamente al correo electrónico: {{emailAddress}}',
        Option3WithoutEmail: 'Enviar diariamente al correo electrónico.'
      },
      ManualCaptureBatchNotification: 'Notificación Manual de Captura de Lote',
      CommunicationsItemsClosingEmail: {
        Option1: 'No quiero recibir este correo electrónico.',
        Option2: 'Enviar diariamente al correo electrónico.'
      },
      TipAdjustment: 'Ajuste de Propina',
      DisputeNotifications: 'Notificaciones de Disputas',
      EnterEmail: 'Ingrese correo electrónico o varios separados con una coma',
      CommunicationsItemsDisputes: {
        Option1: 'Seleccione método de comunicación',
        Option2: 'Correo Electrónico: {{emailAddress}}',
        Option3: 'Por Correo Electrónico.'
      },
      CommunicationItems: {
        Option1: 'Seleccione método de comunicación',
        Option2: 'Correo Electrónico: {{emailAddress}}',
        Option3: 'Por Correo Electrónico.'
      },
      CommunicationDisclaimer: '*La opción de recibir estados de cuenta impresos agregará un cargo de $1.00 a su cuenta',
      InvoiceAlertsHeader: 'Alertas de factura',
      InvoiceAlertsDescription: 'Reciba una copia de todas las facturas y comunicaciones del cliente, con la excepción de los recibos de facturas pagadas.',
      InvoiceSent: 'Factura enviada',
      InvoicePaid: 'Factura pagada',
      InvoiceCanceled: 'Factura cancelada',
      SeriesEnrolled: 'Series inscritas',
      PaymentMethodUpdated: 'Método de pago actualizado',
      InvoicePaymentAmountUpdated: 'Monto de pago de factura actualizado',
      PaymentPending: 'Pago pendiente',
      PaymentFailed: 'Pago fallido',
      CardOnFileAdded: 'Tarjeta en archivo añadida',
      CardOnFileRemoved: 'Tarjeta registrada eliminada',
      CommunicationLoginNotification: 'Notificación de inicio de sesión',
      CommunicationItemsLoginNotification: {
        OptedIn: 'Enviar cuando un nuevo navegador o dispositivo inicia sesión.',
        OptedOut: 'No quiero recibir este correo electrónico.',
      },
    }
  },
  VerifyEmailAddressContent: 'Revise su bandeja de entrada en {{userName}} y verifique su dirección de correo electrónico para recibir comunicaciones de disputas por correo electrónico.',

  // Integrated Access Keys
  IntegratedAccessKeys: {
    GoBackButton: 'Todas las integraciones',
    LinkLikeText: 'Sitio del Desarrollador',
    SubText: 'Configure tokens de acceso para integrarse con nuestra API',
    Title: 'Tokens de Accesso Integrados'
  },

  // Tokens And Passwords
  TokensAndPasswords: {
    AlreadyProvided: 'Proporcionado',
    JsSskTokenLabel: 'Token de JS SDK:',
    ProxyAccessLabel: 'Acceso al Proxy:',
    ProxyAppSourceHeaderLabel: 'Origen del Header del Proxy App:',
    ProxyTokenLabel: 'Token de Proxy:',
    RecreateProxyAccessButton: 'Recrear el Acceso al Proxy',
    TooltipText: 'Esta etiqueta es para ser usada con el valor del header x-nabwss-appsource.'
  },

  // Access API Form
  AccessApiForm: {
    AccessJsSdkLabel: 'Acceso a JS SDK',
    AccessOurApiLabel: 'Acceso a nuestro API',
    AccessPayNowLabel: 'Acceso a Pay Now',
    JsSdkDomainLabel: 'Dominio JS-SDK',
    SemiIntegrationLabel: 'Semiintegración'
  },

  // Recreate Proxy Access Dialog
  RecreateProxyAccessDialog: {
    Title: 'Recrear Acceso al Proxy',
    ConfirmButton: 'Recrear',
    Message: '¿Está seguro que desea recrear el acceso al Proxy?'
  },

  // Business Form
  BusinessForm: {
    BusinessFormTitle: 'Detalles del Negocio',
    TransactionalTitle: 'Detalles Transaccionales',
    AverageMonthlyVolume: 'Volumen Mensual Promedio: ${{averageMonthlyVolume}}',
    AverageTicketAmount: 'Monto del Tiquete Promedio: ${{averageTicketAmount}}',
    BusinessContact: 'Contacto del Negocio',
    BusinessAddress: 'Dirección del Negocio',
    SuiteApt: 'Suite/Apt.',
    City: 'Ciudad',
    State: 'Estado',
    Zip: 'Código Postal',
    Phone: 'Teléfono del Negocio',
    Fax: 'Fax del Negocio',
    AdditionalCorporateInfoMessage: 'Tengo una dirección corporativa separada',
    CorporateAddress: 'Dirección Corporativa',
    CorporateSuiteApt: 'Suite/Apt. Corporativo',
    CorporateCity: 'Ciudad Corporativa',
    CorporateZip: 'Código Postal Corporativo',
    AdditionalShippingInfo: 'Tengo una dirección de envío separada',
    ShippingName: 'Nombre de envío',
    ShippingContact: 'Contacto de envío',
    ShippingAddress: 'Dirección de envío',
    ShippingSuiteApt: 'Suite/Apt. de envío',
    ShippingCity: 'Ciudad de envío',
    ShippingZip: 'Código Postal de envío',
    SetClosingTimer: 'Establecer Tiempo de Lote Automático',
    SelectTime: 'Seleccione el Tiempo'
  },

  // Owner
  Owners: {
    AddOwner: 'Agregar propietario',
    PercentageOwnership: '% de Propiedad',
    PriorityContact: 'Contacto prioritario:',
    Title: 'Propietarios',
    Update: 'ACTUALIZAR'
  },

  // Role Settings Form
  RoleSettingsHeading: 'Editar Rol',
  RoleSettingsHeadingManager: 'Configuración del gerente.',
  RoleSettingsDetailHeader: 'Opciones',
  RoleSettingsDetailHeaderManager: 'Los gerentes tienen acceso completo a la aplicación Payanywhere y acceso limitado a Payments Hub. Este rol no puede ver información personal o comercial vinculada a la cuenta.',
  RoleSettingsFields: {
    RefundVoidEnabledFieldLabel: 'Permitir a los Cajeros realizar Reembolzos y Anulaciones',
    RefundVoidLimitFieldLabel: 'Limite de Reembolzos y Anulaciones',
    RefundVoidLimitFieldInfoText: 'Ingresar una cantidad de dólares máximo que sus cajeros tienen permitido para anular o reembolzar. El monto debe ser mayor a $0.00.'
  },

  // Customers
  CustomerTable: {
    Email: 'Correo Electrónico',
    PhoneNumber: 'Número de Teléfono',
    Unnamed: 'Cliente Sin Nombre',
    CardOnFile: 'Método de pago guardado',
    ActiveReward: 'Recompensa Activa'
  },

  // Customer Detail
  CustomerDetailForm: {
    NewCustomer: 'Nuevo Cliente',
    EditCustomer: 'Editar Cliente',
    CreateInvoice: 'Enviar una Factura',
    Delete: 'Eliminar',
    TransactionHeader: 'Detalles',
    RatingHeader: 'Experiencia del Cliente',
    RatingText: 'Evaluación Promedio',
    RecurringHeader: 'Recurrente',
    AddPaymentMethod: '+ Agregar método de pago',
    SavedPaymentMethods: 'Métodos de Pago Guardados',
    AddPaymentMethodSuccess: 'Método de Pago Guardado',
    SaveCustomerSuccess: 'Cliente guardado con exito',
    AddPaymentMethodErrorTitle: 'No se puede agregar el método de pago.',
    AddPaymentMethodError: 'Confirme que la información ingresada es correcta',
    CardDeleteConfirmationDialog: {
      Title: '¿Está seguro de que quiere eliminar el método de pago?',
      Body: 'Si este método de pago esta asociado con cualquier pago recurrente, ya no se le hará un cargo a esta método de pago.'
    },
    CustomerDeleteConfirmationDialog: {
      Title: 'Eliminar cliente.',
      Body: '¿Está seguro de que quiere eliminar este cliente? Esta acción no se puede deshacer.'
    },
    Fields: {
      FirstName: 'Primer Nombre',
      LastName: 'Apellido',
      PhoneNumber: 'Número de Teléfono',
      AccountNumber: 'Número de cuenta (Opcional)'
    },
    CustomerErrorDialog: {
      Title: 'No se pudo crear el cliente.',
      Content: 'Ya existe un cliente con esta información. Ingrese información de cliente única y vuelva a intentarlo.'
    },
    LoyaltyProgram: 'Loyalty Program',
    AddCC: 'Para agregar un método de pago, registre una dirección de correo electrónico o número de teléfono válidos con un nombre y/o apellido.',
    SeeTransactions: 'Ver Transacciones'
  },

  // Card On File
  CardOnFile: {
    Title: 'Agregar método de pago',
    SubTitle: 'Guarde los métodos de pago en el archivo para facturas recurrentes y pagos más rápidos.',
    Acknowledgement: 'Es su responsabilidad obtener el permiso por escrito de su cliente para guardar su tarjeta. Al agregar una tarjeta, reconoce que es responsable de cualquier transacción rechazada o contracargo.',
    Fields: {
      CardHolderName: 'Nombre del tarjetahabiente',
      CDigits: 'Número de Tarjeta de Crédito',
      EDate: 'Expiración',
      StreetNumber: 'Número de Calle de Facturación',
      CardZip: 'Zip',
      ZipPlus4: 'Zip + 4',
      Optional: 'Opcional'
    }
  },

  // Owner Form
  OwnerForm: {
    FirstNameLabel: 'Nombre',
    LastNameLabel: 'Apellido',
    SsnLabel: 'NSS',
    DateOfBirthLabel: 'Fecha de Nacimiento',
    ContactPriorityLabel: 'Contacto Prioritario',
    PercentageOfOwnership: 'Porcentaje de propietario',
    TitleLabel: 'Título',
    OwnerLabel: 'Dueño',
    PresidentLabel: 'Presidente',
    CeoLabel: 'CEO',
    VicePresidentLabel: 'Vicepresidente',
    PartnerLabel: 'Socio',
    ManagerLabel: 'Gerente',
    EmailLabel: 'Correo Electrónico',
    PhoneNumberLabel: 'Número de Teléfono',
    PhoneExtensionLabel: 'Extensión telefónica',
    AddressLabel: 'Dirección',
    SuitAptLabel: 'Suite/Apt.',
    CityLabel: 'Ciudad',
    StateLabel: 'Estado',
    ZipLabel: 'Código Postal',
    IsPersonalGuarantorLabel: '¿Es el Fiador Personal?',
    IsPrimaryContactLabel: '¿Es el Contacto Principal?'
  },

  // Webhook Form
  WebhookForm: {
    AccessWebhookLabel: 'Acceso al Enlace Web',
    WebhookDomainLabel: 'URL del Enlace Web'
  },

  // Transactions Webhook
  TransactionsWebhook: {
    TransactionsWebHookLabel: 'Transacciones del Enlace Web',
    TransactionsWebHookSubtext: 'Integrar un enlace web para sus transacciones'
  },

  // Merge Category Form
  MergeCategoryForm: {
    Legend: 'Combinar "{{itemName}}" con otra categoría',
    NewCategoryNameHint: 'Digite el nombre de la nueva categoría',
    NewCategoryNameLabel: 'Nuevo nombre de categoría',
    SelectCategory: 'Seleccione Categoría',
    SelectLabel: 'Categoría'
  },

  // Items
  ItemsDetail: {
    AddPrice: 'Agregar Precio',
    AddToCategory: 'Agregar a categoría',
    AddToFavorites: 'Agregar a favoritos',
    AllowOutOfStockSalesLabel: 'Habilitar Ventas Fuera de Inventario',
    ArchiveItem: 'Archivar Artículo',
    ArchiveItemTitle: 'Este artículo está archivado.',
    ArchiveItemDetail: 'Los artículos en su archivo no van a estar disponibles para la venta. Seleccione \"Reactivar este artículo\" abajo para mover este artículo de regreso a su inventario activo.',
    Barcode: 'Código de Barras',
    BarcodeNumber: 'Número de Código de Barras',
    Cost: 'Costo',
    DeleteDialogTitle: 'Archivar artículo.',
    DeleteDialogText_one: '¿Está seguro que quiere mover {{selectedItems}} artículo al archívo? Usted puede reactivar este artículo en cualquier momento. Este artículo va a serguir apareciendo en el reporte histórico.',
    DeleteDialogText_plural: '¿Está seguro que quiere mover {{selectedItems}} artículos al archívo? Usted puede reactivar estos artículos en cualquier momento. Estos artículos van a serguir apareciendo en el reporte histórico.',
    InStock: '{{itemsCount}} en inventario',
    ItemNameLabel: 'Nombre del Artículo',
    LowStockAlertHint: '0',
    LowStockAlertLabel: 'Alerta de Bajo Inventario',
    Modifiers: 'Modificadores',
    NewItem: 'Nuevo Artículo',
    NotTracked: 'Sin rastrear',
    NumberInStock: '# En Inventario',
    Options: 'opciones',
    OutOfStock: 'Fuera de inventario',
    PermanentlyDeleteItem: 'Artículo permanentemente eliminado',
    Price: 'Precio',
    Prices: 'Precios',
    PriceNameHint: 'Nombre del Precio',
    PriceNameLabel: 'Nombre',
    ReactivateLink: 'Reactivar este artículo',
    RemoveFromFavorites: 'Remover de favoritos',
    SalesTaxLabel: 'Impuesto de Ventas',
    SearchItems: 'Buscar Artículos',
    SelectCategory: 'Seleccionar Categoría',
    TrackItemLabel: 'Rastrear Artículo'
  },

  // Modifiers
  Modifiers: {
    AddModifier: 'Agregar Modificador',
    AllowMultipleSelections: 'Habilitar Selección Múltiple',
    CountModifiers: '{{itemCount}} modificadores',
    DeleteConfirmationText: '¿Está seguro que quiere eliminar este conjunto de modficadores?',
    DeleteModifierSet: 'Eliminar este conjunto de modificadores.',
    NewModifierSet: 'Nuevo Conjunto de Modificadores',
    NoData: 'Sin Conjunto de Modificadores',
    NumberOfModifiers: '# de Modificadores',
    ModifierNameLabel: 'Nombre de Modificador',
    ModifierSetName: 'Nombre del Conjunto de Modificadores',
    PriceLabel: 'Precio',
    SearchModifiers: 'Buscar Modificadores'
  },

  // PayOrder Form
  PayOrderForm: {
    Expiration: 'Expiración',
    BillingStreetNumber: 'Número de Calle de Facturación',
    Zip: 'CÓDIGO POSTAL',
    ZipPlus: 'CÓDIGO POSTAL + 4'
  },

  InvoiceForm: {
    AllowCustomerTip: 'Permitir al cliente agregar propinas',
  },
  //PayInvoiceForm
  PayInvoiceForm: {
    ReceiptSent: ' La factura será enviada por email a', // A receipt will be emailed to
    PaymentReceived: 'Tu pago ha sido recibido', //Your payment has been received.
    Paid: 'Pagado',
    CreditCard: 'Número Tarjeta de Crédito',
    Due: 'Pendiente',
    PayBy: 'Pagar antes de',
    Expiration: 'Fecha de Expiración',
    BillingStreet: 'Número de calle de facturación',
    PayNow: 'Pagar Ahora',
    InvoiceNotFound: 'Factura No Encontrada',
    RepeatsEvery: 'Se repite cada',
    Day: 'día',
    Week: 'semana',
    Month: 'mes',
    Year: 'año',
    Until: 'hasta',
    Off: 'de descuento',
    BillTo: 'Cobrar a',
    OrderSummary: 'Resumen del Pedido',
    InvoiceCancelled: 'Esta factura está cancelada y no se puede pagar.',
    CancelledInvoice: 'Factura cancelada',
    TooManyAttempts: 'Demasiados intentos',
    PaymentNotProcessed: 'Su pago no pudo ser procesado. Por favor contactar',
    DirectlyInformation: 'directamente para más información.',
    AutoPaySet: 'Tienes configurado el pago automático en esta factura',
    InvoiceProcess: 'Su factura se procesará el',
    AutomaticCharge: 'Cargue automáticamente esta tarjeta para pagos futuros en esta serie',
    AvsErrorText: 'Su pago no pudo ser procesado. Se ha enviado una notificación automática a {{support}} para contactarlo; Comuníquese directamente con la empresa para obtener más información.'
  },

  CustomerPayment: {
    SomethingWentWrongError: 'Lo sentimos, Algo salió mal. Por favor inténtelo de nuevo',
    Error: 'Error: {{error}}',
    ApplePayError: 'Apple Pay no puede ser usado para esta transacción. Por favor use otra tarjeta.'
  },

  OnlinePayment: {
    ProcessingPaymentError: 'Error al procesar el pago: {{error}}',
    SomethingWentWrongError: 'Lo sentimos, Algo salió mal. Por favor inténtelo de nuevo',
    Error: 'Error: {{error}}',
    ApplePayError: 'Apple Pay no puede ser usado para esta transacción. Por favor use otra tarjeta.',
    NoData: 'Factura no econtrada',
    ButtonAuthorize: 'Autorizar ahora',
    ButtonPayNow: 'Pagar ahora',
    BillTo: 'Cobrar a {{name}}',
    UnnamedCustomer: 'Cliente Sin Nombre',
    InvoiceName: 'Factura #{{invoice}}',
    DetailsDateFormat: 'DD MMMM, YYYY',
    OrderSummary: 'Resumen del Pedido',
    CanceledInvoiceBody: 'Esta factura ha sido cancelada y no puede ser pagada.',
    CanceledInvoiceTitle: 'Factura cancelada'
  },

  OnlinePaymentForm: {
    ThankYouText: 'Su pago ha sido recibido.',
    CompletedSaleId: 'completada - id de compra: {{saleId}}',
    Processed: 'procesada.',
    PaidWithDate: 'Pagado: {{date}}',
    PaidDateFormat: 'DD/MM/YYYY',
    PreAuthorized: 'Preautorizado: ',
    PreAuthorization: 'Su preautorización ha sido ',
    AppleInvoice: 'Factura {{invoice}} Subtotal',
    AppleTax: 'Impuestos',
    AppleTotal: 'Total',
    AppleTip: 'Propina',
    PreAuthorizationDue: 'Vencimiento de preautorización',
    Due: 'Pendiente',
    Field: {
      TipAmountLabel: 'Agregar Propina',
      TipAmountHint: '$0.00',
      EDateLabel: 'Expiración',
      EDateHint: 'MM/YY',
      CvvLabel: 'CVV',
      CidLabel: 'CID',
      StreetNumber: 'Número de calle de facturación',
      Zip: 'Zip',
      ZipPlus4: 'Zip + 4',
      ZipPlus4Hint: 'Opcional'
    },
    PayBy: 'Pague el {{date}}',
    PayByDateFormat: 'DD MMMM, YYYY',
    ViewOrderSummary: 'Ver resumen del pedido'
  },

  PaymentLinkForm: {
    Field: {
      AmountLabel: 'Ingresar monto a pagar',
      AmountHint: '$0.00',
      SelectAmountLabel: 'Seleccionar monto a pagar',
      SelectOtherAmount: 'Otro',
      CardHolderLabel: 'Nombre del titular',
      CardNumberLabel: 'Número de tarjeta',
    },
    Pay: 'Pagar',
    PayViaCreditCardOption: 'Pagar con Tarjeta de Crédito',
    TotalMayInclude: 'El total puede incluir impuestos y/o propina.',
    NotFoundTitle: 'Ups.',
    NotFoundLine1: 'Este enlace de pago ya no está activo o nunca existió.',
    NotFoundLine2: 'Las cosas mejorarán.',
    Success: {
      Title: 'Gracias por tu pago!',
      Subtitle: 'Un recibo ha sido enviado a {{email}}',
      DoAnother: 'Hacer Otro Pago'
    },
    Declined: {
      Title: 'Pago Rechazado',
      Subtitle1: 'No fue posible completar la transacción.',
      Subtitle2: 'Por favor intenta con otro método de pago.',
      LockedSubtitle: 'Intenta de nuevo mas tarde.',
      UnsuccessfulRecaptcha: 'Por favor, recarga la página e inténtalo de nuevo.'
    },
    RedirectionText: 'Serás redirigido en',
  },

  //TwoFactorForm
  TwoFactorForm: {
    '4DigitCode': 'Código de verificación',
    Enter4DigitCode: 'Ingrese el código',
    RequestNewCode: 'Solicitar nuevo código',
    MobileNumber: 'Número de teléfono móvil',
    EnterMobileNumber: 'Ingrese el número de móvil',
    EnableForLogin: 'Habilite la autenticación de dos factores para iniciar sesión',
    VerificationNotice: 'Antes de realizar cambios en su cuenta, nos gustaría verificar su identidad. Hemos enviado un código de verificación a su número de teléfono móvil que termina en',
    EnterCode: 'Ingrese el código enviado a tu número de móvil',
  },

  // Server Messages
  Server: {
    // Error Messages `src/config/error.json`
    Error: {
      ApiAccessBadRequest: 'Problema con la solicitud - por favor intente de nuevo, si continua ocurriendo por favor contacte a servicio al cliente',
      BadRequest: 'Solicitud Incorrecta, por favor revisa que la información enviada no tenga duplicados o información faltante',
      DuplicateEmployee: 'Empleado Duplicado',
      DuplicateName: 'Ya ha ingresado un nombre que ya existe. Por favor intente de nuevo con un nombre diferente.',
      CreateServerPdfFileError: 'Error de Servidor: Creando PDF',
      EquipmentOrderError: 'Hubo un error enviando la petición del Lector de Tarjeta, por favor llame a Servicio al Cliente al 877.387.5640 para asistencia.',
      NotFound: 'Portal: No Encontrado',
      Unauthorized: 'No Autorizado',
      NoUsername: 'Nombre de usuario no suministrado',
      NoCredentials: 'Credenciales no suministradas',
      NoResponse: 'Este servicio no se encuentra disponible en este momento. Por favor intente de nuevo.',
      NoStatementsFound: 'No se encontraron declaraciones.',
      NoStatementPdfFound: 'Hubo un problema recuperando esta declaración.',
      NoMerchantApplicationPdfFound: 'No se encontró una solicitud de comerciante.',
      NoSchedulesFound: 'No se encontraron horarios.',
      NoReceiptFound: 'Incapaz de encontrar un recibo en este momento.',
      UnsuccessfulRequest: 'Credenciales incorrectas. Por favoe intente de nuevo.',
      UnsuccessfulSignInPA: 'Lo sentimos, su usuario o su contraseña son incorrectas. Por favor intente de nuevo. Note: Los Cajeros no pueden acceder Payanywhere Inside.',
      UnsuccessfulSignInPH: 'Lo sentimos, su usuario o su contraseña son incorrectas. Por favor intente de nuevo. Note: Los Cajeros no pueden acceder Payments Hub.',
      DuplicateEmail: 'El correo electrónico esta siendo utilizado por otra cuenta. Por favor intente de nuevo con otro correo electrónico.',
      LockedAccount: 'Esta cuenta esta inactiva o bloqueada. Por favor llame a Servicio al Cliente.',
      LockedAccountRateLimit: 'Este usuario ha sido bloqueado temporalmente por razones de seguridad. Puede volver a intentarlo en 30 minutos.',
      UnsuccessfulMBPMigration: 'Lo sentimos, algo salió mal mientras se intentaba migrar sus datos de MBP.',
      MidsAlreadyExistForUser: 'Los MIDs solicitados ya fueron adjuntados a este usuario.',
      UnsuccessfulSignInNoMerchantAccounts: 'Este usuario no tiene acceso a este sitio.',
      UnsuccessfulSignInDemoAccountInvalidLabel: 'Active su cuenta en north.paymentshub.com',
      UnsuccessfulReCapture: 'El reCAPTURE ingresado no es válido, por favor intente de nuevo.',
      UnsuccessfulReCaptchaV3: 'ReCaptcha ha detectado tu actividad como la de un bot. Por favor, inténtalo de nuevo.',
      UnsuccessfulInvoicePaymentToken: 'Declinado: Error con el token - por favor solicite una factura nueva.',
      NotImplemented: 'No Implementado.',
      UnsuccessfulSendSms: 'Error: enviando SMS',
      UnsuccessfulSendEmail: 'Error: enviando Correo Electrónico',
      UnsuccessfulResetPassword: 'Número de Cuenta o SSN no puede ser verificado, o el token de reinicio de contraseña ya no es válido. Por favor intente de nuevo.',
      UnsuccessfulSecurityPassword: 'Por favor escoja otra contraseña. La contraseña es muy común o fue utilizada recientemente.',
      UnsuccessfulSetPassword: 'El token de establecer contraseña ya no es válido. Por favor intente de nuevo.',
      ValidateTokenError: 'Error de Servidor: Validando el Token',
      UpdateTransactionError: 'Error de Servidor: No se puede actualizar la transacción',
      UpdateDisputeError: 'Error de Servidor: No se puede actualizar la disputa',
      UpdateRoleError: 'Error de Servidor: No se puede actualizar el rol',
      ItemsPayloadTooLarge: 'ERROR: La carga es muy grande: por favor intente con menor tamaño',
      ItemsParsingError: 'ERROR: Ha habido un problema entregando todos sus artículos',
      Idology: 'Una o mas respuestas están equivocadas, por favor intente de nuevo',
      GetIdology: 'Incapaz de obtener preguntas',
      DecryptingTokenError: 'Error de servidos desencriptando el token',
      CommunicationGetError: 'Incapaz de encontrar preferencias de comunicación.',
      CommunicationPostError: 'Por favor revise su información e intente de nuevo, o contacte a Servicio al Cliente al 877.387.5640.',
      TokenValidationError: 'Error de Validación del Token',
      ApkChecksumError: 'Error del Servidor: generando suma de verificación apk ',
      ApkGetManifestInfoError: 'Error del Servidor: generando apk getManifestInfo ',
      PlanUpgradeError: 'Error del Servidor: Incapaz de mejorar su plan.',
      TryingForgotPassword: 'Si esta cuenta existe, un correo ha sido enviado; si no recibe un correo en los próximos 15 minutos intente de nuevo.',
      TwoFactorLoginError: 'Error validando el código de dos factores - por favor intente de nuevo',
      InternalProcessingError: 'Lo sentimos, error de procesamiento interno.',
      ExpiredToken: 'Token Expirado',
      InvalidCSRF: 'Hemos actualizado nuestra aplicación. Por favor refresque su navegador.',
      InvalidCharacters: 'Nombre de Usuario únicamente permite letras (a-z), números (0-9), puntos (.), símbolo de más (+), guión bajo(_) y guión (-).'
    }
  },

  // PayAnywhere Supplies
  PayAnywhereSupplies: {
    AddToOrderLabel: 'Agregar a la orden',
    ErrorMessage: 'Error: Por favor digite un número válido dentro del límite.',
    NoSuppliesFoundLabel: 'No hay Suministros encontrados',
    Order: 'Orden:',
    OutOfStockLabel: 'Fuera de inventario',
    ShowSuccessDialogTitle: 'Orden Procesada',
    SuccessDescription: 'Su pago ha sido recibido. Un recibo va ser enviado al email de {{username}}'
  },

  // Supplies Table
  AllotmentsTable: {
    ItemNameLabel: 'Nombre del Artículo',
    OrderedLabel: 'Ordenados',
    RemainingLabel: 'Restantes'
  },

  // Allotments
  AllotmentsPage: {
    NoAllotmentsFound: 'No hay Asignaciones encontradas'
  },

  // Order History
  OrderHistoryPage: {
    NoOrdersFoundLabel: 'No hay Órdenes',
    Orders: 'Órdenes',
    Items: 'artículos',
    OrderId: 'Orden #{{id}}'
  },

  // Order History Table
  OrderHistoryTable: {
    ItemsOrderedLabel: 'Artículos Ordenados',
    OrderDateLabel: 'Fecha de Orden',
    OrderIdLabel: 'Orden #',
    OrderTotalLabel: 'Total de la Orden',
    ShippingDateLabel: 'Fecha de Envío',
    TrackingLabel: 'Tracking',
  },

  // Order History Details
  OrderHistoryDetails: {
    Details: 'Detalles',
    ItemsOrdered: 'Artículos Ordenados: {{itemsOrdered}}',
    NoData: 'No hay órdenes seleccionadas',
    OrderDate: 'Fecha de Orden: {{orderDate}}',
    OrderDetailsHeader: 'Detalles de la orden',
    OrderDetailsHeading: 'DIRECCIÓN DE ENTREGA',
    OrderDetailsPurchases: 'Compras',
    OrderInvoiceNumber: 'Factura {{invoiceNumber}}',
    OrderNumber: 'Orden #{{orderNumber}}',
    OrderShippingDate: 'Fecha de Entrega: {{shippingDate}}',
    OrderTrackingNumber: 'Tracking #{{trackingNumber}}',
    OrderType: 'Tipo de Orden: {{orderType}}',
    Shipping: 'ENVÍO',
    TotalDue: 'Total Adeudado'
  },

  // Order Summary
  OrderSummaryPage: {
    Checkout: 'Comprar: {{checkoutAmount}}',
    ClearCartButton: 'Vaciar Carrito',
    OrderSummaryTitle: 'Resumen de orden'
  },

  // Checkout Supply
  CheckoutSupplyPage: {
    DebitMyAccountLabel: 'Débito a Mi Cuenta.',
    DebitMyAccountSubLabel: 'El total adeudado se debitará de su cuenta de comerciante.',
    DeliverTo: 'Entregar a',
    OrderCheckoutTitle: 'Comprar Suministros',
    PaymentMethod: 'Método de Pago',
    PlaceOrderButton: 'Realizar Pedido',
    ShippingMethod: 'Método de Transporte'
  },

  // ShippingAddress
  Business: 'Negocio',
  Corporate: 'Corporativo',
  Shipping: 'Transporte',

  // Checkout Hardware
  CheckoutHardwarePage: {
    AmountDue: 'Monto Adeudado: {{amountDue}}',
    OrderCheckoutTitle: 'Comprar Hardware',
    PayTitle: 'Pagar con Tarjeta',
    PaymentError: 'Error al procesar el pago: {{paymentError}}',
    SorryPaymentError: 'Lo sentimos, algo salió mal, inténtalo de nuevo'
  },

  // Electronic Return Labels
  ElectronicReturnLabels: {
    Export: 'Exportar',
    NoData: 'No hay Etiquetas de Devoluciones',
    ReturnLabelName: 'Etiqueta de Devolución #{{rmaId}}',
    ReturnLabelHeaderText: 'Coloque el equipo antiguo en una caja, imprima y coloque la etiqueta de devolución electrónica en la caja y déjelo en una ubicación de FedEx.',
    ReturnLabelHeaderLocations: 'Encuentre ubicaciones de FedEx participantes'
  },

  // Supplies Page
  SuppliesPage: {
    NoData: 'No hay suministros disponibles',
    OrderProcessed: 'Orden Procesada',
    OrderSummaryDialogButton: 'Orden: {{shoppingCartTotalAmount}}',
    Title: 'Hardware y Suministros',
    Total: 'Total: {{shoppingCartTotalAmount}}'
  },

  // Terminal Supplies
  TerminalSupplies: {
    AddToOrder: 'Agregar a la orden',
    ErrorMessage: 'Error: El límite para cada suministros es de {{supplyLimit}}. Por favor ingrese un número dentro del límite.',
    NoData: 'No hay suministros disponibles',
    RemainingAllotmentText: '{{count}} artículo restante este trimestre',
    RemainingAllotmentText_plural: '{{count}} artículos restantes este trimestre'
  },

  // Reputation Project
  ReputationScreen: {
    QuickReplies: 'Respuestas rápidas',
    QuickRepliesDescription: 'Configurar mensajes personalizados que se pueden seleccionar al responder a las opiniones de los clientes.',
    QuickReplyCreate: 'Crear una respuesta rápida',
    QuickRepliesPositive: 'Positivo (4-5 estrellas)',
    QuickRepliesNeutral: 'Neutral (3 estrellas)',
    QuickRepliesNegative: 'Negativo (1-2 estrellas)',
    QuickRepliesCreateTitle: 'Crear una respuesta rápida.',
    QuickRepliesCategory: 'Seleccionar categoría de revisión',
    QuickRepliesMessage: 'Ingrese un mensaje de respuesta rápida',
    QuickRepliesMessageLimit: 'Limitar {{limit}} caracteres.',
    QuickRepliesRemoveTitle: 'Eliminar respuesta rápida.',
    QuickRepliesRemoveDescription: '¿Está seguro de que desea eliminar esta respuesta rápida?',
    QuickRepliesRemove: 'Sí, eliminar',
    QuickRepliesCancel: 'No, cancelar',
    NoRatings: 'Sin calificaciones',
    Average: 'Promedio',
    Ratings: 'calificaciones',
    ReputationManagement: 'Gestión de Reputación',
    Description: 'Conecta tus reseñas de Google y gestiona lo que la gente dice de tu negocio.',
    Hint: '¿No tiene un perfil comercial de Google? Configura tu negocio en Google ',
    Here: 'aquí.',
    TakeMeLink: 'Llévame a la gestión de la reputación',
    Trends: {
      Title: 'Vea lo que dicen los clientes.',
      Subtitle: 'Al conectarse a su cuenta de Google, podrá ver y administrar las reseñas de sus clientes.',
      Button: 'Comenzar',
      GetStarted: {
        View: 'Vea y responda a las opiniones de los clientes.',
        Gain: 'Obtenga información sobre tendencias de revisión positivas versus negativas.',
        Add: 'Agrega tu competencia y mira cómo se compara.'
      },
      ReputationChange: 'Cambio de reputación',
      ReputationChangeCaption: 'Tendencia a la {{trend}} desde el periodo anterior',
      ReputationChangeNoDataCaption: 'No hay datos disponibles para el rango seleccionado',
      Sentiment: 'Reseñas positivas y negativas',
      SentimentCaption_zero: '',
      SentimentCaption: 'Las reseñas son {{count}}% positivas',
      OverallSatisfaction: 'Puntuación de satisfacción general',
      OverallSatisfactionCaption: 'Promedio de {{count}} reseñas totales',
    },
    BetaContainer: {
      Title: 'Está utilizando una funcionalidad beta.',
      Description: 'Esta funcionalidad se encuentra actualmente en pruebas piloto. Envía tus comentarios a ',
      ThanksMessage: '¡Gracias por su participación!'
    }
  },

  // Issue a credit
  IssueCreditError: 'Error emitiendo el crédito',
  CreditAmountLabel: 'Monto del Crédito',
  DebtRepaymentError: 'Por favor intente otro método de pago.',
  DuplicateConfirmationDialog: {
    Title: 'Duplicar Transacción',
    Description: 'Una transacción similar con esta tarjeta fue recientemente autorizada y continuar puede resultar en una transacción duplicada. ¿Desea continuar con esta transacción?'
  },
  IsIssueCreditDescription: 'La emisión de un crédito no está relacionada con una transacción existente. Para un reembolso o rechazo relacionado a una transacción existente, visite la página <1>Transacciones</1>.',
  IssueCreditDialog: {
    Title: 'Sobre emitir créditos.',
    Description: 'La emisión de un crédito no está relacionada con una transacción existente. Para un reembolso o rechazo relacionado a una transacción existente, visite la página Transacciones.',
    Note: 'Tenga en cuenta que existen parámetros específicos relacionados con esta función y es posible que se rechace su crédito.'
  },
  IssueCreditButton: 'Emitir crédito',
  PaymentStatusMessageDialog: {
    SendReceiptButton: 'Enviar recibo',
    Title: 'La transacción ha sido procesada satisfactoriamente.',
    ViewInTransactionsButton: 'Ver en transacciones',
    ContinueTransaction: 'Continuar transacción',
    VoidTransaction: 'Anular transacción',
    LoyaltyRewardEarned: '¡Recompensa de {{amount}} de descuento ganada!',
    LoyaltyRewardInfoFirst: 'de',
    LoyaltyRewardInfoSecond: '{{points_label}} acumulados para una recompensa de {{amount}}'
  },
  InvoiceStatusMessageDialog: {
    Title: 'La factura se ha creado correctamente.',
    Error: 'No se pudo crear la factura',
    ViewInInvoicesButton: 'Ver en facturas'
  },
  PrintReceiptButton: 'Imprimir recibo',
  ProcessPaymentButton: 'Procesar pago',
  SubmitButtonClickedError: 'Por favor complete todos los campos requeridos.',
  RestrictedAccessDialog: {
    'Title': 'Acceso Limitado'
  },
  SaleAmountError: 'Monto de venta debe ser mayor a $0.00.',
  Summary: 'Resumen',

  // Categories
  CategoriesPage: {
    CategoryName: 'Nombre de Categoría',
    CategoryCount: '# de Artículos',
    Count: '{{count}} Artículo',
    Count_plural: '{{count}} Artículos',
    DeleteCategory: 'Borrar categoría',
    MergeCategory: 'Combinar categoría',
    NewCategory: 'Nueva Categoría',
    NoData: 'Sin Categorías',
    PageTitle: 'Categorías',
    SearchCategories: 'Buscar Categorías'
  },

  // Delete Confirmation Dialog
  DeleteConfirmationDialog: {
    ConfirmationText: '¿Está seguro que quiere borrar esta categoría? Esta acción no se puede revertir. Los artículos dentro de este grupo no van a ser borrados.',
    TitleText: 'Borrar categoría.'
  },

  // Merge Category Dialog
  MergeCategoryDialog: {
    TitleText: 'Combinar Categorías',
    ConfirmText: 'Combinar categorías'
  },

  // Card Detail Form
  CardDetailForm: {
    CategoryNameLabel: 'Nombre de la Categoría',
    CategoryNameHint: 'Nombre de la Categoría'
  },

  // Create Menu
  CreateMenu: {
    NewCategory: 'Nueva Categoría',
    NewDiscount: 'Nuevo Descuento',
    NewItem: 'Nuevo Artículo',
    NewModifierSet: 'Nuevo Conjunto de Modificadores'
  },

  // Integrations - Gift Cards
  Factor4: {
    Details: 'Al suscribirse a las tarjetas de regalo de Factor4, agrega la posibilidad de ejecutar transacciones con tarjetas de regalo a través de su terminal. Factor4 trabajará con usted para configurar su negocio con el procesamiento de tarjetas de regalo y la realización de pedidos de tarjetas de regalo. Al suscribirse a Factor4, acepta las tarifas de procesamiento del 3,5 % y 0,50 $ por emisión de tarjeta de regalo.',
    Title: 'Factor 4 - Tarjetas de Regalo',
    Description: 'Venda, canjee y realice un seguimiento de las tarjetas de regalo para crear conciencia de marca y aumentar los ingresos de su empresa.',
    PricePerLocation: '$X.XX/mo por ubicación',
    PriceDetailsText: 'Detalles de precio aquí.',
    Subscribe: 'Suscribirse al Programa de Tarjetas de Regalo Factor 4',
    Unsubscribe: 'Desuscribirse del Programa de Tarjetas de Regalo Factor 4',
    LaunchText: 'Inicie factor4gift.com',
    HowItWorksText: 'Ver cómo funciona'
  },

  // Devices
  DevicesPage: {
    Header: 'Otorgar acceso a los dispositivos de socios de ventas',
    Description: 'Permitir que mi socio de ventas vea la cuenta en mis dispositivos y actualice la configuración de pagos y recibos en mi nombre.',
    DenyTitle: 'Denegar el acceso al socio de ventas.',
    ApproveTitle: 'Otorgar acceso a socios de ventas',
    DenyText: 'Su socio de ventas ya no podrá ver ni administrar sus dispositivos. ¿Está seguro?',
    ApproveText: 'Su socio de ventas podrá ver o administrar sus dispositivos. ¿Está seguro?',
    OkButtonConfirm: 'Sí, negar acceso',
    CloseButtonConfirm: 'No, permitir el acceso',
    OkButtonDeny: 'Sí, otorgar acceso',
    CloseButtonDeny: 'No, no dar acceso',
    DeviceModel: 'Modelo de dispositivo',
    DeviceType: 'Tipo de dispositivo',
    PaVersion: 'Versión de PA',
    PayanywhereVersion: 'Versión de Payanywhere {{paVersion}}',
    Serial: 'Serie #{{serialNumber}}',
    LastHeartbeat: 'Último pulso',
    SearchDevices: 'Buscar dispositivos',
    LastHeartBeatDate: 'DD/MM/YYYY',
    LastHeartBeatText: 'Último pulso {{lastHeartBeatDate}} a las {{lastHeartBeatTime}}',
    LastHeartBeatTextNotApply: 'Último pulso N/A',
    DeviceLocation: 'Ubicación {{deviceLocation}}',
    NoDevicesConnected: 'No se encontraron dispositivos conectados',
    DevicesConnected: 'Dispositivos conectados',
    BatteryPercentage: 'Batería {{batteryPercentage}}%',
    Config: 'Configuración {{config}}',
    TapToPayEnabled: 'Tap to Pay on iPhone Habilitado'
  },
  CardinalDirections: {
    North: 'N',
    East: 'E',
    West: 'O',
    South: 'S'
  },

  //Beta Disclaimer
  BetaDisclaimer: {
    Title: 'Está usando una característica beta.',
    Description: 'Envíe sus comentarios ',
    Link: 'aquí',
    Thanks: '¡Gracias por su participación!'
  },

  //New Feature Announce
  NewFeature: {
    Title: '¡Mira esto!',
    Description: 'Dinos lo que opinas. Envía tus comentarios ',
    Link: 'aquí',
  },

  // Select a Language Dialog
  SelectLanguage: 'Seleccionar idioma',

  // Sales Partner Notification
  SalesPartnerNotification: {
    Text: 'Su socio de ventas esta configurado para ver y administrar los dispositivos de esta cuenta. Puede cambiar el accesso en Configuración del Negocio - Dispositivos',
    ManageAccess: 'Administrar acceso',
    GotItAccess: 'Entendido'
  },

  // Loyalty Program
  LoyaltyPrograms: {
    Title: 'Programas de lealtad',
    TitleSingular: 'Programa de lealtad',
    LoyaltyStatus: '{{points_earned}} de {{points_to_earn_reward}} {{points_label}} ganados para {{dollar}}{{reward_amount}}{{percent}} de descuento',
    DashboardCard: {
      Title: 'Lealtad',
      Heading: 'Cree más clientes leales con nuestro nuevo programa de recompensas.',
      CtaLabel: 'Únete a la prueba piloto'
    },
    Previews: {
      Receipt: 'Recibo con recompensa',
      Email: 'Correo electrónico de recompensas'
    },
    CustomMessageFooter: 'Aparece en recibos y correos electrónicos enviados a clientes que obtuvieron una recompensa.',
    EnableVirtualCard: 'Habilitar el Programa de Lealtad de Payanywhere',
    VirtualCardDescription: '<p>Cree un programa de lealtad definiendo cómo sus clientes ganan puntos y cómo son recompensados.</p>',
    LoyaltyTermsLinkText: 'Al habilitar, usted acepta los ',
    LoyaltyTermsLink: 'Términos del Programa de Lealtad de Payanywhere.',
    LoyaltyTermsCheckboxLabel: 'He leído y acepto los términos del programa de fidelización Payanywhere.',
    ProgramName: 'Nombre del programa',
    HintProgramName: 'Programa de Lealtad',
    PointsName: 'Nombre de los puntos',
    Points: 'puntos',
    HintPointsName: 'ej. puntos, estrellas',
    NumberPoints: 'Puntos necesarios para canjear',
    MinimumSpend: 'Requisito de gasto',
    RewardAmountType: 'Tipo de Descuento',
    RewardAmount: 'Monto de Descuento',
    EarnedExpiration: 'Caducidad de la recompensa ganada',
    ExpireIn3Months: '3 meses',
    ExpireIn6Months: '6 meses',
    ExpireIn9Months: '9 meses',
    ExpireIn12Months: '12 meses',
    NotExpired: 'No expira',
    CustomMessage: 'Mensaje de recompensa personalizado',
    ViewPreview: 'Ver vista previa',
    ViewSampleReceipt: 'Ver recibo de muestra',
    DeleteTitle: '¿Está seguro de que desea desactivar su programa de lealtad?',
    DeleteContent: 'Los clientes ya no acumularán puntos. Su recompensa existente permanecerá activa, a menos que haya establecido una fecha de vencimiento para la recompensa obtenida.',
    Yes: 'Sí, desactivar',
    No: 'No',
    UpdateTitle: '¿Está seguro de que desea cambiar el programa de lealtad?',
    UpdateContent: 'Esto cambiará el programa para los clientes que ya están acumulando puntos.',
    ReEnableTitle: '¿Está seguro de que desea habilitar el programa de lealtad?',
    ReEnableContent: 'Esto reactivará los puntos y recompensas que los clientes hayan ganado anteriormente.',
    RewardEmailHeader: '¡Erica, has obtenido una recompensa!',
    RewardDiscountText: 'de descuento en tu próxima compra.',
    RewardQrText: 'Presenta el código QR o el código de descuento al realizar tu próxima compra.',
    RewardExpires: 'Descuento expira el',
    RewardCustomMsg: 'Mensaje personalizado aquí.',
    PreviewProgram: 'Vista previa de su programa de lealtad',
    MarketingText: {
      First: 'Los clientes ganan puntos automáticamente. No es necesario registrarse.',
      Second: 'Personaliza tu propia estructura de puntos y crea múltiples recompensas.',
      Third: 'Ofrezca a los clientes una tarjeta de fidelidad digital y un sitio de recompensas personalizado.'
    },
    ActivateLoyaltyAddOn: 'Activar Programa en Configuración',
    GeneralPointRuleDialog: {
      Title: 'Reglas de Puntos',
      Text: 'Las reglas de puntos se aplican a todas las recompensas creadas posteriormente. Editar una regla de puntos no afectará una recompensa existente.',
      GeneralPointRule: 'Regla general de puntos',
      EarnByVisit: 'Gana por visita',
      DescriptionFirstPart: 'Gana 1 punto por visita.',
      DescriptionSecondPart: 'gasto mínimo.',
      TapEdit: 'Toque "Editar" para Configurar',
      Edit: 'Editar'
    },
    RewardDialog: {
      Title: 'Crear una Recompensa',
      Text: 'La recompensa será un descuento total en la compra.',
      Rewards: 'Recompensas',
      OffEntirePurchase: 'de descuento en la compra',
      Expires: 'Expira el',
      DoesNotExpire: 'No Expira',
      NoRewards: 'Sin Recompensas',
      Edit: 'Editar',
      Create: 'Crear Recompensa'
    },
    CustomerDirectoryHeader: {
      TotalCustomers: 'Clientes Totales',
      RepeatCustomers: 'Clientes Habituales',
      RewardsRedeemed: 'Recompensas Canjeadas',
      TotalRewardDiscountVolume: 'Volumen Total de Descuentos'
    },
    Clp: {
      InvalidReward: 'El código de recompensa que usted ingresó no es válido o ha expirado',
      DiscountOrReward: 'Descuento o recompensa',
      RemoveReward: 'Remover',
      DiscountOrRewardCode: 'Código de Descuento o Recompensa',
      ApplyReward: 'Aplicar código de descuento o recompensa',
      Apply: 'Apply',
      Status: '{{points_earned}} de {{points_to_earn_reward}} {{points_label}} ganados para su {{dollar}}{{reward_amount}}{{percent}} de recompensa',
      PresentText: 'Presente el código QR o el código de descuento en el momento de su próxima venta. Encuéntralo en tu recibo o en tu correo electrónico.',
      Earned: 'Te has ganado un {{dollar}}{{reward_amount}}{{percent}} de recompensa!',
      Error: 'Error validando el código',
      HintText: 'Ingrese el código de recompensa'
    },
    LoyaltyExpires: '| Expira',
    RewardEarned: 'de Premio obtenido',
    ResendEmail: 'Reenviar Email de Premio',
    SendEmailSuccess: 'Email de Premio Enviado',
    SendEmailFailure: 'Hubo un problema enviando tu Email de Premio',
    CharactersLeft: ' caracteres restantes.'
  },

  LoyaltyProgramSettings: {
    Title: 'Configuración del Programa de Lealtad',
    Text: 'Vista previa del programa de lealtad',
    View: 'Ver',
    TollFreeNumber: {
      Title: 'Crear un número gratuito del programa de lealtad',
      Text: 'Cree un número gratuito generado automáticamente para enviar a sus clientes mensajes SMS con el estado de sus puntos de lealtad e información de recompensas.',
      DisclaimerPartOne: 'Es posible que algunos tipos de empresas no sean elegibles para enviar SMS según la ',
      DisclaimerPartTwo: ' (reglas sobre sexo, odio, alcohol, firmwares, y el tabaco/cannabis).',
      Number: 'Su Número Gratuito'
    },
    Website: {
      Title: 'Sitio Web Comercial (Requerido)',
      DisclaimerPartOne: 'Se requiere un sitio web comercial para obtener un número gratuito. Agregar o editar el sitio web de su negocio también actualizará el campo en ',
      DisclaimerPartTwo: 'Configuración de Recibos.',
      Button: 'Generar Número Gratuito'
    },
    VerificationPending: {
      Title: 'Su Número Gratuito está Pendiente',
      Text: 'Este número está pendiente de verificación y puede tardar hasta 3 semanas en completar el proceso de verificación. Los mensajes no se enviarán hasta que se verifique su número gratuito.'
    },
    VerificationRejected: {
      Title: 'Su Número Gratuito fue Rechazado',
      TextPartOne: 'No pudimos generar un número gratuito según la información proporcionada. Para obtener más información, haga clic ',
      TextPartTwo: 'aquí.'
    }
  },

  TermsMerchantError: {
    ModalTitle: 'Error al obtener información del comerciante',
    ModalText: 'Hemos experimentado un error al recuperar su información comercial. Por favor comuníquese con Servicio al Cliente al 877.464.4218 o support@paymentshub.com para actualizar su información. Haga clic en "De acuerdo" para finalizar sesión.'
  },

  // Scheduled Reports
  ScheduledReports: {
    Scheduled: 'Programado',
    EditSchedule: 'Editar programación',
    Schedule: 'Programar',
    Title: 'Prgramar Reporte.',
    Text: 'Reciba automáticamente este informe por correo electrónico para la frecuencia y el rango de fechas que elija. El intervalo de tiempo se basa en su configuración global.',
    ScheduleReport: 'Programar reporte',
    Report: '',
    SendTo: 'Enviar A',
    AdditionalEmail: 'Destinatario Adicional (opcional)',
    AdditionalEmailHint: 'Ingrese correo electrónico',
    Frequency: 'Frecuencia',
    SendOnThe: 'Enviar el',
    SendOn: 'Enviar el',
    DailyReport: 'Reporte Diario',
    WeeklyReport: 'Reporte Semanal',
    MonthlyReport: 'Reporte Mensual',
    DAILY: 'Diariamente',
    WEEKLY: 'Semanalmente',
    MONTHLY: 'Mensualmente',
    '1st': '1',
    '2nd': '2',
    '3rd': '3',
    '4th': '4',
    '5th': '5',
    '6th': '6',
    '7th': '7',
    '8th': '8',
    '9th': '9',
    '10th': '10',
    '11th': '11',
    '12th': '12',
    '13th': '13',
    '14th': '14',
    '15th': '15',
    '16th': '16',
    '17th': '17',
    '18th': '18',
    '19th': '19',
    '20th': '20',
    '21st': '21',
    '22nd': '22',
    '23rd': '23',
    '24th': '24',
    '25th': '25',
    '26th': '26',
    '27th': '27',
    '28th': '28',
    '29th': '29',
    '30th': '30',
    '31st': '31',
    Monday: 'Lunes',
    Tuesday: 'Martes',
    Wednesday: 'Miércoles',
    Thursday: 'Jueves',
    Friday: 'Viernes',
    Saturday: 'Sábado',
    Sunday: 'Domingo'
  },

  // Frequency
  DAILY: 'DIARIAMENTE',
  WEEKLY: 'SEMANALMENTE',
  MONTHLY: 'MENSUALMENTE',
  Monday: 'Lunes',
  Tuesday: 'Martes',
  Wednesday: 'Miércoles',
  Thursday: 'Jueves',
  Friday: 'Viernes',
  Saturday: 'Sábado',
  Sunday: 'Domingo',

  // No Data Messages
  NoDataMessage: 'Sin Datos',
  NoDataAccountSummary: 'Sin datos par Resumen de Cuenta',
  NoDataBatchReport: 'Sin Informe de Lote',
  NoDataBatchSummaryReport: 'Sin Resumen de Lotes',
  NoDataCardBrandSummaryReport: 'Sin Resumen de Marca de Tarjeta para este período',
  NoDataCustomerExport: 'Sin Clientes',
  NoDataLoyaltyExport: 'Sin Información de Lealtad',
  NoDataLoyaltyNeverEnabled: 'Configure su programa de lealtad hoy en Clientes',
  NoDataCustomerExperienceReport: 'Sin Valoraciones de Clientes',
  NoDataCustomerPerformanceReport: 'Sin Rendimiento del Cliente para este período',
  NoDataDeclinedTransactionExport: 'Sin Transacciones',
  NoDataDeclinedTransactionExportIsLargeProcessor: 'No se puede mostrar la cantidad de datos devueltos en su selección. Haga click en exportar para ver su reporte.',
  NoDataDepositDetailsReport: 'Sin Detalles de Depósito',
  NoDataDepositSummaryReport: 'Sin Depósitos',
  NoDataDepositTransactionsReport: 'No se puede mostrar la cantidad de datos devueltos en su selección. Haga click en exportar para ver su reporte.',
  NoDataDialog: {
    Title: 'Ningún Reporte Generado',
    Message: 'No hay datos para este período de tiempo'
  },
  NoDataFraudReport: 'No hay datos para el Reporte de Fraude',
  NoDataDisputesReport: 'Sin Disputas',
  NoDataEmployeePerformanceReport: 'No hay datos para Rendimiento de los Empleados para este período',
  NoDataExpandedTransactionReport: 'Sin Transacciones',
  NoDataExpandedTransactionReportIsLargeProcessor: 'No se puede mostrar la cantidad de datos devueltos en su selección. Haga click en exportar para ver su reporte.',
  NoDataFlashReport: 'Sin datos para Reporte Flash',
  NoDataItemExport: 'No hay Artículos',
  NoDataItemizedExport: 'Sin datos para Reporte Detallado',
  NoDataModifierPerformance: 'No hay datos para Rendimiento del Modificador para este período',
  NoDataPaymentTypeSummary: 'Sin datos para Resumen de Marca de Tarjeta para este período',
  NoDataProductSalesReport: 'Sin datos para Reporte de Ventas de Productos',
  NoDataRefundSummaryReport: 'Sin Reembolsos',
  NoDataTaxExport: 'Sin Impuestos para este período',
  NoDataTransactionExport: 'Sin Transacciones',
  NoDataTransactionExportIsLargeProcessor: 'No se puede mostrar la cantidad de datos devueltos en su selección. Haga click en exportar para ver su reporte.',
  NoDataTransactionsCategoryExport: 'Sin Transacciones',
  NoDataTransactionsItemExport: 'Sin Transacciones',
  NoReports: 'Sin Reportes',

  UpgradePremiumPlus: {
    ModalTitle: 'Administre su plan de Payments Hub.',
    TitlePremium: 'es una función Premium.',
    TitlePremiumPlus: 'es una función Premium Plus.',
    UpgradeButtonPremiumPlus: 'Actualizar a Premium Plus',
    UpgradeButtonPremiumPlusLoyalty: 'Prueba el Programa de Lealtad',
    UpgradeButtonPremium: 'Actualizar a Premium',
    PlansButton: 'Ver todos los planes',
    ChooseButton: 'Selecciona',
    UpdateButton: 'Actualizar Plan',
    CurrentPlan: 'Plan Actual',
    Loyalty: {
      TopLeft: 'Crea incentivos para tus clientes.',
      TopRight: 'Tus clientes se inscriben y ganan puntos en compras calificadas.',
      BottomLeft: 'Personaliza tu esquema de puntos y el nombre de tu programa.',
      BottomRight: 'Las recompensas se envían automáticamente y son visibles en el momento de la venta.'
    },
    Reputation: {
      TopLeft: 'Mira lo que dicen tus clientes.',
      TopRight: 'Mira y responde a las opiniones de los clientes.',
      BottomLeft: 'Obtén información sobre tendencias de revisión positivas versus negativas.',
      BottomRight: 'Agrega a tu competencia y mira cómo se compara.'
    },
    EnhancedInvoices: {
      TopLeft: 'Envía facturas únicas o periódicas y realiza un seguimiento con facilidad.',
      TopRight: 'Los clientes pueden pagar sin crear una cuenta.',
      BottomLeft: 'Programa facturas, guarda tarjetas en archivo y habilita el pago automático.',
      BottomRight: 'Personaliza tus propias facturas y portal de pago de clientes.'
    },
    PaymentLinks: {
      TopLeft: 'Una manera simple de pagar para tus clientes, desde cualquier lugar y dispositivo.',
      TopRight: 'Copia un enlace, agrega un botón, o comparte un código QR.',
      BottomLeft: 'Ajusta tu propia página de pagos con una cantidad sin fin de usos.',
      BottomRight: 'Personaliza múltiples campañas y monitoréalas por uso y volumen.',
    }
  },

  Plan: {
    Free: {
      Label: 'Free',
      Price: '$0.00/mes',
      SubHeading: 'Gestión básico de cuentas',
      BulletOne: {
        One: 'Actividad de transacciones',
        Two: 'Estados de cuenta',
        Three: 'Gestión de contracargos',
        Four: 'Terminal virtual'
      }
    },
    Premium: {
      Label: 'Premium',
      Price: '$14.95/mes',
      SubHeading: 'Gestión de cuentas y herramientas para negocios.',
      BulletOne: {
        One: 'Suministros gratuitos',
        Two: 'Reemplazo de terminales',
        Three: 'Tarjeta de regalo Factor 4',
        Four: 'Manejo de reputación'
      },
      BulletTwo: {
        One: 'Actividad de transacciones',
        Two: 'Estados de cuenta',
        Three: 'Gestión de contracargos',
        Four: 'Terminal virtual'
      }
    },
    Paid_Plus: {
      Heading: 'Recomendado',
      Label: 'Premium Plus',
      Price: '$29.95/mes',
      SubHeading: 'Todas las herramientas Premium Plus avanzadas.',
      SwitchText: 'Con Programa de Lealtad',
      TooltipText: 'Consulte con su Socio de Ventas si desea utilizar Payanywhere Loyalty.',
      SwitchSubtext: '+ $15.00/mes',
      BulletOne: {
        One: 'Facturación mejorada',
        Two: 'Pagos recurrentes',
        Three: 'Guardar tarjetas en archivo'
      },
      BulletTwo: {
        One: 'Suministros gratuitos',
        Two: 'Reemplazo de terminales',
        Three: 'Tarjeta de regalo Factor 4',
        Four: 'Manejo de reputación'
      },
      BulletThree: {
        One: 'Actividad de transacciones',
        Two: 'Estados de cuenta',
        Three: 'Gestión de contracargos',
        Four: 'Terminal virtual'
      }
    },
    Paid_Plus_Loyalty: {
      Heading: 'Recomendado',
      Label: 'Premium Plus',
      Price: '$29.95/mes',
      SubHeading: 'Todas las herramientas Premium Plus avanzadas.',
      SwitchText: 'Con Programa de Lealtad',
      TooltipText: 'Consulte con su Socio de Ventas si desea utilizar Payanywhere Loyalty.',
      SwitchSubtext: '+ $15.00/mes',
      BulletOne: {
        One: 'Facturación mejorada',
        Two: 'Pagos recurrentes',
        Three: 'Guardar tarjetas en archivo'
      },
      BulletTwo: {
        One: 'Suministros gratuitos',
        Two: 'Reemplazo de terminales',
        Three: 'Tarjeta de regalo Factor 4',
        Four: 'Manejo de reputación'
      },
      BulletThree: {
        One: 'Actividad de transacciones',
        Two: 'Estados de cuenta',
        Three: 'Gestión de contracargos',
        Four: 'Terminal virtual'
      }
    },
  },

  UpgradePlanModal: {
    Title: 'Actualización del plan',
    Body: '¿Está seguro de que desea actualizar a {{plan}} por {{price}}/mes?',
    Button: 'Actualizar'
  },

  UpgradePlanModalLoyalty: {
    Title: 'Agregar Programa de Lealtad',
    Body: '¿Está seguro de que desea agregar Programa de Lealtad?',
    Button: 'Agregar Programa de Lealtad'
  },

  DowngradePlanModalLoyalty: {
    Title: 'Actualización del plan',
    Body: 'Está removiendo el Programa de Lealtad de su plan Premium Plus. Ya no tendrá acceso al Programa de Lealtad. Desea continuar?',
    Button: 'Actualizar'
  },

  DowngradePlanModal: {
    Title: 'Actualización del plan',
    Body: 'Estás bajando de categoría a {{plan}} por {{price}}/mes. Ya no tendrá acceso a facturación, facturación recurrente, tarjeta registrada e integraciones. ¿Estás seguro de que quieres continuar?',
    Button: 'Degradar'
  },

  SuccessUpgradePlanModal: {
    Title: 'Plan actualizado',
    Body: 'Has cambiado correctamente tu plan a {{plan}}.'
  },

  SuccessDowngradePlanModal: {
    Title: 'Plan actualizado',
    Body: 'Ha cancelado con éxito {{oldPlan}} y ha cambiado al plan {{plan}}.'
  },

  ErrorPlanModal: {
    Title: 'Actualización de Plan Fallida',
    Body: 'Hubo un error al actualizar su plan. Comuníquese con Servicio al cliente al {{phone}} o {{email}} o intente nuevamente más tarde.'
  },
  'Payment Links': 'Enlaces de Pago',
  PaymentLink: 'Enlace de Pago',
  NoPaymentLinks: 'No enlaces de pago',
  CreatePaymentLink: 'Crear Enlace de Pago',
  ActiveLinks: 'Enlaces Activos',
  InactiveLinks: 'Enlaces Inactivos',
  'Active Payment Links': 'Enlaces de Pago Activos',
  'Inactive Payment Links': 'Enlaces de Pago Inctivos',
  TotalUses: 'Usos Totales',
  TotalVolumeGenerated: 'Volumen Total Generado',
  PaymentLinksColumns: {
    Title: 'Nombre del Enlace',
    Type: 'Tipo',
    Created: 'Creado',
    Amount: 'Cantidad',
    Uses: 'Usos',
    VolumeGenerated: 'Volumen Generado'
  },
  PaymentLinkTypes: {
    multipleAmount: 'Varios montos',
    multiple: 'Varios montos',
    openEnded: 'Monto abierto',
    open: 'Monto abierto',
    fixedAmount: 'Monto fijo',
    fixed: 'Monto fijo'
  },
  PaymentLinks: {
    CreateForm: {
      TitleLabel: 'Título',
      TitleHint: 'Por ej. "Donaciones"',
      SampleTitle: 'Donaciones',
      DescriptionLabel: 'Descripción (Opcional)',
      DescriptionHint: 'Digite un mensaje personalizado para sus clientes.',
      ImageLabel: 'Imagen (Opcional)',
      ImageUpload: 'Arrastre Imagen o Seleccione Archivo',
      PaymentTypeLabel: 'Tipo de Pago',
      PaymentTypes: {
        FixedAmount: 'Cantidad Fija',
        FixedAmountDescription: 'Defina un único monto de pago que todos los clientes pagan.',
        MultipleAmount: 'Cantidad Multiple',
        MultipleAmountDescription: 'Defina hasta tres montos de pago entre los cuales los clientes seleccionan.',
        OpenEnded: 'Cantidad Abierta',
        OpenEndedDescription: 'Los clientes definen el monto de pago a través de un campo abierto.'
      },
      PaymentAmountLabel: 'Monto de Pago',
      PaymentAmountLabelOptional: 'Monto de Pago (Opcional)',
      MinimumAmount: 'Monto de Pago Mínimo (Opcional)',
      MaximumAmount: 'Monto de Pago Máximo (Opcional)',
      EnableTaxLabel: 'Habilitar Impuestos',
      AcceptTipsLabel: 'Aceptar Propinas',
      CashDiscountingLabel: 'Descuento por Efectivo',
      CustomFieldLabel: 'Agregar Campo Personalizado (Opcional)',
      CustomFieldHint: 'Etiqueta del Campo',
      CustomFieldRequired: 'Campo Requerido',
      RedirectUrlLabel: 'URL a Redireccionar (Opcional)',
      RedirectUrlHint: 'Por ej. "La Dirección de su Sitio Web"',
      RedirectUrlDescription: 'Redireccionar clientes a su sitio web después de un pago exitoso o llegar a un enlace desactivado.',
      CustomAmountLabel: 'Permitir cantidad personalizada',
      Preview: 'Vista Previa',
      Cancel: 'Cancelar',
      CreateLink: 'Crear Enlace',
      Success: 'Enlace de Pago Creado Exitosamente',
      ViewDetails: 'Ver Detalles de Enlace de Pago',
      ToggleTaxDescription: 'Puede establecer manualmente el valor del impuesto o realizar una detección automática por ubicación de la transacción. Administre las opciones de impuestos en <1>Configuración</1>.'
    },
    Sharing: {
      Title: 'Compartir Enlaces de Pago',
      CopyUrl: 'Copie y pegue su URL de Enlace de Pago y compártalo por correo electrónico, mensage de texto SMS, o publicación de red social.',
      Embed: 'Agregue un botón de Enlace de Pago a su sitio web al copiar y pegar el código autogenerado.',
      QRCode: 'Descargue el código QR del Enlace de Pago y permita a sus clientes escanearlo para pagar con sus dispositivos móbiles.',
      Manage: 'Administre los enlaces de pago que ha creado a través de la página de <1>Enlaces de Pago</1>.'
    },
    Detail: {
      Heading: 'Detalles del Enlace de Pago',
      DownloadQR: 'Descargar Código QR',
      CopyURL: 'Copiar URL del Enlace de Pago',
      ViewTransactions: 'Ver Transacciones',
      Deactivate: 'Desactivar Enlace de Pago',
      Reactivate: 'Reactivar Enlace de Pago',
      LinkInformation: 'Información del Enlace',
      NoDescription: 'Sin descripción',
      PaymentInformation: 'Información de Pago',
      TotalUses: 'Usos Totales',
      TotalVolumeGenerated: 'Volumen Total Generado',
      PaymentLinkButton: 'Botón del Enlace de Pago',
      Preview: 'Vista Previa',
      PayNow: 'Pagar Ahora',
      Created: 'Creado el {{date}} a las {{time}} por {{createdBy}}',
      Cancel: 'Cancelar',
      SaveChanges: 'Guardar Cambios',
      CDEnabled: 'Descuento por Efectivo Habilitado',
      InactiveLink: 'Enlace Inactivo',
      DeactivateTitle: 'Desactivar Enlace de Pago',
      DeactivateDescription: '¿Está seguro que desea desactivar este enlace de pago? Sus clientes ya no podrán acceder a su página de pago y realizar un pago. Puede reactivar este enlace posteriormente desde el filtro "Enlaces de Pago Inactivos"'
    },
    PaymentLinkTitle: 'Título del Enlace de Pago',
    PaymentLinkURL: 'URL del Enlace de Pago',
    CopyURLSuccess: 'URL del Enlace de Pago copiado exitosamente.',
    CopyURLError: 'No se pudo copiar la URL del Enlace de Pago.',
    DownloadQRError: 'No se pudo descargar el código QR.',
    CopyCodeSuccess: 'Bot__n del Enlace de Pago copiado exitosamente.',
    CopyCodeError: 'Bot__n del Enlace de Pago no se ha podido copiar.',
    FirstPaymentLink: {
      PageTitle: 'Crea tu primer Enlace de Pago.',
      Card1: {
        Title: 'Haz clic en el botón de "Crear Enlace de Pago".',
        Text: 'Genera una página de pago con marca y crea una forma simple para que los clientes paguen desde cualquier lugar y dispositivo.'
      },
      Card2: {
        Title: 'Comparte tu Enlace de Pago.',
        Text: 'Una vez creado, puede pegar su enlace, insertar un botón, o compartir un código QR para enviar la página de pago a sus clientes.'
      },
      Card3: {
        Title: 'Crea otra!',
        Text: 'Personaliza multiple campañas y rastrea por uso y volumen. Administra todos los enlaces de pago desde esta pagina.'
      }
    }
  },

  ExitAction: 'Salir',

  Activation: {
    Title: 'Activar Cuenta',
    AccountAlreadyStartedAnApplication: 'Ya ha iniciado una aplicación. Para activar su cuenta, comuníquese con su socio de ventas.',
    ActivationPending: 'Activación Pendiente',
    ShowLessHardware: 'Mostrar menos Dispositivos',
    ShowMoreHardware: 'Mostrar más Dispositivos',
    ProceedNoHardware: 'Proceder sin Dispositivos',
    MaxDevices: 'Máximo {{maxDevices}} Dispositivos',
    Flow: {
      Step1: {
        Title: 'Vamos a conocerte.',
        Action: 'Guardar y Continuar',
        OwnerName: 'Nombre completo del propietario',
        Dob: 'Fecha de nacimiento',
        SocialNumber: 'Número de Seguro Social',
        Ownership: 'Propiedad accionaria',
        Address: 'Dirección particular',
        Apt: 'Apto. o Suite',
        City: 'Ciudad',
        State: 'Estado',
        Zip: 'Código postal',
        Owner: 'Propietario',
        PrimaryOwner: 'Este propietario es el contacto principal',
        AddOwner: 'Agregar otro propietario',
        RemoveOwner: 'Eliminar propietario',
        Hint: {
          Dob: 'MM/DD/AA',
          SocialNumber: '000 00 0000',
          MobileNumber: 'Ingrese su número celular',
          Ownership: '1-100%',
          Address: 'Ingrese la dirección postal',
          Apt: 'Opcional',
          City: 'Ingresar ciudad',
          State: 'Seleccionar',
          Zip: 'Ingresar código postal'
        }
      },
      Step2: {
        Title: 'Cuéntenos sobre su negocio.',
        SecondTitle: '¿Cómo quiere recibir el pago?',
        Action: 'Guardar y Continuar',
        LegalBusinessName: 'Nombre Legal del Negocio',
        BusinessType: 'Tipo de Negocio',
        FederalTaxIDSoleProprietor: 'SSN',
        FederalTaxID: 'Identificación Fiscal Federal',
        MerchantCategoryCode: 'Código de Categoría de Comerciante',
        BusinessEmail: 'Dirección de Correo Electrónico Comercial',
        BusinessPhone: 'Número de Teléfono Comercial',
        DepositAccountType: 'Tipo de Cuenta para Depósito',
        DepositAccountDescription: 'Usada para depositar el dinero que hace con cada venta de crédito y debitar los costos de servicio.',
        DepositToolTip: 'En la parte inferior de un cheque, encuentre el número de ruta a la izquierda y el número de cuenta a la derecha.',
        TaxIdToolTip: 'Si es un proprietario individual que usa su número de seguridad social (SSN) como su identificación para impuestos federales, ingrese su SSN aquí.',
        PrimaryBusinessModel: 'Modelo de Negocio Primario',
        CardPresent: 'Tarjeta Presente',
        Keyed: 'Manual',
        Moto: 'Moto',
        Internet: 'Internet',
        BusinessWebsite: 'Sitio web del negocio',
        Hint: {
          LegalBusinessName: 'Ingrese el nombre legal del negocio',
          DBAName: 'Ingrese el nombre de "haciendo negocios como"',
          FederalTaxID: '00-0000000',
          FederalTaxIDSoleProprietor: '000-00-0000',
          MerchantCategoryCode: 'Busque su industria específica...',
          BusinessEmail: 'Ingrese la dirección de correo electrónico comercial',
          AccountNumber: '4-17 dígitos',
          RoutingNumber: '9 dígitos',
          BusinessWebsite: 'Ingrese un sitio web válido'
        }
      },
      Step3: {
        Title: 'Obtén una prueba gratuita de 60 días de Premium Plus.',
        SubTitle: 'Puedes cambiar tu plan cuando haya expirado el periodo de prueba.',
        Action: 'Agregar Hardware'
      },
      Step4: {
        Title: 'Agregar Hardware',
        SubTitle: 'Opcional. Puedes agregar hardware y accesorios más tarde.',
        Action: 'Revisar y Terminar',
        ActionWithoutHardware: 'Continuar sin Hardware'
      },
      Step5: {
        Title: 'Revisar y Terminar',
        MerchantApplicationTitle: 'Merchant Application',
        MerchantApplicationCheckboxLabel: 'He revisado y confirmo la exactitud de la información proporcionada como parte de la "Merchant Processing Application", incluida cualquier garantía incluida en ella..',
        MerchantProcessingTitle: 'Merchant Processing Agreement',
        MerchantProcessingSubTitle: 'Actualizado 22/11/2022',
        MerchantProcessingCheckboxLabel: 'He revisado y acepto los términos del "Merchant Processing Agreement", incluida la cláusula de arbitraje y renuncia a demandas colectivas.',
        ScrollDown: 'Desplácese hacia abajo para continuar',
        Pricing: 'insertandola, sin contacto, o deslizandola',
        SeePricingDetails: 'Ver los detalles del precio',
        SeeDevicePricingDetails: 'Ver los precios de los dispositivos',
        PlanType: 'Plan Premium Plus',
        PlanTypePricing: 'Prueba gratuita de 60 días, luego $29.95/mes',
        Device: '{{count}} Dispositivo',
        Device_plural: '{{count}} Dispositivos',
        Finish: 'Terminar',
        DownloadMerchantApplication: 'Descargar "Merchant Application"'
      }
    },
    BankAccountVerificationModal: {
      Title: 'Verificación de cuenta bancaria',
      Description: 'No pudimos verificar la información bancaria que proporcionó. Inténtelo de nuevo o sube una imagen de un cheque anulado con tu información bancaria claramente visible.',
      DropFile: 'Arrastre su archivo o ',
      ChooseFile: 'elija un Archivo',
      DropFileFormat: 'Debe ser un archivo .jpeg, .png, .gif, .tiff, .pdf,  tamaño máximo 5MB',
      Uploading: 'Subiendo...',
      ContactDescription: 'No pudimos verificar la información bancaria que proporcionó. Si cree que esto es un error, comuníquese con ',
      ContactEmail: 'cssales@north.com'
    },
    Close: {
      Title: 'Confirmar Salida',
      Message: 'Guardaremos tu progreso para que puedas volver y completar la activación de tu cuenta.',
      Proceed: 'Grabar y Cerrar'
    },
    Button: {
      Activate: 'Activar',
      ActivateAccount: 'Activar cuenta',
      ActivateNow: 'Activar ahora'
    }
  },

  // Add-ons
  AddOns: {
    Title: 'Complementos (Requiren Plan Premium Plus)',
    Loyalty: {
      Title: 'Payanywhere Loyalty',
      Pricing: '$15/mes',
      FreeTrialExpiration: 'Prueba gratuita termina en ',
      Activated: 'Activo',
      GoToLoyalty: 'Ir a Loyalty',
      Deactivate: 'Desactivar',
      Activate: 'Activar',
      ActivationDialog: {
        Title: 'Activar Payanywhere Loyalty',
        BodyFirstPart: '¿Quieres agregar Payanywhere Loyalty a tu Plan Premium Plus por $15 adicionales al mes?',
        BodySecondPart: 'Payanywhere Loyalty solo está disponible para comerciantes en Payments Hub Premium Plus con procesamiento de equipos Payanywhere en EPX.'
      },
      DeactivationDialog: {
        Title: 'Desactivar Payanywhere Loyalty',
        BodyFirstPart: '¿Está seguro de que desea eliminar Payanywhere Loyalty de su plan Premium Plus? Ya no podrá acceder a esta función.',
        BodySecondPart: 'Los $15 adicionales al mes por Payanywhere Loyalty dejarán de facturarse después de su próximo ciclo de facturación.'
      }
    }
  },

  PlanDisclaimer: {
    SeeDetails: 'Ver todos los detalles del plan',
    Compare: 'Comparar planes',
    Free: '*Algunas funciones solo están disponibles para comerciantes con ciertos equipos y procesadores. Comuníquese con su representante de ventas o llame al {{phone}} para ver para qué funciones es elegible o para actualizar su cuenta/equipo.',
    PremiumPlus: '**Payanywhere Loyalty está disponible por un costo adicional para los comerciantes en Payments Hub Premium Plus con procesamiento de equipos Payanywhere en EPX.'
  },
  'or': 'o',
  LoyaltyActivity: {
    Title: 'Actividad y Reportes',
    DataError: 'Se produjo un error al cargar los datos.',
    TopLoyaltyCustomers: 'Mejores Clientes',
    RewardPerformance: 'Rendimiento de las Recompensas',
    DoesNotExpire: 'No expira',
    TotalCustomersTitle: 'Clientes Totales en el Programa de Lealtad',
    TotalCustomersFooter: 'Clientes Participantes',
    LoyaltyPointsTitle: 'Puntos de Lealtad',
    LoyaltyPointsFooter: 'Acumulados vs. Redimidos',
    RedemptionsTitle: 'Redenciones',
    RedemptionsBody: 'Recompensas',
    RedemptionsFooter: 'Valor Total',
    RepeatCustomersTitle: 'Clientes que Repiten',
    RepeatCustomersUpward: 'Tendencia al Alza',
    RepeatCustomersDownward: 'Tendencia a la Baja',
    RepeatCustomersNoData: 'No hay datos disponibles para el rango',
    LoyaltySpendTitle: 'Gasto en el Programa de Lealtad',
    LoyaltySpendFooter: 'Transacciones con Lealtad',
    AverageSpendTitle: 'Gasto Promedio',
    AverageSpendFooter: 'Por Transacción con Lealtad',
    Name: 'Nombre',
    Points: 'Puntos',
    Redemptions: 'Redenciones',
    Transactions: 'Transacciones',
    VolumeSpent: 'Volumen Gastado',
    RewardName: 'Nombre de la Recompensa',
    Expires: 'Expira',
    VolumeRedeemed: 'Volumen Redimido'
  },

  EnrollInLoyalty: {
    TitleFirstPart: 'gane',
    TitleSecondPart: 'en cada compra.',
    Subtitle: 'Gane recompensas con',
    Enroll: 'Inscríbase en el Programa de Lealtad',
    ButtonText: 'Inscribirse',
    FormField: 'Número de móvil del Programa de Lealtad',
    FormPlaceholder: 'Ingrese el número de móvil',
    FormDisclaimer: 'Acepto recibir recibos y estado del Programa de Lealtad (si estoy inscrito), incluidos los puntos y recompensas obtenidos a través de mensajes de texto. La frecuencia de los mensajes varía. Se pueden aplicar tarifas de mensajes y datos. Responda STOP para detener o HELP para obtener ayuda.'
  },

  ApplePayErrorDialog: {
    Title: 'Error de Apple Pay',
    Body: 'Algo salió mal con Apple Pay. Actualice el navegador si el problema persiste.'
  },

  NorthGatekeeper: {
    Title: 'Active su cuenta para acceder a pagos y mucho más.',
    Description: 'Está en modo de demostración. Active ahora para obtener acceso completo a las herramientas comerciales y de pago de North.',
    ActivateNow: 'Activar',
    ActivateYourAccount: 'Active Su Cuenta'
  },

  NorthDashboardBanner: {
    Activate: 'Activar',
    Approved: {
      Title: 'Bienvenido a North',
      MobileTitle: 'Bienvenido a North',
      Description: {
        Default: 'Su solicitud fue aprobada y ahora puede aceptar pagos.',
        PendingLogOut: 'Su solicitud fue aprobada. Cierre sesión y vuelva a iniciar sesión para comenzar a aceptar pagos.',
      },
      MobileDescription: {
        Default: 'Ahora puede aceptar pagos.',
        PendingLogOut: 'Cierre sesión y vuelva a iniciar sesión para comenzar a aceptar pagos.',
      },
      Action: {
        Default: 'Entendido',
        PendingLogOut: 'Cerrar sesión',
      },
    },
    Declined: {
      Title: 'Hubo un problema con su aplicación.',
      Description: 'No pudimos aprobar su aplicación. Por favor contáctenos para más información.',
      MobileTitle: 'No pudimos aprobar su aplicación',
      MobileDescription: 'Por favor contáctenos para más información.',
      Action: 'Contacta Con Nosotros'
    },
    GetPayanywhere: 'Obtener Payanywhere',
    FirstLanding: {
      Title: 'Ponga su negocio en funcionamiento en minutos.',
      Description: 'Está en modo de demostración. Active su cuenta para aceptar pagos y utilizar todas las funciones.',
      MobileTitle: 'Está en modo de demostración',
      MobileDescription: 'Active su cuenta para aceptar pagos y utilizar todas las funciones.',
    },
    DemoPayment: {
      Title: 'Vaya más allá del modo de demostración. Acepte un pago real.',
      Description: 'Active su cuenta para permitir pagos y completar su transacción.',
      MobileTitle: 'Vaya más allá del modo de demostración.',
      MobileDescription: 'Active su cuenta para permitir pagos y completar su transacción.',
    },
    IncompleteApplication: {
      Title: 'Continúe donde lo dejó.',
      Description: 'Está a solo unos minutos de tener acceso completo a herramientas comerciales y de aceptación de pagos.',
      MobileTitle: 'Continúe donde lo dejó.',
      MobileDescription: 'Está a solo unos minutos de tener acceso completo a herramientas comerciales y de aceptación de pagos.'
    },
    HighRiskApplication: {
      Title: '¡Hablaremos pronto!',
      Description: 'Su tipo de negocio requiere una verificación adicional. Nos comunicaremos con usted dentro de las siguientes 24 horas o llámenos al 866.611.4225.',
      MobileTitle: 'Su tipo de negocio requiere una verificación adicional',
      MobileDescription: 'Nos comunicaremos con usted dentro de las siguientes 24 horas o llámenos al 866.611.4225.',
    },
    PendingApproval: {
      Title: '¡Esté atento a su bandeja de entrada!',
      Description: 'Su solicitud fue enviada. Recibirá un correo electrónico con su estado de aprobación dentro de las siguientes 24 horas.',
      MobileTitle: 'Su solicitud fue enviada',
      MobileDescription: 'Recibirá un correo electrónico con su estado de aprobación dentro de las siguientes 24 horas.',
    },
    DemoPaymentCard: 'Pago de Demo',
    ChooseAPlan: 'Escoger Plan',
    AddBusinessInfo: 'Agregar Información del Negocio',
    AddHardware: 'Agregar Hardware'
  },

  PageNotFound: {
    Title: 'Página no encontrada',
    Subtitle: 'Lo sentimos, pero esta página no existe.'
  },

  Signup: {
    Label: {
      FullName: 'Nombre Completo',
      EmailAddress: 'Correo Electrónico',
      MobileNumber: 'Número Celular',
      Password: 'Contraseña',
      ConfirmPassword: 'Contraseña',
    },
    Hint: {
      EnterFullName: 'Ingrese su nombre completo',
      EnterEmailAddress: 'Ingrese su correo electrónico',
      EnterMobileNumber: 'Ingrese su número celular',
      CreatePassword: 'Ingrese su contraseña',
      ConfirmPassword: 'Confirme su contraseña',
    },
    Link: {
      ExistingAccount: 'Iniciar sesión en cuenta existente',
      NorthDeveloper: 'Ir a North Developer'
    },
    Heading: 'Crear una cuenta para acceso instantáneo.',
    CreateAccount: 'Crear Cuenta',
    CreatingAccount: 'Creando',
    TsAndCs: {
      Start: 'He leído y estoy de acuerdo con los',
      Terms: 'Términos',
      And: 'y',
      PrivacyPolicy: 'Política de Privacidad'
    },
    Mfa: {
      Title: 'Ingrese el código de verificación.',
      Description: 'Ingrese el código de verificación enviado a su teléfono móvil que termina en {{phoneNumLastFourDigits}}. Solicite un nuevo código o intente otro método de verificación si es necesario.',
      ResendCode: 'Reenviar código',
      PhoneVerify: 'Obtener código por llamada de voz',
      EmailVerify: 'Obtener código por correo electrónico'
    }
  },

  ActivationPlans: {
    Footer: 'Diseñe un plan de precios específico para su negocio.',
    FooterLink: 'Diseñe un Plan Personalizado',
    Free: {
      Type: 'Free',
      Price: '$0.00/mes',
      Heading: 'Aceptación de Pagos Básica',
      SubHeading: '2,69% por toque o deslizamiento',
      Feature1: 'Pagos en persona',
      Feature2: 'Financiamiento al día siguiente',
      Feature3: 'Tap to Pay on iPhone',
      Feature4: 'Terminal Virtual',
      Feature5: 'Gestión de contracargos',
      Feature6: 'Gestión del inventario',
      Feature7: 'Usuarios ilimitados',
      Feature8: 'Acceso a las API de desarrollador'
    },
    Premium: {
      Type: 'Premium',
      Price: '$14.95/mes',
      Heading: 'Potentes herramientas comerciales',
      SubHeading: '2,69% por toque o deslizamiento',
      Feature1: 'Suministros gratuitos',
      Feature2: 'Reemplazo de terminales',
      Feature3: 'Acceso completo a integraciones',
      Feature4: 'Gestión de reputación',
      Feature5: 'Además de todas las funciones gratuitas',
      SeeMore: {
        Heading: 'Ver más',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
      }
    },
    PremiumPlus: {
      Type: 'Premium Plus',
      Price: '$29.95/es',
      Heading: 'Herramientas de Ecommerce avanzadas',
      SubHeading: '2,69% por toque o deslizamiento',
      Feature1: 'Facturación mejorada',
      Feature2: 'Pagos recurrentes',
      Feature3: 'Guardar tarjetas en archivo',
      Feature4: 'Programa de lealtad (+$15/mes)',
      Feature5: 'Además de todas las funciones Premium',
      SeeMore: {
        Heading: 'Ver más',
        Text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
      }
    }
  },
  ActivationHardware: {
    Devices: {
      SmartFlex: {
        Title: 'Smart Flex'
      },
      SmartTerminal: {
        Title: 'Smart Terminal'
      },
      SmartTerminalMini: {
        Title: 'Smart Terminal Mini'
      },
      SmartPos: {
        Title: 'Smart POS+'
      },
      SmartPinPadPro: {
        Title: 'Smart PINPad Pro'
      },
      '3in1Reader': {
        Title: '3-in-1 Reader'
      }
    },
    MonthlyPrice: '{{price}}/mes',
    SetupPrice: '+{{price}} tarifa de alta'
  },
  TerminalsDetails: {
    Details: {
      Detail1: 'Pantallas táctiles duales HD.',
      Detail2: 'Acepte pagos sin contacto, tarjetas con chip y tarjetas con banda magnética.',
      Detail3: 'Escáner de código de barras integrado.',
      Detail4: 'Recibos impresos y digitales.',
      Detail5: '4G + wifi con transacciones fuera de línea.',
      Detail6: 'Se conecta a su teléfono inteligente o tableta a través de Bluetooth.',
      Detail7: 'Recibos digitales.',
      Detail8: 'Terminal de pago externo.',
      Detail9: 'Wifi con transacciones fuera de línea.'
    }
  },
  DraftNotification: {
    Title: 'Borrador Guardado',
    Description: 'Encuéntrelo en Facturas > Borradores.'
  },
  BatchesTable: {
    Date: 'Fecha',
    BatchNumber: 'Lote #',
    Sales: 'Ventas',
    SalesAmount: 'Monto de las Ventas',
    Refunds: 'Reembolsos',
    RefundsAmount: 'Monto de los Reembolsos',
    TotalTransactions: 'Total Transacciones',
    GrossAmount: 'Monto Bruto',
    NetAmount: 'Monto Neto'
  },
  BatchDetailsTable: {
    Date: 'Fecha',
    Time: 'Tiempo',
    Brand: 'Marca',
    Card: 'Tarjeta',
    Type: 'Tipo',
    Process: 'Proceso',
    Total: 'Total',
    ProcessType: {
      Sale: 'Venta',
      Refund: 'Reembolso'
    }
  },
  BatchDetailsSummary: {
    ViewBatchDetails: 'Ver detalles de lote',
    ExportBatchDetails: 'Exportar detalles del lote',
    PrintBatchDetails: 'Imprimir detalles del lote',
    CardTypeBreakdown: 'Desglose del tipo de tarjeta',
    PaymentMethodBreakdown: 'Desglose del método de pago',
    Visa: 'Visa',
    Mastercard: 'Mastercard',
    AmericanExpress: 'American Express',
    Discover: 'Discover',
    Debit: 'Débito',
    EBT: 'EBT',
    Other: 'Otro',
    Swiped: 'Deslizada',
    Keyed: 'Manual',
    Voice: 'Voz',
    Online: 'En línea',
    TooManyTransactionsWarning: 'Este lote contiene demasiadas transacciones para mostrar los resúmenes desglosados del tipo de tarjeta y del método de pago. Para ver esos resúmenes desglosados, utilice la sección Informes'
  },
  BulkInvoices: {
    CreateBulkInvoices: 'Crea facturas masivas.',
    DownloadOurTemplate: 'Descargue nuestra plantilla para preparar su archivo de facturas.',
    DownloadOurTemplateInstructions: 'Para cada factura a crear, simplemente agregue una nueva fila a la hoja de cálculo.',
    ImportFile: 'Importe el archivo de facturas.',
    ImportFileInstructions: 'Utilice el enlace Importar facturas en esta página para cargar su archivo de facturas. Las facturas se crearán automáticamente.',
    SendWithOneClick: 'Envíe con un clic.',
    SendWithOneClickInstructions: 'Revise las facturas importadas como borradores. Cuando esté listo, envíe de forma masiva con solo un clic.',
    BulkInvoicing: 'Facturación masiva',
    ReviewInvoices: 'Revisar facturas.',
    DiscardUnsentInvoices: 'Descartar facturas no enviadas',
    SendInvoicesButton: 'Enviar {{total}} facturas',
    SendInvoicesDisabledButton: 'Enviar facturas',
    UploadInProgress: 'Carga en progreso.',
    Uploading: 'Procesando.',
    UploadComplete: 'Carga completa.',
    UploadFailed: 'Carga fallida.',
    MissingHeaderRow: 'Fila de encabezado no presente.',
    MissingHeaderRowDescription: 'Falta una fila de encabezado en el archivo que ha subido. Por favor súbalo nuevamente una vez que se corrija el error.',
    MissingColumns: 'Columnas Faltantes.',
    MissingColumnsDescription: 'Faltan una o más columnas en el archivo que ha subido. Verifique el archivo y vuelva a subirlo.',
    BulkInvoicesId: 'Facturación masiva',
    CreatedAt : 'Fecha de creación {{date}}',
    InvoicesReadyToSend: '{{count}} facturas están listas para enviarse.',
    InvoicesError: 'Detectamos errores con {{count}} facturas.',
    InvoicesErrorDescription: 'Sin embargo, aún puede enviar las {{count}} facturas importadas correctamente.',
    ReadyToSend: 'Listos para enviar',
    Errors: 'Errores',
    ErrorDescription: 'Para solucionar errores más fácilmente, descargue el archivo y vuelva a cargarlo una vez que se corrijan los errores. Recomendamos enviar primero las facturas importadas correctamente desde la pestaña "Listos para enviar" y luego cargar las facturas corregidas en una tarea separada.',
    DownloadWithErrors: 'Descargar facturas con errores',
    UploadErrorDescription: 'Ocurrió un error al procesar su archivo. Asegúrese de que su archivo utilice la plantilla proporcionada. No agregue ni elimine columnas, ni cambie los nombres de las columnas. Vuelva a cargarlo una vez que se corrijan los errores.',
    ErrorHeaders: {
      'Customer Email Address': 'Dirección de correo electrónico del cliente',
      'Customer Phone Number': 'Número de teléfono del cliente',
      'Customer First Name': 'Nombre del cliente',
      'Customer Last Name': 'Apellido del cliente',
      'Invoice Name': 'Nombre de la factura',
      'Invoice Number': 'Número de factura',
      'Description': 'Descripción',
      'Payment Amount': 'Monto del pago',
      'Tax Rate': 'Tasa de impuesto',
      'Cash Discounting': 'Descuento por Efectivo Habilitado',
      'Allow customer to add tip': 'Permitir al cliente agregar propina',
      'Frequency': 'Frecuencia',
      'Send Date': 'Fecha de envio',
      'Last Send Date': 'Última fecha de envío',
      'Repeat Every': 'Repite cada',
      'Cadence': 'Cadencia',
      'Due Date': 'Fecha de vencimiento',
      'Payment Method': 'Método de pago'
    },
    ConfirmationDialogDescription: 'Una vez que haga clic en enviar, todas las facturas se enviarán a los clientes y esta acción no se podrá deshacer. ¿Esta seguro que desea continuar?',
    ConfirmationDialogSendButton: 'Sí, enviar {{total}} facturas',
    ConfirmationDialogNotSendButton: 'No, no enviar',
    ConfirmationPageHeader: '{{total}} facturas estan siendo creadas.',
    ConfirmationPageDescription: 'Las facturas con fecha de envío de hoy se enviarán a los clientes de inmediato. Se programarán facturas con fecha de envío futura. Cuando se crean todas las facturas, puede verlas en',
    ConfirmationPageDescriptionSecondLine: 'Puede hacer clic en "Continuar" ahora para importar un nuevo archivo.',
    ConfirmationPageCardInvoices:'Facturas Importadas',
    ConfirmationPageCardInvoicesSending: 'Facturas enviándose',
    ConfirmationPageCardCustomers: 'Nuevos clientes creados',
    ConfirmationPageCardVolume: 'Volumen total de facturas',
    ConfirmationPageCardErrorCount: '{{count}} facturas no se enviaron debido a errores.',
    ConfirmationPageCardErrorDescription: 'Descargue el archivo de error a continuación. Después de corregir los errores, cárguelo en una tarea separada.',
    ProgressDialogDescription: 'Espere mientras importamos sus datos. Podrá revisar las facturas importadas antes de enviarlas a los clientes.',
    ProgressDialogCancelButton: 'Cancelar carga',
    DiscardDialogDescription: 'Descartar facturas no enviadas borrará todas las facturas importadas en esta página. ¿Esta seguro que desea continuar?',
    DiscardDialogOkButton: 'Sí, descartar facturas no enviadas',
    DiscardDialogCancelSendButton: 'No, no descartar',
    BulkInvoicesTooltip: 'Crear Facturas Masivas',
    BulkInvoicesTooltipText: 'Facturas masivas le permite crear y enviar múltiples facturas al mismo tiempo al subir un archivo o usando nuestra plantilla. Si necesita crear muchas facturas, ¡esto le ahorrará tiempo!',
    Retrieving: 'Obteniendo'
  },
  AchAuthorization: {
    Mail: 'Correo',
    Email: 'Correo electrónico',
    Telephone: 'Teléfono',
    NotificationLength5: 'Cinco (5) días',
    NotificationLength10: 'Diez (10) días',
    NotificationLength30: 'Treinta (30) días',
    Title: 'Autorización ACH',
    ChannelDescription: '¿Cómo prefiere recibir una notificación de un cliente para revocar la autorización de un pago ACH recurrente?',
    LengthDescription: '¿Con cuánta anticipación necesita recibir una notificación de un cliente para revocar la autorización de un pago ACH recurrente?',
  }
};
